import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, SubmissionError } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import { RELATORIO_MA_LIST_LOAD, RELATORIO_MA_UNLOAD, FINANCIAL_RELATORIO_MA_LOAD, RELATORIO_DETAILS_MA_LOAD, RELATORIO_DETAILS_MA_UNLOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
import SelectField from "../../components/Fields/SelectField";
import DropdownListField from "../../components/Fields/DropdownListField";
import MaskDecimal from "../../components/Util/MaskDecimal";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import SvgIcon from "react-icons-kit";
import MaskCNPJ from "../../components/Util/MaskCNPJ";
import jsPDF from "jspdf";

const months = [
  { label: "Selecione", value: "" },
  { label: "Janeiro", value: "01" },
  { label: "Fevereiro", value: "02" },
  { label: "Março", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Maio", value: "05" },
  { label: "Junho", value: "06" },
  { label: "Julho", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Setembro", value: "09" },
  { label: "Outubro", value: "10" },
  { label: "Novembro", value: "11" },
  { label: "Dezembro", value: "12" },
];

const years = [
  { label: "Selecione", value: "" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
];

class RelatorioDetranMA extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
    };
  }

  componentDidMount() {
    if (
      _.findIndex(this.props.currentUser.perfis, function (perfil) {
        return perfil === "ALIAS";
      }) > -1
    ) {
      Promise.resolve(api.Financial.filtroPorUf("MA")).then((all) => {
        this.props.onLoad(all);
      });
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  filtrar = (values) => {
    this.setState({ pesquisando: true });
    const errors = this.validateValues(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else {
      var props = this.props;
      var params = {
        mesAno: values.mesReferencia.concat(values.anoReferencia),
        cnpjFinanceira: values.agente.cnpj,
      };
      Promise.resolve(api.Financial.registrosDetranMA(params))
        .then((ret) => {
          this.props.onListLoad(ret);
          if (Object.keys(ret).length === 0) {
            toastr.warning("Atenção", "Nenhum registro encontrado.");
          }
          this.setState({ pesquisando: false });
        })
        .catch(function (resp) {
          this.setState({ pesquisando: false });
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  };

  validateValues(values) {
    const errors = {};

    if (!values.anoReferencia || values.anoReferencia.length === 0 || !values.anoReferencia.trim()) {
      errors.anoReferencia = "Campo obrigatório";
    }
    if (!values.mesReferencia || values.mesReferencia.length === 0 || !values.mesReferencia.trim()) {
      errors.mesReferencia = "Campo obrigatório";
    }
    if (values.agente === undefined || !values.agente.cnpj) {
      errors.agente = "Campo obrigatório";
    }
    return errors;
  }

  exportToCSV() {
    const headersExcell = [["Contrato", "Data Registro", "Chassi", "CPF/CNPJ Proprietário", "Nome Proprietário"]];

    var DataExcel = this.props.detailsList.map((row) => {
      return {
        numContrato: row.numContrato,
        dataRegistro: row.dataRegistro,
        chassi: row.chassi,
        cpfCnpjProprietario: row.cpfCnpjProprietario,
        nomeProprietario: row.nomeProprietario,
      };
    });

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `ContratosRelatorioMA.xlsx`);
  }

  exportToPDF() {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 50;

    const headers = [["Contrato", "Data Registro", "Chassi", "CPF/CNPJ Proprietário", "Nome Proprietário"]];

    var title = `Relatório de contratos MA `;

    title += `\n\nMês/Ano de Referência: ${this.props.mesReferencia}/${this.props.anoReferencia}`;
    startTableY = startTableY + 15;
    var razaoSocial = this.props.companies.filter((item) => item.cnpj === this.props.agente.cnpj)[0].razaoSocial;
    title = title + `\nCredor: ${razaoSocial}`;
    startTableY = startTableY + 15;
    title = title + `\nCnpj: ${MaskCNPJ.TO_CNPJ(this.props.agente.cnpj)}`;
    startTableY = startTableY + 15;

    const data = this.props.detailsList.map((row) => [row.numContrato, row.dataRegistro, row.chassi, row.cpfCnpjProprietario, row.nomeProprietario]);

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      //pageBreak: 'always',
      columnStyles: {
        0: { columnWidth: "wrap" },
        1: {
          columnWidth: "wrap",
        },
        2: {
          columnWidth: "wrap",
        },
        3: {
          columnWidth: "wrap",
        },
      },
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.save(`ContratosRelatorioMA.pdf`);
  }

  render() {
    //const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} />
        <div className="content">
          {!this.props.showDetails && (
            <div className="row">
              <div className="col-lg-6">
                <Field name="mesReferencia" required label="Mês de Referência" data={months} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
              <div className="col-lg-6">
                <Field name="anoReferencia" required label="Ano de referência" data={years} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
              <div className="col-lg-12">
                <div className="md-4">
                  <Field
                    name="agente"
                    blank={true}
                    required
                    label="Credor"
                    data={this.props.companies}
                    credor={true}
                    textField="razaoSocial"
                    valueField="cnpj"
                    component={DropdownListField}
                    type="text"
                  />
                </div>
                <div className="hpanel">
                  <Link className="btn btn-info" disabled={this.state.pesquisando} onClick={this.props.handleSubmit(this.filtrar.bind(this))}>
                    {" "}
                    {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                  </Link>
                  <div className="panel-heading">Consultar Cobranças DETRAN</div>
                  <div className="panel-body">
                    <div className="table-responsive">
                      {this.props.all && Object.keys(this.props.all).length > 0 ? (
                        <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th className="text-center align-middle">Cnpj Financeira</th>
                              <th className="text-center align-middle">Credor</th>
                              <th className="text-center align-middle">Qtde. Contratos</th>
                              <th className="text-center align-middle">Data Pagamento</th>
                              <th className="text-center align-middle">Valor Total</th>
                              <th className="text-center align-middle">Ação</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center align-middle">{this.props.all.cnpjFinanceira}</td>
                              <td className="text-center align-middle">{this.props.all.nomeFinanceira}</td>
                              <td className="text-center align-middle">{this.props.all.quantContratos}</td>
                              <td className="text-center align-middle">{MaskDate.TO_BR(this.props.all.dataPagamento)}</td>
                              <td className="text-center align-middle">{MaskDecimal.TO_BRL(this.props.all.valorGuia)}</td>
                              <td className="align-middle text-center">
                                <div className="btn-group-vertical">
                                  <Link onClick={() => this.props.onDetailsLoad(this.props.all.itens)} target="_blank" className="btn btn-sm btn-default">
                                    <i className="fas fa-search" /> Detalhar
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <small>Nenhum Relatório Encontrado</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.props.showDetails && (
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-right">
                    {this.props.detailsList && this.props.detailsList.length > 0 && (
                      <Link className="btn btn-default btn-sm" onClick={() => this.exportToPDF()} title="Gerar PDF">
                        {" "}
                        <SvgIcon icon={filePdf} />
                      </Link>
                    )}
                    {this.props.detailsList && this.props.detailsList.length > 0 && (
                      <Link className="btn btn-default btn-sm" onClick={() => this.exportToCSV()} title="Gerar XLS">
                        {" "}
                        <SvgIcon icon={fileExcelO} />
                      </Link>
                    )}
                    <Link className="btn btn-default btn-sm" onClick={() => this.props.onDetailsUnload()}>
                      <i className="fas fa-arrow-left"></i> Voltar{" "}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="hpanel">
                    <div className="panel-body">
                      <div className="table-responsive">
                        {this.props.detailsList && this.props.detailsList.length > 0 ? (
                          <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th className="text-center align-middle">Contrato</th>
                                <th className="text-center align-middle">Data Registro</th>
                                <th className="text-center align-middle">Chassi</th>
                                <th className="text-center align-middle">CPF/CNPJ Proprietário</th>
                                <th className="text-center align-middle">Nome Proprietário</th>
                              </tr>
                            </thead>
                            <tbody>{this.renderDetails()}</tbody>
                          </table>
                        ) : (
                          <small>Nenhum Relatório Encontrado</small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderDetails() {
    if (!this.props.detailsList) {
      return null;
    }
    return this.props.detailsList.map((item, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{item.numContrato}</td>
          <td className="text-center align-middle">{item.dataRegistro}</td>
          <td className="text-center align-middle">{item.chassi}</td>
          <td className="text-center align-middle">{item.cpfCnpjProprietario}</td>
          <td className="text-center align-middle">{item.nomeProprietario}</td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: FINANCIAL_RELATORIO_MA_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: RELATORIO_MA_LIST_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: RELATORIO_MA_UNLOAD, payload }),
  onDetailsLoad: (payload) => dispatch({ type: RELATORIO_DETAILS_MA_LOAD, payload }),
  onDetailsUnload: () => dispatch({ type: RELATORIO_DETAILS_MA_UNLOAD }),
});

const selector = formValueSelector("RelatorioDetranMAForm");
const mapStateToProps = (state) => ({
  ...state.relatorioDetranMA,
  currentUser: state.common.currentUser,
  agente: selector(state, "agente"),
  anoReferencia: selector(state, "anoReferencia"),
  mesReferencia: selector(state, "mesReferencia"),
});

const form = reduxForm({
  form: "RelatorioDetranMAForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(RelatorioDetranMA));
