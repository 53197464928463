import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, untouch } from "redux-form";
import _ from "lodash";
import Title from "../../components/Layout/Title";
import { Link, browserHistory } from "react-router";
import { CONFIG_UF_SYSTEM_LOAD, CONFIG_UF_SYSTEM_UNLOAD, CONFIG_UF_EMAIL_LOAD } from "../../actions/Constants";
import TextField from "../../components/Fields/TextField";
import api from "../../api/apiUtil";
import { toastr } from "react-redux-toastr";
import MaskDate from "../../components/Util/MaskDate";
import FieldValidation from "../../components/Validation/FieldValidation";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import SvgIcon from "react-icons-kit";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import TextareaField from "../../components/Fields/TextareaField";

class ConfiguracaoUf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editando: false,
    };
  }

  componentDidMount() {
    this.props.onUfSystemLoad(Promise.resolve(api.Ufs.allCollumns()));
  }

  componentWillUnmount() {
    this.props.onConfigUnload();
  }

  formSubmit(values) {
    var props = this.props;
    let dados = {
      sigla: values.uf,
      dataUltimoCredenciamento: MaskDate.TO_DATABASE(values.dataUltimoCredenciamento),
      dataFimCredenciamento: MaskDate.TO_DATABASE(values.dataFimCredenciamento),
      qtdDiasNotificar: values.qtdDiasNotificar,
      emailsNotificacao: values.emailsNotificacao,
    };

    Promise.resolve(api.Ufs.config(dados))
      .then((response) => {
        this.props.onUfSystemLoad(Promise.resolve(api.Ufs.allCollumns()));
        this.setState({ editando: false });
        toastr.success("Sucesso", "Configuração alterada.");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleOnCancel = () => (ev) => {
    ev.preventDefault();
    this.setState({ editando: false });
    this.props.dispatch(change("ConfiguracaoUfForm", "uf", null));
    this.props.dispatch(untouch("ConfiguracaoUfForm", "uf"));
    this.props.dispatch(change("ConfiguracaoUfForm", "dataUltimoCredenciamento", null));
    this.props.dispatch(untouch("ConfiguracaoUfForm", "dataUltimoCredenciamento"));
    this.props.dispatch(change("ConfiguracaoUfForm", "dataFimCredenciamento", null));
    this.props.dispatch(untouch("ConfiguracaoUfForm", "dataFimCredenciamento"));
    this.props.dispatch(change("ConfiguracaoUfForm", "qtdDiasNotificar", null));
    this.props.dispatch(untouch("ConfiguracaoUfForm", "qtdDiasNotificar"));
  };

  exportToXLSX = () => {
    var DataExcel = [];
    const headersExcell = [["UF", "DATA ÚLTIMO CREDENCIAMENTO", "DATA FIM CREDENCIAMENTO", "QTD DIAS RESTANTES", "NOTIFICAR COM"]];

    DataExcel = this.props.ufs.map((uf) => {
      return {
        sigla: uf.sigla,
        dataUltimoCredenciamento: uf.dataUltimoCredenciamento ? MaskDate.TO_BR(uf.dataUltimoCredenciamento) : "Não informada",
        dataFimCredenciamento: uf.dataFimCredenciamento ? MaskDate.TO_BR(uf.dataFimCredenciamento) : "Não informada",
        qtdDaysRemaining: uf.qtdDaysRemaining || uf.qtdDaysRemaining == "0" ? uf.qtdDaysRemaining : "-",
        qtdDiasNotificar: uf.qtdDiasNotificar ? uf.qtdDiasNotificar + " dias" : "Não informada",
      };
    });

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ListaConfiguracaoUFs");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `ListaConfiguracaoUFs.xlsx`);
  };

  handleOnSelectUf = (uf) => {
    Promise.resolve(api.Configuracao.getPorAtributo("EMAILS_CREDENCIAMENTO"))
      .then((response) => {
        this.setState({ editando: true });
        this.props.dispatch(change("ConfiguracaoUfForm", "uf", uf.sigla));
        this.props.dispatch(change("ConfiguracaoUfForm", "dataUltimoCredenciamento", MaskDate.TO_BR(uf.dataUltimoCredenciamento)));
        this.props.dispatch(change("ConfiguracaoUfForm", "dataFimCredenciamento", MaskDate.TO_BR(uf.dataFimCredenciamento)));
        this.props.dispatch(change("ConfiguracaoUfForm", "qtdDiasNotificar", uf.qtdDiasNotificar));
        this.props.dispatch(change("ConfiguracaoUfForm", "emailsNotificacao", response.valor));
        this.HandleScroll();
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  HandleScroll() {
    window.scrollTo(0, 0);
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                {this.state.editando && (
                  <div className="panel-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                      <div className="row">
                        <div className="col-md-1">
                          <Field name="uf" label="UF" component={TextField} type="text" disabled />
                        </div>
                        <div className="col-md-2">
                          <Field
                            name="dataUltimoCredenciamento"
                            label="Data último credenciamento"
                            component={TextDatePickerField}
                            type="text"
                            validate={[FieldValidation.date, FieldValidation.required]}
                            normalize={FieldNormalize.DATE}
                            required
                            placeholder="00/00/0000"
                          />
                        </div>
                        <div className="col-md-2">
                          <Field
                            name="dataFimCredenciamento"
                            label="Data fim credenciamento"
                            component={TextDatePickerField}
                            type="text"
                            validate={[FieldValidation.date, FieldValidation.required]}
                            normalize={FieldNormalize.DATE}
                            required
                            placeholder="00/00/0000"
                          />
                        </div>
                        <div className="col-md-2">
                          <Field
                            name="qtdDiasNotificar"
                            label="Notificar com"
                            component={TextField}
                            type="text"
                            normalize={FieldNormalize.NUMBER}
                            maxlength={3}
                            validate={[FieldValidation.required]}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Field
                            name="emailsNotificacao"
                            label="E-mails para notificação de vencimento (Comum para todas as ufs)"
                            component={TextareaField}
                            type="text"
                            rows={2}
                            validate={[FieldValidation.required]}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="text-right">
                            <Link className="btn btn-default" onClick={this.handleOnCancel()}>
                              Cancelar
                            </Link>
                            <Link className="btn btn-info" onClick={handleSubmit(this.formSubmit.bind(this))}>
                              {" "}
                              {"Alterar"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="hpanel">
                      <div className="panel-heading">UFs habilitadas</div>
                      <div className="panel-body">
                        {this.props.ufs && this.props.ufs.length > 0 && (
                          <Link className="btn btn-default float-right" onClick={() => this.exportToXLSX()} title="Gerar Excel">
                            {" "}
                            <SvgIcon size={20} icon={fileExcelO} />
                          </Link>
                        )}

                        <div className="table-responsive">
                          {this.props.ufs && this.props.ufs.length > 0 ? (
                            <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th className="text-center align-middle">UF</th>
                                  <th className="text-center align-middle">Data último credenciamento</th>
                                  <th className="text-center align-middle">Data fim credenciamento</th>
                                  <th className="text-center align-middle">QTD dias restantes</th>
                                  <th className="text-center align-middle">Notificar com</th>
                                  <th className="text-center align-middle">AÇÕES</th>
                                </tr>
                              </thead>
                              <tbody>{this.renderList()}</tbody>
                            </table>
                          ) : (
                            <small>Nenhum registro encontrado</small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderList() {
    if (!this.props.ufs) {
      return null;
    }
    return this.props.ufs.map((uf, index) => {
      return (
        <tr
          key={index}
          style={{
            backgroundColor: uf.qtdDaysRemaining < 0 ? "#ff5b5b" : uf.qtdDaysRemaining <= uf.qtdDiasNotificar ? "#ffc75b" : "",
            color: uf.qtdDaysRemaining < 0 || uf.qtdDaysRemaining <= uf.qtdDiasNotificar ? "black" : "",
          }}
        >
          <td className="text-center align-middle">{uf.sigla}</td>
          <td className="text-center align-middle">{uf.dataUltimoCredenciamento ? MaskDate.TO_BR(uf.dataUltimoCredenciamento) : "Não informada"}</td>
          <td className="text-center align-middle">{uf.dataFimCredenciamento ? MaskDate.TO_BR(uf.dataFimCredenciamento) : "Não informada"}</td>
          <td className="text-center align-middle">{uf.qtdDaysRemaining || uf.qtdDaysRemaining == "0" ? uf.qtdDaysRemaining : "-"}</td>
          <td className="text-center align-middle">{uf.qtdDiasNotificar ? uf.qtdDiasNotificar + " dias" : "Não informada"}</td>
          <td className="align-middle text-center">
            <div className="btn-group">
              <button className="btn btn-sm btn-default" onClick={() => this.handleOnSelectUf(uf)} title="Editar">
                <i className="far fa-edit"></i>
              </button>
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onUfSystemLoad: (payload) => dispatch({ type: CONFIG_UF_SYSTEM_LOAD, payload }),
  onConfigUnload: (payload) => dispatch({ type: CONFIG_UF_SYSTEM_UNLOAD, payload }),
  onUfEmailsLoad: (payload) => dispatch({ type: CONFIG_UF_EMAIL_LOAD, payload }),
});

const mapStateToProps = (state) => ({
  ...state.configuracaoUf,
});

const form = reduxForm({
  form: "ConfiguracaoUfForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ConfiguracaoUf));
