import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, formValueSelector } from "redux-form";
import { Link } from "react-router";
import _ from "lodash";

import {
  FINANCIAL_DASHBOARD_LIST_LOAD,
  FINANCIAL_DASHBOARD_LIST_UNLOAD,
  FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_LOAD,
  FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_7_LOAD,
  FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_15_LOAD,
  FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_30_LOAD,
  FINANCIAL_DASHBOARD_COMPANIES_LOAD,
  UF_SYSTEM_LOAD,
  FINANCIAL_DASHBOARD_NOT_REGISTRED_LOAD,
  FINANCIAL_DASHBOARD_REGISTRED_ERROR_LOAD,
  FINANCIAL_DASHBOARD_REGISTRED_WITHOUT_IMAGE_LOAD,
  FINANCIAL_DASHBOARD_REGISTRED_LOAD,
  FINANCIAL_DASHBOARD_CLEAR_IMAGENS_VISAO,
  FINANCIAL_INVALID,
  FINANCIAL_LIST_FILTER,
} from "../../actions/Constants";
import api from "../../api/apiUtil";
import MaskDate from "../../components/Util/MaskDate";

import Title from "../../components/Layout/Title";

import ReactTable from "react-table";
import { Bar } from "react-chartjs-2";
import { HorizontalBar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import MaskDecimal from "../../components/Util/MaskDecimal";
import { ic_attach_money } from "react-icons-kit/md/ic_attach_money";
import SvgIcon from "react-icons-kit";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import SelectField from "../../components/Fields/SelectField";
import DropdownListField from "../../components/Fields/DropdownListField";
import { stack } from "react-icons-kit/icomoon/stack";
import { BeatLoader } from "react-spinners";
import { toastr } from "react-redux-toastr";
import MaskCNPJ from "../../components/Util/MaskCNPJ";

const years = [
  { label: "Selecione", value: "" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
];

const trimestres = [
  { label: "Selecione", value: "" },
  { label: "Primeiro Timestre", value: "1" },
  { label: "Segundo Trimestre", value: "2" },
  { label: "Terceiro Trimestre", value: "3" },
  { label: "Quarto Trimestre", value: "4" },
];

const periodicidade = [
  { label: "Top 10", value: "1" },
  { label: "Top 20", value: "2" },
  { label: "Top 50", value: "3" },
  { label: "Todas com registro", value: "4" },
  { label: "Todas", value: "5" },
];

class FinancialDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      otherValuesSelected: false,
      selected: null,
      unit: null,
      top10: "all",
      credor: null,
      pesquisando: false,
      open: null,
      contractsNotRegistred: null,
      contractsRegistredError: null,
      contractsRegistredWithoutImage: null,
      contractsRegistred: null,
      contractsCancel: null,
    };
    this.myRef = null;
  }

  handleInputChange(value) {
    this.setState({ otherValuesSelected: !this.state.otherValuesSelected });
  }

  handleFilterChart(values) {
    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    var obj = {
      trimestre: values && values.trimestre && values.ano ? values.trimestre : null,
      ano: values && values.ano && values.trimestre ? values.ano : null,
      uf: values && values.uf ? values.uf : null,
    };

    Promise.resolve(api.Management.dashboardFinancial(obj.trimestre, obj.ano, obj.uf, this.props.currentUser.idParceiro)).then(
      async (res) => {
        res.financeirasAgrupadas.sort((a, b) => (a.qtdMes3 < b.qtdMes3 ? 1 : a.qtdMes3 > b.qtdMes3 ? -1 : 0));
        this.props.onListLoad(res);
        this.props.onListLoadFilter(null);
        let resFilter = await this.filterListByParam({ ...this.props.all }, 20);
        this.props.dispatch(change("FinancialDashboardForm", "periodicidade", "2"));
        this.props.onListLoadFilter(resFilter);
        this.setState({ pesquisando: false });
        toastr.removeByType("info");
        if (!res || (res.qtdTotalMes1 == 0 && res.qtdTotalMes2 == 0 && res.qtdTotalMes3 == 0)) {
          toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handleDefaultChart(values) {
    const { reset } = this.props;
    var uf = values.uf;
    console.log(this.props);
    Promise.resolve(api.Management.dashboardFinancial(null, null, values.uf, this.props.currentUser.idParceiro)).then(
      async (res) => {
        res.financeirasAgrupadas.sort((a, b) => (a.qtdMes3 < b.qtdMes3 ? 1 : a.qtdMes3 > b.qtdMes3 ? -1 : 0));
        this.props.onListLoad(res);
        this.props.onListLoadFilter(null);
        let resFilter = await this.filterListByParam({ ...this.props.all }, 20);
        this.props.dispatch(change("FinancialDashboardForm", "periodicidade", "2"));
        this.props.onListLoadFilter(resFilter);
        if (this.props.anyTouched) reset();
        this.props.dispatch(change("FinancialDashboardForm", "uf", uf));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  componentWillMount() {
    Promise.resolve(api.Management.dashboardFinancial(null, null, null, this.props.currentUser.idParceiro)).then(async (res) => {
      res.financeirasAgrupadas.sort((a, b) => (a.qtdMes3 < b.qtdMes3 ? 1 : a.qtdMes3 > b.qtdMes3 ? -1 : 0));
      this.props.onListLoad(res);
      let resFilter = await this.filterListByParam({ ...this.props.all }, 20);
      this.props.dispatch(change("FinancialDashboardForm", "periodicidade", "2"));
      this.props.onListLoadFilter(resFilter);
      this.props.onCompaniesLoad([{ cnpj: 0, razaoSocial: "Todos" }, ...res.financeiras]);
    });

    this.handleContractImages({ cnpj: 0 });
    this.props.onListLoadFinancialInvalid(api.Financial.listFinancialInvalid());
    if (this.props.currentUser.tipo && this.props.currentUser.tipo.includes("P")) {
      Promise.resolve(api.Financial.recuperaUfsParceiro(this.props.currentUser.idParceiro)).then((all) => {
        this.props.onUfSystemLoad([{ sigla: "Todas" }, ...all.ufs]);
      });
    } else {
      Promise.resolve(api.Ufs.sigla_system()).then((all) => {
        var todas = {
          sigla: "Todas",
        };
        all.unshift(todas);
        this.props.onUfSystemLoad(all);
      });
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  async filterListByParam(list, param) {
    let item = list;
    item.financeirasAgrupadas = item.financeirasAgrupadas.slice(0, param);
    item.qtdTotalMes1 = this.calculateSum(item.financeirasAgrupadas, "qtdMes1");
    item.qtdTotalMes2 = this.calculateSum(item.financeirasAgrupadas, "qtdMes2");
    item.qtdTotalMes3 = this.calculateSum(item.financeirasAgrupadas, "qtdMes3");
    item.valorDetranTotalMes1 = this.calculateSum(item.financeirasAgrupadas, "valorDetranMes1");
    item.valorDetranTotalMes2 = this.calculateSum(item.financeirasAgrupadas, "valorDetranMes2");
    item.valorDetranTotalMes3 = this.calculateSum(item.financeirasAgrupadas, "valorDetranMes3");
    item.valorERCTotalMes1 = this.calculateSum(item.financeirasAgrupadas, "valorERCMes1");
    item.valorERCTotalMes2 = this.calculateSum(item.financeirasAgrupadas, "valorERCMes2");
    item.valorERCTotalMes3 = this.calculateSum(item.financeirasAgrupadas, "valorERCMes3");
    return item;
  }

  calculateSum(array, property) {
    const total = array.reduce((accumulator, object) => {
      return accumulator + object[property];
    }, 0);
    return total;
  }

  async filtrarPeriodicidade(values) {
    let qtd = 0;
    let resFilter = null;
    if (values.periodicidade == "1") {
      qtd = 10;
    } else if (values.periodicidade == "2") {
      qtd = 20;
    } else if (values.periodicidade == "3") {
      qtd = 50;
    } else if (values.periodicidade == "4") {
      qtd = this.props.all.financeirasAgrupadas.length;
    } else {
      qtd = this.props.all.financeirasAgrupadas.length;
    }
    this.props.onListLoadFilter(null);
    if (values.periodicidade != "4") {
      resFilter = await this.filterListByParam({ ...this.props.all }, qtd);
      this.props.onListLoadFilter(resFilter);
    } else {
      resFilter = { ...this.props.all };
      resFilter.financeirasAgrupadas = resFilter.financeirasAgrupadas.filter((item) => item.qtdMes1 || item.qtdMes2 || item.qtdMes3);
      resFilter = await this.filterListByParam(resFilter, qtd);
      this.props.onListLoadFilter(resFilter);
    }
  }

  // renderHorizontalBarData1(data) {
  //   return (
  //     <HorizontalBar
  //       data={data}
  //       height={30 * data.datasets[0].data.length + 45}
  //       options={{
  //         tooltips: {
  //           titleFontColor: "#E0E0E0",
  //           callbacks: {
  //             label: function (tooltipItem, data) {
  //               var label = data.datasets[tooltipItem.datasetIndex].label || "";

  //               if (label && label === "Valor em R$") {
  //                 label += ": " + MaskDecimal.TO_BRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).substring(2);
  //               } else {
  //                 label += ": " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
  //               }
  //               return label;
  //             },
  //           },
  //         },
  //         legend: {
  //           display: false,
  //         },
  //         maintainAspectRatio: false,
  //       }}
  //     />
  //   );
  // }

  // renderHorizontalBarData2(data) {
  //   return (
  //     <HorizontalBar
  //       data={data}
  //       height={35 * data.datasets[0].data.length + 70}
  //       options={{
  //         tooltips: {
  //           titleFontColor: "#E0E0E0",
  //           callbacks: {
  //             label: function (tooltipItem, data) {
  //               var label = data.datasets[tooltipItem.datasetIndex].label || "";

  //               if (label && label.includes("Valor de")) {
  //                 label += ": " + MaskDecimal.TO_BRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).substring(2);
  //               } else {
  //                 label += ": " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
  //               }
  //               return label;
  //             },
  //           },
  //         },
  //         maintainAspectRatio: false,
  //       }}
  //     />
  //   );
  // }

  // handleChangeTop10(type) {
  //   window.scrollTo(0, this.myRef.offsetTop + 50);
  //   this.setState({ top10: type });
  // }

  // handleChangeTopFake10(type) {
  //   this.setState({ top10: type });
  // }

  exportToCSV = () => {
    var Heading = [
      ["", this.props.all.mes1.toUpperCase(), "", "", this.props.all.mes2.toUpperCase(), "", "", this.props.all.mes3.toUpperCase()],
      ["Financeiras", "Qtd", "R$ Alias", "R$ Detran", "Qtd", "R$ Alias", "R$ Detran", "Qtd", "R$ Alias", "R$ Detran"],
    ];
    var Data = [];

    this.props.all.financeiras.map((fin, index) => {
      Data.push({
        razaoSocial: fin.razaoSocial,
        qtd1: fin.qtdMes1,
        rsAlias1: fin.valorERCMes1,
        rsDetran1: fin.valorDetranMes1,
        qtd2: fin.qtdMes2,
        rsAlias2: fin.valorERCMes2,
        rsDetran2: fin.valorDetranMes2,
        qtd3: fin.qtdMes3,
        rsAlias3: fin.valorERCMes3,
        rsDetran3: fin.valorDetranMes3,
      });
    });
    Data.push(
      {
        razaoSocial: "",
        qtd1: "",
        rsAlias1: "",
        rsDetran1: "",
        qtd2: "",
        rsAlias2: "",
        rsDetran2: "",
        qtd3: "",
        rsAlias3: "",
        rsDetran3: "",
      },
      {
        razaoSocial: "Total",
        qtd1: this.props.all.qtdTotalMes1,
        rsAlias1: this.props.all.valorERCTotalMes1,
        rsDetran1: this.props.all.valorDetranTotalMes1,
        qtd2: this.props.all.qtdTotalMes2,
        rsAlias2: this.props.all.valorERCTotalMes2,
        rsDetran2: this.props.all.valorDetranTotalMes2,
        qtd3: this.props.all.qtdTotalMes3,
        rsAlias3: this.props.all.valorERCTotalMes3,
        rsDetran3: this.props.all.valorDetranTotalMes3,
      }
    );

    var ws = XLSX.utils.aoa_to_sheet(Heading);
    const merge = [
      { s: { r: 0, c: 1 }, e: { r: 0, c: 3 } },
      { s: { r: 0, c: 4 }, e: { r: 0, c: 6 } },
      { s: { r: 0, c: 7 }, e: { r: 0, c: 9 } },
    ];
    ws["!merges"] = merge;
    XLSX.utils.sheet_add_json(ws, Data, {
      header: ["razaoSocial", "qtd1", "rsAlias1", "rsDetran1", "qtd2", "rsAlias2", "rsDetran2", "qtd3", "rsAlias3", "rsDetran3"],
      skipHeader: true,
      origin: "A3",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), "DashboardFinanceiras-Tabela.xlsx");
  };

  exportToCSVGroup = () => {
    var Heading = [
      ["", this.props.all.mes1.toUpperCase(), "", "", this.props.all.mes2.toUpperCase(), "", "", this.props.all.mes3.toUpperCase()],
      ["Financeiras", "Qtd", "R$ Alias", "R$ Detran", "Qtd", "R$ Alias", "R$ Detran", "Qtd", "R$ Alias", "R$ Detran"],
    ];
    var Data = [];

    this.props.all.financeirasAgrupadas.map((fin, index) => {
      Data.push({
        razaoSocial: fin.razaoSocial,
        qtd1: fin.qtdMes1,
        rsAlias1: fin.valorERCMes1,
        rsDetran1: fin.valorDetranMes1,
        qtd2: fin.qtdMes2,
        rsAlias2: fin.valorERCMes2,
        rsDetran2: fin.valorDetranMes2,
        qtd3: fin.qtdMes3,
        rsAlias3: fin.valorERCMes3,
        rsDetran3: fin.valorDetranMes3,
      });
    });
    Data.push(
      {
        razaoSocial: "",
        qtd1: "",
        rsAlias1: "",
        rsDetran1: "",
        qtd2: "",
        rsAlias2: "",
        rsDetran2: "",
        qtd3: "",
        rsAlias3: "",
        rsDetran3: "",
      },
      {
        razaoSocial: "Total",
        qtd1: this.props.all.qtdTotalMes1,
        rsAlias1: this.props.all.valorERCTotalMes1,
        rsDetran1: this.props.all.valorDetranTotalMes1,
        qtd2: this.props.all.qtdTotalMes2,
        rsAlias2: this.props.all.valorERCTotalMes2,
        rsDetran2: this.props.all.valorDetranTotalMes2,
        qtd3: this.props.all.qtdTotalMes3,
        rsAlias3: this.props.all.valorERCTotalMes3,
        rsDetran3: this.props.all.valorDetranTotalMes3,
      }
    );

    var ws = XLSX.utils.aoa_to_sheet(Heading);
    const merge = [
      { s: { r: 0, c: 1 }, e: { r: 0, c: 3 } },
      { s: { r: 0, c: 4 }, e: { r: 0, c: 6 } },
      { s: { r: 0, c: 7 }, e: { r: 0, c: 9 } },
    ];
    ws["!merges"] = merge;
    XLSX.utils.sheet_add_json(ws, Data, {
      header: ["razaoSocial", "qtd1", "rsAlias1", "rsDetran1", "qtd2", "rsAlias2", "rsDetran2", "qtd3", "rsAlias3", "rsDetran3"],
      skipHeader: true,
      origin: "A3",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), "DashboardFinanceiras-Tabela.xlsx");
  };

  // exportChartToCSV = (type) => {
  //   if (type === "chart1") {
  //     var Heading = [["Financeiras", "Quantidade"]];
  //     var Data = [];

  //     this.props.all.financeiras.map((fin, index) => {
  //       Data.push({
  //         razaoSocial: fin.razaoSocial,
  //         qtd3: fin.qtdMes3,
  //       });
  //     });
  //     Data.push(
  //       { razaoSocial: "", qtd3: "" },
  //       {
  //         razaoSocial: "Total",
  //         qtd3: this.props.all.qtdTotalMes3,
  //       }
  //     );

  //     var ws = XLSX.utils.aoa_to_sheet(Heading);
  //     XLSX.utils.sheet_add_json(ws, Data, {
  //       header: ["razaoSocial", "qtd3"],
  //       skipHeader: true,
  //       origin: "A2",
  //     });

  //     var wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, "Resultado");
  //     var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), "DashboardFinanceiras-Geral-MesAtual.xlsx");
  //   } else if (type === "chart2") {
  //     var Heading = [["Financeiras", `Quantidade - ${this.props.all.mes1.toUpperCase()}`, `Quantidade - ${this.props.all.mes2.toUpperCase()}`, `Quantidade - ${this.props.all.mes3.toUpperCase()}`]];
  //     var Data = [];

  //     this.props.all.financeiras.map((fin, index) => {
  //       Data.push({
  //         razaoSocial: fin.razaoSocial,
  //         qtd1: fin.qtdMes1,
  //         qtd2: fin.qtdMes2,
  //         qtd3: fin.qtdMes3,
  //       });
  //     });
  //     Data.push(
  //       { razaoSocial: "", qtd1: "", qtd2: "", qtd3: "" },
  //       {
  //         razaoSocial: "Total",
  //         qtd1: this.props.all.qtdTotalMes1,
  //         qtd2: this.props.all.qtdTotalMes2,
  //         qtd3: this.props.all.qtdTotalMes3,
  //       }
  //     );

  //     var ws = XLSX.utils.aoa_to_sheet(Heading);
  //     XLSX.utils.sheet_add_json(ws, Data, {
  //       header: ["razaoSocial", "qtd1", "qtd2", "qtd3"],
  //       skipHeader: true,
  //       origin: "A2",
  //     });

  //     var wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, "Resultado");
  //     var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), "DashboardFinanceiras-Geral-Ultimos3Meses.xlsx");
  //   } else if (type === "chart3") {
  //     var Heading = [[`Quantidade - ${this.props.all.mes1.toUpperCase()}`, `Quantidade - ${this.props.all.mes2.toUpperCase()}`, `Quantidade - ${this.props.all.mes3.toUpperCase()}`]];
  //     var Data = [];

  //     Data.push({
  //       qtdMes1: this.state.selected._original.qtdMes1,
  //       qtdMes2: this.state.selected._original.qtdMes2,
  //       qtdMes3: this.state.selected._original.qtdMes3,
  //     });

  //     var ws = XLSX.utils.aoa_to_sheet(Heading);
  //     XLSX.utils.sheet_add_json(ws, Data, {
  //       header: ["qtdMes1", "qtdMes2", "qtdMes3"],
  //       skipHeader: true,
  //       origin: "A2",
  //     });

  //     var wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, "Resultado");
  //     var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `DashboardFinanceiras-Unitário-${this.state.selected._original.razaoSocial}-Ultimos3Meses.xlsx`);
  //   } else if (type === "chart4") {
  //     var Heading = [["Dia", "Quantidade"]];
  //     var Data = [];

  //     const filteredDays = this.state.selected._original.qtdPorDia.filter((row, index) => new Date().getDate() >= index + 1);
  //     Data = filteredDays.map((row, index) => {
  //       return { dia: index + 1, qtd: row };
  //     });

  //     var ws = XLSX.utils.aoa_to_sheet(Heading);
  //     XLSX.utils.sheet_add_json(ws, Data, {
  //       header: ["dia", "qtd"],
  //       skipHeader: true,
  //       origin: "A2",
  //     });

  //     var wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, "Resultado");
  //     var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `DashboardFinanceiras-Unitário-${this.state.selected._original.razaoSocial}-MesAtual.xlsx`);
  //   }
  // };

  exportToPDF(type) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 50;

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    var drawCell = function (data) {
      var doc = data.doc;
      var rows = data.table.body;
      if (data.row.index === rows.length - 1) {
        doc.setTextColor(255, 255, 255);
        doc.setFontStyle("bold");
        doc.setFillColor(80, 80, 80);
      }
    };

    if (type === "table") {
      var title = `Relatório de Contratos E-Registro`;

      const head = [
        [
          { content: "", colSpan: 1 },
          { content: this.props.all.mes1, colSpan: 3 },
          { content: this.props.all.mes2, colSpan: 3 },
          { content: this.props.all.mes3, colSpan: 3 },
        ],
        ["Financeiras", `Qtd`, `R$ Alias`, `R$ Detran`, `Qtd`, `R$ Alias`, `R$ Detran`, `Qtd`, `R$ Alias`, `R$ Detran`],
      ];

      const data = this.props.all.financeiras.map((row) => [
        row.razaoSocial,
        row.qtdMes1,
        MaskDecimal.TO_BRL(row.valorERCMes1),
        MaskDecimal.TO_BRL(row.valorDetranMes1),
        row.qtdMes2,
        MaskDecimal.TO_BRL(row.valorERCMes2),
        MaskDecimal.TO_BRL(row.valorDetranMes2),
        row.qtdMes3,
        MaskDecimal.TO_BRL(row.valorERCMes3),
        MaskDecimal.TO_BRL(row.valorDetranMes3),
      ]);

      const dataWithFinal = [
        ...data,
        [
          "TOTAL",
          this.props.all.qtdTotalMes1,
          MaskDecimal.TO_BRL(this.props.all.valorERCTotalMes1),
          MaskDecimal.TO_BRL(this.props.all.valorDetranTotalMes1),
          this.props.all.qtdTotalMes2,
          MaskDecimal.TO_BRL(this.props.all.valorERCTotalMes2),
          MaskDecimal.TO_BRL(this.props.all.valorDetranTotalMes2),
          this.props.all.qtdTotalMes3,
          MaskDecimal.TO_BRL(this.props.all.valorERCTotalMes3),
          MaskDecimal.TO_BRL(this.props.all.valorDetranTotalMes3),
        ],
      ];

      let content = {
        startY: startTableY,
        willDrawCell: drawCell,
        head,
        body: dataWithFinal,
        bodyStyles: { valign: "top" },
        styles: {
          cellPadding: 1.5,
          overflow: "linebreak",
          valign: "middle",
          halign: "center",
          lineColor: [0, 0, 0],
          lineWidth: 0.2,
        },
        didDrawPage: function (data) {
          footer();
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(`DashboardFinanceiras-Tabela.pdf`);
    } else if (type === "chart") {
      if (this.state.unit) {
        var titleUnitTable1 = `Relatório de Contratos E-Registro - ${this.state.selected._original.razaoSocial} - 3 Meses`;

        const headUnitTable1 = [[{ content: `Quantidade - ${this.props.all.mes1}` }, { content: `Quantidade - ${this.props.all.mes2}` }, { content: `Quantidade - ${this.props.all.mes3}` }]];

        const dataUnitTable1 = [[this.state.selected._original.qtdMes1, this.state.selected._original.qtdMes2, this.state.selected._original.qtdMes3]];

        let contentUnitTable1 = {
          startY: startTableY,
          head: headUnitTable1,
          body: dataUnitTable1,
          bodyStyles: { valign: "top" },
          styles: {
            cellPadding: 1.5,
            overflow: "linebreak",
            valign: "middle",
            halign: "center",
            lineColor: [0, 0, 0],
            lineWidth: 0.2,
          },
          didDrawPage: function (data) {
            footer();
          },
        };

        doc.autoTable(contentUnitTable1);

        doc.text(titleUnitTable1, marginLeft, 30);

        //Segunda tabela do tipo unitário

        const headUnitTable2 = [[{ content: "Dia" }, { content: "Quantidade" }]];

        const filteredDays = this.state.selected._original.qtdPorDia.filter((row, index) => new Date().getDate() >= index + 1);
        const dataUnitTable2 = filteredDays.map((row, index) => [index + 1, row]);

        var titleUnitTable2 = `Relatório de Contratos E-Registro - ${this.state.selected._original.razaoSocial} - Mês - ${this.props.all.mes3}`;

        doc.text(titleUnitTable2, marginLeft, doc.autoTable.previous.finalY + 30);

        let contentUnitTable2 = {
          startY: doc.autoTable.previous.finalY + 50,
          head: headUnitTable2,
          body: dataUnitTable2,
          bodyStyles: { valign: "top" },
          styles: {
            cellPadding: 1.5,
            overflow: "linebreak",
            valign: "middle",
            halign: "center",
            lineColor: [0, 0, 0],
            lineWidth: 0.2,
          },
          didDrawPage: function (data) {
            footer();
          },
        };
        doc.autoTable(contentUnitTable2);

        doc.save(`RelatorioContratos-${this.state.selected._original.razaoSocial}-UnitarioMes-${this.props.all.mes3}.pdf`);
      } else {
        var titleTable1 = `Relatório de Contratos E-Registro - Geral Mês`;

        const headTable1 = [[{ content: "Financeira" }, { content: "Quantidade" }]];

        const dataTable1 = this.props.all.financeiras.map((row) => [row.razaoSocial, row.qtdMes3]);

        let contentTable1 = {
          startY: startTableY,
          head: headTable1,
          body: dataTable1,
          bodyStyles: { valign: "top" },
          styles: {
            cellPadding: 1.5,
            overflow: "linebreak",
            valign: "middle",
            halign: "center",
            lineColor: [0, 0, 0],
            lineWidth: 0.2,
          },
          didDrawPage: function (data) {
            footer();
          },
        };

        doc.autoTable(contentTable1);

        doc.text(titleTable1, marginLeft, 30);

        const headTable2 = [
          [{ content: "Financeira" }, { content: `Quantidade - ${this.props.all.mes1}` }, { content: `Quantidade - ${this.props.all.mes2}` }, { content: `Quantidade - ${this.props.all.mes3}` }],
        ];

        const dataTable2 = this.props.all.financeiras.map((row) => [row.razaoSocial, row.qtdMes1, row.qtdMes2, row.qtdMes3]);

        var titleTable2 = `Relatório de Contratos E-Registro - 3 Meses`;
        doc.text(titleTable2, marginLeft, doc.autoTable.previous.finalY + 30);

        let contentTable2 = {
          startY: doc.autoTable.previous.finalY + 50,
          head: headTable2,
          body: dataTable2,
          bodyStyles: { valign: "top" },
          styles: {
            cellPadding: 1.5,
            overflow: "linebreak",
            valign: "middle",
            halign: "center",
            lineColor: [0, 0, 0],
            lineWidth: 0.2,
          },
          didDrawPage: function (data) {
            footer();
          },
        };
        doc.autoTable(contentTable2);
        doc.save(`RelatorioContratos-GeralMes-${this.props.all.mes3}.pdf`);
      }
    }
  }

  exportFinInvalidasToCSV() {
    var head = [["Razão Social", "UF", "Data último registro", "Financeira Mãe"]];

    var ref = ["razaoSocial", "uf", "ultimaCobranca", "financeiraMae"];
    var fileName = "RelatorioFinanceirasInativas";
    var data = [];

    for (const fin of this.props.listFinancialInvalid) {
      var result = fin.financeiraFilha.map((row) => {
        return {
          razaoSocial: row.filha,
          uf: row.uf,
          ultimaCobranca: MaskDate.TO_BR(row.ultimaCobranca),
          financeiraMae: row.raiz !== row.filha ? row.raiz : "",
        };
      });
      data = data.concat(result);
    }

    var ws = XLSX.utils.aoa_to_sheet(head);
    XLSX.utils.sheet_add_json(ws, data, {
      header: ref,
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${fileName}.xlsx`);
  }

  async filterUf(value) {
    const { reset } = this.props;
    var uf = value.uf;
    await Promise.resolve(api.Management.dashboardFinancial(null, null, value.uf, this.props.currentUser.idParceiro)).then(async (res) => {
      res.financeirasAgrupadas.sort((a, b) => (a.qtdMes3 < b.qtdMes3 ? 1 : a.qtdMes3 > b.qtdMes3 ? -1 : 0));
      this.props.onListLoad(res);
      this.props.onListLoadFilter(null);
      let resFilter = await this.filterListByParam({ ...this.props.all }, 20);
      this.props.dispatch(change("FinancialDashboardForm", "periodicidade", "2"));
      this.props.onListLoadFilter(resFilter);
      this.props.onCompaniesLoad([{ cnpj: 0, razaoSocial: "Todos" }, ...res.financeiras]);
      if (this.props.anyTouched) reset();
      this.props.dispatch(change("FinancialDashboardForm", "uf", uf));
    });

    this.props.onContractClearImageVisao();
    //document.getElementById("top").click();
    //document.getElementById("geral").click();
    this.setState({
      contractsNotRegistred: 0,
      contractsRegistredError: 0,
      contractsRegistredWithoutImage: 0,
      contractsRegistred: 0,
      contractsCancel: 0,
    });
    //this.handleContractImages({cnpj:0, uf:value.uf});
  }

  async handleCotractView(values) {
    if (values.cnpj2) {
      this.setState({ credor: values.cnpj2.cnpj, pesquisando: true });
      toastr.info("Aguarde", "Realizando consulta...", {
        timeOut: 0,
        component: () => {
          return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
        },
      });
      var props = this.props;
      await Promise.resolve(api.Contracts.getQtdVisaoGeral(values.cnpj2.cnpj, values.uf == "Todas" ? undefined : values.uf))
        .then((res) => {
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (res.length > 0) {
            this.setState({
              contractsNotRegistred: res.filter((element) => element.status == "naoEnviadoEmProc")[0],
              contractsRegistredError: res.filter((element) => element.status == "comErro")[0],
              contractsRegistredWithoutImage: res.filter((element) => element.status == "registradoSemImagem")[0],
              contractsRegistred: res.filter((element) => element.status == "registrado")[0],
              contractsCancel: res.filter((element) => element.status == "canceladoBaixado")[0],
            });
          } else {
            toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
          }
        })
        .catch((e) => {
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (e === "Unauthorized" || e === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  }

  handleContractImages(values) {
    if (values.cnpj) {
      this.setState({ credor: values.cnpj.cnpj, pesquisando: true });
      toastr.info("Aguarde", "Realizando consulta...", {
        timeOut: 0,
        component: () => {
          return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
        },
      });

      var props = this.props;
      Promise.resolve(api.Contracts.getQtdImagensContratos(values.cnpj.cnpj, values.uf == "Todas" ? undefined : values.uf))
        .then((res) => {
          let contractsExpired = {
            qtd: 0,
          };
          let contractsExpired7 = {
            qtd: 0,
          };
          let contractsExpired15 = {
            qtd: 0,
          };
          let contractsExpired30 = {
            qtd: 0,
          };
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (res.length > 0) {
            contractsExpired.qtd += res.filter((element) => element.vencido == "vencido30")[0].qtd;
            contractsExpired.data = res.filter((element) => element.vencido == "vencido30")[0].data;
            contractsExpired7.qtd += res.filter((element) => element.vencido == "vencerEm7")[0].qtd;
            contractsExpired7.data = res.filter((element) => element.vencido == "vencerEm7")[0].data;
            contractsExpired15.qtd += res.filter((element) => element.vencido == "vencerEm15")[0].qtd;
            contractsExpired15.data = res.filter((element) => element.vencido == "vencerEm15")[0].data;
            contractsExpired30.qtd += res.filter((element) => element.vencido == "vencerEm30")[0].qtd;
            contractsExpired30.data = res.filter((element) => element.vencido == "vencerEm30")[0].data;
          } else {
            toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
          }
          this.props.onContractsExpiredLoad(contractsExpired);
          this.props.onContractsExpired7Load(contractsExpired7);
          this.props.onContractsExpired15Load(contractsExpired15);
          this.props.onContractsExpired30Load(contractsExpired30);
        })
        .catch((e) => {
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (e === "Unauthorized" || e === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  }

  render() {
    const { handleSubmit, reset } = this.props;
    const { otherValuesSelected, selected } = this.state;
    // var data1,
    //   data2,
    //   data3,
    //   data4 = [];

    // if (this.props.all && this.props.all.financeirasAgrupadas) {
    //   data1 = {
    //     labels: this.props.all.financeirasAgrupadas.map((f, index) => {
    //       return f.razaoSocial;
    //     }),
    //     datasets: [
    //       {
    //         label: "Contratos Registrados",
    //         backgroundColor: "rgba(24,73,170,0.2)",
    //         borderColor: "rgba(24,73,170,1)",
    //         borderWidth: 1,
    //         hoverBackgroundColor: "rgba(24,73,170,0.4)",
    //         hoverBorderColor: "rgba(24,73,170,1)",
    //         data: this.props.all.financeirasAgrupadas.map((f, index) => {
    //           return f.qtdMes3;
    //         }),
    //       },
    //     ],
    //   };
    //   data2 = {
    //     labels: this.props.all.financeirasAgrupadas.map((f, index) => {
    //       return f.razaoSocial;
    //     }),
    //     datasets: [
    //       {
    //         label: this.props.all.mes1,
    //         backgroundColor: "#3366ff",
    //         data: this.props.all.financeirasAgrupadas.map((f, index) => {
    //           return f.qtdMes1;
    //         }),
    //       },
    //       {
    //         label: this.props.all.mes2,
    //         backgroundColor: "#F2910C",
    //         data: this.props.all.financeirasAgrupadas.map((f, index) => {
    //           return f.qtdMes2;
    //         }),
    //       },
    //       {
    //         label: this.props.all.mes3,
    //         backgroundColor: "#92d400",
    //         data: this.props.all.financeirasAgrupadas.map((f, index) => {
    //           return f.qtdMes3;
    //         }),
    //       },
    //     ],
    //   };
    //   switch (this.state.top10) {
    //     case "values":
    //       //Chart 1
    //       var filteredByValue = this.props.all.financeirasAgrupadas
    //         .slice()
    //         .sort((a, b) => b.valorERCMes3 - a.valorERCMes3)
    //         .filter((value, index) => index < 10 && value);
    //       var data = filteredByValue.map((f, index) => {
    //         return MaskDecimal.TO_BR_NO_DOTS(f.valorERCMes3);
    //       });
    //       var labels = filteredByValue.map((f, index) => {
    //         return f.razaoSocial;
    //       });

    //       data1 = {
    //         labels,
    //         datasets: [{ ...data1.datasets[0], label: "Valor em R$", data }],
    //       };

    //       //Chart 2
    //       var filteredByValue2 = this.props.all.financeirasAgrupadas
    //         .slice()
    //         .sort((a, b) => b.valorERCMes1 + b.valorERCMes2 + b.valorERCMes3 - (a.valorERCMes1 + a.valorERCMes2 + a.valorERCMes3))
    //         .filter((value, index) => index < 10 && value);
    //       var data2_mes1 = filteredByValue2.map((f, index) => {
    //         return MaskDecimal.TO_BR_NO_DOTS(f.valorERCMes1);
    //       });
    //       var data2_mes2 = filteredByValue2.map((f, index) => {
    //         return MaskDecimal.TO_BR_NO_DOTS(f.valorERCMes2);
    //       });
    //       var data2_mes3 = filteredByValue2.map((f, index) => {
    //         return MaskDecimal.TO_BR_NO_DOTS(f.valorERCMes3);
    //       });
    //       var labels2 = filteredByValue2.map((f, index) => {
    //         return f.razaoSocial;
    //       });

    //       var datasets = [
    //         {
    //           ...data2.datasets[0],
    //           data: data2_mes1,
    //           label: `Valor de ${this.props.all.mes1} em R$`,
    //         },
    //         {
    //           ...data2.datasets[1],
    //           data: data2_mes2,
    //           label: `Valor de ${this.props.all.mes2} em R$`,
    //         },
    //         {
    //           ...data2.datasets[2],
    //           data: data2_mes3,
    //           label: `Valor de ${this.props.all.mes3} em R$`,
    //         },
    //       ];
    //       data2 = { labels: labels2, datasets };
    //       break;
    //     case "qtd":
    //       //Chart 1
    //       var filteredByQuantity = this.props.all.financeirasAgrupadas
    //         .slice()
    //         .sort((a, b) => b.qtdMes3 - a.qtdMes3)
    //         .filter((value, index) => index < 10 && value);
    //       var data = filteredByQuantity.map((f, index) => {
    //         return f.qtdMes3;
    //       });
    //       var labels = filteredByQuantity.map((f, index) => {
    //         return f.razaoSocial;
    //       });

    //       data1 = { labels, datasets: [{ ...data1.datasets[0], data }] };

    //       //Chart 2
    //       var filteredByQuantity2 = this.props.all.financeirasAgrupadas
    //         .slice()
    //         .sort((a, b) => b.qtdMes1 + b.qtdMes2 + b.qtdMes3 - (a.qtdMes1 + a.qtdMes2 + a.qtdMes3))
    //         .filter((value, index) => index < 10 && value);
    //       var data2_mes1 = filteredByQuantity2.map((f, index) => {
    //         return f.qtdMes1;
    //       });
    //       var data2_mes2 = filteredByQuantity2.map((f, index) => {
    //         return f.qtdMes2;
    //       });
    //       var data2_mes3 = filteredByQuantity2.map((f, index) => {
    //         return f.qtdMes3;
    //       });
    //       var labels2 = filteredByQuantity2.map((f, index) => {
    //         return f.razaoSocial;
    //       });

    //       var datasets = [
    //         { ...data2.datasets[0], data: data2_mes1 },
    //         { ...data2.datasets[1], data: data2_mes2 },
    //         { ...data2.datasets[2], data: data2_mes3 },
    //       ];
    //       data2 = { labels: labels2, datasets };
    //       break;
    //     default:
    //       break;
    //   }

    //   if (this.state.selected) {
    //     data3 = {
    //       labels: [this.props.all.mes1, this.props.all.mes2, this.props.all.mes3],
    //       datasets: [
    //         {
    //           label: this.state.selected._original.razaoSocial,
    //           backgroundColor: "rgba(24,73,170,0.2)",
    //           borderColor: "rgba(24,73,170,1)",
    //           borderWidth: 1,
    //           hoverBackgroundColor: "rgba(24,73,170,0.4)",
    //           hoverBorderColor: "rgba(24,73,170,1)",
    //           data: [this.state.selected._original.qtdMes1, this.state.selected._original.qtdMes2, this.state.selected._original.qtdMes3],
    //         },
    //       ],
    //     };
    //   }
    //   if (this.state.selected && this.state.unit) {
    //     const data = JSON.parse(JSON.stringify(this.state.selected._original.qtdPorDia));
    //     data4 = {
    //       labels: this.props.all.arrayDias,
    //       datasets: [
    //         {
    //           label: `${this.state.selected._original.razaoSocial} - ${this.props.all.mes3}`,
    //           backgroundColor: "rgba(24,73,170,0.2)",
    //           borderColor: "rgba(24,73,170,1)",
    //           borderWidth: 1,
    //           hoverBackgroundColor: "rgba(24,73,170,0.4)",
    //           hoverBorderColor: "rgba(24,73,170,1)",
    //           data,
    //         },
    //       ],
    //     };
    //   }
    // }

    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="row">
                    <div className="col-md-2">
                      <Field name="uf" label="UF" data={this.props.ufSystem} textField="sigla" component={SelectField} type="text" />
                    </div>
                    <div className="col-lg-1 align-self-end form-group">
                      <button className="btn btn-success" onClick={handleSubmit(this.filterUf.bind(this))} type="button">
                        Filtrar
                      </button>
                    </div>
                  </div>
                  <div className="panel-tools"></div>
                  <p>Dashboard</p>
                </div>
                <div className="panel-body">
                  <div>
                    {/*<label>Maiores detalhes</label>
                  <input
                    name="otherValues"
                    type="checkbox"
                    checked={this.state.otherValuesSelected}
                    onChange={this.handleInputChange.bind(this)}
                />*/}

                    <form onSubmit={handleSubmit(this.handleFilterChart.bind(this))}>
                      <div className="row">
                        <div className="col-lg-3">
                          <Field name="trimestre" label="Trimestre" data={trimestres} textField="label" valueField="value" component={SelectField} type="text" />
                        </div>
                        <div className="col-lg-3">
                          <Field name="ano" label="Ano" data={years} textField="label" valueField="value" component={SelectField} type="text" />
                        </div>
                        <div className="col-lg-6 align-self-end form-group">
                          <button className="btn btn-secondary" type="button" onClick={handleSubmit(this.handleDefaultChart.bind(this))}>
                            Últimos 3 Meses
                          </button>
                          &nbsp;&nbsp;&nbsp;
                          <button disabled={this.props.inProgress || this.props.submitting || this.props.invalid || this.state.pesquisando} className="btn btn-success" type="submit">
                            {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3">
                          <Field name="periodicidade" label="Exibir" data={periodicidade} textField="label" valueField="value" component={SelectField} type="text" />
                        </div>
                        <div className="col-lg-6 align-self-end form-group">
                          <button className="btn btn-success" type="button" onClick={handleSubmit(this.filtrarPeriodicidade.bind(this))}>
                            Filtrar
                          </button>
                        </div>
                      </div>
                    </form>
                    {this.props.listFilter && this.props.listFilter.financeirasAgrupadas && (
                      <>
                        <Link className="btn btn-default float-right" onClick={this.exportToCSVGroup.bind(this)} title="Gerar Excel Agrupado">
                          {" "}
                          <SvgIcon size={20} icon={stack} />
                        </Link>
                        <Link className="btn btn-default float-right" onClick={this.exportToCSV.bind(this)} title="Gerar Excel">
                          {" "}
                          <SvgIcon size={20} icon={fileExcelO} />
                        </Link>
                        <Link className="btn btn-default float-right" onClick={() => this.exportToPDF("table")} title="Gerar PDF">
                          {" "}
                          <SvgIcon size={20} icon={filePdf} />
                        </Link>
                        <Link className="btn btn-default float-right" checked={this.state.otherValuesSelected} onClick={this.handleInputChange.bind(this)} title="Mais detalhes">
                          {" "}
                          <SvgIcon size={20} icon={ic_attach_money} />
                        </Link>
                      </>
                    )}
                  </div>
                  <div className="table-responsive">
                    {this.props.listFilter && this.props.listFilter.financeirasAgrupadas ? (
                      <ReactTable
                        className="-striped -highlight"
                        defaultPageSize={this.props.listFilter.financeirasAgrupadas.length}
                        data={this.props.listFilter.financeirasAgrupadas}
                        loadingText="Carregando..."
                        noDataText="Nenhuma Informação encontrada"
                        showPageSizeOptions={false}
                        minRows={1}
                        showPagination={false}
                        getTdProps={(state, rowInfo, column) => {
                          if (rowInfo && rowInfo.row && column && column.Header) {
                            return {
                              style: {
                                border: "none",
                              },
                              onClick: (e) => {
                                if (rowInfo.row._original.correspondentes.length === 1 && this.myRef) {
                                  window.scrollTo(0, this.myRef.offsetTop);
                                  this.setState({
                                    selected: rowInfo.row,
                                    unit: true,
                                  });
                                }
                              },
                            };
                          } else {
                            return {};
                          }
                        }}
                        columns={[
                          {
                            expander: true,
                            Header: "",
                            Expander: ({ isExpanded, ...rest }) => {
                              return <div className="div-expander">{rest.original.correspondentes.length >= 2 ? isExpanded ? <span> &#x2bc6;</span> : <span> &#x2bc8;</span> : null}</div>;
                            },
                            getProps: (state, rowInfo, column) => {
                              if (!rowInfo || (rowInfo && rowInfo.original.correspondentes.length < 2)) {
                                return {
                                  onClick: () => {},
                                };
                              }
                              return {
                                className: "show-pointer",
                              };
                            },
                          },
                          {
                            Header: "Financeiras",
                            accessor: "razaoSocial",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 290,
                            Cell: (row) => (
                              <div className="cursor-pointer" style={{ textAlign: "left" }}>
                                {row.original.nomeAgrupamento ? row.original.nomeAgrupamento.toUpperCase() : row.original.razaoSocial}
                              </div>
                            ),
                            Footer: (
                              <span>
                                <strong>Total:</strong>{" "}
                              </span>
                            ),
                          },
                          {
                            Header: `${this.props.listFilter.mes1}`,
                            columns: [
                              {
                                Header: "Qtd",
                                accessor: "qtdMes1",
                                className: "align-middle text-center",
                                headerClassName: "font-extra-bold",
                                width: this.state.otherValuesSelected ? 45 : undefined,
                                sortMethod: (a, b) => a - b,
                                Cell: (row) => <div className="cursor-pointer">{row.original.qtdMes1}</div>,
                                Footer: (
                                  <span>
                                    <strong>{this.props.listFilter.qtdTotalMes1}</strong>
                                  </span>
                                ),
                              },
                              {
                                Header: "R$ Alias",
                                accessor: "valorERCMes1",
                                className: "align-middle text-center",
                                headerClassName: "font-extra-bold",
                                show: otherValuesSelected,
                                minWidth: 90,
                                sortMethod: (a, b) => a - b,
                                Cell: (row) => (
                                  <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                    {MaskDecimal.TO_BRL(row.original.valorERCMes1)}
                                  </div>
                                ),
                                Footer: (
                                  <span>
                                    <strong>{MaskDecimal.TO_BRL(this.props.listFilter.valorERCTotalMes1)}</strong>
                                  </span>
                                ),
                              },
                              {
                                Header: "R$ Detran",
                                accessor: "valorDetranMes1",
                                className: "align-middle text-center",
                                headerClassName: "font-extra-bold",
                                show: otherValuesSelected,
                                minWidth: 90,
                                sortMethod: (a, b) => a - b,
                                Cell: (row) => (
                                  <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                    {MaskDecimal.TO_BRL(row.original.valorDetranMes1)}
                                  </div>
                                ),
                                Footer: (
                                  <span>
                                    <strong>{MaskDecimal.TO_BRL(this.props.listFilter.valorDetranTotalMes1)}</strong>
                                  </span>
                                ),
                              },
                            ],
                          },
                          {
                            Header: `${this.props.listFilter.mes2}`,
                            columns: [
                              {
                                Header: "Qtd",
                                accessor: "qtdMes2",
                                className: "align-middle text-center",
                                headerClassName: "font-extra-bold",
                                width: this.state.otherValuesSelected ? 45 : undefined,
                                sortMethod: (a, b) => a - b,
                                Cell: (row) => <div className="cursor-pointer">{row.original.qtdMes2}</div>,
                                Footer: (
                                  <span>
                                    <strong>{this.props.listFilter.qtdTotalMes2}</strong>
                                  </span>
                                ),
                              },
                              {
                                Header: "R$ Alias",
                                accessor: "valorERCMes2",
                                className: "align-middle text-center",
                                headerClassName: "font-extra-bold",
                                show: otherValuesSelected,
                                minWidth: 90,
                                sortMethod: (a, b) => a - b,
                                Cell: (row) => (
                                  <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                    {MaskDecimal.TO_BRL(row.original.valorERCMes2)}
                                  </div>
                                ),
                                Footer: (
                                  <span>
                                    <strong>{MaskDecimal.TO_BRL(this.props.listFilter.valorERCTotalMes2)}</strong>
                                  </span>
                                ),
                              },
                              {
                                Header: "R$ Detran",
                                accessor: "valorDetranMes2",
                                className: "align-middle text-center",
                                headerClassName: "font-extra-bold",
                                show: otherValuesSelected,
                                minWidth: 90,
                                sortMethod: (a, b) => a - b,
                                Cell: (row) => (
                                  <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                    {MaskDecimal.TO_BRL(row.original.valorDetranMes2)}
                                  </div>
                                ),
                                Footer: (
                                  <span>
                                    <strong>{MaskDecimal.TO_BRL(this.props.listFilter.valorDetranTotalMes2)}</strong>
                                  </span>
                                ),
                              },
                            ],
                          },
                          {
                            Header: `${this.props.listFilter.mes3}`,
                            columns: [
                              {
                                Header: "Qtd",
                                accessor: "qtdMes3",
                                className: "align-middle text-center",
                                headerClassName: "font-extra-bold",
                                width: this.state.otherValuesSelected ? 45 : undefined,
                                sortMethod: (a, b) => a - b,
                                Cell: (row) => <div className="cursor-pointer">{row.original.qtdMes3}</div>,
                                Footer: (
                                  <span>
                                    <strong>{this.props.listFilter.qtdTotalMes3}</strong>
                                  </span>
                                ),
                              },
                              {
                                Header: "R$ Alias",
                                accessor: "valorERCMes3",
                                className: "align-middle text-center",
                                headerClassName: "font-extra-bold",
                                show: otherValuesSelected,
                                minWidth: 90,
                                sortMethod: (a, b) => a - b,
                                Cell: (row) => (
                                  <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                    {MaskDecimal.TO_BRL(row.original.valorERCMes3)}
                                  </div>
                                ),
                                Footer: (
                                  <span>
                                    <strong>{MaskDecimal.TO_BRL(this.props.listFilter.valorERCTotalMes3)}</strong>
                                  </span>
                                ),
                              },
                              {
                                Header: "R$ Detran",
                                accessor: "valorDetranMes3",
                                className: "align-middle text-center",
                                headerClassName: "font-extra-bold",
                                show: otherValuesSelected,
                                minWidth: 90,
                                sortMethod: (a, b) => a - b,
                                Cell: (row) => (
                                  <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                    {MaskDecimal.TO_BRL(row.original.valorDetranMes3)}
                                  </div>
                                ),
                                Footer: (
                                  <span>
                                    <strong>{MaskDecimal.TO_BRL(this.props.listFilter.valorDetranTotalMes3)}</strong>
                                  </span>
                                ),
                              },
                            ],
                          },
                        ]}
                        SubComponent={(row) => {
                          return (
                            <div style={{ padding: "20px" }}>
                              <ReactTable
                                className="-striped -highlight"
                                defaultPageSize={1000}
                                data={row.original.correspondentes}
                                loadingText="Carregando..."
                                noDataText="Nenhuma Informação encontrada"
                                showPageSizeOptions={false}
                                minRows={1}
                                showPagination={false}
                                getTdProps={(state, rowInfo, column) => {
                                  if (rowInfo && rowInfo.row && column && column.Header) {
                                    return {
                                      style: {
                                        border: "none",
                                      },
                                      onClick: (e) => {
                                        if (this.myRef) {
                                          window.scrollTo(0, this.myRef.offsetTop);
                                          this.setState({
                                            selected: rowInfo.row,
                                            unit: true,
                                          });
                                        }
                                      },
                                    };
                                  } else {
                                    return {};
                                  }
                                }}
                                columns={[
                                  {
                                    columns: [
                                      {
                                        Header: "Correspondentes",
                                        accessor: "razaoSocial",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        width: 300,
                                        sortMethod: (a, b) => a - b,
                                        Cell: (row) => <div className="cursor-pointer">{row.original.razaoSocial}</div>,
                                      },
                                    ],
                                  },
                                  {
                                    Header: `${this.props.listFilter.mes1}`,
                                    columns: [
                                      {
                                        Header: "Qtd",
                                        accessor: "qtdMes1",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        width: this.state.otherValuesSelected ? 40 : undefined,
                                        sortMethod: (a, b) => a - b,
                                        Cell: (row) => <div className="cursor-pointer">{row.original.qtdMes1}</div>,
                                      },
                                      {
                                        Header: "R$ Alias",
                                        accessor: "valorERCMes1",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        show: otherValuesSelected,
                                        minWidth: 90,
                                        sortMethod: (a, b) => a - b,
                                        Cell: (row) => (
                                          <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                            {MaskDecimal.TO_BRL(row.original.valorERCMes1)}
                                          </div>
                                        ),
                                      },
                                      {
                                        Header: "R$ Detran",
                                        accessor: "valorDetranMes1",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        show: otherValuesSelected,
                                        minWidth: 90,
                                        sortMethod: (a, b) => a - b,
                                        Cell: (row) => (
                                          <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                            {MaskDecimal.TO_BRL(row.original.valorDetranMes1)}
                                          </div>
                                        ),
                                      },
                                    ],
                                  },
                                  {
                                    Header: `${this.props.listFilter.mes2}`,
                                    columns: [
                                      {
                                        Header: "Qtd",
                                        accessor: "qtdMes2",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        width: this.state.otherValuesSelected ? 40 : undefined,
                                        sortMethod: (a, b) => a - b,
                                        Cell: (row) => <div className="cursor-pointer">{row.original.qtdMes2}</div>,
                                      },
                                      {
                                        Header: "R$ Alias",
                                        accessor: "valorERCMes2",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        show: otherValuesSelected,
                                        minWidth: 90,
                                        sortMethod: (a, b) => a - b,
                                        Cell: (row) => (
                                          <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                            {MaskDecimal.TO_BRL(row.original.valorERCMes2)}
                                          </div>
                                        ),
                                      },
                                      {
                                        Header: "R$ Detran",
                                        accessor: "valorDetranMes2",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        show: otherValuesSelected,
                                        minWidth: 90,
                                        sortMethod: (a, b) => a - b,
                                        Cell: (row) => (
                                          <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                            {MaskDecimal.TO_BRL(row.original.valorDetranMes2)}
                                          </div>
                                        ),
                                      },
                                    ],
                                  },
                                  {
                                    Header: `${this.props.listFilter.mes3}`,
                                    columns: [
                                      {
                                        Header: "Qtd",
                                        accessor: "qtdMes3",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        width: this.state.otherValuesSelected ? 40 : undefined,
                                        sortMethod: (a, b) => a - b,
                                        Cell: (row) => <div className="cursor-pointer">{row.original.qtdMes3}</div>,
                                      },
                                      {
                                        Header: "R$ Alias",
                                        accessor: "valorERCMes3",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        show: otherValuesSelected,
                                        minWidth: 90,
                                        sortMethod: (a, b) => a - b,
                                        Cell: (row) => (
                                          <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                            {MaskDecimal.TO_BRL(row.original.valorERCMes3)}
                                          </div>
                                        ),
                                      },
                                      {
                                        Header: "R$ Detran",
                                        accessor: "valorDetranMes3",
                                        className: "align-middle text-center",
                                        headerClassName: "font-extra-bold",
                                        show: otherValuesSelected,
                                        minWidth: 90,
                                        sortMethod: (a, b) => a - b,
                                        Cell: (row) => (
                                          <div className="cursor-pointer" style={{ textAlign: "right" }}>
                                            {MaskDecimal.TO_BRL(row.original.valorDetranMes3)}
                                          </div>
                                        ),
                                      },
                                    ],
                                  },
                                ]}
                              />
                            </div>
                          );
                        }}
                      />
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div ref={(ref) => (this.myRef = ref)} className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    <Link className="btn btn-default btn-sm" onClick={() => this.exportToPDF("chart")} title={`Gerar PDF de Ambos os Gráficos ${this.state.unit ? "Unitários" : "Gerais"}`}>
                      {" "}
                      <SvgIcon icon={filePdf} />
                    </Link>
                    <span>
                      <butto id="top" style={{ display: "none" }} onClick={(e) => this.handleChangeTopFake10("qtd")} />
                      <button id="geral" style={{ display: "none" }} onClick={(e) => this.handleChangeTopFake10("all")} />

                      <button className="btn btn-default btn-sm" onClick={(e) => this.handleChangeTop10("qtd")}>
                        Top 10 Quantidade
                      </button>
                      <button className="btn btn-default btn-sm" onClick={(e) => this.handleChangeTop10("values")}>
                        Top 10 Valores
                      </button>
                      <button className="btn btn-default btn-sm" onClick={(e) => this.handleChangeTop10("all")}>
                        Geral
                      </button>
                    </span>
                    {this.props.all.financeirasAgrupadas && this.state.selected && (
                      <button
                        className="btn btn-default btn-sm"
                        onClick={(e) => {
                          window.scrollTo(0, this.myRef.offsetTop + 50);
                          this.setState({ unit: !this.state.unit });
                        }}
                      >
                        Ir para Visualização {this.state.unit ? "Geral" : "Unitária"}
                      </button>
                    )}
                  </div>
                  <p>Gráficos {!this.state.unit ? "Gerais" : "Unitários"}</p>
                </div>
                {this.props.all.financeirasAgrupadas && !this.state.unit && (
                  <div>
                    <div className="panel-body">
                      <div className="stats-title">
                        <h4>
                          {this.props.all.mes3.toUpperCase()}
                          &nbsp;
                          <Link className="btn btn-default btn-sm" onClick={() => this.exportChartToCSV("chart1")} title="Gerar Excel">
                            {" "}
                            <SvgIcon icon={fileExcelO} />
                          </Link>
                        </h4>
                      </div>
                      <br></br>
                      <div className="row">
                        <div className="col-lg-12">
                          {this.state.top10 === "qtd" && this.renderHorizontalBarData1(data1)}
                          {this.state.top10 === "values" && this.renderHorizontalBarData1(data1)}
                          {this.state.top10 === "all" && this.renderHorizontalBarData1(data1)}
                        </div>
                      </div>
                    </div>
                    <div className="panel-body">
                      <div className="stats-title">
                        <h4>
                          3 meses &nbsp;
                          <Link className="btn btn-default btn-sm" onClick={() => this.exportChartToCSV("chart2")} title="Gerar Excel">
                            {" "}
                            <SvgIcon icon={fileExcelO} />
                          </Link>
                        </h4>
                      </div>
                      <br></br>
                      <div className="row">
                        <div className="col-lg-12">
                          {this.state.top10 === "qtd" && this.renderHorizontalBarData2(data2)}
                          {this.state.top10 === "values" && this.renderHorizontalBarData2(data2)}
                          {this.state.top10 === "all" && this.renderHorizontalBarData2(data2)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.props.all.financeirasAgrupadas && this.state.selected && this.state.unit && (
                  <div>
                    <div className="panel-body">
                      <div className="stats-title">
                        <h4>
                          3 meses e {this.props.all.mes3.toUpperCase()}
                          &nbsp;
                          <Link className="btn btn-default btn-sm" onClick={() => this.exportChartToCSV("chart3")} title="Gerar Excel do Primeiro Gráfico">
                            {" "}
                            <SvgIcon icon={fileExcelO} />
                          </Link>
                          <Link className="btn btn-default btn-sm" onClick={() => this.exportChartToCSV("chart4")} title="Gerar Excel do Segundo Gráfico">
                            {" "}
                            <SvgIcon icon={fileExcelO} />
                          </Link>
                        </h4>
                      </div>

                      <br></br>
                      <div className="row">
                        <div className="col-lg-3">
                          <Bar
                            data={data3}
                            height={400}
                            options={{
                              tooltips: {
                                titleFontColor: "#E0E0E0",
                                callbacks: {
                                  title: function (tooltipItem, data) {
                                    return data["labels"][tooltipItem[0]["index"]];
                                  },
                                  label: function (tooltipItem, data) {
                                    return `Contratos Registrados: ${data["datasets"][0]["data"][tooltipItem["index"]]}`;
                                  },
                                },
                              },
                              maintainAspectRatio: false,
                            }}
                          />
                        </div>
                        <div className="col-lg-9">
                          <Line
                            data={data4}
                            height={400}
                            options={{
                              scales: {
                                yAxes: [
                                  {
                                    ticks: {
                                      stepSize: Math.max(...this.state.selected._original.qtdPorDia) <= 10 ? 1 : undefined,
                                    },
                                  },
                                ],
                              },
                              tooltips: {
                                titleFontColor: "#E0E0E0",
                                callbacks: {
                                  title: function (tooltipItem, data) {
                                    return `Dia ${data["labels"][tooltipItem[0]["index"]]}`;
                                  },
                                  label: function (tooltipItem, data) {
                                    return `Contratos Registrados: ${data["datasets"][0]["data"][tooltipItem["index"]]}`;
                                  },
                                },
                              },
                              maintainAspectRatio: false,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div> */}
          {!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("P") ? (
            <div className="row">
              <div className="col-md-4">
                <div className="hpanel stats">
                  <div className="panel-heading">
                    <div className="panel-tools"></div>
                    <p>Imagens</p>
                  </div>
                  <div className="panel-body h-100">
                    <div className="row m-b-sm">
                      <div className="col-md-12">
                        <div className="stats-title float-left">
                          {" "}
                          <h3>IMAGENS DE REGISTRO DE CONTRATOS</h3>{" "}
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(this.handleContractImages.bind(this))}>
                      <div className="row">
                        <div className="col-md-8">
                          <Field name="cnpj" blank={true} label="Credor" data={this.props.companies} textField="razaoSocial" valueField="cnpj" component={DropdownListField} type="text" />
                        </div>
                        <div className="col-lg-2 align-self-end form-group">
                          <button disabled={this.props.inProgress || this.props.submitting || this.props.invalid || this.state.pesquisando} className="btn btn-success" type="submit">
                            {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                          </button>
                        </div>
                        <div className="col-lg-2">&nbsp;</div>
                      </div>
                    </form>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="font-bold float-left">Vencidos</div>
                        <br />
                        <ul className="list-group m-t-sm">
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Mais de 30 Dias</div>
                              <div>
                                {this.props.contractsExpired.qtd > 0 ? (
                                  <Link
                                    className="font-weight-bold float-left text-danger"
                                    to={`/alias/aprove/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired.data[0])}/${this.state.credor}/${this.props.selectedUf}`}
                                  >
                                    {this.props.contractsExpired.qtd}
                                  </Link>
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                        <br></br>
                        <div className="font-bold float-left">A Vencer</div>
                        <br />
                        <ul className="list-group m-t-sm">
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Em 7 Dias</div>
                              <div>
                                {this.props.contractsExpired7.qtd > 0 ? (
                                  <Link
                                    className="font-weight-bold float-left text-danger"
                                    to={`/alias/aprove/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired7.data[0])}/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired7.data[1])}/${
                                      this.state.credor
                                    }/${this.props.selectedUf}`}
                                  >
                                    {this.props.contractsExpired7.qtd}
                                  </Link>
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Em 15 Dias</div>
                              <div>
                                {this.props.contractsExpired15.qtd > 0 ? (
                                  <Link
                                    className="font-weight-bold float-left text-danger"
                                    to={`/alias/aprove/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired15.data[0])}/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired15.data[1])}/${
                                      this.state.credor
                                    }/${this.props.selectedUf}`}
                                  >
                                    {this.props.contractsExpired15.qtd}
                                  </Link>
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Em 30 Dias</div>
                              <div>
                                {this.props.contractsExpired30.qtd > 0 ? (
                                  <Link
                                    className="font-weight-bold float-left text-danger"
                                    to={`/alias/aprove/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired30.data[0])}/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired30.data[1])}/${
                                      this.state.credor
                                    }/${this.props.selectedUf}`}
                                  >
                                    {this.props.contractsExpired30.qtd}
                                  </Link>
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="hpanel stats">
                  <div className="panel-heading">
                    <div className="panel-tools"></div>
                    <p>Contratos</p>
                  </div>
                  <div className="panel-body h-100">
                    <div className="row m-b-sm">
                      <div className="col-md-12">
                        <div className="stats-title float-left">
                          {" "}
                          <h3>VISÃO GERAL DE CONTRATOS</h3>{" "}
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(this.handleCotractView.bind(this))}>
                      <div className="row">
                        <div className="col-md-8">
                          <Field
                            name="cnpj2"
                            blank={true}
                            label="Credor"
                            data={this.props.companies}
                            textField="razaoSocial"
                            credor={true}
                            valueField="cnpj2"
                            component={DropdownListField}
                            type="text"
                          />
                        </div>
                        <div className="col-lg-2 align-self-end form-group">
                          <button disabled={this.props.inProgress || this.props.submitting || this.props.invalid || this.state.pesquisando} className="btn btn-success" type="submit">
                            {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                          </button>
                        </div>
                        <div className="col-lg-2">&nbsp;</div>
                      </div>
                    </form>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square"></i> - Não Enviado/Aguardando DETRAN
                          </div>
                          <div>{this.state.contractsNotRegistred ? <div className="font-weight-bold float-left text-danger">{this.state.contractsNotRegistred.qtd}</div> : "0"}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-danger"></i> - Registro com erro
                          </div>
                          <div>{this.state.contractsRegistredError ? <div className="font-weight-bold float-left text-danger">{this.state.contractsRegistredError.qtd}</div> : "0"}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-warning"></i> - Registrado sem imagem
                          </div>
                          <div>{this.state.contractsRegistredWithoutImage ? <div className="font-weight-bold float-left text-danger">{this.state.contractsRegistredWithoutImage.qtd}</div> : "0"}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-success"></i> - Registrado
                          </div>
                          <div>{this.state.contractsRegistred ? <div className="font-weight-bold float-left text-danger">{this.state.contractsRegistred.qtd}</div> : "0"}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-primary"></i> - Cancelado/Baixado
                          </div>
                          <div>{this.state.contractsCancel ? <div className="font-weight-bold float-left text-danger">{this.state.contractsCancel.qtd}</div> : "0"}</div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-light font-weight-bold">
                        <div className="d-flex justify-content-between">
                          <div>TOTAL</div>
                          <div>
                            {(this.state.contractsNotRegistred ? this.state.contractsNotRegistred.qtd : 0) +
                              (this.state.contractsRegistredError ? this.state.contractsRegistredError.qtd : 0) +
                              (this.state.contractsRegistredWithoutImage ? this.state.contractsRegistredWithoutImage.qtd : 0) +
                              (this.state.contractsRegistred ? this.state.contractsRegistred.qtd : 0) +
                              (this.state.contractsCancel ? this.state.contractsCancel.qtd : 0)}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="hpanel stats">
                  <div className="panel-heading">
                    <div className="panel-tools"></div>
                    <p>Financeiras</p>
                  </div>
                  <div className="panel-body h-100">
                    <div className="row m-b-sm">
                      <div className="col-md-12">
                        <div className="stats-title float-left">
                          {" "}
                          <h3>{"FINANCEIRAS INATIVAS (> 5 DIAS)"}</h3>
                        </div>
                        <div className="float-rigth">
                          <Link className="btn btn-default btn-sm float-right" onClick={() => this.exportFinInvalidasToCSV()} title="Gerar Excel">
                            {" "}
                            <SvgIcon icon={fileExcelO} />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <ul className="list-group">
                      <li className="list-group-item list-group-item-light font-weight-bold">
                        <div className="d-flex justify-content-between">
                          <div>TOTAL</div>
                          <div>{this.props.listFinancialInvalid.length}</div>
                        </div>
                      </li>
                      {this.mountFinInvalid()}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-6">
                <div className="hpanel stats">
                  <div className="panel-heading">
                    <div className="panel-tools"></div>
                    <p>Contratos</p>
                  </div>
                  <div className="panel-body h-100">
                    <div className="row m-b-sm">
                      <div className="col-md-12">
                        <div className="stats-title float-left">
                          {" "}
                          <h3>VISÃO GERAL DE CONTRATOS</h3>{" "}
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(this.handleCotractView.bind(this))}>
                      <div className="row">
                        <div className="col-md-8">
                          <Field
                            name="cnpj2"
                            blank={true}
                            label="Credor"
                            data={this.props.companies}
                            textField="razaoSocial"
                            credor={true}
                            valueField="cnpj2"
                            component={DropdownListField}
                            type="text"
                          />
                        </div>
                        <div className="col-lg-2 align-self-end form-group">
                          <button disabled={this.props.inProgress || this.props.submitting || this.props.invalid || this.state.pesquisando} className="btn btn-success" type="submit">
                            {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                          </button>
                        </div>
                        <div className="col-lg-2">&nbsp;</div>
                      </div>
                    </form>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square"></i> - Não Enviado/Aguardando DETRAN
                          </div>
                          <div>{this.state.contractsNotRegistred ? <div className="font-weight-bold float-left text-danger">{this.state.contractsNotRegistred.qtd}</div> : "0"}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-danger"></i> - Registro com erro
                          </div>
                          <div>{this.state.contractsRegistredError ? <div className="font-weight-bold float-left text-danger">{this.state.contractsRegistredError.qtd}</div> : "0"}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-warning"></i> - Registrado sem imagem
                          </div>
                          <div>{this.state.contractsRegistredWithoutImage ? <div className="font-weight-bold float-left text-danger">{this.state.contractsRegistredWithoutImage.qtd}</div> : "0"}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-success"></i> - Registrado
                          </div>
                          <div>{this.state.contractsRegistred ? <div className="font-weight-bold float-left text-danger">{this.state.contractsRegistred.qtd}</div> : "0"}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-primary"></i> - Cancelado/Baixado
                          </div>
                          <div>{this.state.contractsCancel ? <div className="font-weight-bold float-left text-danger">{this.state.contractsCancel.qtd}</div> : "0"}</div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-light font-weight-bold">
                        <div className="d-flex justify-content-between">
                          <div>TOTAL</div>
                          <div>
                            {(this.state.contractsNotRegistred ? this.state.contractsNotRegistred.qtd : 0) +
                              (this.state.contractsRegistredError ? this.state.contractsRegistredError.qtd : 0) +
                              (this.state.contractsRegistredWithoutImage ? this.state.contractsRegistredWithoutImage.qtd : 0) +
                              (this.state.contractsRegistred ? this.state.contractsRegistred.qtd : 0) +
                              (this.state.contractsCancel ? this.state.contractsCancel.qtd : 0)}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="hpanel stats">
                  <div className="panel-heading">
                    <div className="panel-tools"></div>
                    <p>Financeiras</p>
                  </div>
                  <div className="panel-body h-100">
                    <div className="row m-b-sm">
                      <div className="col-md-12">
                        <div className="stats-title float-left">
                          {" "}
                          <h3>{"FINANCEIRAS INATIVAS (> 5 DIAS)"}</h3>
                        </div>
                        <div className="float-rigth">
                          <Link className="btn btn-default btn-sm float-right" onClick={() => this.exportFinInvalidasToCSV()} title="Gerar Excel">
                            {" "}
                            <SvgIcon icon={fileExcelO} />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <ul className="list-group">
                      <li className="list-group-item list-group-item-light font-weight-bold">
                        <div className="d-flex justify-content-between">
                          <div>TOTAL</div>
                          <div>{this.props.listFinancialInvalid.length}</div>
                        </div>
                      </li>
                      {this.mountFinInvalid()}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          )}
        </div>
      </div>
    );
  }

  mountFinInvalid() {
    if (!this.props.listFinancialInvalid) {
      return null;
    }
    let listFinHtml = [];
    let x = 0;
    while (x < 5 && this.props.listFinancialInvalid.length > x) {
      listFinHtml.push(
        <li className="list-group-item">
          <div className="d-flex justify-content-between">
            <div
              className="font-weight-bold float-left text-danger"
              id={x}
              onClick={() => {
                this.setState({ open: window.event.target.id });
              }}
            >
              {this.props.listFinancialInvalid[x].razaoSocial}
            </div>
          </div>
          <div
            id={x}
            className={
              document.getElementById(x) && document.getElementById(x).id == this.state.open
                ? "panel-collapse-dasboard-financeira"
                : "panel-collapse-dasboard-financeira panel-close-dasboard-financeira"
            }
          >
            <ul className="list-group">
              {document.getElementById(x) &&
                this.props.listFinancialInvalid[document.getElementById(x).id].financeiraFilha.map((item) => {
                  return (
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between col-md-12">
                        <div className="col-md-8" style={{ margin: "0 -37px" }}>
                          {item.filha}
                        </div>
                        <div className="col-md-1">{item.uf}</div>
                        <div className="col-md-3">{MaskDate.TO_BR(item.ultimaCobranca)}</div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </li>
      );
      x++;
    }
    return listFinHtml;
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_LIST_LOAD, payload }),
  onContractsExpiredLoad: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_LOAD, payload }),
  onContractsExpired7Load: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_7_LOAD, payload }),
  onContractsExpired15Load: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_15_LOAD, payload }),
  onContractsExpired30Load: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_30_LOAD, payload }),
  onCompaniesLoad: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_COMPANIES_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_LIST_UNLOAD }),
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
  onNotRegistredLoad: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_NOT_REGISTRED_LOAD, payload }),
  onRegistredErrorLoad: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_REGISTRED_ERROR_LOAD, payload }),
  onRegistredWithoutImageLoad: (payload) =>
    dispatch({
      type: FINANCIAL_DASHBOARD_REGISTRED_WITHOUT_IMAGE_LOAD,
      payload,
    }),
  onContractsRegistredLoad: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_REGISTRED_LOAD, payload }),
  onContractClearImageVisao: (payload) => dispatch({ type: FINANCIAL_DASHBOARD_CLEAR_IMAGENS_VISAO, payload }),
  onListLoadFinancialInvalid: (payload) => dispatch({ type: FINANCIAL_INVALID, payload }),
  onListLoadFilter: (payload) => dispatch({ type: FINANCIAL_LIST_FILTER, payload }),
});

const selector = formValueSelector("FinancialDashboardForm");
const mapStateToProps = (state) => ({
  ...state.financialDashboard,
  selectedUf: selector(state, "uf"),
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "FinancialDashboardForm",
  enableReinitialize: true,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(FinancialDashboard));
