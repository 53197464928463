import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, untouch, formValueSelector } from "redux-form";
import { Link } from "react-router";

import { Button, ButtonGroup, ListGroup, ListGroupItem } from "react-bootstrap";

import ReactTable from "react-table";

import TextField from "../../components/Fields/TextField";
import SelectField from "../../components/Fields/SelectField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import MaskDate from "../../components/Util/MaskDate";
import UfField from "../UfFields/UfField";
import CityField from "../UfFields/CityField";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import ButtonWithBadge from "../../components/Fields/ButtonWithBadge";

import Title from "../../components/Layout/Title";
import {
  CONTRACT_NOT_REGISTRED_LOAD,
  CONTRACT_LIST_FILTER_SELECTED,
  CONTRACT_NO_IMAGE_LOAD,
  CONTRACT_TOTAL_LOAD,
  CONTRACT_REGISTERED_LOAD,
  CONTRACT_LIST_UNLOAD,
  CONTRACT_TOTAIS_LOAD,
} from "../../actions/Constants";
import api from "../../api/apiUtil";

import { SubmissionError } from "redux-form";
import _ from "lodash";

import SvgIcon from "react-icons-kit";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import DropdownListField from "../../components/Fields/DropdownListField";
import { BeatLoader } from "react-spinners";
import { toastr } from "react-redux-toastr";
import Modal from "react-bootstrap4-modal";

var func21;
var func22;
var tipoUser;
var reenviarContrato;
const statusFiltrosAbas = {
  NI: {
    status: "2",
  },
  NR: {
    status: "0,1,90,8,10,11,9",
  },
  NR2: {
    status: "0,1,8,10,11",
  },
  R: {
    status: "3,4,7",
  },
  ERR: {
    status: "90",
  },
  TODOS: {
    status: "2,0,1,90,8,10,5,6,11,3,4,7,9",
  },
  CB: {
    status: "5,6",
  },
};

const columns = [
  {
    Header: "Status",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    accessor: "status",
    width: 70,
    Cell: (row) => (
      <span>
        <span
          style={{
            color:
              row.value === 0 || row.value === 8
                ? "#6A6C6F"
                : row.value === 1 || row.value === 9
                ? "#b9a463"
                : row.value === 2
                ? "#FFC107"
                : row.value === 5
                ? "#00b7ff"
                : row.value === 6
                ? "#007BFF"
                : row.value === 11
                ? "#cb09ed"
                : row.value === 90 || row.value === 10
                ? "#DC3545"
                : "#57d500",
            transition: "all .3s ease",
          }}
        >
          {row.original.idContratoAnterior ? (
            <span title="Averbação" key="averbacao" style={{ fontSize: "28px" }}>
              <i className="fa fa-file-alt"></i>
            </span>
          ) : (
            <span title="Contrato" key="contrato" style={{ fontSize: "28px" }}>
              <i className="fa fa-file"></i>
            </span>
          )}
        </span>
      </span>
    ),
  },
  {
    Header: "UF",
    accessor: "uf",
    width: 50,
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Observação",
    accessor: "justificativa",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Registro",
    accessor: "numRegistro",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Contrato",
    accessor: "numero",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Credor",
    accessor: "row",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div>
        {row.original.razaoSocial}
        <br />
        {FieldNormalize.CNPJ(row.original.cnpj, row.original.cnpj)}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Devedor",
    accessor: "rowDevedor",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (rowDevedor) => (
      <div>
        {rowDevedor.original.devNome}
        <br />
        {rowDevedor.original.devCpfCnpj}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="align-middle text-center">
        {row.original.chassi.split(",").map((item) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  {
    Header: "Gravame",
    accessor: "rowGravame",
    headerClassName: "font-extra-bold",
    width: 100,
    Cell: (rowGravame) => (
      <div className="align-middle text-center">
        {rowGravame.original.numGravame.split(",").map((item, index) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  // {
  //   Header: "Placa",
  //   accessor: "row",
  //   headerClassName: "font-extra-bold",
  //   Cell: (row) => (
  //     <div className="align-middle text-center">
  //       {row.original.ufPlaca.split(",").map((item, index) => {
  //         return (
  //           <span>
  //             {item} {row.original.numPlaca.split(",")[index]}
  //             <br />
  //           </span>
  //         );
  //       })}
  //     </div>
  //   ),
  // },
  {
    Header: "", // Custom header components!
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    width: 70,
    Cell: (row) => (
      <div className="btn-group-vertical">
        <>
          {func21 && (
            <Link className="btn btn-sm btn-default" style={{ margin: "auto" }} to={`/secure/contract/view/${row.original.id}`} title="Visualizar">
              <i className="fa fa-info-circle"></i>
            </Link>
          )}
          {row.original.status != 1 &&
            row.original.status != 3 &&
            row.original.status != 4 &&
            row.original.status != 5 &&
            row.original.status != 6 &&
            row.original.status != 7 &&
            row.original.status != 9 &&
            row.original.status != 11 &&
            func22 && (
              <Link className="btn btn-sm btn-default" style={{ margin: "auto" }} to={`/secure/contract/${row.original.id}`} title="Editar">
                <i className="far fa-edit"></i>
              </Link>
            )}
          {tipoUser && tipoUser.includes("S") && row.original.status == 90 && (
            <Link className="btn btn-sm btn-default" style={{ margin: "auto" }} onClick={() => reenviarContrato(row.original, true)} title="Reenviar">
              <i className="fa fa-ambulance"></i>
            </Link>
          )}
        </>
      </div>
    ),
  },
];

const columnsTotal = [
  {
    Header: "Status",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    accessor: "status",
    width: 70,
    Cell: (row) => (
      <span>
        <span
          style={{
            color:
              row.value === 0 || row.value === 8
                ? "#6A6C6F"
                : row.value === 1 || row.value === 9
                ? "#b9a463"
                : row.value === 3 || row.value === 4
                ? "#28A745"
                : row.value === 7
                ? "#a1ed09"
                : row.value === 2
                ? "#FFC107"
                : row.value === 5
                ? "#00b7ff"
                : row.value === 11
                ? "#cb09ed"
                : row.value === 6
                ? "#007BFF"
                : row.value === 90 || row.value === 10
                ? "#DC3545"
                : "#57d500",
            transition: "all .3s ease",
          }}
        >
          {row.original.idContratoAnterior ? (
            <span title="Averbação" key="averbacao" style={{ fontSize: "28px" }}>
              <i className="fa fa-file-alt"></i>
            </span>
          ) : (
            <span title="Contrato" key="contrato" style={{ fontSize: "28px" }}>
              <i className="fa fa-file"></i>
            </span>
          )}
        </span>
      </span>
    ),
  },
  {
    Header: "UF",
    accessor: "uf",
    width: 50,
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Registro",
    accessor: "numRegistro",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Contrato",
    accessor: "numero",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Credor",
    accessor: "row",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div>
        {row.original.razaoSocial}
        <br />
        {FieldNormalize.CNPJ(row.original.cnpj, row.original.cnpj)}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Devedor",
    accessor: "rowDevedor",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (rowDevedor) => (
      <div>
        {rowDevedor.original.devNome}
        <br />
        {rowDevedor.original.devCpfCnpj}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="align-middle text-center">
        {row.original.chassi.split(",").map((item) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  {
    Header: "Gravame",
    accessor: "rowGravame",
    headerClassName: "font-extra-bold",
    width: 100,
    Cell: (rowGravame) => (
      <div className="align-middle text-center">
        {rowGravame.original.numGravame.split(",").map((item, index) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  // {
  //   Header: "Placa",
  //   accessor: "row",
  //   headerClassName: "font-extra-bold",
  //   Cell: (row) => (
  //     <div className="align-middle text-center">
  //       {row.original.ufPlaca.split(",").map((item, index) => {
  //         return (
  //           <span>
  //             {item} {row.original.numPlaca.split(",")[index]}
  //             <br />
  //           </span>
  //         );
  //       })}
  //     </div>
  //   ),
  // },
  {
    Header: "", // Custom header components!
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    width: 50,
    Cell: (row) => (
      <div className="btn-group-vertical">
        <>
          {func21 && (
            <Link className="btn btn-sm btn-default" style={{ margin: "auto" }} to={`/secure/contract/view/${row.original.id}`} title="Visualizar">
              <i className="fa fa-info-circle"></i>
            </Link>
          )}
          {row.original.status != 1 &&
            row.original.status != 3 &&
            row.original.status != 4 &&
            row.original.status != 5 &&
            row.original.status != 6 &&
            row.original.status != 7 &&
            row.original.status != 9 &&
            row.original.status != 11 &&
            func22 && (
              <Link className="btn btn-sm btn-default" style={{ margin: "auto" }} to={`/secure/contract/${row.original.id}`} title="Editar">
                <i className="far fa-edit"></i>
              </Link>
            )}
          {tipoUser && tipoUser.includes("S") && row.original.status == 90 && (
            <Link className="btn btn-sm btn-default" style={{ margin: "auto" }} onClick={() => reenviarContrato(row.original, false)} title="Reenviar">
              <i className="fa fa-ambulance"></i>
            </Link>
          )}
        </>
      </div>
    ),
  },
];

const columnsNoImage = [
  {
    Header: "Status",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    accessor: "status",
    width: 70,
    Cell: (row) => (
      <span>
        <span
          style={{
            color:
              row.value === 0 || row.value === 8
                ? "#6A6C6F"
                : row.value === 1 || row.value === 9
                ? "#b9a463"
                : row.value === 2
                ? "#FFC107"
                : row.value === 90 || row.value === 10
                ? "#DC3545"
                : "#57d500",
            transition: "all .3s ease",
          }}
        >
          {row.original.idContratoAnterior ? (
            <span title="Averbação" key="averbacao" style={{ fontSize: "28px" }}>
              <i className="fa fa-file-alt"></i>
            </span>
          ) : (
            <span title="Contrato" key="contrato" style={{ fontSize: "28px" }}>
              <i className="fa fa-file"></i>
            </span>
          )}
        </span>
      </span>
    ),
  },
  {
    Header: "UF",
    accessor: "uf",
    width: 50,
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Registro",
    accessor: "numRegistro",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Contrato",
    accessor: "numero",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Credor",
    accessor: "row",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div>
        {row.original.razaoSocial}
        <br />
        {FieldNormalize.CNPJ(row.original.cnpjFinanceira, row.original.cnpjFinanceira)}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Devedor",
    accessor: "rowDevedor",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (rowDevedor) => (
      <div>
        {rowDevedor.original.devNome}
        <br />
        {rowDevedor.original.devCpfCnpj}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="align-middle text-center">
        {row.original.chassi.split(",").map((item) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  {
    Header: "Gravame",
    accessor: "rowGravame",
    headerClassName: "font-extra-bold",
    width: 100,
    Cell: (rowGravame) => (
      <div className="align-middle text-center">
        {rowGravame.original.numGravame.split(",").map((item, index) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  // {
  //   Header: "Placa",
  //   accessor: "row",
  //   headerClassName: "font-extra-bold",
  //   Cell: (row) => (
  //     <div className="align-middle text-center">
  //       {row.original.ufPlaca.split(",").map((item, index) => {
  //         return (
  //           <span>
  //             {item} {row.original.numPlaca.split(",")[index]}
  //             <br />
  //           </span>
  //         );
  //       })}
  //     </div>
  //   ),
  // },
  {
    Header: "", // Custom header components!
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    width: 50,
    Cell: (row) => (
      <div className="btn-group-vertical">
        {func21 && (
          <Link className="btn btn-sm btn-default" style={{ margin: "auto" }} to={`/secure/contract/view/${row.original.id}`} title="Visualizar">
            <i className="fa fa-info-circle"></i>
          </Link>
        )}
        {row.original.status != 3 && row.original.status != 4 && row.original.status != 5 && row.original.status != 6 && row.original.status != 7 && func22 && (
          <Link className="btn btn-sm btn-default" style={{ margin: "auto" }} to={`/secure/contract/${row.original.id}`} title="Editar">
            <i className="far fa-edit"></i>
          </Link>
        )}
      </div>
    ),
  },
];

const columnsRegistered = [
  {
    Header: "Status",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    accessor: "status",
    width: 70,
    Cell: (row) => (
      <span>
        <span
          style={{
            color:
              row.value === 0 || row.value === 8
                ? "#6A6C6F"
                : row.value === 1 || row.value === 9
                ? "#b9a463"
                : row.value === 2
                ? "#FFC107"
                : row.value === 3
                ? "#28A745"
                : row.value === 4
                ? "#28A745" //Antigo homologado
                : row.value === 7
                ? "#a1ed09"
                : row.value === 90 || row.value === 10
                ? "#DC3545"
                : "#57d500",
            transition: "all .3s ease",
          }}
        >
          {row.original.idContratoAnterior ? (
            <span title="Averbação" key="averbacao" style={{ fontSize: "28px" }}>
              <i className="fa fa-file-alt"></i>
            </span>
          ) : (
            <span title="Contrato" key="contrato" style={{ fontSize: "28px" }}>
              <i className="fa fa-file"></i>
            </span>
          )}
        </span>
      </span>
    ),
  },
  {
    Header: "UF",
    accessor: "uf",
    width: 50,
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Registro",
    accessor: "numRegistro",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Contrato",
    accessor: "numero",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Credor",
    accessor: "row",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div>
        {row.original.razaoSocial}
        <br />
        {FieldNormalize.CNPJ(row.original.cnpj, row.original.cnpj)}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Devedor",
    accessor: "rowDevedor",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (rowDevedor) => (
      <div>
        {rowDevedor.original.devNome}
        <br />
        {rowDevedor.original.devCpfCnpj}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="align-middle text-center">
        {row.original.chassi.split(",").map((item) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  {
    Header: "Gravame",
    accessor: "rowGravame",
    headerClassName: "font-extra-bold",
    width: 100,
    Cell: (rowGravame) => (
      <div className="align-middle text-center">
        {rowGravame.original.numGravame.split(",").map((item, index) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  // {
  //   Header: "Placa",
  //   accessor: "row",
  //   headerClassName: "font-extra-bold",
  //   Cell: (row) => (
  //     <div className="align-middle text-center">
  //       {row.original.ufPlaca.split(",").map((item, index) => {
  //         return (
  //           <span>
  //             {item} {row.original.numPlaca.split(",")[index]}
  //             <br />
  //           </span>
  //         );
  //       })}
  //     </div>
  //   ),
  // },
  {
    Header: "", // Custom header components!
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    width: 50,
    Cell: (row) => (
      <div className="btn-group-vertical">
        {func21 && (
          <Link className="btn btn-sm btn-default" to={`/secure/contract/view/${row.original.id}`} title="Visualizar">
            <i className="fa fa-info-circle"></i>
          </Link>
        )}
      </div>
    ),
  },
];

class ContractList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLegend: false,
      showFilters: false,
      pesquisando: false,
      exibeModalReenvio: false,
      idsContratosReenvio: [],
      idContratoSelReenvio: null,
      descricaoErro: null,
    };
    this.myRef = null;
    this.myRefFilters = null;
    this.myRefDivSuperior = null;
    document.addEventListener("keyup", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    if (event.keyCode === 13) {
      if (this.props.selectedFilter === "NR" && document.getElementById("filterNR")) {
        document.getElementById("filterNR").click();
      } else if (this.props.selectedFilter === "T" && document.getElementById("filterT")) {
        document.getElementById("filterT").click();
      } else if (this.props.selectedFilter === "NI" && document.getElementById("filterNI")) {
        document.getElementById("filterNI").click();
      } else if (this.props.selectedFilter === "R" && document.getElementById("filterR")) {
        document.getElementById("filterR").click();
      }
    }
  };

  exportToPDF() {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();

    doc.page = 1;
    doc.setFontSize(12);

    var startY = 50;

    var footer = function () {
      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    const selected = this.props.selectedFilter;
    var willDrawCell = function (data) {
      if (data.column.index === 0 && data.section === "body" && selected === "NR" && (data.row.raw[8] === 90 || data.row.raw[8] === 10)) {
        data.doc.setTextColor(220, 53, 69);
      }
    };

    var title = "";
    var fileName = "";
    var content = {};
    var body = [];
    var data;
    var head = [
      [
        { content: "Status" },
        { content: "UF" },
        { content: "Registro" },
        { content: "Contrato" },
        { content: "Credor / CNPJ" },
        { content: "Devedor / CPF - CNPJ" },
        { content: "Chassi " },
        { content: "Gravame" },
        //{ content: "Placa" },
        { content: "Espécie/Tipo" },
      ],
    ];
    var styles = {
      cellPadding: 1.5,
      overflow: "linebreak",
      valign: "middle",
      halign: "center",
      lineColor: [0, 0, 0],
      lineWidth: 0.2,
      fontSize: 10,
    };

    if (this.props.selectedFilter == "NI") {
      title = "Relatório de Contratos - Sem Imagem";
      fileName = "RelatorioContratos-Sem-Imagem";
      data = this.props.noImage;
    } else if (this.props.selectedFilter == "R") {
      title = "Relatório de Contratos - Registrados";
      fileName = "RelatorioContratos-Registrados";
      data = this.props.registered;
      styles.fontSize = 9;
    } else if (this.props.selectedFilter == "NR") {
      title = "Relatório de Contratos - Não Registrados";
      fileName = "RelatorioContratos-Não-Registrados";
      data = this.props.notRegistred;
      styles.fontSize = 8;
    } else {
      title = "Relatório de Contratos - Não Registrados";
      fileName = "RelatorioContratos-Não-Registrados";
      data = this.props.total;
      styles.fontSize = 8;
    }

    body = data.map((row) => [
      row.status === 0 || row.status === 8
        ? "Contrato Criado"
        : row.status === 1 || row.status === 9
        ? "Contrato Aguardando Detran"
        : row.status === 2
        ? "Contrato Registrado"
        : row.status === 3
        ? "Imagem do Contrato Enviada"
        : row.status === 5
        ? "Contrato Baixado"
        : row.status === 6
        ? "Contrato Cancelado"
        : row.status === 7
        ? "Contrato Averbado"
        : row.status === 11
        ? "Contrato Em Processamento"
        : row.status === 90
        ? "Erro no registro do Contrato"
        : "Não tratado",
      row.uf,
      row.numRegistro,
      row.numero,
      row.razaoSocial + " / " + FieldNormalize.CNPJ(row.cnpjFinanceira),
      row.devNome + " / " + FieldNormalize.CPF_CNPJ(row.devCpfCnpj),
      row.chassi.split(",").join(", "),
      row.numGravame.split(",").join(", "),
      // row.ufPlaca
      //   .split(",")
      //   .map((item, index) => {
      //     return item + " " + row.numPlaca.split(",")[index];
      //   })
      //   .join(", "),
      row.especieVeiculo.split(",").join(", "),
    ]);

    content = {
      willDrawCell,
      startY,
      head,
      body,
      bodyStyles: { valign: "top" },
      styles,
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.save(`${fileName}.pdf`);
  }

  exportToCSV = (type) => {
    var head = [["Status", "UF", "Registro", "Contrato", "Data Criação Contrato", "Credor / CNPJ", "Devedor / CPF - CNPJ", "Chassi", "Gravame", "Placa", "Espécie/Tipo"]];
    var ref = ["idContratoAnterior", "uf", "numRegistro", "numero", "dataCriacao", "razaoSocial", "devNome", "chassi", "numGravame", "placa", "especieVeiculo"];
    var fileName = "RelatorioContratos";
    var data;

    if (this.props.selectedFilter == "NI") {
      data = this.props.noImage;
    } else if (this.props.selectedFilter == "R") {
      data = this.props.registered;
    } else if (this.props.selectedFilter == "NR") {
      data = this.props.notRegistred;
    } else {
      data = this.props.total;
    }

    var result = data.map((row) => {
      return {
        idContratoAnterior: row.idContratoAnterior ? "Averbação" : "Contrato",
        uf: row.uf,
        numRegistro: row.numRegistro,
        numero: row.numero,
        dataCriacao: MaskDate.TO_BR_DATETIME(row.dataCriacao),
        razaoSocial: row.razaoSocial + " / " + FieldNormalize.CNPJ(row.cnpjFinanceira, row.cnpjFinanceira),
        devNome: row.devNome + " / " + FieldNormalize.CPF_CNPJ(row.devCpfCnpj),
        chassi: row.chassi.split(",").join(", "),
        numGravame: row.numGravame.split(",").join(", "),
        placa: row.ufPlaca
          .split(",")
          .map((item, index) => {
            return item + " " + row.numPlaca.split(",")[index];
          })
          .join(", "),
        especieVeiculo: row.especieVeiculo.split(",").join(", "),
        erro: row.msgComunicacaoDetran && JSON.parse(row.msgComunicacaoDetran)[0] ? JSON.parse(row.msgComunicacaoDetran)[0].descricao : "",
      };
    });

    if (this.props.selectedFilter === "NR" || this.props.selectedFilter === "T") {
      head[0].push("Mensagem Erro");
      ref.push("erro");
    } else {
      result.forEach((item) => {
        delete item.erro;
      });
    }

    var ws = XLSX.utils.aoa_to_sheet(head);
    XLSX.utils.sheet_add_json(ws, result, {
      header: ref,
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${fileName}.xlsx`);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedEnderecoUfDevedor) {
      if (this.props.selectedEnderecoUfDevedor !== nextProps.selectedEnderecoUfDevedor) {
        this.props.dispatch(change("ContractListForm", "devMunicipio", null));
      }
    }
  }

  componentWillMount() {
    var uf;
    if (localStorage.getItem("uf_home")) {
      uf = localStorage.getItem("uf_home");
      localStorage.removeItem("uf_home");
    }
    let filtros = JSON.parse(window.localStorage.getItem("filtros"));
    //Promise.resolve(api.Companies.authorized(this.props.currentUser.idFinanceira)).then((cs) => {
    //this.props.onCompaniesLoad(cs);
    this.props.onQtdNaoEnvSemImg(api.Contracts.getQtdNaoEnvSemImg());
    if (filtros) {
      this.props.onSelectedFilterChange(filtros.selectedFilter);
      this.preencheFiltros(filtros);
      if (filtros.selectedFilter === "NR") {
        this.filter(filtros);
      } else if (filtros.selectedFilter === "NI") {
        this.filterNI(filtros);
      } else if (filtros.selectedFilter === "R") {
        this.filterR(filtros);
      } else if (filtros.selectedFilter === "T") {
        this.filterT(filtros);
      }
    } else {
      if (this.props.params.slug) {
        if (this.props.params.slug === "NI") {
          var dataI;
          var dataF;
          if (this.props.params.dataI) {
            dataI = this.props.params.dataI;
          }
          if (this.props.params.dataF) {
            dataF = this.props.params.dataF;
          }

          this.props.onSelectedFilterChange("NI");
          var promises = [
            api.Contracts.getRegistered(
              0,
              statusFiltrosAbas["NI"].status,
              null,
              null,
              null,
              null,
              null,
              dataI && dataF ? MaskDate.TO_DATABASE_DATE(dataI) : null,
              dataI && dataF ? MaskDate.TO_DATABASE_DATE(dataF) : dataI ? MaskDate.TO_DATABASE_DATE(dataI) : null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              "0",
              uf
            ),
          ];

          // var promises = this.props.companies.map(function (obj) {
          //   return api.Contracts.getRegistered(
          //     obj.cnpj,
          //     statusFiltrosAbas["NI"].status,
          //     null,
          //     null,
          //     null,
          //     null,
          //     null,
          //     dataI && dataF ? MaskDate.TO_DATABASE_DATE(dataI) : null,
          //     dataI && dataF ? MaskDate.TO_DATABASE_DATE(dataF) : dataI ? MaskDate.TO_DATABASE_DATE(dataI) : null,
          //     null,
          //     null,
          //     null,
          //     null,
          //     null,
          //     null,
          //     null,
          //     null,
          //     null,
          //     "0",
          //     uf
          //   );
          // });
          Promise.all(promises).then((results) => {
            let contratos = [];
            for (const result of results) {
              for (const contrato of result.contratos) {
                contratos = [...contratos, contrato];
              }
            }
            if (contratos.length > 0) {
              this.props.onNoImageLoad(contratos);
            }
          });
        } else {
          let paramFilter = "";
          if (this.props.params.slug === "ERR") {
            this.props.onSelectedFilterChange("NR");
            paramFilter = "ERR";
            this.props.dispatch(change("ContractListForm", "status", 90));
          }
          if (this.props.params.slug === "NR") {
            this.props.onSelectedFilterChange("NR");
            paramFilter = "NR2";
          }
          if (this.props.params.slug === "R") {
            this.props.onSelectedFilterChange("R");
            paramFilter = "R";
          }
          if (this.props.params.slug === "CB") {
            this.props.onSelectedFilterChange("T");
            paramFilter = "CB";
          }
          if (this.props.companies) {
            var promises = [
              api.Contracts.getRegistered(0, statusFiltrosAbas[paramFilter].status, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, uf),
            ];
            // var promises = this.props.companies.map(function (obj) {
            //   return api.Contracts.getRegistered(
            //     obj.cnpj,
            //     statusFiltrosAbas[paramFilter].status,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     null,
            //     uf
            //   );
            // });
            Promise.all(promises).then((results) => {
              let contratos = [];
              for (const result of results) {
                for (const contrato of result.contratos) {
                  contratos = [...contratos, contrato];
                }
              }
              if (contratos.length > 0) {
                if (paramFilter === "ERR" || paramFilter === "NR2") {
                  this.props.onNotRegistredLoad(contratos);
                } else if (paramFilter === "R") {
                  this.props.onRegisteredLoad(contratos);
                } else if (paramFilter === "CB") {
                  this.props.onTotalLoad(contratos);
                }
              }
            });
          }
        }
      } else {
        this.props.onSelectedFilterChange("T");
        this.props.dispatch(change("ContractListForm", "status", "90"));
        if (this.props.companies) {
          var promises = [api.Contracts.getRegistered(0, statusFiltrosAbas["ERR"].status, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, uf)];
          // var promises = this.props.companies.map(function (obj) {
          //   return api.Contracts.getRegistered(obj.cnpj, statusFiltrosAbas["ERR"].status, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, uf);
          // });
          Promise.all(promises).then((results) => {
            let contratos = [];
            for (const result of results) {
              for (const contrato of result.contratos) {
                contratos = [...contratos, contrato];
              }
            }
            if (contratos.length > 0) {
              this.props.onTotalLoad(contratos);
            }
          });
        }
      }
    }
    //});
    func21 = this.props.currentUser.funcionalidades.includes(21); //Visualizar contrato
    func22 = this.props.currentUser.funcionalidades.includes(22); //Editar contrato
    tipoUser = this.props.currentUser.tipo;
    reenviarContrato = this.reenviarContrato.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyDownHandler);
    this.props.onUnload();
  }

  preencheFiltros(values) {
    this.props.dispatch(change("ContractListForm", "chassi", values.chassi));
    this.props.dispatch(change("ContractListForm", "credor", values.credor));
    this.props.dispatch(change("ContractListForm", "uf", values.uf));
    this.props.dispatch(change("ContractListForm", "numero", values.numero));
    this.props.dispatch(change("ContractListForm", "registro", values.registro));
    this.props.dispatch(change("ContractListForm", "tipoContrato", values.tipoContrato));
    this.props.dispatch(change("ContractListForm", "devNome", values.devNome));
    this.props.dispatch(change("ContractListForm", "devCpfCnpj", values.devCpfCnpj));
    this.props.dispatch(change("ContractListForm", "status", values.status));
    this.props.dispatch(change("ContractListForm", "dataGravame", values.dataGravame));
    this.props.dispatch(change("ContractListForm", "numGravame", values.numGravame));
    this.props.dispatch(change("ContractListForm", "renavam", values.renavam));
    this.props.dispatch(change("ContractListForm", "marcaModelo", values.marcaModelo));
    this.props.dispatch(change("ContractListForm", "placa", values.placa));
    this.props.dispatch(change("ContractListForm", "ano", values.ano));
    this.props.dispatch(change("ContractListForm", "tpPesquisaData", values.tpPesquisaData));
    this.props.dispatch(change("ContractListForm", "dtInicial", values.dtInicial));
    this.props.dispatch(change("ContractListForm", "dtFinal", values.dtFinal));
    this.props.dispatch(change("ContractListForm", "devUf", values.devUf));
    this.props.dispatch(change("ContractListForm", "devMunicipio", values.devMunicipio));
  }

  handleChangeFilterToNotRegistered = () => (ev) => {
    ev.preventDefault();
    this.props.dispatch(change("ContractListForm", "uf", "Todas"));
    this.props.dispatch(change("ContractListForm", "status", ""));
    this.props.onSelectedFilterChange("NR");
    this.handleAutoScroll("table");
    var promises = [api.Contracts.getRegistered(0, statusFiltrosAbas["NR"].status, null)];
    // var promises = this.props.companies.map(function (obj) {
    //   return api.Contracts.getRegistered(obj.cnpj, statusFiltrosAbas["NR"].status, null);
    // });
    Promise.all(promises).then((results) => {
      let contratos = [];
      results.forEach(function (value) {
        if (value.contratos && value.contratos.length > 0) {
          contratos = contratos.concat(value.contratos);
        }
      });
      if (contratos.length > 0) {
        this.props.onNotRegistredLoad(contratos);
      }
    });
  };

  handleChangeFilterToTotal = () => (ev) => {
    ev.preventDefault();
    this.props.onSelectedFilterChange("T");
    this.props.dispatch(change("ContractListForm", "uf", "Todas"));
    this.props.dispatch(change("ContractListForm", "status", "90"));
    this.handleAutoScroll("table");

    var promises = [api.Contracts.getRegistered(0, statusFiltrosAbas["ERR"].status, null)];
    // var promises = this.props.companies.map(function (obj) {
    //   return api.Contracts.getRegistered(obj.cnpj, statusFiltrosAbas["ERR"].status, null);
    // });
    Promise.all(promises).then((results) => {
      let contratos = [];
      results.forEach(function (value) {
        if (value.contratos && value.contratos.length > 0) {
          contratos = contratos.concat(value.contratos);
        }
      });
      if (contratos.length > 0) {
        this.props.onTotalLoad(contratos);
      }
    });
  };

  handleChangeFilterToNoImage = () => (ev) => {
    ev.preventDefault();
    this.props.onSelectedFilterChange("NI");
    this.props.dispatch(change("ContractListForm", "uf", "Todas"));
    var promises = [api.Contracts.getRegistered(0, statusFiltrosAbas["NI"].status, null)];
    // var promises = this.props.companies.map(function (obj) {
    //   return api.Contracts.getRegistered(obj.cnpj, statusFiltrosAbas["NI"].status, null);
    // });
    Promise.all(promises).then((results) => {
      let contratos = [];
      results.forEach(function (value) {
        if (value.contratos && value.contratos.length > 0) {
          contratos = contratos.concat(value.contratos);
        }
      });
      if (contratos.length > 0) {
        this.props.onNoImageLoad(contratos);
      }
    });
    this.handleAutoScroll("table");
  };

  handleChangeFilterToRegistered = () => (ev) => {
    ev.preventDefault();
    this.props.onSelectedFilterChange("R");
    this.props.dispatch(change("ContractListForm", "uf", "Todas"));
    var promises = [api.Contracts.getRegistered(0, statusFiltrosAbas["R"].status, null)];
    // var promises = this.props.companies.map(function (obj) {
    //   return api.Contracts.getRegistered(obj.cnpj, statusFiltrosAbas["R"].status, null);
    // });
    Promise.all(promises).then((results) => {
      let contratos = [];
      results.forEach(function (value) {
        if (value.contratos && value.contratos.length > 0) {
          contratos = contratos.concat(value.contratos);
        }
      });
      if (contratos.length > 0) {
        this.props.onRegisteredLoad(contratos);
      }
    });
    this.handleAutoScroll("table");
  };

  async filter(values) {
    this.setState({ pesquisando: true });
    this.armazenaFiltros(values);
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    var promises = [];
    var params = [
      statusFiltrosAbas["NR"].status,
      values.registro,
      values.numero,
      values.devCpfCnpj,
      values.renavam,
      values.chassi ? values.chassi.trim() : values.chassi,
      MaskDate.TO_DATABASE_DATE(values.dtInicial),
      MaskDate.TO_DATABASE_DATE(values.dtFinal),
      values.devNome,
      values.devUf,
      values.devMunicipio,
      values.tipoContrato,
      values.marcaModelo,
      values.placa,
      values.ano,
      MaskDate.TO_DATABASE_DATE(values.dataGravame),
      null,
      values.tpPesquisaData,
      values.uf,
      values.numGravame,
    ];
    if (values.status) {
      params[0] = values.status;
    }
    if (values.credor) {
      var filtered = this.props.companies.filter((comp) => comp.id == values.credor.id);
      promises = [api.Contracts.getRegistered(filtered[0].cnpj, ...Object.values(params))];
    } else {
      promises = [api.Contracts.getRegistered(0, ...Object.values(params))];

      // promises = this.props.companies.map(function (obj) {
      //   return api.Contracts.getRegistered(obj.cnpj, ...Object.values(params));
      // });
    }
    Promise.all(promises).then((results) => {
      let contratos = [];
      results.forEach(function (value) {
        if (value.contratos && value.contratos.length > 0) {
          contratos = contratos.concat(value.contratos);
        }
      });
      this.setState({ pesquisando: false });
      toastr.removeByType("info");
      this.props.onNotRegistredLoad(contratos);
      if (contratos.length == 0) {
        toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
      }
    });
  }

  filterNI(values) {
    this.setState({ pesquisando: true });
    this.armazenaFiltros(values);
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    var promises = [];
    var params = [
      statusFiltrosAbas["NI"].status,
      values.registro,
      values.numero,
      values.devCpfCnpj,
      values.renavam,
      values.chassi ? values.chassi.trim() : values.chassi,
      MaskDate.TO_DATABASE_DATE(values.dtInicial),
      MaskDate.TO_DATABASE_DATE(values.dtFinal),
      values.devNome,
      values.devUf,
      values.devMunicipio,
      values.tipoContrato,
      values.marcaModelo,
      values.placa,
      values.ano,
      MaskDate.TO_DATABASE_DATE(values.dataGravame),
      null,
      values.tpPesquisaData,
      values.uf,
      values.numGravame,
    ];
    if (values.credor) {
      var filtered = this.props.companies.filter((comp) => comp.id == values.credor.id);
      promises = [api.Contracts.getRegistered(filtered[0].cnpj, ...Object.values(params))];
    } else {
      promises = [api.Contracts.getRegistered(0, ...Object.values(params))];
      // promises = this.props.companies.map(function (obj) {
      //   return api.Contracts.getRegistered(obj.cnpj, ...Object.values(params));
      // });
    }
    Promise.all(promises).then((results) => {
      let contratos = [];
      results.forEach(function (value) {
        if (value.contratos && value.contratos.length > 0) {
          contratos = contratos.concat(value.contratos);
        }
      });
      this.setState({ pesquisando: false });
      toastr.removeByType("info");
      this.props.onNoImageLoad(contratos);
      if (contratos.length == 0) {
        toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
      }
    });
  }

  filterT = (values) => {
    this.setState({ pesquisando: true });
    this.armazenaFiltros(values);
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });

    var promises = [];
    var params = [
      statusFiltrosAbas["TODOS"].status,
      values.registro,
      values.numero,
      values.devCpfCnpj,
      values.renavam,
      values.chassi ? values.chassi.trim() : values.chassi,
      MaskDate.TO_DATABASE_DATE(values.dtInicial),
      MaskDate.TO_DATABASE_DATE(values.dtFinal),
      values.devNome,
      values.devUf,
      values.devMunicipio,
      values.tipoContrato,
      values.marcaModelo,
      values.placa,
      values.ano,
      MaskDate.TO_DATABASE_DATE(values.dataGravame),
      null,
      values.tpPesquisaData,
      values.uf,
      values.numGravame,
    ];
    if (values.status) {
      params[0] = values.status;
    }
    if (values.credor) {
      var filtered = this.props.companies.filter((comp) => comp.id == values.credor.id);
      promises = [api.Contracts.getRegistered(filtered[0].cnpj, ...Object.values(params))];
    } else {
      promises = [api.Contracts.getRegistered(0, ...Object.values(params))];
      // promises = this.props.companies.map(function (obj) {
      //   return api.Contracts.getRegistered(obj.cnpj, ...Object.values(params));
      // });
    }
    Promise.all(promises).then((results) => {
      let contratos = [];
      results.forEach(function (value) {
        if (value.contratos && value.contratos.length > 0) {
          contratos = contratos.concat(value.contratos);
        }
      });
      this.setState({ pesquisando: false });
      toastr.removeByType("info");
      this.props.onTotalLoad(contratos);
      if (contratos.length == 0) {
        toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
      }
    });
  };

  filterR(values) {
    this.setState({ pesquisando: true });
    this.armazenaFiltros(values);
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    var promises = [];
    var params = [
      statusFiltrosAbas["R"].status,
      values.registro,
      values.numero,
      values.devCpfCnpj,
      values.renavam,
      values.chassi ? values.chassi.trim() : values.chassi,
      MaskDate.TO_DATABASE_DATE(values.dtInicial),
      MaskDate.TO_DATABASE_DATE(values.dtFinal),
      values.devNome,
      values.devUf,
      values.devMunicipio,
      values.tipoContrato,
      values.marcaModelo,
      values.placa,
      values.ano,
      MaskDate.TO_DATABASE_DATE(values.dataGravame),
      null,
      values.tpPesquisaData,
      values.uf,
      values.numGravame,
    ];
    if (values.credor) {
      var filtered = this.props.companies.filter((comp) => comp.id == values.credor.id);
      var promises = [api.Contracts.getRegistered(filtered[0].cnpj, ...Object.values(params))];
    } else {
      var promises = [api.Contracts.getRegistered(0, ...Object.values(params))];
      // var promises = this.props.companies.map(function (obj) {
      //   return api.Contracts.getRegistered(obj.cnpj, ...Object.values(params));
      // });
    }
    Promise.all(promises).then((results) => {
      let contratos = [];
      results.forEach(function (value) {
        if (value.contratos && value.contratos.length > 0) {
          contratos = contratos.concat(value.contratos);
        }
      });
      this.setState({ pesquisando: false });
      toastr.removeByType("info");
      this.props.onRegisteredLoad(contratos);
      if (contratos.length == 0) {
        toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
      }
    });
  }

  handleAutoScroll(pos) {
    if (pos === "table") {
      window.scrollTo(0, this.myRefDivSuperior.offsetTop + this.myRef.offsetTop - 15);
    } else if (pos === "filters") {
      window.scrollTo(0, this.myRefFilters.offsetTop - 5);
    }
  }

  handleShowFilters() {
    if (this.state.showFilters) {
      this.props.dispatch(change("ContractListForm", "tpPesquisaData", null));
      this.props.dispatch(untouch("ContractListForm", "tpPesquisaData"));
      this.props.dispatch(change("ContractListForm", "dtInicial", null));
      this.props.dispatch(untouch("ContractListForm", "dtInicial"));
      this.props.dispatch(change("ContractListForm", "dtFinal", null));
      this.props.dispatch(untouch("ContractListForm", "dtFinal"));
    }
    this.setState({ showFilters: !this.state.showFilters });
    this.handleAutoScroll("filters");
  }

  reenviarContrato(contrato, notRegistred) {
    if (contrato.msgComunicacaoDetran) {
      let erro = JSON.parse(contrato.msgComunicacaoDetran)[0];
      let contratos = [];
      if (notRegistred) {
        contratos = this.props.notRegistred
          .filter(
            (item) =>
              item.status == 90 && item.msgComunicacaoDetran && JSON.parse(item.msgComunicacaoDetran)[0].codigo == erro.codigo && JSON.parse(item.msgComunicacaoDetran)[0].descricao == erro.descricao
          )
          .map((item) => item.id);
      } else {
        contratos = this.props.total
          .filter(
            (item) =>
              item.status == 90 && item.msgComunicacaoDetran && JSON.parse(item.msgComunicacaoDetran)[0].codigo == erro.codigo && JSON.parse(item.msgComunicacaoDetran)[0].descricao == erro.descricao
          )
          .map((item) => item.id);
      }
      this.setState({ idsContratosReenvio: contratos, idContratoSelReenvio: contrato.id, descricaoErro: erro.descricao, exibeModalReenvio: true });
    }
  }

  reenviarContratos(todos) {
    var props = this.props;
    let ids = todos ? this.state.idsContratosReenvio : [this.state.idContratoSelReenvio];

    Promise.resolve(api.Contracts.reenviarContratos({ idsContrato: ids }))
      .then((ret) => {
        if (this.props.selectedFilter == "T") {
          this.handleChangeFilterToTotal();
        } else {
          this.handleChangeFilterToNotRegistered();
        }
        this.setState({ idsContratosReenvio: [], idContratoSelReenvio: null, descricaoErro: null, exibeModalReenvio: false });
        toastr.success("Sucesso", "Contratos reenviados para processamento. Dentro de alguns minutos os contratos serão processados.");
      })
      .catch((ret) => {
        if (ret.message === "Locked") {
          this.setState({ idsContratosReenvio: [], idContratoSelReenvio: null, descricaoErro: null, exibeModalReenvio: false });
          toastr.warning("Atenção", "Ainda existem contratos sendo reenviados, favor aguardar alguns minutos e tente novamente!");
        } else if (ret.message === "Unauthorized" || ret.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  renderTotal() {
    if (this.props.selectedFilter !== "T") {
      return null;
    }
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.total}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={columnsTotal}
        defaultPageSize={10}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  renderNotRegistred() {
    if (this.props.selectedFilter !== "NR") {
      return null;
    }
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.notRegistred}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={columns}
        defaultPageSize={10}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  renderNoImage() {
    if (this.props.selectedFilter !== "NI") {
      return null;
    }
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.noImage}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={columnsNoImage}
        defaultPageSize={10}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  renderRegistered() {
    if (this.props.selectedFilter !== "R") {
      return null;
    }
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.registered}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={columnsRegistered}
        defaultPageSize={10}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  filterUf(values) {
    const { reset } = this.props;
    var uf = values.uf;
    if (this.props.anyTouched) reset();
    this.props.dispatch(change("ContractListForm", "uf", uf));
    if (this.props.selectedFilter === "R") {
      this.filterR({ uf });
    } else if (this.props.selectedFilter === "NI") {
      this.filterNI({ uf });
    } else if (this.props.selectedFilter === "T") {
      this.filterT({ uf });
    } else if (this.props.selectedFilter === "NR") {
      this.filter({ uf });
    }
  }

  armazenaFiltros(values) {
    let filtros = {
      selectedFilter: this.props.selectedFilter,
      chassi: values.chassi,
      credor: values.credor,
      uf: values.uf,
      numero: values.numero,
      registro: values.registro,
      tipoContrato: values.tipoContrato,
      devNome: values.devNome,
      devCpfCnpj: values.devCpfCnpj,
      status: values.status,
      dataGravame: values.dataGravame,
      numGravame: values.numGravame,
      renavam: values.renavam,
      marcaModelo: values.marcaModelo,
      placa: values.placa,
      ano: values.ano,
      tpPesquisaData: values.tpPesquisaData,
      dtInicial: values.dtInicial,
      dtFinal: values.dtFinal,
      devUf: values.devUf,
      devMunicipio: values.devMunicipio,
    };
    window.localStorage.setItem("filtros", JSON.stringify(filtros));
  }

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Lista de contratos" />{" "}
        <div className="content">
          <div ref={(ref) => (this.myRefFilters = ref)} className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="row">
                  <div className="col-md-2">
                    <Field name="uf" label="UF" data={this.props.currentUser.ufs} textField="sigla" component={SelectField} type="text" />
                  </div>
                  <div className="col-lg-1 align-self-end form-group">
                    <button className="btn btn-success" onClick={handleSubmit(this.filterUf.bind(this))} type="button">
                      Filtrar
                    </button>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="m-b-md">
                    <h4>Filtros</h4>
                    <small>Pesquise os veículos pelos filtros abaixo.</small>
                  </div>
                  <form className="form-horizontal">
                    <div className="m-b-md">
                      <ButtonGroup justified block>
                        <Button style={{ fontSize: "12px" }} bsStyle="success" bsSize="xsmall" active={this.props.selectedFilter === "T" ? true : false} onClick={this.handleChangeFilterToTotal()}>
                          Todos
                        </Button>
                        <ButtonWithBadge
                          label="Não registrado"
                          count={this.props.qtdContracts[0].naoEnviados}
                          active={this.props.selectedFilter === "NR"}
                          onClick={this.handleChangeFilterToNotRegistered()}
                        />
                        <ButtonWithBadge label="Sem imagem" count={this.props.qtdContracts[0].semImagem} active={this.props.selectedFilter === "NI"} onClick={this.handleChangeFilterToNoImage()} />
                        <Button
                          style={{ fontSize: "12px" }}
                          bsStyle="success"
                          bsSize="xsmall"
                          active={this.props.selectedFilter === "R" ? true : false}
                          onClick={this.handleChangeFilterToRegistered()}
                        >
                          Registrado
                        </Button>
                      </ButtonGroup>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <Field name="chassi" label="Chassi" component={TextField} validate={[FieldValidation.chassi]} type="text" inline={false} normalize={FieldNormalize.CHASSI} />
                      </div>
                      <div className="col-md-2">
                        <Field name="numGravame" label="Gravame" maxlength={8} normalize={FieldNormalize.NUMBER} component={TextField} type="text" />
                      </div>
                      <div className="col-md-2">
                        <Field name="numero" label="Número do contrato" component={TextField} type="text" inline={false} />
                      </div>
                      <div className="col-md-5">
                        <Field name="credor" blank={true} label="Credor" credor={true} data={this.props.companies} textField="razaoSocial" valueField="id" component={DropdownListField} type="text" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <Field
                          name="tipoContrato"
                          label="Tipo de Contrato"
                          blank={true}
                          data={[
                            {
                              id: "1",
                              name: "Alienação Fiduciária - Financiamento",
                            },
                            {
                              id: "2",
                              name: "Alienação Fiduciária - Consórcio",
                            },
                            {
                              id: "3",
                              name: "Alienação Fiduciária - Cédula de Crédito",
                            },
                            {
                              id: "4",
                              name: "Arrendamento Mercantil - Leasing",
                            },
                            { id: "5", name: "Reserva de Domínio" },
                            { id: "6", name: "Penhor" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div>
                      <div className="col-md-5">
                        <Field name="devNome" label="Nome do Devedor" component={TextField} type="text" inline={false} />
                      </div>
                      <div className="col-md-3">
                        <Field name="devCpfCnpj" label="CPF/CNPJ do Devedor" normalize={FieldNormalize.CPF_CNPJ} component={TextField} type="text" inline={false} />
                      </div>
                    </div>
                    {this.state.showFilters && (
                      <div className="row">
                        {this.props.selectedFilter === "NR" && (
                          <div className="col-md-4">
                            <Field
                              name="status"
                              label="Status"
                              blank={true}
                              data={[
                                { id: "0,8", desc: "Não Enviado" },
                                { id: "1,9", desc: "Aguardando DETRAN" },
                                {
                                  id: "90",
                                  desc: "Registro com erro",
                                },
                                { id: "11", desc: "Em Processamento" },
                              ]}
                              component={SelectField}
                              type="text"
                              textField="desc"
                              valueField="id"
                              description="Status do registro do veículo"
                            />
                          </div>
                        )}
                        {this.props.selectedFilter === "T" && (
                          <div className="col-md-4">
                            <Field
                              name="status"
                              label="Status"
                              blank={true}
                              data={[
                                { id: "0,8", desc: "Não Enviado" },
                                { id: "1,9", desc: "Aguardando DETRAN" },
                                { id: "2", desc: "Registrado sem Imagem" },
                                { id: "3,4,7", desc: "Registrado" },
                                {
                                  id: "90",
                                  desc: "Registro com erro",
                                },
                                { id: "5", desc: "Baixado" },
                                { id: "6", desc: "Cancelado" },
                                { id: "11", desc: "Em Processamento" },
                              ]}
                              component={SelectField}
                              type="text"
                              textField="desc"
                              valueField="id"
                              description="Status do registro do veículo"
                            />
                          </div>
                        )}
                        <div className="col-md-2">
                          <Field
                            name="dataGravame"
                            label="Data do Gravame"
                            validate={[FieldValidation.date]}
                            normalize={FieldNormalize.DATE}
                            placeholder="00/00/0000"
                            data={this.props.dataGravame}
                            component={TextDatePickerField}
                            type="text"
                            inline={false}
                          />
                        </div>
                        <div className={this.props.selectedFilter === "NI" || this.props.selectedFilter === "R" ? "col-md-4" : "col-md-3"}>
                          <Field name="renavam" label="Renavam" component={TextField} type="text" inline={false} />
                        </div>
                        {(this.props.selectedFilter === "NI" || this.props.selectedFilter === "R") && (
                          <div className="col-md-3">
                            <Field name="registro" label="Número de registro" component={TextField} type="text" inline={false} />
                          </div>
                        )}
                      </div>
                    )}
                    {this.state.showFilters && (
                      <div className="row">
                        <div className="col-md-4">
                          <Field name="marcaModelo" label="Marca/Modelo" component={TextField} type="text" inline={false} />
                        </div>
                        <div className="col-md-4">
                          <Field name="placa" label="Placa" component={TextField} type="text" maxlength={11} inline={false} />
                        </div>
                        <div className="col-md-4">
                          <Field name="ano" label="Ano de Fabricação" component={TextField} type="text" maxlength={4} inline={false} />
                        </div>
                        {(this.props.selectedFilter === "NR" || this.props.selectedFilter === "T") && (
                          <div className="col-md-3">
                            <Field
                              name="tpPesquisaData"
                              label="Pesquisar por"
                              blank={true}
                              data={[
                                { id: 1, desc: "Data de Cadastro" },
                                { id: 2, desc: "Data do Contrato" },
                              ]}
                              component={SelectField}
                              type="text"
                              textField="desc"
                              valueField="id"
                            />
                          </div>
                        )}
                        {(this.props.selectedFilter === "NI" || this.props.selectedFilter === "R") && (
                          <div className="col-md-3">
                            <Field
                              name="tpPesquisaData"
                              label="Pesquisar por"
                              blank={true}
                              data={[
                                { id: 0, desc: "Data de Registro" },
                                { id: 1, desc: "Data de Cadastro" },
                                { id: 2, desc: "Data do Contrato" },
                              ]}
                              component={SelectField}
                              type="text"
                              textField="desc"
                              valueField="id"
                            />
                          </div>
                        )}
                        <div className="col-md-2">
                          <Field
                            name="dtInicial"
                            label="Data de início"
                            validate={[FieldValidation.date]}
                            normalize={FieldNormalize.DATE}
                            placeholder="00/00/0000"
                            data={this.props.dtInicial}
                            component={TextDatePickerField}
                            type="text"
                            inline={false}
                          />
                        </div>
                        <div className="col-md-2">
                          <Field
                            name="dtFinal"
                            label="Data de fim"
                            validate={[FieldValidation.date]}
                            normalize={FieldNormalize.DATE}
                            placeholder="00/00/0000"
                            data={this.props.dtFinal}
                            component={TextDatePickerField}
                            type="text"
                            inline={false}
                          />
                        </div>
                        <div className="col-md-2">
                          <UfField name="devUf" label="UF" description="UF do devedor" inline={false} />
                        </div>
                        <div className="col-md-3">
                          <CityField
                            name="devMunicipio"
                            label="Município"
                            description="Município do devedor"
                            valueField="municipioId"
                            selectedUf={this.props.selectedEnderecoUfDevedor}
                            inline={false}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link
                            className="btn btn-secondary"
                            type="button"
                            style={{ color: "white" }}
                            onClick={() => {
                              this.handleShowFilters();
                            }}
                          >
                            {this.state.showFilters ? "Ocultar" : "+"} Filtros
                          </Link>
                          &nbsp;
                          {this.props.selectedFilter === "NR" && (
                            <button
                              className="btn btn-success"
                              disabled={this.state.pesquisando}
                              id="filterNR"
                              onClick={this.props.handleSubmit((values) => {
                                return new Promise((resolve, reject) => {
                                  const errors = validate(values);
                                  if (errors && !_.isEmpty(errors)) {
                                    reject(new SubmissionError(errors));
                                  } else {
                                    if (!this.state.showFilters) {
                                      const { credor, numero, tipoContrato, devNome, devCpfCnpj, chassi, numGravame } = values;
                                      const smallFilterValues = {
                                        credor,
                                        numero,
                                        tipoContrato,
                                        devNome,
                                        devCpfCnpj,
                                        chassi,
                                        numGravame,
                                      };
                                      this.filter(smallFilterValues);
                                    } else {
                                      this.filter(values);
                                    }
                                    this.handleAutoScroll("table");
                                    resolve();
                                  }
                                });
                              })}
                            >
                              {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                            </button>
                          )}
                          {this.props.selectedFilter === "T" && (
                            <button
                              className="btn btn-success"
                              disabled={this.state.pesquisando}
                              id="filterT"
                              onClick={this.props.handleSubmit((values) => {
                                return new Promise((resolve, reject) => {
                                  const errors = validate(values);
                                  if (errors && !_.isEmpty(errors)) {
                                    reject(new SubmissionError(errors));
                                  } else {
                                    if (!this.state.showFilters) {
                                      const { credor, numero, numGravame, tipoContrato, devNome, devCpfCnpj, chassi } = values;
                                      const smallFilterValues = {
                                        credor,
                                        numero,
                                        numGravame,
                                        tipoContrato,
                                        devNome,
                                        devCpfCnpj,
                                        chassi,
                                      };
                                      this.filterT(smallFilterValues);
                                    } else {
                                      this.filterT(values);
                                    }
                                    this.handleAutoScroll("table");
                                    resolve();
                                  }
                                });
                              })}
                            >
                              {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                            </button>
                          )}
                          {this.props.selectedFilter === "NI" && (
                            <button
                              className="btn btn-success"
                              disabled={this.state.pesquisando}
                              id="filterNI"
                              onClick={this.props.handleSubmit((values) => {
                                return new Promise((resolve, reject) => {
                                  const errors = validate(values);
                                  if (errors && !_.isEmpty(errors)) {
                                    reject(new SubmissionError(errors));
                                  } else {
                                    if (!this.state.showFilters) {
                                      const { credor, numero, numGravame, tipoContrato, devNome, devCpfCnpj, chassi } = values;
                                      const smallFilterValues = {
                                        credor,
                                        numero,
                                        numGravame,
                                        tipoContrato,
                                        devNome,
                                        devCpfCnpj,
                                        chassi,
                                      };
                                      this.filterNI(smallFilterValues);
                                    } else {
                                      this.filterNI(values);
                                    }
                                    this.handleAutoScroll("table");
                                    resolve();
                                  }
                                });
                              })}
                            >
                              {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                            </button>
                          )}
                          {this.props.selectedFilter === "R" && (
                            <button
                              className="btn btn-success"
                              disabled={this.state.pesquisando}
                              id="filterR"
                              onClick={this.props.handleSubmit((values) => {
                                return new Promise((resolve, reject) => {
                                  const errors = validate(values);
                                  if (errors && !_.isEmpty(errors)) {
                                    reject(new SubmissionError(errors));
                                  } else {
                                    if (!this.state.showFilters) {
                                      const { credor, numero, numGravame, tipoContrato, devNome, devCpfCnpj, chassi } = values;
                                      const smallFilterValues = {
                                        credor,
                                        numero,
                                        numGravame,
                                        tipoContrato,
                                        devNome,
                                        devCpfCnpj,
                                        chassi,
                                      };
                                      this.filterR(smallFilterValues);
                                    } else {
                                      this.filterR(values);
                                    }
                                    this.handleAutoScroll("table");
                                    resolve();
                                  }
                                });
                              })}
                            >
                              {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div ref={(ref) => (this.myRefDivSuperior = ref)} className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading m-b-md">
                  <div className="row">
                    <div className="col-md-3">
                      <button className="btn btn-secondary btn-sm" onClick={() => this.setState({ showLegend: !this.state.showLegend })}>
                        {this.state.showLegend ? "Ocultar" : "Mostrar"} Legenda
                      </button>
                    </div>
                  </div>
                  {this.state.showLegend && <br></br>}
                  {this.state.showLegend && (
                    <div className="row">
                      <div className="col-md-3">
                        Tipo:
                        <ListGroup>
                          <ListGroupItem>
                            <span style={{ color: "#6A6C6F" }}>
                              <span style={{ fontSize: "20px" }}>
                                <i className="fa fa-file"></i>
                              </span>{" "}
                              - Contrato
                            </span>
                          </ListGroupItem>
                          <ListGroupItem>
                            <span style={{ color: "#6A6C6F" }}>
                              <span style={{ fontSize: "20px" }}>
                                <i className="fa fa-file-alt"></i>
                              </span>{" "}
                              - Averbação
                            </span>
                          </ListGroupItem>
                        </ListGroup>
                      </div>
                      <br />
                      <div className="col-md-9">
                        Status:
                        <ListGroup>
                          <div>
                            <ListGroupItem>
                              <i className="fa fas fa-square"> </i> - Não Enviado &nbsp;
                              <i className="fa fas fa-square" style={{ color: "#b9a463" }}>
                                {" "}
                              </i>{" "}
                              - Aguardando DETRAN &nbsp;
                              <i className="fa fas fa-square text-danger"></i> - Registro com erro
                            </ListGroupItem>
                            <ListGroupItem>
                              <i className="fa fas fa-square text-warning"></i> - Registrado sem imagem &nbsp;
                              <i className="fa fas fa-square text-success"></i> - Registrado &nbsp;
                              <i className="fa fas fa-square" style={{ color: "#a1ed09" }}></i> - Registrado (Averbado)
                              {/* &nbsp; <i className="fa fas fa-square text-info"></i> - Homologado */}
                            </ListGroupItem>
                            <ListGroupItem bsStyle="info">
                              <i className="fa fas fa-square" style={{ color: "#00b7ff" }}></i> - Baixado &nbsp;
                              <i className="fa fas fa-square text-primary"></i> - Cancelado &nbsp;
                              <i className="fa fas fa-square" style={{ color: "#cb09ed" }}></i> - Em Processamento
                            </ListGroupItem>
                          </div>
                        </ListGroup>
                      </div>
                    </div>
                  )}
                </div>
                <div ref={(ref) => (this.myRef = ref)} className="panel-body">
                  <div className="stats-title float-left m-b-sm">
                    <h4>
                      Quantidade:{" "}
                      <b>
                        {this.props.selectedFilter == "T" && this.props.total
                          ? this.props.total.length
                          : this.props.selectedFilter == "NR" && this.props.notRegistred
                          ? this.props.notRegistred.length
                          : this.props.selectedFilter == "NI" && this.props.noImage
                          ? this.props.noImage.length
                          : this.props.selectedFilter == "R" && this.props.registered
                          ? this.props.registered.length
                          : 0}
                      </b>
                    </h4>
                  </div>
                  <div className="stats-icon float-right">
                    <Link className="btn btn-default btn-sm" onClick={() => this.exportToPDF()} title="Gerar PDF">
                      {" "}
                      <SvgIcon icon={filePdf} />
                    </Link>
                    <Link className="btn btn-default btn-sm" onClick={() => this.exportToCSV()} title="Gerar XLS">
                      {" "}
                      <SvgIcon icon={fileExcelO} />
                    </Link>
                  </div>
                  <div className="table-responsive">
                    {this.renderTotal()}
                    {this.renderNotRegistred()}
                    {this.renderNoImage()}
                    {this.renderRegistered()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal visible={this.state.exibeModalReenvio}>
          <form>
            <div className="modal-header">
              <div className="text-center">
                <h5 className="modal-title font-weight-bold" style={{ color: "red" }}>
                  ATENÇÃO
                </h5>
                <hr></hr>
                <h6>
                  <h4>
                    Existem <strong>{this.state.idsContratosReenvio.length}</strong> contrato(s) com esse mesmo erro: <strong>{this.state.descricaoErro}</strong>
                  </h4>
                  <br />
                  <h4>Deseja reenviar todos os contratos com o mesmo erro ou somente o contrato selecionado?</h4>
                </h6>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => this.setState({ exibeModalReenvio: !this.state.exibeModalReenvio })}>
                Cancelar
              </button>
              <button type="button" className="btn btn-primary" onClick={() => this.reenviarContratos(false)}>
                Reenviar o Selecionado
              </button>
              <button type="button" className="btn btn-primary" onClick={() => this.reenviarContratos(true)}>
                Reenviar Todos
              </button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if ((values.dtInicial || values.dtFinal) && !values.tpPesquisaData) {
    errors.tpPesquisaData = "Campo obrigatório quando alguma das datas são informadas.";
  }
  return errors;
}

const mapDispatchToProps = (dispatch) => ({
  onNotRegistredLoad: (payload) => dispatch({ type: CONTRACT_NOT_REGISTRED_LOAD, payload }),
  onNoImageLoad: (payload) => dispatch({ type: CONTRACT_NO_IMAGE_LOAD, payload }),
  onTotalLoad: (payload) => dispatch({ type: CONTRACT_TOTAL_LOAD, payload }),
  onRegisteredLoad: (payload) => dispatch({ type: CONTRACT_REGISTERED_LOAD, payload }),
  onSelectedFilterChange: (payload) => dispatch({ type: CONTRACT_LIST_FILTER_SELECTED, payload }),
  //onCompaniesLoad: (payload) => dispatch({ type: CONTRACT_LIST_COMPANIES_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: CONTRACT_LIST_UNLOAD, payload }),
  onQtdNaoEnvSemImg: (payload) => dispatch({ type: CONTRACT_TOTAIS_LOAD, payload }),
});

const selector = formValueSelector("ContractListForm");
const mapStateToProps = (state) => ({
  ...state.contractList,
  currentUser: state.common.currentUser,
  selectedEnderecoUfDevedor: selector(state, "devUf"),
  dataGravame: selector(state, "dataGravame"),
  dtInicial: selector(state, "dtInicial"),
  dtFinal: selector(state, "dtFinal"),
  companies: state.common.companies.length > 0 ? state.common.companies : [],
  status: selector(state, "status"),
});

const form = reduxForm({
  form: "ContractListForm",
  // destroyOnUnmount: false,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ContractList));
