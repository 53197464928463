import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change, untouch, autofill } from "redux-form";
import { SubmissionError, getFormSyncErrors } from "redux-form";
import { Link, browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";
import Dropzone from "react-dropzone";
import { BarLoader } from "react-spinners";
import _ from "lodash";

import ContractLog from "./ContractLog";

import TextField from "../../components/Fields/TextField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import SelectField from "../../components/Fields/SelectField";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import MaskDecimal from "../../components/Util/MaskDecimal";
import MaskDate from "../../components/Util/MaskDate";
import MaskCPF from "../../components/Util/MaskCPF";
import MaskCNPJ from "../../components/Util/MaskCNPJ";
import DropdownListFieldBrandModel from "../../components/Fields/DropdownListFieldBrandModel";
import CheckboxField from "../../components/Fields/CheckboxField";

import UfField from "../UfFields/UfField";
import CityField from "../UfFields/CityField";
import CityFilteredField from "../UfFields/CityFilteredField";

import Title from "../../components/Layout/Title";
import DropdownListField from "../../components/Fields/DropdownListField";

import {
  CONFIRM_OPERATION_OPEN,
  CONFIRM_ACTION_OPEN,
  CONTRACT_EDIT_LOAD,
  CONTRACT_EDIT_UNLOAD,
  CONTRACT_EDIT_COMPANIES_LOAD,
  CONTRACT_EDIT_CONTRACT_LOAD,
  CONTRACT_EDIT_VEHICLE_ADD,
  CONTRACT_EDIT_VEHICLE_LOAD,
  CONTRACT_EDIT_LOG_LOAD,
  CONTRACT_EDIT_VEHICLE_SELECTED,
  CONTRACT_EDIT_COMPANY_ERROR,
  CONTRACT_EDIT_UPLOAD,
  CONTRACT_ADDITIVE_CREATED,
  CONTRACT_EDIT_CONTRACT_VEHICLE_LOAD,
  CONTRACT_EDIT_RESET,
  CONTRACT_EDIT_ERR_MSG_LOAD,
  CONTRACT_SENT_SUCCESS,
  CONTRACT_DELETE_SUCCESS,
  SHOW_VEHICLE_FORM,
  HIDE_VEHICLE_FORM,
  SHOW_AVERBACAO,
  CONTRACT_EDIT_UPLOAD_SUCCESS,
  CONTRACT_CANCEL_SUCCESS,
  CONTRACT_SHELVE_SUCCESS,
  CONTRACT_UF_LOAD,
  CONTRACT_CLEAR_VEHICLE,
  LOGOUT,
} from "../../actions/Constants";
import api from "../../api/apiUtil";
import { BeatLoader, PropagateLoader } from "react-spinners";
import Modal from "react-bootstrap4-modal";
import moment from "moment";
// import { scroller } from "react-scroll";

const valuesIndice = ["PREFIXADO", "POSFIXADO", "VALOR BEM", "IGP", "IGPM", "IPC", "IPCA", "OUTROS", "0"];
const ufDevedorNaoValidar = [];
class Contract extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      files: [],
      placa: false,
      renavam: false,
      uf: false,
      nomeImagem: null,
      modalChassiOpen: false,
      hiddenDivBtn: false,
      btnAlterar: false,
      estadoVeiculo: false,
      modalAlteracaoOpen: false,
      exibeBtnExcluir: false,
      permiteAcao: true,
      credorInvalido: false,
    };
    this.myRefDivVeic = null;
  }

  onDrop(files) {
    this.setState({
      files: files,
    });
  }

  reset = () => (ev) => {
    ev.preventDefault();
    this.props.onReset();
    this.props.dispatch(change("ContractForm", "findChassi", null));
    this.props.dispatch(untouch("ContractForm", "findChassi"));
    this.props.dispatch(change("ContractForm", "findPlaca", null));
    this.props.dispatch(untouch("ContractForm", "findPlaca"));
    this.props.dispatch(change("ContractForm", "findGravame", null));
    this.props.dispatch(untouch("ContractForm", "findGravame"));
    this.context.router.replace(`/secure/contract/`);
  };

  hideVehicleForm = () => (ev) => {
    ev.preventDefault();
    this.props.onHideVehicleForm();
    this.clearValuesVehicle();
  };

  showVehicleForm = () => (ev) => {
    ev.preventDefault();
    this.setState({ estadoVeiculo: false });
    this.props.onShowVehicleForm();
    this.props.dispatch(change("ContractForm", "ufLicenciamento", this.props.selectedUf));
  };

  // setVehicleData() {
  //   if (this.props.limitVehicles && this.props.vehicles[0]) {
  //     this.props.onVehicleIndexSelected(0);
  //     this.props.dispatch(change("ContractForm", "ufPlaca", this.props.vehicles[0].ufPlaca));
  //     this.props.dispatch(change("ContractForm", "numPlaca", this.props.vehicles[0].numPlaca));
  //     this.props.dispatch(change("ContractForm", "renavam", this.props.vehicles[0].renavam));
  //     this.props.dispatch(change("ContractForm", "chassi", this.props.vehicles[0].chassi));
  //     this.props.dispatch(change("ContractForm", "chassiRemarcado", this.props.vehicles[0].chassiRemarcado));
  //     this.props.dispatch(change("ContractForm", "anoFabricacao", this.props.vehicles[0].anoFabricacao));
  //     this.props.dispatch(change("ContractForm", "anoModelo", this.props.vehicles[0].anoModelo));
  //     this.props.dispatch(change("ContractForm", "numGravame", this.props.vehicles[0].numGravame));
  //     this.props.dispatch(change("ContractForm", "estadoVeiculo", this.props.vehicles[0].estadoVeiculo == "N" ? true : false));
  //     this.props.dispatch(change("ContractForm", "marcaModelo", this.props.vehicles[0].marcaModelo));
  //     this.props.dispatch(change("ContractForm", "dataGravame", MaskDate.TO_BR(this.props.vehicles[0].dataGravame)));

  //     if (this.props.haveUfLicenciamento) {
  //       this.props.dispatch(change("ContractForm", "ufLicenciamento", this.props.vehicles[0].ufLicenciamento));
  //     }
  //   }
  // }

  setVehicleData(vh) {
    if (this.props.limitVehicles && vh && vh[0]) {
      this.props.onVehicleIndexSelected(0);
      this.props.dispatch(change("ContractForm", "ufPlaca", vh[0].ufPlaca));
      this.props.dispatch(change("ContractForm", "numPlaca", vh[0].numPlaca));
      this.props.dispatch(change("ContractForm", "renavam", vh[0].renavam));
      this.props.dispatch(change("ContractForm", "chassi", vh[0].chassi));
      this.props.dispatch(change("ContractForm", "chassiRemarcado", vh[0].chassiRemarcado));
      this.props.dispatch(change("ContractForm", "anoFabricacao", vh[0].anoFabricacao));
      this.props.dispatch(change("ContractForm", "anoModelo", vh[0].anoModelo));
      this.props.dispatch(change("ContractForm", "numGravame", vh[0].numGravame));
      this.props.dispatch(change("ContractForm", "estadoVeiculo", vh[0].estadoVeiculo == "N" ? true : false));
      this.setState({ estadoVeiculo: this.props.vehicles[0].estadoVeiculo == "N" ? true : false });
      this.props.dispatch(change("ContractForm", "marcaModelo", vh[0].marcaModelo));
      this.props.dispatch(change("ContractForm", "dataGravame", MaskDate.TO_BR(vh[0].dataGravame)));
      this.props.dispatch(change("ContractForm", "especieVeiculo", vh[0].especieVeiculo));
      this.props.dispatch(change("ContractForm", "cor", vh[0].cor));

      if (this.props.haveUfLicenciamento) {
        this.props.dispatch(change("ContractForm", "ufLicenciamento", vh[0].ufLicenciamento));
      }
    } else if (this.props.limitVehicles && this.props.vehicles[0]) {
      this.props.onVehicleIndexSelected(0);
      this.props.dispatch(change("ContractForm", "ufPlaca", this.props.vehicles[0].ufPlaca));
      this.props.dispatch(change("ContractForm", "numPlaca", this.props.vehicles[0].numPlaca));
      this.props.dispatch(change("ContractForm", "renavam", this.props.vehicles[0].renavam));
      this.props.dispatch(change("ContractForm", "chassi", this.props.vehicles[0].chassi));
      this.props.dispatch(change("ContractForm", "chassiRemarcado", this.props.vehicles[0].chassiRemarcado));
      this.props.dispatch(change("ContractForm", "anoFabricacao", this.props.vehicles[0].anoFabricacao));
      this.props.dispatch(change("ContractForm", "anoModelo", this.props.vehicles[0].anoModelo));
      this.props.dispatch(change("ContractForm", "numGravame", this.props.vehicles[0].numGravame));
      this.props.dispatch(change("ContractForm", "estadoVeiculo", this.props.vehicles[0].estadoVeiculo == "N" ? true : false));
      this.setState({ estadoVeiculo: this.props.vehicles[0].estadoVeiculo == "N" ? true : false });
      this.props.dispatch(change("ContractForm", "marcaModelo", this.props.vehicles[0].marcaModelo));
      this.props.dispatch(change("ContractForm", "dataGravame", MaskDate.TO_BR(this.props.vehicles[0].dataGravame)));
      this.props.dispatch(change("ContractForm", "especieVeiculo", this.props.vehicles[0].especieVeiculo));
      this.props.dispatch(change("ContractForm", "cor", this.props.vehicles[0].cor));
      if (this.props.haveUfLicenciamento) {
        this.props.dispatch(change("ContractForm", "ufLicenciamento", this.props.vehicles[0].ufLicenciamento));
      }
    }
  }

  async handleClickFindSP(values) {
    if (!values.findChassi && !values.findPlaca) {
      toastr.error("Erro", "É necessário preencher o Chassi ou a Placa para efetuar a consulta.");
      return null;
    }
    let result;
    if (values.findChassi) {
      await Promise.resolve(api.Contracts.findByChassi(values.findChassi)).then((e) => {
        result = e;
      });
    } else if (values.findPlaca) {
      await Promise.resolve(api.Contracts.findByPlaca(values.findPlaca)).then((e) => {
        result = e;
      });
    }
    if (result && result.RetornoConsultaGravame && result.RetornoConsultaGravame.codigo === 0) {
      result.RetornoConsultaGravame.detalheGravame.credor = values.credor;
      result.RetornoConsultaGravame.detalheGravame.uf = values.uf;
      this.preencheContratoExistenteMGRJSP(result.RetornoConsultaGravame.detalheGravame);
    } else if (result && result.RetornoConsultaGravame && result.RetornoConsultaGravame.contratoExistente) {
      toastr.error("Erro", "Já existe contrato ativo no sistema com o mesmo número, chassi e gravame!");
    } else {
      toastr.error("Veículo não encontrado", result.RetornoConsultaGravame.descricao);
    }
  }

  handleClickFindMG(values) {
    if (values.findChassi && values.findGravame) {
      Promise.resolve(api.Contracts.findByDadosMG(values.findChassi, values.findGravame)).then((e) => {
        if (e.flag_erro === "001") {
          toastr.error("Erro", e.mensagem_erro ? `DETRAN: ${JSON.stringify(e.mensagem_erro)}` : "O veículo pesquisado não foi encontrado");
        } else {
          if (e.situacaoGravame === "1") {
            Promise.resolve(api.Companies.get(this.props.selectedCredor.id)).then((comp) => {
              e.credor = values.credor;
              e.uf = values.uf;
              e.numeroGravame = values.findGravame;
              this.preencheContratoExistenteMGRJSP(e);
            });
          } else {
            toastr.error("Erro", "Veículo não encontrado");
          }
        }
      });
    }
  }

  handleClickFindRJ(values) {
    if (values.findChassi /*&& values.findCodigoAgente*/) {
      Promise.resolve(api.Companies.get(this.props.selectedCredor.id)).then((comp) => {
        Promise.resolve(api.Contracts.findByDadosRJ(comp.cnpj, values.findChassi)).then((res) => {
          if (res.descricao == "OK") {
            res.chassi = values.findChassi;
            //res.codigoAgente = values.findCodigoAgente;
            res.credor = values.credor;
            res.uf = values.uf;
            this.preencheContratoExistenteMGRJSP(res);
          } else {
            toastr.error("Erro", res.descricao);
          }
        });
      });
    }
  }

  preencheContratoExistenteMGRJSP(detalheGravame) {
    if (this.props.selectedUf == "SP") {
      this.props.onContractVehiclesLoad([this.contractFromGravame(detalheGravame), this.vehicleFromGravame(detalheGravame)]);
    } else if (this.props.selectedUf == "MG") {
      this.props.onContractVehiclesLoad([this.contractFromGravameMG(detalheGravame), this.vehicleFromGravameMG(detalheGravame)]);
    } else {
      this.props.onContractVehiclesLoad([this.contractFromGravameRJ(detalheGravame), this.vehicleFromGravameRJ(detalheGravame)]);
    }

    this.props.onLoad();
    this.setVehicleData();
    Promise.resolve(
      api.Contracts.getContractByNrUf(this.props.selectedUf == "SP" || this.props.selectedUf == "MG" ? detalheGravame.numeroContrato : detalheGravame.numero.trim(), this.props.selectedUf)
    ).then((response) => {
      if (response && response.length > 0) {
        this.props.onActionOpen({
          description: "Contrato já cadastrado no sistema, deseja importar os dados?",
          onSuccess: this.populaValuesContract.bind(this, response),
        });
      }
    });
  }

  vehicleFromGravame(v) {
    var vehicle = {
      numPlaca: v.placa, //	JJJ-1222
      ufPlaca: v.UFPlaca,
      renavam: v.renavam,
      chassi: v.chassi,
      status: 0,
      anoFabricacao: v.anoFabricacao,
      anoModelo: v.anoModelo,
      numGravame: v.numeroGravame,
      chassiRemarcado: v.idenRemarcacao,
      estadoVeiculo: v.estadoVeiculo,
      marcaModelo: v.marcaModelo,
      dataGravame: v.dataGravame,
      especieVeiculo: v.especieVeiculo,
    };
    return [vehicle];
  }

  contractFromGravame(v) {
    var contract = {
      codigoAgente: v.codigoAgente,
      numero: v.numeroContrato,
      status: 0,
      devNome: v.nomeFinanciado,
      devCpfCnpj:
        v.tipoDocumentoFinanciado == 1
          ? MaskCPF.TO_CPF(v.numeroDocumentoFinanciado ? v.numeroDocumentoFinanciado.padStart(11, "0") : v.numeroDocumentoFinanciado)
          : MaskCNPJ.TO_CNPJ(v.numeroDocumentoFinanciado ? v.numeroDocumentoFinanciado.padStart(14, "0") : v.numeroDocumentoFinanciado),
      data: v.dataVigenciaContrato,
      uf: v.uf,
      idFinanceira: v.credor.id,
    };

    this.props.dispatch(change("ContractForm", "contrato", contract.numero));
    this.props.dispatch(change("ContractForm", "data", MaskDate.TO_BR(contract.data)));
    this.props.dispatch(change("ContractForm", "devCpfCnpj", contract.devCpfCnpj));
    this.props.dispatch(change("ContractForm", "devNome", contract.devNome));
    this.props.dispatch(change("ContractForm", "credor", v.credor));
    this.props.dispatch(change("ContractForm", "uf", contract.uf));

    return contract;
  }

  vehicleFromGravameMG(v) {
    var vehicle = {
      chassi: v.chassi,
      status: 0,
      anoFabricacao: v.anoFabricacao,
      numGravame: v.numeroGravame,
      ufLicenciamento: this.props.selectedUf,
    };
    return [vehicle];
  }

  contractFromGravameMG(v) {
    var contract = {
      numero: v.numeroContrato,
      status: 0,
      uf: v.uf,
      idFinanceira: v.credor.id,
    };

    this.props.dispatch(change("ContractForm", "contrato", contract.numero));
    this.props.dispatch(change("ContractForm", "data", MaskDate.TO_BR(contract.data)));
    this.props.dispatch(change("ContractForm", "devCpfCnpj", contract.devCpfCnpj));
    this.props.dispatch(change("ContractForm", "devNome", contract.devNome));
    this.props.dispatch(change("ContractForm", "credor", v.credor));
    this.props.dispatch(change("ContractForm", "uf", contract.uf));
    return contract;
  }

  contractFromGravameRJ(v) {
    var contract = {
      codigoAgente: v.duda,
      numero: v.numero.trim(),
      data: MaskDate.TO_DATABASE_DATE(v.data),
      tipoContrato: v.tipoContrato,
      devCpfCnpj: v.tipoFinanciado == "1" ? MaskCPF.TO_CPF(v.devCpfCnpj.substring(3, 14)) : MaskCNPJ.TO_CNPJ(v.devCpfCnpj),
      devNome: v.devNome.trim().toUpperCase(),
      status: 0,
      uf: v.uf,
      idFinanceira: v.credor.id,
    };
    this.props.dispatch(change("ContractForm", "contrato", contract.numero));
    this.props.dispatch(change("ContractForm", "data", contract.data));
    this.props.dispatch(change("ContractForm", "tipoContrato", contract.tipoContrato));
    this.props.dispatch(change("ContractForm", "devCpfCnpj", contract.devCpfCnpj));
    this.props.dispatch(change("ContractForm", "devNome", contract.devNome));
    this.props.dispatch(change("ContractForm", "credor", v.credor));
    this.props.dispatch(change("ContractForm", "uf", contract.uf));
    return contract;
  }

  vehicleFromGravameRJ(v) {
    var vehicle = {
      numPlaca: v.placa,
      renavam: v.renavam,
      anoFabricacao: v.anoFabricacao,
      anoModelo: v.anoModelo,
      status: 0,
      numGravame: v.numGravame,
      statusGravame: v.statusGravame,
      chassi: v.chassi,
    };
    return [vehicle];
  }

  handleClickSendAll = async () => {
    this.props.onOpen({
      description: "Registrar o contrato e todos seus veículos?",
      onSuccess: this.onSendAllSuccess.bind(this),
    });
  };

  onSendAllSuccess() {
    var props = this.props;
    toastr.removeByType("success");
    this.setState({ hiddenDivBtn: true });
    toastr.info("Aguarde", "Enviando contrato...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    Promise.resolve(api.Contracts.register(this.props.contract.id))
      .then(async (e) => {
        let stop = false;
        for (let i = 0; i < 5; i++) {
          //console.log("passou ", i);
          Promise.resolve(api.Contracts.get(this.props.contract.id)).then((response) => {
            if (response.status != 1 && response.status != 9) {
              this.setState({ hiddenDivBtn: false });
              toastr.removeByType("info");
              stop = true;
              if (response.status == 2 || response.status == 3) {
                this.props.onContractSentSuccess();
                toastr.success("Operação realizada", "O contrato e todos seus veículos foram registrados!");
              } else if (response.status == 90) {
                this.props.params.slug = response.id;
                toastr.error("Erro", "Erro ao registrar contrato!");
                this.montaContrato();
              } else if (response.status == 11) {
                this.props.onContractSentSuccess();
                toastr.warning("Operação realizada", "O contrato e todos seus veículos foram enviados ao DETRAN, aguardando confirmação do processamento!");
              }
            } else if (i == 4) {
              toastr.removeByType("info");
              this.props.onContractSentSuccess();
              toastr.warning("Operação realizada", "O contrato e todos seus veículos foram enviados ao DETRAN, aguardando confirmação do processamento!");
            }
          });
          if (stop) {
            break;
          } else {
            await sleep(2000);
          }
        }
      })
      .catch((e) => {
        this.setState({ hiddenDivBtn: false });
        toastr.removeByType("info");
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleClickAdditiveSendAll = () => {
    this.props.onOpen({
      description: "Averbar o contrato?",
      onSuccess: this.onAdditiveSendAllSuccess.bind(this),
    });
  };

  onAdditiveSendAllSuccess() {
    var props = this.props;
    toastr.removeByType("success");
    this.setState({ hiddenDivBtn: true });
    toastr.info("Aguarde", "Enviando contrato...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    Promise.resolve(api.Contracts.registerAdditive(this.props.contract.id))
      .then(async (e) => {
        let stop = false;
        for (let i = 0; i < 5; i++) {
          //console.log("passou ", i);
          Promise.resolve(api.Contracts.get(this.props.contract.id)).then((response) => {
            if (response.status != 1 && response.status != 9) {
              this.setState({ hiddenDivBtn: false });
              toastr.removeByType("info");
              stop = true;
              if (response.status == 2 || response.status == 3) {
                this.props.onContractSentSuccess();
                toastr.success("Operação realizada", "O contrato e todos seus veículos foram registrados!");
              } else if (response.status == 90) {
                this.props.params.slug = response.id;
                toastr.error("Erro", "Erro ao registrar contrato!");
                this.montaContrato();
              } else if (response.status == 11) {
                this.props.onContractSentSuccess();
                toastr.warning("Operação realizada", "O contrato e todos seus veículos foram enviados ao DETRAN, aguardando confirmação do processamento!");
              }
            } else if (i == 4) {
              toastr.removeByType("info");
              this.props.onContractSentSuccess();
              toastr.warning("Operação realizada", "O contrato e todos seus veículos foram enviados ao DETRAN, aguardando confirmação do processamento!");
            }
          });
          if (stop) {
            break;
          } else {
            await sleep(2000);
          }
        }
      })
      .catch((e) => {
        this.setState({ hiddenDivBtn: false });
        toastr.removeByType("info");
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  /*handleClickCancel = () => ev => {
    ev.preventDefault();
     this.props.onOpen({description: 'Cancelar o contrato.',onSuccess: this.onCancelSuccess.bind(this)});
  }*/

  async handleClickSendChanges(values) {
    var houveAlteracao = false;
    if (this.props.selectedUf === "PR") {
      houveAlteracao = true;
    } else {
      var valores = values;
      var changedValuesContract = [
        "cidadeLiberacaoCred",
        "codigoAgente",
        "contrato",
        "data",
        "dataAditivo",
        "dataLiberacaoCred",
        "dataPrimeiroVenc",
        "dataUltimoVenc",
        "devBairro",
        "devCep",
        "devComplemento",
        "devCpfCnpj",
        "devDddTelefone",
        "devEmail",
        "devEndereco",
        "devMunicipio",
        "devNome",
        "devNumero",
        "devTelefone",
        "devUf",
        "diaVencParcelas",
        "indices",
        "municipio",
        "numCotaConsorcio",
        "numGrupoConsorcio",
        "numRegistro",
        "numRegistroOriginal",
        "numRestricao",
        "numero",
        "numeroAditivo",
        "penalidade",
        "possuiTaxaMulta",
        "qtdeParcelas",
        "sequencialContrato",
        "status",
        "taxaComissao",
        "taxaCorrecaoMonetaria",
        "taxaJurosAno",
        "taxaJurosMes",
        "taxaMora",
        "taxaMulta",
        "tipoContrato",
        "ufLiberacaoCred",
        "valorCredito",
        "valorIOF",
        "valorParcela",
        "valorTaxaContrato",
        "valorTotalFinanciamento",
        "devDddCelular",
        "devCelular",
        "ipoc",
      ];

      var changedValuesVehicle = [
        "anoFabricacao",
        "anoModelo",
        "chassi",
        "chassiRemarcado",
        "estadoVeiculo",
        "numGravame",
        "numPlaca",
        "renavam",
        "ufLicenciamento",
        "ufPlaca",
        "dataGravame",
        "especieVeiculo",
        "cor",
      ];

      for (var i = 0; i < changedValuesContract.length; i++) {
        if (changedValuesContract[i] === "tipoContrato") {
          if (this.props.initialValues[changedValuesContract[i]] !== parseInt(valores[changedValuesContract[i]])) {
            houveAlteracao = true;
            break;
          }
        } else if (changedValuesContract[i] === "valorTotalFinanciamento") {
          var valor = valores[changedValuesContract[i]] ? valores[changedValuesContract[i]].replace(/\./g, "").replace(/,/g, ".") : valores[changedValuesContract[i]];
          var valorInit = this.props.initialValues[changedValuesContract[i]]
            ? this.props.initialValues[changedValuesContract[i]].replace(/\./g, "").replace(/,/g, ".")
            : this.props.initialValues[changedValuesContract[i]];
          if (valorInit !== valor) {
            houveAlteracao = true;
            break;
          }
        } else if (changedValuesContract[i] === "devMunicipio") {
          if (
            !(isEmpty(this.props.initialValues[changedValuesContract[i]]) && isEmpty(valores[changedValuesContract[i]])) &&
            this.props.initialValues[changedValuesContract[i]].municipioId != valores[changedValuesContract[i]].municipioId
          ) {
            houveAlteracao = true;
            break;
          }
        } else if (changedValuesContract[i] === "cidadeLiberacaoCred") {
          if (
            !(isEmpty(this.props.initialValues[changedValuesContract[i]]) && isEmpty(valores[changedValuesContract[i]])) &&
            this.props.initialValues[changedValuesContract[i]] !=
              (valores[changedValuesContract[i]] ? (valores[changedValuesContract[i]].municipio ? valores[changedValuesContract[i]].municipio : valores[changedValuesContract[i]]) : null)
          ) {
            houveAlteracao = true;
            break;
          }
        } else if (
          !(isEmpty(this.props.initialValues[changedValuesContract[i]]) && isEmpty(valores[changedValuesContract[i]])) &&
          this.props.initialValues[changedValuesContract[i]] !== valores[changedValuesContract[i]]
        ) {
          houveAlteracao = true;
          break;
        }
      }
      if (!houveAlteracao) {
        if (this.props.limitVehicles) {
          for (var iValue = 0; iValue < changedValuesVehicle.length; iValue++) {
            if (changedValuesVehicle[iValue] === "dataGravame") {
              if (
                !(isEmpty(this.props.initialValues.veiculos[0][changedValuesVehicle[iValue]]) && isEmpty(valores[changedValuesVehicle[iValue]])) &&
                MaskDate.TO_BR(this.props.initialValues.veiculos[0][changedValuesVehicle[iValue]]) !== valores[changedValuesVehicle[iValue]]
              ) {
                houveAlteracao = true;
                break;
              }
            } else if (changedValuesVehicle[iValue] === "estadoVeiculo") {
              if (!valores[changedValuesVehicle[iValue]] && this.props.initialValues.veiculos[0][changedValuesVehicle[iValue]] != "U") {
                houveAlteracao = true;
                break;
              } else if (valores[changedValuesVehicle[iValue]] && this.props.initialValues.veiculos[0][changedValuesVehicle[iValue]] != "N") {
                houveAlteracao = true;
                break;
              }
            } else if (this.props.initialValues.veiculos[0][changedValuesVehicle[iValue]] !== valores[changedValuesVehicle[iValue]] && valores[changedValuesVehicle[iValue]] !== undefined) {
              houveAlteracao = true;
              break;
            }
          }
        }
        //else{
        //   let veiculos = this.props.vehicles;
        //   if(veiculos.filter(veic => veic.status === 91).length > 0){
        //     houveAlteracao = true;
        //   }else{
        //     await Promise.resolve(api.Contracts.vehicles(this.props.contract.id)).then(veic => {
        //       if(veic.length != veiculos.length){
        //         houveAlteracao = true;
        //       }else{
        //         veic.forEach(function (v) {
        //           let veiculo = veiculos.filter(veic => veic.id === v.id);
        //           for (var iValue = 0; iValue < changedValuesVehicle.length; iValue++) {
        //             if (v[changedValuesVehicle[iValue]] !== veiculo[0][changedValuesVehicle[iValue]] && veiculo[0][changedValuesVehicle[iValue]] !== undefined) {
        //               houveAlteracao = true;
        //               break;
        //             }
        //           }
        //         });
        //       }
        //     });
        //   }
        // }
      }
    }

    if (!houveAlteracao) {
      this.setState({ modalAlteracaoOpen: !this.state.modalAlteracaoOpen });
    } else {
      this.props.onOpen({
        description: "Enviar alterações ao Detran?",
        onSuccess: this.props.handleSubmit(this.onSendChangesSuccess.bind(this)),
      });
    }
  }

  onOpenDialog() {
    this.setState({ modalAlteracaoOpen: false });
    this.props.onOpen({
      description: "Enviar alterações ao Detran?",
      onSuccess: this.props.handleSubmit(this.onSendChangesSuccess.bind(this)),
    });
  }

  onSendChangesSuccess(values) {
    var dados = this.formToDados(values);
    var props = this.props;
    dados.dados.contrato.id = this.props.contract.id;
    Promise.resolve(api.Contracts.change(dados))
      .then((ret) => {
        toastr.success("Operação realizada", "O contrato foi alterado!");
        this.props.onContractSentSuccess();
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", resp.message);
        }
      });
  }

  componentWillMount() {
    this.montaContrato();
    this.props.onUfLoad([{ sigla: "" }, ...this.props.currentUser.ufs.slice(1)]);
    // if (localStorage.getItem("atrTaxa")) {
    //   let valuesTaxa = JSON.parse(localStorage.getItem("atrTaxa"));
    //   localStorage.removeItem("atrTaxa");
    //   this.props.dispatch(change("ContractForm", "credor", valuesTaxa.cnpjFinanceira));
    //   this.props.dispatch(change("ContractForm", "numGravame", valuesTaxa.numeroGravame));
    //   this.props.dispatch(change("ContractForm", "chassi", valuesTaxa.chassi));
    //   this.props.dispatch(change("ContractForm", "data", MaskDate.TO_BR(valuesTaxa.dataContrato)));
    // }
  }

  montaContrato() {
    if (this.props.params.register) {
      this.props.onHideVehicleForm();
      Promise.resolve(api.Contracts.getByRegister(this.props.params.register)).then((response) => {
        this.props.onContractLoad(response);
        if (response.status === 90 || (response.status === 0 && response.uf === "BA")) {
          Promise.resolve(api.Contracts.lastMessage(response.id)).then((msg) => {
            if (msg && msg.length > 0) {
              this.props.onErrLoad(msg[0].retorno);
              if (msg && msg[0] && msg[0].contratoJaRegistrado) {
                if (this.props.limitVehicles) {
                  Promise.resolve(api.Contracts.vehicles(response.id)).then((vs) => {
                    Promise.resolve(api.Contracts.getByChassi(vs[0].chassi)).then((cs) => {
                      var showAverbacaoError = true;
                      cs.forEach(function (item, index) {
                        if (item.status !== 90 && item.status !== 1 && item.status !== 91) {
                          showAverbacaoError = false;
                        }
                      });
                      if (showAverbacaoError) {
                        this.props.onShowAverbacaoError();
                      }
                    });
                  });
                } else {
                  Promise.resolve(api.Contracts.getByNumber(response.numero, response.uf)).then((cs) => {
                    var showAverbacaoError = true;
                    cs.forEach(function (item, index) {
                      if (item.status !== 90 && item.status !== 1 && item.status !== 91) {
                        showAverbacaoError = false;
                      }
                    });
                    if (showAverbacaoError) {
                      this.props.onShowAverbacaoError();
                    }
                  });
                }
              }
            }
          });
        }
        this.props.params.slug = response.id;
        Promise.resolve(api.Contracts.vehicles(this.props.params.slug)).then((vs) => {
          this.props.onVehiclesLoad(vs);
          this.setVehicleData();
        });
        this.props.onLogLoad(api.Contracts.log(this.props.params.slug));
        this.props.onLoad();
        this.setVehicleData();
        if (response.uf === "PB" || response.uf === "PE") {
          Promise.resolve(api.Contracts.recupera785(this.props.params.slug)).then((res785) => {
            if (!res785 || res785.length == 0) {
              this.setState({ exibeBtnExcluir: true });
            }
          });
        } else {
          this.setState({ exibeBtnExcluir: true });
        }
      });
    } else {
      if (this.props.params.slug) {
        Promise.resolve(api.Contracts.get(this.props.params.slug)).then((c) => {
          this.props.onContractLoad(c);
          this.props.onHideVehicleForm();
          if (c.status === 90 || (c.status === 0 && c.uf === "BA")) {
            Promise.resolve(api.Contracts.lastMessage(c.id)).then((msg) => {
              if (msg && msg.length > 0) {
                this.props.onErrLoad(msg[0].retorno);
                if (msg && msg[0] && msg[0].contratoJaRegistrado) {
                  if (this.props.limitVehicles) {
                    Promise.resolve(api.Contracts.vehicles(c.id)).then((vs) => {
                      Promise.resolve(api.Contracts.getByChassi(vs[0].chassi)).then((cs) => {
                        var showAverbacaoError = true;
                        cs.forEach(function (item, index) {
                          if (item.status !== 90 && item.status !== 1 && item.status !== 91) {
                            showAverbacaoError = false;
                          }
                        });
                        if (showAverbacaoError) {
                          this.props.onShowAverbacaoError();
                        }
                      });
                    });
                  } else {
                    Promise.resolve(api.Contracts.getByNumber(c.numero, c.uf)).then((cs) => {
                      var showAverbacaoError = true;
                      cs.forEach(function (item, index) {
                        if (item.status !== 90 && item.status !== 1 && item.status !== 91) {
                          showAverbacaoError = false;
                        }
                      });
                      if (showAverbacaoError) {
                        this.props.onShowAverbacaoError();
                      }
                    });
                  }
                }
              }
            });
          }
          Promise.resolve(api.Contracts.vehicles(this.props.params.slug)).then((vs) => {
            this.props.onVehiclesLoad(vs);
            this.setVehicleData();
          });
          this.props.onLogLoad(api.Contracts.log(this.props.params.slug));
          if (c.uf === "PB" || c.uf === "PE") {
            Promise.resolve(api.Contracts.recupera785(this.props.params.slug)).then((res785) => {
              if (!res785 || res785.length == 0) {
                this.setState({ exibeBtnExcluir: true });
              }
            });
          } else {
            this.setState({ exibeBtnExcluir: true });
          }
        });
      }
      this.props.onLoad();
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.valid && Object.keys(this.props.synchronousError).length > 0) {
      this.scrollToFirstError(this.props.synchronousError);
    }
    if (nextProps.selectedCredor) {
      if (this.props.selectedCredor !== nextProps.selectedCredor) {
        Promise.resolve(api.Companies.get(nextProps.selectedCredor.id)).then((comp) => {
          Promise.resolve(api.Detrans.companyStatus(comp.cnpj))
            .then((v) => {
              this.props.onCompanyError(false);
              if (this.props.contract && this.props.contract.id && this.state.credorInvalido) {
                var filtered = this.props.companies.filter((comp) => comp.id == this.props.contract.idFinanceira);
                this.props.dispatch(change("ContractForm", "credor", filtered[0]));
              }
            })
            .catch((err) => {
              this.props.onCompanyError(true);
              toastr.error("Erro", `Não é possível continuar com a operação: ${err.response.text}`);
            });
        });
      }
    } else if (this.props.contract && this.props.contract.id) {
      var filtered = this.props.companies.filter((comp) => comp.id == this.props.contract.idFinanceira);
      this.props.dispatch(change("ContractForm", "credor", filtered[0]));
    }
  }

  handleDeleteContract = () => (ev) => {
    ev.preventDefault();
    this.props.onActionOpen({
      description: "Confirma exclusão do contrato?",
      onSuccess: this.props.handleSubmit(this.deleteContract.bind(this)),
    });
  };

  deleteContract() {
    var props = this.props;
    Promise.resolve(api.Contracts.deleteContract(this.props.contract.id))
      .then((ret) => {
        toastr.success("Sucesso", "Contrato excluido!");
        this.props.onContractDeleteSuccess();
      })
      .catch((ret) => {
        if (ret === "Unauthorized" || ret === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleOnCancelContract = () => (ev) => {
    ev.preventDefault();
    this.props.onOpen({
      description: this.props.selectedUf === "BA" ? "Realizar o cancelamento do contrato." : "Esta operação fará apenas a alteração da situação do contrato na base do E-Registro. Deseja prosseguir?",
      onSuccess: this.cancelContract.bind(this),
    });
  };

  cancelContract() {
    var props = this.props;
    Promise.resolve(api.Contracts.cancelContract(this.props.contract.id))
      .then((ret) => {
        toastr.success("Sucesso", "Cancelamento enviado com sucesso!");
        this.props.onContractCancelSuccess();
      })
      .catch((ret) => {
        if (ret.message === "Unauthorized" || ret.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleOnShelveContract = () => (ev) => {
    ev.preventDefault();
    this.props.onOpen({
      description: this.props.selectedUf === "BA" ? "Realizar a baixa do contrato." : "Esta operação fará apenas a alteração da situação do contrato na base do E-Registro. Deseja prosseguir?",
      onSuccess: this.shelveContract.bind(this),
    });
  };

  shelveContract() {
    var props = this.props;
    Promise.resolve(api.Contracts.shelveContract(this.props.contract.id))
      .then((ret) => {
        toastr.success("Sucesso", "Baixa enviada com sucesso!");
        this.props.onContractShelveSuccess();
      })
      .catch((ret) => {
        if (ret.message === "Unauthorized" || ret.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleCreateAdditive = () => (ev) => {
    ev.preventDefault();
    this.props.onActionOpen({
      description: "Deseja criar uma averbação para o contrato?",
      onSuccess: this.props.handleSubmit(this.createAdditive.bind(this)),
    });
  };

  createAdditive(values) {
    var props = this.props;
    var dados = this.formToDados(values);
    dados.dados.contrato.idContratoAnterior = this.props.contract.id;
    dados.dados.contrato.id = this.props.contract.id;

    Promise.resolve(api.Contracts.additive(dados))
      .then((ret) => {
        this.props.onUnload();
        this.props.onAdditiveCreated(ret.id);

        toastr.success("Sucesso", "Averbação criada");
        this.props.params.slug = ret.id;
        Promise.resolve(api.Contracts.get(ret.id)).then((c) => {
          this.props.onContractLoad(c);
          this.props.onHideVehicleForm();
          if (c.status === 90) {
            Promise.resolve(api.Contracts.lastMessage(c.id)).then((msg) => {
              this.props.onErrLoad(msg[0].retorno);
              if (msg && msg[0] && msg[0].contratoJaRegistrado) {
                //   Promise.resolve(api.Contracts.getByNumber(response.numero)).then(cs => {
                //     var showAverbacaoError = true;
                //     cs.forEach(function (item, index) {
                //       if (item.status !== 90 && item.status !== 1) {
                //          showAverbacaoError = false;
                //       }
                //     });
                //     if (showAverbacaoError) {
                this.props.onShowAverbacaoError();
                //     }
                //   })
              }
            });
          }
          // if (c.status === 10) {
          //   Promise.resolve(api.Contracts.lastLog(c.id)).then(msg => {
          //     this.props.onErrLoad(msg[0].justificativa);
          //   })
          // }
          Promise.resolve(api.Contracts.vehicles(this.props.params.slug)).then((vs) => {
            this.props.onVehiclesLoad(vs);
            this.setVehicleData();
          });
          this.props.onLogLoad(api.Contracts.log(this.props.params.slug));
        });
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  vehicleToDelete = (index) => (ev) => {
    ev.preventDefault();
    this.props.onVehicleIndexSelected(index);
    this.props.onActionOpen({
      description: "Excluir veículo?",
      onSuccess: this.deleteVehicle.bind(this),
    });
  };

  vehicleToEdit = (index) => (ev) => {
    ev.preventDefault();
    this.props.onVehicleIndexSelected(index);
    this.props.dispatch(change("ContractForm", "ufPlaca", this.props.vehicles[index].ufPlaca));
    this.props.dispatch(change("ContractForm", "numPlaca", this.props.vehicles[index].numPlaca));
    this.props.dispatch(change("ContractForm", "renavam", this.props.vehicles[index].renavam));
    this.props.dispatch(change("ContractForm", "chassi", this.props.vehicles[index].chassi));
    this.props.dispatch(change("ContractForm", "chassiRemarcado", this.props.vehicles[index].chassiRemarcado));
    this.props.dispatch(change("ContractForm", "anoFabricacao", this.props.vehicles[index].anoFabricacao));
    this.props.dispatch(change("ContractForm", "anoModelo", this.props.vehicles[index].anoModelo));
    this.props.dispatch(change("ContractForm", "numGravame", this.props.vehicles[index].numGravame));
    this.props.dispatch(change("ContractForm", "estadoVeiculo", this.props.vehicles[index].estadoVeiculo == "N" ? true : false));
    this.setState({ estadoVeiculo: this.props.vehicles[index].estadoVeiculo == "N" ? true : false });
    this.props.dispatch(change("ContractForm", "marcaModelo", this.props.vehicles[index].marcaModelo));
    this.props.dispatch(change("ContractForm", "dataGravame", MaskDate.TO_BR(this.props.vehicles[index].dataGravame)));
    this.props.dispatch(change("ContractForm", "especieVeiculo", this.props.vehicles[index].especieVeiculo));
    this.props.dispatch(change("ContractForm", "cor", this.props.vehicles[index].cor));

    if (this.props.haveUfLicenciamento) {
      this.props.dispatch(change("ContractForm", "ufLicenciamento", this.props.vehicles[index].ufLicenciamento));
    }

    this.props.onShowVehicleForm();
    window.scrollTo(0, this.props.vehicles.filter((item) => item.status != 91).length == 1 ? this.myRefDivVeic.offsetTop + 130 : this.myRefDivVeic.offsetTop + this.props.vehicles.length * 180);
  };

  deleteVehicle() {
    let vehicleOld = this.props.vehicles[this.props.selectedVehicleIndex];
    let vehicle = this.mountVehicle(vehicleOld);
    let vehicles = this.props.vehicles.slice();

    if (vehicleOld.id) {
      vehicle.id = vehicleOld.id;
      vehicle.status = 91;
      vehicles.splice([this.props.selectedVehicleIndex], 1);
      this.props.onVehiclesLoad([...vehicles, vehicle]);
    } else {
      vehicles.splice([this.props.selectedVehicleIndex], 1);
      this.props.onVehiclesLoad([...vehicles]);
    }
  }

  updateVehicle(values) {
    let indexExists = this.props.vehicles.findIndex((item) => item.chassi.trim() == values.chassi.trim());
    if (indexExists == -1 || indexExists == this.props.selectedVehicleIndex) {
      let vehicleOld = this.props.vehicles[this.props.selectedVehicleIndex];
      let vehicle = this.mountVehicle(values);
      let vehicles = this.props.vehicles.slice();

      vehicle.id = vehicleOld.id === "undefined" ? null : vehicleOld.id;
      vehicle.status = vehicleOld.status === "undefined" ? null : vehicleOld.status;
      vehicle.estadoVeiculo = this.state.estadoVeiculo ? "N" : "U";

      vehicles.splice([this.props.selectedVehicleIndex], 1);
      //var cont = this.mountContract(values);

      this.props.onVehiclesLoad([vehicle, ...vehicles]);
      //this.props.onContractVehiclesLoad([cont, [vehicle, ...vehicles]]);
      this.props.onHideVehicleForm();
      if (!this.props.limitVehicles) {
        this.clearValuesVehicle();
      }
    } else {
      toastr.warning("Atenção", "Veículo já adicionado ao contrato!");
    }
  }

  saveContract(values) {
    return new Promise(
      function (fulfill, reject) {
        var props = this.props;
        var dados = this.formToDados(values);
        var onUploading = this.props.onUploading;

        dados.dados.contrato.diaVencParcelas != "" ? dados.dados.contrato.diaVencParcelas : (dados.dados.contrato.diaVencParcelas = null);
        this.setState({ permiteAcao: false });
        if (this.props.params.slug || (this.props.contract && this.props.contract.id)) {
          if ((this.props.contract.status === 10 || this.props.contract.status === 8) && this.props.contract.idContratoAnterior) {
            dados.dados.contrato.status = 8;
          } else if (this.props.contract.status === 10 && !this.props.contract.idContratoAnterior) {
            dados.dados.contrato.status = 0;
          }

          dados.dados.contrato.id = this.props.params.slug ? this.props.params.slug : this.props.contract.id;

          Promise.resolve(api.Contracts.update(dados))
            .then((ret) => {
              toastr.success("Sucesso", "Contrato alterado");
              Promise.resolve(api.Contracts.vehicles(ret.id)).then((vs) => {
                this.props.onVehiclesLoad(vs);
                this.setVehicleData();
                this.setState({ permiteAcao: true });
              });
              this.props.onLogLoad(api.Contracts.log(ret.id));
              if (this.state.files && this.state.files.length > 0) {
                onUploading(true);
                toastr.info("Aguarde", "Processando imagem...", {
                  timeOut: 0,
                  component: () => {
                    return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
                  },
                });
                Promise.resolve(api.Contracts.sendImagesTemp(ret.id, this.state.files))
                  .then((response) => {
                    this.setState({ files: [] });
                    this.props.onContractLoad(api.Contracts.get(ret.id));
                    onUploading(false);
                    toastr.removeByType("info");
                    toastr.success("Sucesso", "Imagem enviada.");
                    fulfill();
                  })
                  .catch(function (resp) {
                    if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
                      api.Auth.logout();
                      props.onLogout();
                    } else {
                      toastr.removeByType("info");
                      toastr.error("Erro", resp.response.body.message);
                    }
                    onUploading(false);
                    reject();
                  });
              } else {
                this.props.onContractLoad(api.Contracts.get(ret.id));
                fulfill();
              }
            })
            .catch(
              function (resp) {
                this.setState({ permiteAcao: true });
                if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
                  api.Auth.logout();
                  props.onLogout();
                } else if (resp.response.statusCode == 400) {
                  toastr.error("Erro", resp.response.body.message);
                } else {
                  toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
                }
                reject();
              }.bind(this)
            );
        } else {
          Promise.resolve(api.Contracts.create(dados))
            .then((ret) => {
              toastr.success("Sucesso", "Contrato salvo");

              Promise.resolve(api.Contracts.get(ret.id)).then((c) => {
                this.props.onContractLoad(c);
                Promise.resolve(api.Contracts.vehicles(ret.id)).then(async (vs) => {
                  this.props.onVehiclesLoad(vs);
                  this.setVehicleData();
                  this.setState({ permiteAcao: true });
                  this.props.onLogLoad(api.Contracts.log(ret.id));
                  if (this.state.files && this.state.files.length > 0) {
                    onUploading(true);
                    toastr.info("Aguarde", "Processando imagem...", {
                      timeOut: 0,
                      component: () => {
                        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
                      },
                    });
                    Promise.resolve(api.Contracts.sendImagesTemp(ret.id, this.state.files))
                      .then((response) => {
                        this.setState({ files: [] });
                        this.setState({ nomeImagem: `${ret.id}.pdf` });
                        onUploading(false);
                        toastr.removeByType("info");
                        toastr.success("Sucesso", "Imagem enviada.");
                        fulfill();
                      })
                      .catch(function (resp) {
                        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
                          api.Auth.logout();
                          props.onLogout();
                        } else {
                          toastr.removeByType("info");
                          toastr.error("Erro", resp.response.body.message);
                        }
                        onUploading(false);
                        reject();
                      });
                  } else {
                    fulfill();
                  }
                });
              });
            })
            .catch(
              function (resp) {
                this.setState({ permiteAcao: true });
                if (resp === "Unauthorized" || resp === "jwt expired") {
                  api.Auth.logout();
                  props.onLogout();
                } else if (resp.response.statusCode == 400) {
                  toastr.error("Erro", resp.response.body.message);
                } else {
                  toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
                }
                reject();
              }.bind(this)
            );
        }
      }.bind(this)
    );
  }

  mountVehicle(values) {
    var vehicle = {
      ufPlaca: typeof values.ufPlaca === "undefined" ? null : values.ufPlaca,
      numPlaca: typeof values.numPlaca === "undefined" ? null : values.numPlaca,
      renavam: typeof values.renavam === "undefined" ? null : values.renavam,
      chassi: typeof values.chassi === "undefined" ? null : values.chassi,
      chassiRemarcado: typeof values.chassiRemarcado === "undefined" ? null : values.chassiRemarcado,
      anoFabricacao: typeof values.anoFabricacao === "undefined" ? null : values.anoFabricacao,
      anoModelo: typeof values.anoModelo === "undefined" ? null : values.anoModelo,
      numGravame: typeof values.numGravame === "undefined" ? null : values.numGravame,
      //estadoVeiculo: typeof values.estadoVeiculo === "undefined" ? null : values.estadoVeiculo,
      estadoVeiculo: this.state.estadoVeiculo ? "N" : "U",
      marcaModelo: typeof values.marcaModelo === "undefined" ? null : values.marcaModelo,
      dataGravame: typeof values.dataGravame === "undefined" ? null : MaskDate.TO_DATABASE(values.dataGravame),
      especieVeiculo: typeof values.especieVeiculo === "undefined" || (typeof values.especieVeiculo === "string" && !values.especieVeiculo.trim()) ? null : values.especieVeiculo,
      cor: typeof values.cor === "undefined" ? null : values.cor,
      ufLicenciamento: this.props.haveUfLicenciamento ? values.ufLicenciamento : null,
      status:
        this.props.limitVehicles && this.props.vehicles && this.props.vehicles.length > 0 && this.props.vehicles[0].id
          ? this.props.vehicles[0].status
          : this.props.contract && this.props.contract.id && (this.props.contract.status === 8 || this.props.contract.status === 90)
          ? this.props.contract.status
          : 0,
      id: this.props.limitVehicles && this.props.vehicles && this.props.vehicles.length > 0 && this.props.vehicles[0].id ? this.props.vehicles[0].id : null,
    };
    return vehicle;
  }

  addVehicle(values) {
    if (!this.props.vehicles.find((item) => item.chassi.trim() == values.chassi.trim())) {
      this.props.onVehiclesLoad([this.mountVehicle(values), ...this.props.vehicles]);
      this.props.onHideVehicleForm();
      if (!this.props.limitVehicles) {
        this.clearValuesVehicle();
      }
    } else {
      toastr.warning("Atenção", "Veículo já adicionado ao contrato!");
    }
  }

  handleClickUpload = (filesToUpload) => {
    var props = this.props;
    var onUploading = this.props.onUploading;
    var onUploadSuccess = this.props.onUploadSuccess;
    var contractId = this.props.contract.id;
    this.props.onOpen({
      description: "Enviar a imagem do contrato ao DETRAN.",
      onSuccess: function () {
        onUploading(true);
        toastr.info("Aguarde", "Processando imagem...", {
          timeOut: 0,
          component: () => {
            return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
          },
        });
        Promise.resolve(api.Contracts.sendImages(contractId, filesToUpload))
          .then((response) => {
            onUploading(false);
            onUploadSuccess();
            toastr.removeByType("info");
            toastr.success("Sucesso", "Imagem enviada.");
          })
          .catch(
            function (resp) {
              if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
                api.Auth.logout();
                props.onLogout();
              } else {
                onUploading(false);
                toastr.removeByType("info");
                toastr.error("Erro", resp.response.body.message);
                this.setState({ btnAlterar: true });
              }
            }.bind(this)
          );
      }.bind(this),
    });
  };

  handleBlurCEP(event) {
    Promise.resolve(api.Ufs.cep(event.target.value)).then((e) => {
      if (Object.keys(e).length > 0) {
        //para casos que o servico nao devolve resultado
        if (!ufDevedorNaoValidar.includes(this.props.selectedUf) && this.props.selectedUf != e.uf) {
          this.props.dispatch(change("ContractForm", "devCep", null));
          this.props.dispatch(change("ContractForm", "devUf", null));
          this.props.dispatch(change("ContractForm", "devMunicipio", null));
          toastr.error("Erro", `CEP não pertence à UF do(a) ${this.props.selectedUf}.`);
        } else {
          this.props.dispatch(change("ContractForm", "devEndereco", e.logradouro ? e.logradouro.substring(0, 30).toUpperCase() : ""));
          this.props.dispatch(change("ContractForm", "devComplemento", e.complemento ? e.complemento.substring(0, 20).toUpperCase() : ""));
          this.props.dispatch(change("ContractForm", "devBairro", e.bairro ? e.bairro.substring(0, 20).toUpperCase() : ""));
          this.props.dispatch(change("ContractForm", "devUf", e.uf));
          this.props.dispatch(change("ContractForm", "devMunicipio", { municipioId: e.municipioId }));
        }
      }
    });
  }

  handleBlurChassi(value) {
    if (this.props.selectedUf === "SC" && this.props.contract && (this.props.contract.status === 2 || this.props.contract.status === 3) && this.props.vehicles[0].chassi !== value.chassi) {
      this.props.dispatch(change("ContractForm", "chassi", this.props.vehicles[0].chassi));
      this.setState({ modalChassiOpen: true });
    } else {
      let event = {
        target: {
          value: value.chassi,
          name: "chassi",
        },
      };
      this.handleBlurValidaContratoExistente(event);
    }
  }

  handleBlurCPFCNPJ(event) {
    Promise.resolve(api.Contracts.getPersonalDataByCPFCNPJ(event.target.value, this.props.selectedCredor.id)).then((response) => {
      if (response && response[0]) {
        this.props.onActionOpen({
          description: "Devedor já cadastrado no sistema, deseja importar os dados?",
          onSuccess: this.populaValuesPersonal.bind(this, response),
        });
      }
    });

    this.props.companies.map((c, index) => {
      if (c.id == this.props.selectedCredor.id) {
        if (event.target.value.replace(/[-./]/g, "") === c.cnpj) {
          toastr.info("Aviso", "O CNPJ informado é o mesmo do Credor!");
        }
      }
    });

    this.handleBlurValidaContratoExistente(event);
  }

  handleBlurValidaContratoExistente(event) {
    if (!this.props.contract || (this.props.contract && !this.props.contract.id)) {
      if (this.props.selectedUf === "PR" && event.target.name === "contrato") {
        if (event.target.value) {
          this.handleBlurValidaNrContrato(event);
        }
      } else if (this.props.selectedUf !== "SP" && this.props.selectedUf !== "MG" && this.props.selectedUf !== "RJ" && event.target.name === "contrato") {
        Promise.resolve(api.Contracts.getContractByNrUf(event.target.value, this.props.selectedUf)).then((response) => {
          if (response && response.length > 0) {
            this.props.onActionOpen({
              description: "Contrato já cadastrado no sistema, deseja importar os dados?",
              onSuccess: this.populaValuesContract.bind(this, response),
            });
          }
        });

        // var dados = {
        //   numero: event.target.value,
        //   uf: this.props.selectedUf,
        //   idFinanceira: this.props.selectedCredor.id,
        // };
        // Promise.resolve(api.Contracts.getByNumberExact(dados)).then((response) => {
        //   if (response && response.length > 0) {
        //     this.props.onActionOpen({
        //       description: "Contrato já cadastrado no sistema, deseja importar os dados?",
        //       onSuccess: this.populaValuesContract.bind(this, response),
        //     });
        //   }
        // });
      } else if (!event.target.name !== "contrato") {
        this.handleBlurValidaNrContratoChassiRenavam(event);
      }
    }
  }

  handleBlurValidaNrContrato(event) {
    var dados = {
      numero: event.target.value,
      uf: this.props.selectedUf,
      idFinanceira: this.props.selectedCredor.id,
    };
    Promise.resolve(api.Contracts.getByNumberExact(dados)).then((response) => {
      if (response && response.length > 0) {
        toastr.error("Erro", "Número de contrato já existente!");
        this.props.dispatch(change("ContractForm", "contrato", null));
      }
    });
  }

  handleBlurValidaNrContratoChassiRenavam(event) {
    if (this.props.selectedUf === "MA") {
      if (this.props.selectedChassi && this.props.selectedGravame && this.props.selectedDevCpfCnpj && this.props.selectedDevNome) {
        var dados = {
          uf: this.props.selectedUf,
          idFinanceira: this.props.selectedCredor.id,
          chassi: this.props.selectedChassi,
          numGravame: this.props.selectedGravame,
          devCpfCnpj: this.props.selectedDevCpfCnpj,
          devNome: this.props.selectedDevNome,
        };
        Promise.resolve(api.Contracts.getByNumberExact(dados)).then((response) => {
          if (response && response.length > 0) {
            toastr.error("Erro", "Veículo já cadastrado!");
            this.props.dispatch(change("ContractForm", event.target.name, null));
          }
        });
      }
    } else if (this.props.selectedChassi && this.props.selectedGravame) {
      var dados = {
        //numero: this.props.selectedContract,
        uf: this.props.selectedUf,
        idFinanceira: this.props.selectedCredor.id,
        chassi: this.props.selectedChassi,
        numGravame: this.props.selectedGravame,
      };
      Promise.resolve(api.Contracts.getByNumberExact(dados)).then((response) => {
        if (response && response.length > 0) {
          toastr.error("Erro", "Veículo já cadastrado!");
          this.props.dispatch(change("ContractForm", event.target.name, null));
        }
      });
    }
    // else if (event.target.name === "contrato") {
    //   var dados = {
    //     numero: event.target.value,
    //     uf: this.props.selectedUf,
    //     idFinanceira: this.props.selectedCredor.id,
    //   };
    //   Promise.resolve(api.Contracts.getByNumberExact(dados)).then((response) => {
    //     if (response && response.length > 0) {
    //       this.props.onActionOpen({
    //         description: "Contrato já cadastrado no sistema, deseja importar os dados?",
    //         onSuccess: this.populaValuesContract.bind(this, response),
    //       });
    //     }
    //   });
    // }
  }

  handleBlurValidaCamposUF(event) {
    if (!this.props.contract || (this.props.contract && !this.props.contract.id)) {
      var uf = event.target.value,
        credor = this.props.selectedCredor;
      this.props.onClearVehicle();
      this.clearValuesVehicle();
      this.clearValuesContract();
      this.props.dispatch(change("ContractForm", "uf", uf));
      this.props.dispatch(change("ContractForm", "credor", credor));
      if (uf != "SP" && uf != "BA") {
        this.props.dispatch(change("ContractForm", "ufLicenciamento", uf));
      }
    }
  }

  handleBlurDataUltimoVenc(event) {
    if (this.props.selectedQtdeParcelas && event.target.value) {
      const dtInicio = moment(event.target.value, "DD/MM/YYYY");
      dtInicio.add(Number(this.props.selectedQtdeParcelas) - 1, "M");
      this.props.dispatch(change("ContractForm", "dataUltimoVenc", MaskDate.TO_BR(dtInicio)));
    }
  }

  handleVeicNovo() {
    this.setState({ estadoVeiculo: !this.state.estadoVeiculo });
    this.props.dispatch(change("ContractForm", "renavam", null));
    this.props.dispatch(change("ContractForm", "ufPlaca", null));
    this.props.dispatch(change("ContractForm", "numPlaca", null));
  }

  clearValuesContract() {
    this.props.dispatch(change("ContractForm", "contrato", null));
    this.props.dispatch(change("ContractForm", "data", null));
    this.props.dispatch(change("ContractForm", "tipoContrato", null));
    this.props.dispatch(change("ContractForm", "numGrupoConsorcio", null));
    this.props.dispatch(change("ContractForm", "numCotaConsorcio", null));
    this.props.dispatch(change("ContractForm", "valorCredito", null));
    this.props.dispatch(change("ContractForm", "diaVencParcelas", null));
    this.props.dispatch(change("ContractForm", "valorResidual", null));
    this.props.dispatch(change("ContractForm", "tipoValorResidual", null));
    this.props.dispatch(change("ContractForm", "taxaJurosMes", null));
    this.props.dispatch(change("ContractForm", "taxaJurosAno", null));
    this.props.dispatch(change("ContractForm", "taxaMulta", null));
    this.props.dispatch(change("ContractForm", "taxaMora", null));
    this.props.dispatch(change("ContractForm", "taxaCorrecaoMonetaria", null));
    this.props.dispatch(change("ContractForm", "valorTotalFinanciamento", null));
    this.props.dispatch(change("ContractForm", "valorIOF", null));
    this.props.dispatch(change("ContractForm", "qtdeParcelas", null));
    this.props.dispatch(change("ContractForm", "valorParcela", null));
    this.props.dispatch(change("ContractForm", "dataPrimeiroVenc", null));
    this.props.dispatch(change("ContractForm", "dataUltimoVenc", null));
    this.props.dispatch(change("ContractForm", "ufLiberacaoCred", null));
    this.props.dispatch(change("ContractForm", "cidadeLiberacaoCred", null));
    this.props.dispatch(change("ContractForm", "dataLiberacaoCred", null));
    this.props.dispatch(change("ContractForm", "indices", null));
    this.props.dispatch(change("ContractForm", "penalidade", null));
    this.props.dispatch(change("ContractForm", "taxaComissao", null));
    this.props.dispatch(change("ContractForm", "devCpfCnpj", null));
    this.props.dispatch(change("ContractForm", "devNome", null));
    this.props.dispatch(change("ContractForm", "devCep", null));
    this.props.dispatch(change("ContractForm", "devUf", null));
    this.props.dispatch(change("ContractForm", "devMunicipio", null));
    this.props.dispatch(change("ContractForm", "devEndereco", null));
    this.props.dispatch(change("ContractForm", "devNumero", null));
    this.props.dispatch(change("ContractForm", "devComplemento", null));
    this.props.dispatch(change("ContractForm", "devBairro", null));
    this.props.dispatch(change("ContractForm", "devDddTelefone", null));
    this.props.dispatch(change("ContractForm", "devTelefone", null));
    this.props.dispatch(change("ContractForm", "devDddCelular", null));
    this.props.dispatch(change("ContractForm", "devCelular", null));
    this.props.dispatch(change("ContractForm", "devEmail", null));
    this.props.dispatch(change("ContractForm", "ipoc", null));
  }

  clearValuesVehicle() {
    this.props.dispatch(change("ContractForm", "ufPlaca", null));
    this.props.dispatch(untouch("ContractForm", "ufPlaca"));
    this.props.dispatch(change("ContractForm", "numPlaca", null));
    this.props.dispatch(untouch("ContractForm", "numPlaca"));
    this.props.dispatch(change("ContractForm", "renavam", null));
    this.props.dispatch(untouch("ContractForm", "renavam"));
    this.props.dispatch(change("ContractForm", "chassi", null));
    this.props.dispatch(untouch("ContractForm", "chassi"));
    this.props.dispatch(change("ContractForm", "chassiRemarcado", null));
    this.props.dispatch(untouch("ContractForm", "chassiRemarcado"));
    this.props.dispatch(change("ContractForm", "anoFabricacao", null));
    this.props.dispatch(untouch("ContractForm", "anoFabricacao"));
    this.props.dispatch(change("ContractForm", "anoModelo", null));
    this.props.dispatch(untouch("ContractForm", "anoModelo"));
    this.props.dispatch(change("ContractForm", "numGravame", null));
    this.props.dispatch(untouch("ContractForm", "numGravame"));
    this.props.dispatch(change("ContractForm", "estadoVeiculo", null));
    this.props.dispatch(untouch("ContractForm", "estadoVeiculo"));
    this.props.dispatch(change("ContractForm", "marcaModelo", null));
    this.props.dispatch(untouch("ContractForm", "marcaModelo"));
    this.props.dispatch(change("ContractForm", "dataGravame", null));
    this.props.dispatch(untouch("ContractForm", "dataGravame"));
    this.props.dispatch(change("ContractForm", "especieVeiculo", null));
    this.props.dispatch(untouch("ContractForm", "especieVeiculo"));
    this.props.dispatch(change("ContractForm", "cor", null));
    this.props.dispatch(untouch("ContractForm", "cor"));
    this.props.dispatch(change("ContractForm", "ufLicenciamento", null));
    this.props.dispatch(untouch("ContractForm", "ufLicenciamento"));
  }

  handleBlurTipoOperacao(event) {
    if (event.target.value === "1" || event.target.value === "3" || event.target.value === "5" || event.target.value === "6") {
      this.props.dispatch(change("ContractForm", "numGrupoConsorcio", null));
      this.props.dispatch(change("ContractForm", "numCotaConsorcio", null));
      this.props.dispatch(change("ContractForm", "valorCredito", null));
      this.props.dispatch(change("ContractForm", "diaVencParcelas", null));
      this.props.dispatch(change("ContractForm", "valorResidual", null));
      this.props.dispatch(change("ContractForm", "tipoValorResidual", null));
    } else if (event.target.value === "2") {
      this.props.dispatch(change("ContractForm", "taxaJurosMes", null));
      this.props.dispatch(change("ContractForm", "taxaJurosAno", null));
      this.props.dispatch(change("ContractForm", "taxaMulta", null));
      this.props.dispatch(change("ContractForm", "taxaMora", null));
      this.props.dispatch(change("ContractForm", "taxaCorrecaoMonetaria", null));
      this.props.dispatch(change("ContractForm", "valorResidual", null));
      this.props.dispatch(change("ContractForm", "tipoValorResidual", null));
    } else if (event.target.value === "4") {
      this.props.dispatch(change("ContractForm", "numGrupoConsorcio", null));
      this.props.dispatch(change("ContractForm", "numCotaConsorcio", null));
      this.props.dispatch(change("ContractForm", "valorCredito", null));
      this.props.dispatch(change("ContractForm", "diaVencParcelas", null));
    }
  }

  populaValuesPersonal(response) {
    if (this.props.selectedUf != "SP" && this.props.selectedUf != "RJ") {
      this.props.dispatch(change("ContractForm", "devCpfCnpj", response[0].devCpfCnpj));
      this.props.dispatch(change("ContractForm", "devNome", response[0].devNome ? response[0].devNome.toUpperCase() : ""));
    }
    this.props.dispatch(change("ContractForm", "devCep", response[0].devCep));
    this.props.dispatch(change("ContractForm", "devUf", response[0].devUf));
    this.props.dispatch(
      change("ContractForm", "devMunicipio", {
        municipioId: response[0].devMunicipio,
      })
    );
    this.props.dispatch(change("ContractForm", "devEndereco", response[0].devEndereco ? response[0].devEndereco.toUpperCase() : ""));
    this.props.dispatch(change("ContractForm", "devNumero", response[0].devNumero));
    this.props.dispatch(change("ContractForm", "devComplemento", response[0].devComplemento ? response[0].devComplemento.toUpperCase() : ""));
    this.props.dispatch(change("ContractForm", "devBairro", response[0].devBairro ? response[0].devBairro.toUpperCase() : ""));
    this.props.dispatch(change("ContractForm", "devDddTelefone", response[0].devDddTelefone));
    this.props.dispatch(change("ContractForm", "devTelefone", response[0].devTelefone));
    this.props.dispatch(change("ContractForm", "devDddCelular", response[0].devDddCelular));
    this.props.dispatch(change("ContractForm", "devCelular", response[0].devCelular));
    this.props.dispatch(change("ContractForm", "devEmail", response[0].devEmail ? response[0].devEmail.toUpperCase() : ""));
  }

  populaValuesContract(response) {
    if (this.props.selectedUf != "SP" && this.props.selectedUf != "RJ") {
      this.props.dispatch(change("ContractForm", "data", MaskDate.TO_BR(response[0].data)));
    }
    if (this.props.selectedUf != "RJ") {
      this.props.dispatch(change("ContractForm", "tipoContrato", response[0].tipoContrato));
    }

    this.props.dispatch(change("ContractForm", "numGrupoConsorcio", response[0].numGrupoConsorcio));
    this.props.dispatch(change("ContractForm", "numCotaConsorcio", response[0].numCotaConsorcio));
    this.props.dispatch(change("ContractForm", "valorCredito", MaskDecimal.TO_BR_TO_REAL(response[0].valorCredito)));
    this.props.dispatch(change("ContractForm", "diaVencParcelas", response[0].diaVencParcelas));
    this.props.dispatch(change("ContractForm", "valorResidual", response[0].valorResidual));
    this.props.dispatch(change("ContractForm", "tipoValorResidual", response[0].tipoValorResidual));
    this.props.dispatch(change("ContractForm", "taxaJurosMes", MaskDecimal.TO_BR_3_DECIMALS(response[0].taxaJurosMes)));
    this.props.dispatch(change("ContractForm", "taxaJurosAno", MaskDecimal.TO_BR_3_DECIMALS(response[0].taxaJurosAno)));
    this.props.dispatch(change("ContractForm", "taxaMulta", MaskDecimal.TO_BR_3_DECIMALS(response[0].taxaMulta)));
    this.props.dispatch(change("ContractForm", "taxaMora", response[0].uf == "BA" ? MaskDecimal.TO_BR_TO_REAL(response[0].taxaMora) : MaskDecimal.TO_BR_3_DECIMALS(response[0].taxaMora)));
    this.props.dispatch(change("ContractForm", "taxaCorrecaoMonetaria", MaskDecimal.TO_BR_3_DECIMALS(response[0].taxaCorrecaoMonetaria)));
    this.props.dispatch(change("ContractForm", "valorTotalFinanciamento", MaskDecimal.TO_BR_TO_REAL(response[0].valorTotalFinanciamento)));
    this.props.dispatch(change("ContractForm", "valorIOF", MaskDecimal.TO_BR_TO_REAL(response[0].valorIOF)));
    this.props.dispatch(change("ContractForm", "qtdeParcelas", MaskDecimal.TO_BR(response[0].qtdeParcelas)));
    this.props.dispatch(change("ContractForm", "valorParcela", MaskDecimal.TO_BR_TO_REAL(response[0].valorParcela)));
    this.props.dispatch(change("ContractForm", "dataPrimeiroVenc", MaskDate.TO_BR(response[0].dataPrimeiroVenc)));
    this.props.dispatch(change("ContractForm", "dataUltimoVenc", MaskDate.TO_BR(response[0].dataUltimoVenc)));
    this.props.dispatch(change("ContractForm", "ufLiberacaoCred", response[0].ufLiberacaoCred));
    this.props.dispatch(change("ContractForm", "cidadeLiberacaoCred", response[0].cidadeLiberacaoCred));
    this.props.dispatch(change("ContractForm", "dataLiberacaoCred", MaskDate.TO_BR(response[0].dataLiberacaoCred)));
    this.props.dispatch(
      change(
        "ContractForm",
        "indices",
        (response[0].uf == "RJ" || response[0].uf == "MG" || response[0].uf == "SP") && valuesIndice.indexOf(response[0].indices) == -1
          ? ""
          : valuesIndice.indexOf(response[0].indices) == -1
          ? "OUTROS"
          : response[0].indices
      )
    );
    this.props.dispatch(change("ContractForm", "penalidade", response[0].penalidade));
    this.props.dispatch(change("ContractForm", "taxaComissao", MaskDecimal.TO_BR_TO_REAL(response[0].taxaComissao)));
    this.props.dispatch(change("ContractForm", "ipoc", response[0].ipoc));
    this.populaValuesPersonal(response);
  }

  onDropRejected = () => {
    toastr.error("Erro", "Tamanho máximo do arquivo é 7.0MB!");
  };

  formToDados(values) {
    var contrato = {
      idFinanceira: this.props.companies.length === 1 ? this.props.companies[0].id : values.credor.id,
      numero: values.contrato,
      uf: values.uf,
      tipoContrato: values.tipoContrato,

      devCpfCnpj: values.devCpfCnpj,
      devNome: values.devNome,
      devUf: values.devUf,
      devMunicipio: values.devMunicipio.municipioId,
      devCep: values.devCep,
      devEndereco: values.devEndereco,
      devNumero: values.devNumero,
      devComplemento: values.devComplemento,
      devBairro: values.devBairro,
      devTelefone: values.devTelefone,
      devDddTelefone: values.devDddTelefone,
      devDddCelular: values.devDddCelular,
      devCelular: values.devCelular,
      devEmail: values.devEmail,

      status: values.status,
      idContratoAnterior: values.idContratoAnterior,

      numeroAditivo: values.numeroAditivo,
      dataAditivo: MaskDate.TO_DATABASE(values.dataAditivo),
      numRegistroOriginal: values.numRegistroOriginal,

      data: MaskDate.TO_DATABASE(values.data),
      taxaJurosMes: MaskDecimal.TO_DATABASE(values.taxaJurosMes),
      taxaJurosAno: MaskDecimal.TO_DATABASE(values.taxaJurosAno),
      taxaMulta: MaskDecimal.TO_DATABASE(values.taxaMulta),
      taxaMora: MaskDecimal.TO_DATABASE(values.taxaMora),
      taxaCorrecaoMonetaria: MaskDecimal.TO_DATABASE(values.taxaCorrecaoMonetaria),
      possuiTaxaMulta: values.possuiTaxaMulta,

      valorTotalFinanciamento: MaskDecimal.TO_DATABASE(values.valorTotalFinanciamento),
      valorIOF: MaskDecimal.TO_DATABASE(values.valorIOF),
      valorParcela: MaskDecimal.TO_DATABASE(values.valorParcela),
      qtdeParcelas: MaskDecimal.TO_DATABASE(values.qtdeParcelas),
      dataPrimeiroVenc: MaskDate.TO_DATABASE(values.dataPrimeiroVenc),
      dataUltimoVenc: MaskDate.TO_DATABASE(values.dataUltimoVenc),
      dataLiberacaoCred: MaskDate.TO_DATABASE(values.dataLiberacaoCred),
      indices: values.indices,
      penalidade: values.penalidade,
      taxaComissao: MaskDecimal.TO_DATABASE(values.taxaComissao),
      ufLiberacaoCred: values.ufLiberacaoCred,
      cidadeLiberacaoCred: values.cidadeLiberacaoCred ? (values.cidadeLiberacaoCred.municipio ? values.cidadeLiberacaoCred.municipio : values.cidadeLiberacaoCred) : null,
      numGrupoConsorcio: values.numGrupoConsorcio,
      numCotaConsorcio: values.numCotaConsorcio,
      valorCredito: MaskDecimal.TO_DATABASE(values.valorCredito),
      diaVencParcelas: values.diaVencParcelas,
      codigoAgente: values.codigoAgente,
      ipoc: values.ipoc,

      //Para alteração quando os campos existirem em banco de dados
      numRegistro: this.props.contract ? this.props.contract.numRegistro : null,
      numRegistroOriginal: this.props.contract ? this.props.contract.numRegistroOriginal : null,
    };

    if (this.props.contract && (this.props.contract.status === 8 || this.props.contract.status === 90)) {
      contrato.numRegistroOriginal = values.numRegistroOriginal;
    }
    var vehicles = [];
    if (this.props.limitVehicles) {
      vehicles.push(this.mountVehicle(values));
    } else {
      _.forEach(this.props.vehicles, function (v) {
        vehicles = [v, ...vehicles];
      });
    }

    var dados = {
      dados: {
        contrato: contrato,
        veiculos: vehicles,
      },
    };
    return dados;
  }

  statusColor() {
    if (this.props.contract) {
      if (this.props.contract.status === 0 || this.props.contract.status === 1 || this.props.contract.status === 8) {
        return "hpanel hblue";
      }
      if (this.props.contract.status === 2) {
        return "hpanel hyellow";
      }
      if (this.props.contract.status === 3) {
        return "hpanel hgreen";
      }
      if (this.props.contract.status === 6) {
        return "hpanel hviolet";
      }
      if (this.props.contract.status === 90 || this.props.contract.status === 10) {
        return "hpanel hred";
      }
      return "hpanel";
    } else {
      return "hpanel hblue";
    }
  }

  preparaUFs(event) {
    if (this.props.contract && this.props.contract.id) {
      var filtered = this.props.companies.filter((comp) => comp.id == event.id);
      if (filtered[0].ufs.find((item) => item.uf === this.props.contract.uf)) {
        this.props.dispatch(change("ContractForm", "credor", filtered[0]));
        this.setState({ credorInvalido: false });
      } else {
        this.setState({ credorInvalido: true });
        toastr.error("Erro", `Financeira não possuí a uf ${this.props.contract.uf} habilitada!`);
      }
    } else {
      this.props.dispatch(change("ContractForm", "uf", null));
      if (event) {
        var filtered = this.props.companies.filter((comp) => comp.id == event.id);
        this.props.onUfLoad([{ sigla: "" }, ...this.props.currentUser.ufs.filter((item) => filtered[0].ufs.some((item2) => item2.uf === item.sigla))]);
      }
    }
  }

  scrollToFirstError(errors) {
    const firstError = Object.keys(errors)[0];
    const el = document.querySelector(`[name="${firstError}"]`);
    const position = el.getBoundingClientRect().top + document.documentElement.scrollTop;

    const offset = 150;

    window.scrollTo({ top: position - offset, behavior: "smooth" });
  }

  renderAlienacaoFiduciariaFinanciamento() {
    if (this.props.selectedType && (this.props.selectedType == 1 || this.props.selectedType == 3)) {
      return (
        <div>
          <div className="row">
            <div className="col-md-6">
              <Field name="taxaJurosMes" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} label="Taxa juros ao mês" placeholder="000,000" component={TextField} type="text" />
            </div>
            <div className="col-md-6">
              <Field name="taxaJurosAno" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} label="Taxa juros ao ano" placeholder="000,000" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className={this.props.selectedUf && this.props.selectedUf === "MG" ? "col-md-4" : "col-md-6"}>
              <Field name="taxaMulta" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} placeholder="000,000" label="Taxa de multa %" component={TextField} type="text" />
            </div>
            {this.props.selectedUf && this.props.selectedUf === "BA" ? (
              <div className="col-md-6">
                <Field name="taxaMora" placeholder="0.000.000,00" label="Valor da mora" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} component={TextField} type="text" />
              </div>
            ) : (
              <div className={this.props.selectedUf && this.props.selectedUf === "MG" ? "col-md-4" : "col-md-6"}>
                <Field name="taxaMora" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} placeholder="000,000" label="Taxa de mora %" component={TextField} type="text" />
              </div>
            )}
            {this.props.selectedUf && (this.props.selectedUf === "MG" || this.props.selectedUf === "RJ" || this.props.selectedUf === "PI") && (
              <div className="col-md-4">
                <Field
                  name="taxaCorrecaoMonetaria"
                  normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3}
                  maxlength={7}
                  label="Correção Monetária %"
                  placeholder="000,000"
                  component={TextField}
                  type="text"
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="valorTotalFinanciamento"
                placeholder="0.000.000,00"
                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                maxlength={13}
                validate={[FieldValidation.requiredNot0, FieldValidation.vlMaximoTotal]}
                label={this.props.selectedUf && this.props.selectedUf === "MG" ? "Valor da dívida" : "Valor total do financiamento"}
                component={TextField}
                type="text"
                description="Total da Dívida, ou sua estimativa"
                required={true}
              />
            </div>
            <div className="col-md-6">
              <Field name="valorIOF" placeholder="0.000.000,00" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} label="Valor do IOF" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field name="qtdeParcelas" normalize={FieldNormalize.NUMBER} label="Quantidade de parcelas" component={TextField} type="text" maxlength={3} required={true} />
            </div>
            <div className="col-md-6">
              <Field
                name="valorParcela"
                placeholder="0.000.000,00"
                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                maxlength={13}
                label="Valor da parcela"
                component={TextField}
                type="text"
                required={this.props.selectedUf === "AC" || this.props.selectedUf === "AP" || this.props.selectedUf === "RN" ? true : false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="dataPrimeiroVenc"
                label="Data primeiro vencimento"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataPrimeiroVenc}
                component={TextDatePickerField}
                type="text"
                onBlur={this.handleBlurDataUltimoVenc.bind(this)}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="dataUltimoVenc"
                label="Data último vencimento"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataUltimoVenc}
                component={TextDatePickerField}
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <UfField name="ufLiberacaoCred" label="UF liberação do crédito" required={true} />
            </div>
            <div className="col-md-8">
              <Field name="cidadeLiberacaoCred" label="Cidade liberação crédito" component={CityFilteredField} selectedUf={this.props.selectedUfLiberacaoCred} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="dataLiberacaoCred"
                label="Data liberação crédito"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataLiberacaoCred}
                component={TextDatePickerField}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <Field
                name="indices"
                required={true}
                label="Índices"
                blank={true}
                data={[
                  { id: "PREFIXADO", name: "PREFIXADO" },
                  { id: "POSFIXADO", name: "POSFIXADO" },
                  { id: "VALOR BEM", name: "VALOR BEM" },
                  { id: "IGP", name: "IGP" },
                  { id: "IGPM", name: "IGPM" },
                  { id: "IPC", name: "IPC" },
                  { id: "IPCA", name: "IPCA" },
                  { id: "OUTROS", name: "OUTROS" },
                  { id: "0", name: "0" },
                ]}
                component={SelectField}
                type="text"
                valueField="id"
                textField="name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="penalidade"
                label="Texto da penalidade"
                component={TextField}
                type="text"
                maxlength={30}
                normalize={FieldNormalize.UPPERCASE}
                required={this.props.selectedUf === "RJ" ? true : false}
              />
            </div>
            <div className="col-md-6">
              <Field name="taxaComissao" placeholder="0.000.000,00" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} label="Taxa da comissão" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field name="ipoc" label="IPOC" component={TextField} type="text" maxlength={100} />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  renderAlienacaoFiduciariaConsorcio() {
    if (this.props.selectedType && this.props.selectedType == 2) {
      return (
        <div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="valorTotalFinanciamento"
                placeholder="0.000.000,00"
                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                maxlength={13}
                validate={[FieldValidation.requiredNot0, FieldValidation.vlMaximoTotal]}
                label={this.props.selectedUf && this.props.selectedUf === "MG" ? "Valor da dívida" : "Valor total do financiamento"}
                component={TextField}
                type="text"
                description="Total da Dívida, ou sua estimativa"
                required={true}
              />
            </div>
            <div className="col-md-6">
              <Field name="valorIOF" placeholder="0.000.000,00" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} label="Valor do IOF" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field name="qtdeParcelas" normalize={FieldNormalize.NUMBER} label="Quantidade de parcelas" component={TextField} type="text" maxlength={3} required={true} />
            </div>
            <div className="col-md-6">
              <Field
                name="valorParcela"
                placeholder="0.000.000,00"
                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                maxlength={13}
                label="Valor da parcela"
                component={TextField}
                type="text"
                required={this.props.selectedUf === "AC" || this.props.selectedUf === "AP" || this.props.selectedUf === "RN" ? true : false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="dataPrimeiroVenc"
                label="Data primeiro vencimento"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataPrimeiroVenc}
                component={TextDatePickerField}
                type="text"
                onBlur={this.handleBlurDataUltimoVenc.bind(this)}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="dataUltimoVenc"
                label="Data último vencimento"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataUltimoVenc}
                component={TextDatePickerField}
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <UfField name="ufLiberacaoCred" label="UF liberação do crédito" required={true} />
            </div>
            <div className="col-md-6">
              <Field name="cidadeLiberacaoCred" label="Cidade liberação crédito" component={CityFilteredField} selectedUf={this.props.selectedUfLiberacaoCred} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="dataLiberacaoCred"
                label="Data liberação crédito"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataLiberacaoCred}
                component={TextDatePickerField}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <Field
                name="indices"
                required={true}
                label="Índices"
                blank={true}
                data={[
                  { id: "PREFIXADO", name: "PREFIXADO" },
                  { id: "POSFIXADO", name: "POSFIXADO" },
                  { id: "VALOR BEM", name: "VALOR BEM" },
                  { id: "IGP", name: "IGP" },
                  { id: "IGPM", name: "IGPM" },
                  { id: "IPC", name: "IPC" },
                  { id: "IPCA", name: "IPCA" },
                  { id: "OUTROS", name: "OUTROS" },
                  { id: "0", name: "0" },
                ]}
                component={SelectField}
                type="text"
                valueField="id"
                textField="name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field name="numGrupoConsorcio" label="Número grupo consórcio" component={TextField} normalize={FieldNormalize.UPPERCASE} type="text" maxlength={6} />
            </div>
            <div className="col-md-6">
              <Field name="numCotaConsorcio" label="Número cota consórcio" normalize={FieldNormalize.NUMBER} component={TextField} type="text" maxlength={6} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field name="valorCredito" placeholder="0.000.000,00" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} label="Valor do crédito" component={TextField} type="text" />
            </div>
            <div className="col-md-6">
              <Field name="diaVencParcelas" normalize={FieldNormalize.NUMBER} maxlength={2} label="Dia de vencimento das parcelas" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field name="penalidade" label="Texto da penalidade" component={TextField} type="text" maxlength={50} normalize={FieldNormalize.UPPERCASE} />
            </div>
            <div className="col-md-6">
              <Field name="taxaComissao" placeholder="0.000.000,00" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} label="Taxa da comissão" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field name="ipoc" label="IPOC" component={TextField} type="text" maxlength={100} />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  renderArrendamentoMercantilLeasing() {
    if (this.props.selectedType && this.props.selectedType == 4) {
      return (
        <div>
          <div className="row">
            <div className="col-md-6">
              <Field name="taxaJurosMes" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} placeholder="000,000" label="Taxa juros ao mês" component={TextField} type="text" />
            </div>
            <div className="col-md-6">
              <Field name="taxaJurosAno" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} placeholder="000,000" label="Taxa juros ao ano" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className={this.props.selectedUf && this.props.selectedUf === "MG" ? "col-md-4" : "col-md-6"}>
              <Field name="taxaMulta" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} placeholder="000,000" label="Taxa de multa %" component={TextField} type="text" />
            </div>
            {this.props.selectedUf && this.props.selectedUf === "BA" ? (
              <div className="col-md-6">
                <Field name="taxaMora" placeholder="0.000.000,00" label="Valor da mora" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} component={TextField} type="text" />
              </div>
            ) : (
              <div className={this.props.selectedUf && this.props.selectedUf === "MG" ? "col-md-4" : "col-md-6"}>
                <Field name="taxaMora" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} placeholder="000,000" label="Taxa de mora %" component={TextField} type="text" />
              </div>
            )}
            {this.props.selectedUf && (this.props.selectedUf === "MG" || this.props.selectedUf === "RJ" || this.props.selectedUf === "PI") && (
              <div className="col-md-4">
                <Field
                  name="taxaCorrecaoMonetaria"
                  normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3}
                  maxlength={7}
                  label="Correção Monetária %"
                  placeholder="000,000"
                  component={TextField}
                  type="text"
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="valorTotalFinanciamento"
                placeholder="0.000.000,00"
                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                maxlength={13}
                validate={[FieldValidation.requiredNot0, FieldValidation.vlMaximoTotal]}
                label={this.props.selectedUf && this.props.selectedUf === "MG" ? "Valor da dívida" : "Valor total do financiamento"}
                component={TextField}
                type="text"
                description="Total da Dívida, ou sua estimativa"
                required={true}
              />
            </div>
            <div className="col-md-6">
              <Field name="valorIOF" placeholder="0.000.000,00" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} label="Valor do IOF" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field name="qtdeParcelas" normalize={FieldNormalize.NUMBER} label="Quantidade de parcelas" component={TextField} type="text" maxlength={3} required={true} />
            </div>
            <div className="col-md-6">
              <Field
                name="valorParcela"
                placeholder="0.000.000,00"
                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                maxlength={13}
                label="Valor da parcela"
                component={TextField}
                type="text"
                required={this.props.selectedUf === "AC" || this.props.selectedUf === "AP" || this.props.selectedUf === "RN" ? true : false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="dataPrimeiroVenc"
                label="Data primeiro vencimento"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataPrimeiroVenc}
                component={TextDatePickerField}
                type="text"
                onBlur={this.handleBlurDataUltimoVenc.bind(this)}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="dataUltimoVenc"
                label="Data último vencimento"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataUltimoVenc}
                component={TextDatePickerField}
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <UfField name="ufLiberacaoCred" label="UF liberação do crédito" required={true} />
            </div>
            <div className="col-md-6">
              <Field name="cidadeLiberacaoCred" label="Cidade liberação crédito" component={CityFilteredField} selectedUf={this.props.selectedUfLiberacaoCred} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="dataLiberacaoCred"
                label="Data liberação crédito"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataLiberacaoCred}
                component={TextDatePickerField}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <Field
                name="indices"
                required={true}
                label="Índices"
                blank={true}
                data={[
                  { id: "PREFIXADO", name: "PREFIXADO" },
                  { id: "POSFIXADO", name: "POSFIXADO" },
                  { id: "VALOR BEM", name: "VALOR BEM" },
                  { id: "IGP", name: "IGP" },
                  { id: "IGPM", name: "IGPM" },
                  { id: "IPC", name: "IPC" },
                  { id: "IPCA", name: "IPCA" },
                  { id: "OUTROS", name: "OUTROS" },
                  { id: "0", name: "0" },
                ]}
                component={SelectField}
                type="text"
                valueField="id"
                textField="name"
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6">
              <Field name="valorResidual" normalize={FieldNormalize.DECIMAL} label="Valor residual garantido" component={TextField} type="text"/>
            </div>
            <div className="col-md-6">
              <Field name="tipoValorResidual" label="Tipo do valor residual" component={TextField} normalize={FieldNormalize.UPPERCASE} type="text" description="Indicação do valor residual em % ou R$"/>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-6">
              <Field name="penalidade" label="Texto da penalidade" component={TextField} type="text" maxlength={50} normalize={FieldNormalize.UPPERCASE} />
            </div>
            <div className="col-md-6">
              <Field name="taxaComissao" placeholder="0.000.000,00" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} label="Taxa da comissão" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field name="ipoc" label="IPOC" component={TextField} type="text" maxlength={100} />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  renderReservaDeDominioOuPenhor() {
    if (this.props.selectedType && (this.props.selectedType == 5 || this.props.selectedType == 6)) {
      return (
        <div>
          <div className="row">
            <div className="col-md-6">
              <Field name="taxaJurosMes" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} placeholder="000,000" label="Taxa juros ao mês" component={TextField} type="text" />
            </div>
            <div className="col-md-6">
              <Field name="taxaJurosAno" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} placeholder="000,000" label="Taxa juros ao ano" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className={this.props.selectedUf && this.props.selectedUf === "MG" ? "col-md-4" : "col-md-6"}>
              <Field name="taxaMulta" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} placeholder="000,000" label="Taxa de multa %" component={TextField} type="text" />
            </div>
            {this.props.selectedUf && this.props.selectedUf === "BA" ? (
              <div className="col-md-6">
                <Field name="taxaMora" placeholder="0.000.000,00" label="Valor da mora" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} component={TextField} type="text" />
              </div>
            ) : (
              <div className={this.props.selectedUf && this.props.selectedUf === "MG" ? "col-md-4" : "col-md-6"}>
                <Field name="taxaMora" normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3} maxlength={7} placeholder="000,000" label="Taxa de mora %" component={TextField} type="text" />
              </div>
            )}
            {this.props.selectedUf && (this.props.selectedUf === "MG" || this.props.selectedUf === "RJ" || this.props.selectedUf === "PI") && (
              <div className="col-md-4">
                <Field
                  name="taxaCorrecaoMonetaria"
                  normalize={FieldNormalize.PERCENT_INPUT_FORMAT_3}
                  maxlength={7}
                  label="Correção Monetária %"
                  placeholder="000,000"
                  component={TextField}
                  type="text"
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="valorTotalFinanciamento"
                placeholder="0.000.000,00"
                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                maxlength={13}
                validate={[FieldValidation.requiredNot0, FieldValidation.vlMaximoTotal]}
                label={this.props.selectedUf && this.props.selectedUf === "MG" ? "Valor da dívida" : "Valor total do financiamento"}
                component={TextField}
                type="text"
                description="Total da Dívida, ou sua estimativa"
                required={true}
              />
            </div>
            <div className="col-md-6">
              <Field name="valorIOF" placeholder="0.000.000,00" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} label="Valor do IOF" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field name="qtdeParcelas" normalize={FieldNormalize.NUMBER} label="Quantidade de parcelas" component={TextField} type="text" maxlength={3} required={true} />
            </div>
            <div className="col-md-6">
              <Field
                name="valorParcela"
                placeholder="0.000.000,00"
                normalize={FieldNormalize.PERCENT_INPUT_FORMAT}
                maxlength={13}
                label="Valor da parcela"
                component={TextField}
                type="text"
                required={this.props.selectedUf === "AC" || this.props.selectedUf === "AP" || this.props.selectedUf === "RN" ? true : false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="dataPrimeiroVenc"
                label="Data primeiro vencimento"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataPrimeiroVenc}
                component={TextDatePickerField}
                type="text"
                onBlur={this.handleBlurDataUltimoVenc.bind(this)}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="dataUltimoVenc"
                label="Data último vencimento"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataUltimoVenc}
                component={TextDatePickerField}
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <UfField name="ufLiberacaoCred" label="UF liberação do crédito" required={true} />
            </div>
            <div className="col-md-6">
              <Field name="cidadeLiberacaoCred" label="Cidade liberação crédito" component={CityFilteredField} selectedUf={this.props.selectedUfLiberacaoCred} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="dataLiberacaoCred"
                label="Data liberação crédito"
                required={true}
                validate={[FieldValidation.date]}
                normalize={FieldNormalize.DATE}
                placeholder="00/00/0000"
                data={this.props.dataLiberacaoCred}
                component={TextDatePickerField}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <Field
                name="indices"
                required={true}
                label="Índices"
                blank={true}
                data={[
                  { id: "PREFIXADO", name: "PREFIXADO" },
                  { id: "POSFIXADO", name: "POSFIXADO" },
                  { id: "VALOR BEM", name: "VALOR BEM" },
                  { id: "IGP", name: "IGP" },
                  { id: "IGPM", name: "IGPM" },
                  { id: "IPC", name: "IPC" },
                  { id: "IPCA", name: "IPCA" },
                  { id: "OUTROS", name: "OUTROS" },
                  { id: "0", name: "0" },
                ]}
                component={SelectField}
                type="text"
                valueField="id"
                textField="name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field name="penalidade" label="Texto da penalidade" component={TextField} type="text" maxlength={50} normalize={FieldNormalize.UPPERCASE} />
            </div>
            <div className="col-md-6">
              <Field name="taxaComissao" placeholder="0.000.000,00" normalize={FieldNormalize.PERCENT_INPUT_FORMAT} maxlength={12} label="Taxa da comissão" component={TextField} type="text" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field name="ipoc" label="IPOC" component={TextField} type="text" maxlength={100} />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    if (!this.props.companies) {
      return null;
    }

    const { handleSubmit, reset, pristine, submitting } = this.props;

    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description={this.props.contrato ? "Alteração de um contrato" : "Registro de um novo contrato"} />
        <div className="content">
          <form className="form-horizontal">
            {this.props.errorMessage && this.props.errorMessage && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="hpanel">
                    <div className="alert alert-warning">
                      <i className="fa fa-comment"></i> {this.props.errorMessage}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.props.showAverbacao && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="hpanel">
                    {this.props.selectedUf !== "BA" && this.props.selectedUf !== "MA" ? (
                      <div className="alert alert-info">
                        <i className="fa fa-exclamation"></i> Possivelmente este contrato já foi registrado utilizando outro sistema de registro, caso deseje realizar uma averbação selecione a opção a
                        abaixo.
                        <br />
                        <br />
                        <Link className="btn btn-default btn-sm" onClick={this.handleCreateAdditive()}>
                          <i className="far fa-plus-square"></i> Averbar{" "}
                        </Link>
                      </div>
                    ) : (
                      <div className="alert alert-info">
                        <i className="fa fa-exclamation"></i> Possivelmente este contrato já foi registrado utilizando outro sistema de registro, de acordo com a legislação do estado é necessário que
                        alterações e averbações sejam realizadas pelo mesmo sistema.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-12">
                <div className={this.statusColor()}>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-2">
                        {/*
                        {((this.props.limitVehicles || ( !this.props.limitVehicles && !this.props.showVehicleForm)) && ((!this.props.contract || (this.props.contract.status === 0 || this.props.contract.status === 8 || this.props.contract.status === 90 || this.props.contract.status === 10)) && ( this.props.contract && this.props.contract.id))) &&

                          <button className="btn btn-outline-gray btn-sm" type="button" onClick={
                              this.reset()
                          }><i className="far fa-copy"></i> Copiar Contrato</button>
                        }

                      */}
                      </div>

                      <div className="col-md-12" hidden={this.state.hiddenDivBtn}>
                        {this.props.contract && this.props.contract.status !== 8 && this.props.contract.idContratoAnterior && (
                          <div className="row float-left">
                            <Link className="btn btn-sm btn-default" to={`/secure/contract/view/${encodeURIComponent(this.props.contract.idContratoAnterior)}`}>
                              Visualizar contrato anterior <i className="fa fa-search"></i>
                            </Link>
                          </div>
                        )}
                        {this.state.permiteAcao && !this.props.isUploading && (this.props.limitVehicles || (!this.props.limitVehicles && !this.props.showVehicleForm)) && (
                          <div className="row float-right">
                            {(!this.props.contract || this.props.contract.status === 0 || this.props.contract.status === 8 || this.props.contract.status === 90 || this.props.contract.status === 10) &&
                              (this.props.limitVehicles || this.props.vehicles.length > 0) &&
                              this.props.currentUser.funcionalidades.includes(23) && (
                                <button
                                  className="btn btn-default btn-sm"
                                  type="button"
                                  style={{ margin: "0 2px" }}
                                  //ref="btnSalvar"
                                  onClick={this.props.handleSubmit((values) => {
                                    return new Promise((resolve, reject) => {
                                      var errors;
                                      if (this.props.limitVehicles) {
                                        errors = _.assign(validateContract(values), validateDebtor(values, this.props.selectedUf), validateCar(values, this.props.haveUfLicenciamento));
                                      } else {
                                        errors = _.assign(validateContract(values), validateDebtor(values, this.props.selectedUf));
                                      }
                                      if (errors && !_.isEmpty(errors)) {
                                        toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
                                        this.scrollToFirstError(errors);
                                        reject(new SubmissionError(errors));
                                      } else {
                                        this.saveContract(values);
                                        resolve();
                                      }
                                    });
                                  })}
                                >
                                  <i className="fa fa-save"></i> Salvar{" "}
                                </button>
                              )}

                            {this.state.exibeBtnExcluir &&
                              this.props.contract &&
                              this.props.contract.id &&
                              (this.props.contract.status === 0 || this.props.contract.status === 8 || (this.props.contract.status === 90 && this.props.contract.uf !== "BA")) &&
                              this.props.currentUser.funcionalidades.includes(24) && (
                                <button className="btn btn-default btn-sm" type="button" style={{ margin: "0 2px" }} onClick={this.handleDeleteContract()}>
                                  <i className="fas fa-trash-alt"></i> Excluir Contrato{" "}
                                </button>
                              )}

                            {this.props.contract &&
                              this.props.contract.id &&
                              (this.props.contract.status === 0 || (this.props.contract.status === 90 && !this.props.contract.idContratoAnterior)) &&
                              (this.props.limitVehicles || this.props.vehicles.length > 0) &&
                              this.props.currentUser.funcionalidades.includes(25) && (
                                <button
                                  className="btn btn-primary btn-sm"
                                  typpe="button"
                                  style={{ margin: "0 2px" }}
                                  onClick={this.props.handleSubmit((values) => {
                                    return new Promise((resolve, reject) => {
                                      var errors;
                                      if (this.props.limitVehicles) {
                                        errors = _.assign(validateContract(values), validateDebtor(values, this.props.selectedUf), validateCar(values, this.props.haveUfLicenciamento));
                                      } else {
                                        errors = _.assign(validateContract(values), validateDebtor(values, this.props.selectedUf));
                                      }
                                      if (errors && !_.isEmpty(errors)) {
                                        toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
                                        this.scrollToFirstError(errors);
                                        reject(new SubmissionError(errors));
                                      } else {
                                        this.saveContract(values)
                                          .then((res) => {
                                            this.handleClickSendAll();
                                            resolve();
                                          })
                                          .catch((err) => {
                                            resolve();
                                          });
                                      }
                                    });
                                  })}
                                >
                                  <i className="fa fa-exchange-alt"></i> Enviar ao Detran
                                </button>
                              )}

                            {/* {this.props.selectedUf &&
                              this.props.selectedUf === "BA" &&
                              this.props.contract &&
                              (this.props.contract.status === 4 || this.props.contract.status === 3 || this.props.contract.status === 2) && (
                                <button className="btn btn-primary btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnCancelContract()}>
                                  <i className="fas fa-window-close"></i>
                                  {this.props.selectedUf === "BA" ? " Enviar Cancelamento ao Detran" : " Cancelar"}
                                </button>
                              )}
                            {this.props.selectedUf &&
                              this.props.selectedUf === "BA" &&
                              this.props.contract &&
                              (this.props.contract.status === 4 || this.props.contract.status === 3 || this.props.contract.status === 2) && (
                                <button className="btn btn-primary btn-sm" style={{ margin: "0 2px" }} onClick={this.handleOnShelveContract()}>
                                  <i className="fas fa-archive"></i>
                                  {this.props.selectedUf === "BA" ? " Enviar Baixa ao Detran" : " Baixar"}
                                </button>
                              )} */}

                            {this.props.contract &&
                              this.props.contract.id &&
                              (this.props.contract.numeroAditivo || (this.props.selectedUf && this.props.selectedUf == "MA")) &&
                              ((this.props.contract.status === 90 && this.props.contract.idContratoAnterior) || this.props.contract.status === 8) &&
                              this.props.currentUser.funcionalidades.includes(29) && (
                                <button
                                  className="btn btn-primary btn-sm"
                                  typpe="button"
                                  style={{ margin: "0 2px" }}
                                  onClick={this.props.handleSubmit((values) => {
                                    return new Promise((resolve, reject) => {
                                      var errors;
                                      if (this.props.vehicles && this.props.vehicles.length > 0) {
                                        errors = _.assign(validateContract(values), validateDebtor(values, this.props.selectedUf));
                                      } else {
                                        errors = _.assign(validateContract(values), validateDebtor(values, this.props.selectedUf), validateCar(values, this.props.haveUfLicenciamento));
                                      }

                                      if (errors && !_.isEmpty(errors)) {
                                        toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
                                        this.scrollToFirstError(errors);
                                        reject(new SubmissionError(errors));
                                      } else {
                                        this.saveContract(values)
                                          .then((res) => {
                                            this.handleClickAdditiveSendAll();
                                            resolve();
                                          })
                                          .catch((err) => {
                                            resolve();
                                          });
                                      }
                                    });
                                  })}
                                >
                                  <i className="fa fa-exchange-alt"></i> Enviar Averbação ao Detran
                                </button>
                              )}
                            {/*(this.props.contract && this.props.contract.status === 2 && !this.props.isUploading && this.props.currentUser.funcionalidades.includes(27)) &&
                            <Dropzone accept="application/pdf" maxSize={this.props.selectedUf == 'SC' ? 7000000 : 9999999999} onDropRejected={this.onDropRejected}
                              name="file" className="dropzone-blank fallback" style={{margin: '0 2px'}} multiple={false}
                              onDropAccepted={( filesToUpload, e ) => this.handleClickUpload(filesToUpload)} >
                              <button type="button" className="btn btn-primary btn-sm" ><i className="fa fa-upload"/> Enviar Imagem</button>
                            </Dropzone>
                          */}
                            {/*(this.props.contract && this.props.contract.status === 2 && this.props.isUploading === true) &&
                            <div type="button" className="btn btn-sm" style={{margin: '0 2px'}} >Enviando Imagem <BarLoader color={'#18395F'} loading={true} /></div>
                          */}
                            {this.props.contract &&
                              (this.props.contract.status === 3 || this.props.contract.status === 2) &&
                              this.props.currentUser.funcionalidades.includes(28) &&
                              this.props.selectedUf !== "RS" &&
                              this.props.selectedUf !== "RN" &&
                              this.props.selectedUf !== "MS" && (
                                <div>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    style={{ margin: "0 2px" }}
                                    typpe="button"
                                    hidden={this.state.btnAlterar}
                                    onClick={this.props.handleSubmit((values) => {
                                      return new Promise((resolve, reject) => {
                                        var errors;
                                        if (this.props.limitVehicles) {
                                          errors = _.assign(validateContract(values), validateDebtor(values, this.props.selectedUf), validateCar(values, this.props.haveUfLicenciamento));
                                        } else {
                                          errors = _.assign(validateContract(values), validateDebtor(values, this.props.selectedUf));
                                        }
                                        if (errors && !_.isEmpty(errors)) {
                                          toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
                                          this.scrollToFirstError(errors);
                                          reject(new SubmissionError(errors));
                                        } else {
                                          this.handleClickSendChanges(values);
                                          resolve();
                                        }
                                      });
                                    })}
                                  >
                                    <i className="fas fa-exchange-alt"></i> Enviar Alteração ao Detran
                                  </button>
                                </div>
                              )}
                            <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={browserHistory.goBack}>
                              <i className="fas fa-arrow-left"></i> Voltar{" "}
                            </Link>
                          </div>
                        )}
                        {!this.state.permiteAcao && (
                          <div className="row float-right col-md-1">
                            {/* <div className="col-md-3">
                              <span>Aguarde</span>
                            </div> */}

                            <PropagateLoader margin={2} color={"#58abc3"} loading={true} />
                          </div>
                        )}
                        {!this.props.limitVehicles && this.props.showVehicleForm && (
                          <div className="text-right">
                            <Link className="btn btn-default btn-sm" style={{ margin: "0 2px" }} onClick={browserHistory.goBack}>
                              <i className="fas fa-arrow-left"></i> Voltar{" "}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <br />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7">
                        {this.props.contract && this.props.contract.id && (this.props.selectedUf == "SP" || this.props.selectedUf == "MG" || this.props.selectedUf == "RJ") ? (
                          <Field name="credorName" label="Credor" component={TextField} type="text" disabled={true} />
                        ) : this.props.companies && this.props.companies.length > 0 ? (
                          <Field
                            name="credor"
                            label="Credor"
                            data={this.props.companies}
                            credor={true}
                            required={true}
                            textField="razaoSocialCnpj"
                            valueField="id"
                            component={DropdownListField}
                            type="text"
                            description="Instituição credora do contrato"
                            blank={true}
                            onChange={this.preparaUFs.bind(this)}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {/*(!this.props.contract || (this.props.contract && (this.props.contract.status == 0 || this.props.contract.status == 90))) &&*/}
                      {(!this.props.contract || (this.props.contract && !this.props.contract.id)) && (
                        <div className="col-md-2">
                          <Field
                            name="uf"
                            label="UF Detran"
                            data={this.props.ufs}
                            required
                            textField="sigla"
                            valueField="sigla"
                            component={SelectField}
                            type="text"
                            onChange={this.handleBlurValidaCamposUF.bind(this)}
                          />
                        </div>
                      )}
                      {/*this.props.contract && (this.props.contract.status != 0 && this.props.contract.status != 90) && */}
                      {this.props.contract && this.props.contract.id && (
                        <div className="col-md-2">
                          <Field name="uf" label="UF Detran" component={TextField} type="text" disabled={true} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.props.selectedUf &&
              ((this.props.searchBeforeRegister && this.props.selectedCredor && !this.props.contract) ||
                (this.props.contract && this.props.contract.status !== 2 && this.props.contract.status !== 3 && this.props.searchBeforeRegister)) && (
                <div className="row">
                  {this.props.searchBeforeRegisterUf && this.props.searchBeforeRegisterUf == "SP" && (!this.props.contract || (this.props.contract && !this.props.contract.id)) && (
                    <div className="col-lg-12">
                      <div className="hpanel">
                        <div className="panel-heading">
                          <div className="panel-tools"></div>
                          Pesquisa de Veículo
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-6 border-right">
                              <Field
                                name="findChassi"
                                label="Chassi"
                                maxlength={17}
                                component={TextField}
                                type="text"
                                inline={false}
                                required={true}
                                validate={[FieldValidation.chassi]}
                                normalize={FieldNormalize.UPPERCASE}
                              />
                            </div>

                            <div className="col-md-6">
                              <Field name="findPlaca" label="Placa" component={TextField} type="text" inline={false} required={true} normalize={FieldNormalize.PLATE} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="float-right">
                                <button className="btn btn-default btn-sm" type="button" onClick={this.props.handleSubmit(this.handleClickFindSP.bind(this))}>
                                  <i className="fa fa-search"></i> Pesquisar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.props.searchBeforeRegisterUf && this.props.searchBeforeRegisterUf == "MG" && (!this.props.contract || (this.props.contract && !this.props.contract.id)) && (
                    <div className="col-lg-12">
                      <div className="hpanel">
                        <div className="panel-heading">
                          <div className="panel-tools"></div>
                          Pesquisa de Veículo
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-6 border-right">
                              <Field
                                name="findChassi"
                                label="Chassi"
                                maxlength={17}
                                component={TextField}
                                type="text"
                                inline={false}
                                required={true}
                                validate={[FieldValidation.maxLength21, FieldValidation.chassi]}
                                normalize={FieldNormalize.UPPERCASE}
                              />
                            </div>

                            <div className="col-md-6">
                              <Field name="findGravame" label="Gravame" maxlength={8} component={TextField} type="text" inline={false} required={true} validate={[FieldValidation.maxLength8]} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="float-right">
                                <button
                                  className="btn btn-default btn-sm"
                                  type="button"
                                  onClick={this.props.handleSubmit((values) => {
                                    return new Promise((resolve, reject) => {
                                      const errors = _.assign(validateToFindMG(values));
                                      if (errors && !_.isEmpty(errors)) {
                                        toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
                                        reject(new SubmissionError(errors));
                                      } else {
                                        this.handleClickFindMG(values);
                                        resolve();
                                      }
                                    });
                                  })}
                                >
                                  <i className="fa fa-search"></i> Pesquisar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.props.searchBeforeRegisterUf && this.props.searchBeforeRegisterUf == "RJ" && (!this.props.contract || (this.props.contract && !this.props.contract.id)) && (
                    <div className="col-lg-12">
                      <div className="hpanel">
                        <div className="panel-heading">
                          <div className="panel-tools"></div>
                          Pesquisa de Veículo
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="findChassi"
                                label="Chassi"
                                maxlength={17}
                                component={TextField}
                                type="text"
                                inline={false}
                                required={true}
                                validate={[FieldValidation.maxLength21, FieldValidation.chassi]}
                                normalize={FieldNormalize.UPPERCASE}
                              />
                            </div>

                            {/* <div className="col-md-6">
                          <Field name="findCodigoAgente" label="Número do DUDA" component={TextField} type="text" required={true} maxlength={10} normalize={FieldNormalize.NUMBER}/>
                        </div> */}
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="float-right">
                                <button
                                  className="btn btn-default btn-sm"
                                  type="button"
                                  onClick={this.props.handleSubmit((values) => {
                                    return new Promise((resolve, reject) => {
                                      const errors = _.assign(validateToFindRJ(values));
                                      if (errors && !_.isEmpty(errors)) {
                                        toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
                                        reject(new SubmissionError(errors));
                                      } else {
                                        this.handleClickFindRJ(values);
                                        resolve();
                                      }
                                    });
                                  })}
                                >
                                  <i className="fa fa-search"></i> Pesquisar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

            {this.props.selectedUf &&
              ((this.props.searchBeforeRegister && this.props.selectedCredor && this.props.vehicles && this.props.vehicles.length > 0) ||
                (this.props.selectedCredor && !this.props.searchBeforeRegister)) && (
                <div className="row">
                  <div className="col-lg-7">
                    <div className="hpanel">
                      <div className="panel-heading">
                        <div className="panel-tools"></div>
                        Contrato
                      </div>
                      <div className="panel-body">
                        {this.props.contract && this.props.contract.numRegistro && (
                          <div>
                            <span>
                              <strong>Número do Registro no Detran: {this.props.contract.numRegistro}</strong>
                            </span>
                            <br></br>
                            <br></br>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-md-6">
                            <Field
                              name="contrato"
                              label="Número do contrato"
                              validate={[FieldValidation.nospace, FieldValidation.letterOrNumber, FieldValidation.noCharacterSpecial]}
                              maxlength={20}
                              component={TextField}
                              type="text"
                              inline={false}
                              required={true}
                              normalize={FieldNormalize.UPPERCASE}
                              onBlur={this.handleBlurValidaContratoExistente.bind(this)}
                              disabled={
                                this.props.selectedUf && this.props.selectedUf === "PR" && this.props.contract && (this.props.contract.status === 2 || this.props.contract.status === 3) ? true : false
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            {(this.props.contract || this.props.selectedContract) && (
                              <Field
                                name="data"
                                label={this.props.selectedUf && this.props.selectedUf === "SP" ? "Data de vigência do contrato" : "Data do contrato"}
                                placeholder="00/00/0000"
                                data={this.props.data}
                                component={TextDatePickerField}
                                type="text"
                                validate={[FieldValidation.date]}
                                normalize={FieldNormalize.DATE}
                                required={true}
                              />
                            )}
                          </div>
                        </div>

                        {this.props.contract && this.props.contract.idContratoAnterior && this.props.selectedUf && this.props.selectedUf !== "MA" && (
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="numeroAditivo"
                                label="Número aditivo contrato"
                                component={TextField}
                                type="text"
                                maxlength={this.props.selectedUf !== "RN" ? 20 : 6}
                                normalize={FieldNormalize.UPPERCASE}
                                required={true}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="dataAditivo"
                                label="Data aditivo contrato"
                                validate={[FieldValidation.date]}
                                normalize={FieldNormalize.DATE}
                                placeholder="00/00/0000"
                                data={this.props.dataAditivo}
                                component={TextDatePickerField}
                                required={true}
                                type="text"
                              />
                            </div>
                            <div className="col-md-12">
                              <Field
                                name="numRegistroOriginal"
                                label="Número do registro original do contrato"
                                component={TextField}
                                type="text"
                                normalize={FieldNormalize.NUMBER}
                                required={true}
                                maxlength={14}
                                textTooltip="Número do Registro retornado pelo sistema que realizou o registro do contrato original."
                              />
                            </div>
                          </div>
                        )}

                        {(this.props.contract || this.props.selectedContract) && (
                          <div>
                            <div className="row">
                              <div className={this.props.selectedUf && this.props.selectedUf === "RJ" ? "col-md-8" : "col-md-12"}>
                                <Field
                                  name="tipoContrato"
                                  label={this.props.selectedUf && this.props.selectedUf === "MG" ? "Tipo de Contrato" : "Tipo de Operação"}
                                  blank={true}
                                  data={[
                                    {
                                      id: "1",
                                      name: "Alienação Fiduciária - Financiamento",
                                    },
                                    {
                                      id: "2",
                                      name: "Alienação Fiduciária - Consórcio",
                                    },
                                    {
                                      id: "3",
                                      name: "Alienação Fiduciária - Cédula de Crédito",
                                    },
                                    {
                                      id: "4",
                                      name: "Arrendamento Mercantil - Leasing",
                                    },
                                    { id: "5", name: "Reserva de Domínio" },
                                    { id: "6", name: "Penhor" },
                                  ]}
                                  component={SelectField}
                                  type="text"
                                  valueField="id"
                                  textField="name"
                                  required={true}
                                  onBlur={this.handleBlurTipoOperacao.bind(this)}
                                />
                              </div>
                              {this.props.selectedUf && this.props.selectedUf === "RJ" && (
                                <div className="col-md-4">
                                  <Field name="codigoAgente" label="Número do DUDA" component={TextField} type="text" disabled={true} />
                                </div>
                              )}
                            </div>

                            {this.renderAlienacaoFiduciariaFinanciamento()}
                            {this.renderAlienacaoFiduciariaConsorcio()}
                            {this.renderArrendamentoMercantilLeasing()}
                            {this.renderReservaDeDominioOuPenhor()}
                          </div>
                        )}
                      </div>
                    </div>
                    {(this.props.contract || this.props.selectedContract) && (
                      <div className="hpanel">
                        <div className="panel-heading">
                          <div className="panel-tools">
                            <a className="showhide">
                              <i className="fa fa-chevron-up"></i>
                            </a>
                          </div>
                          Devedor (proprietário do veículo)
                        </div>
                        <div className="panel-body">
                          <Field
                            name="devCpfCnpj"
                            label="CPF / CNPJ"
                            normalize={FieldNormalize.CPF_CNPJ}
                            component={TextField}
                            type="text"
                            description="CPF ou CNPJ do devedor"
                            required={true}
                            validate={[FieldValidation.cpf_cnpj]}
                            onBlur={this.handleBlurCPFCNPJ.bind(this)}
                          />

                          <Field
                            name="devNome"
                            label="Nome"
                            component={TextField}
                            type="text"
                            description="Nome completo do devedor"
                            maxlength={80}
                            validate={[FieldValidation.nospace]}
                            normalize={FieldNormalize.UPPERCASE}
                            required={true}
                            onBlur={this.handleBlurValidaContratoExistente.bind(this)}
                          />

                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="devCep"
                                label="CEP"
                                description="CEP do endereço do devedor"
                                component={TextField}
                                type="text"
                                validate={[FieldValidation.cep]}
                                normalize={FieldNormalize.CEP}
                                required={true}
                                onBlur={this.handleBlurCEP.bind(this)}
                              />
                            </div>
                            <div className="col-md-6">
                              <UfField name="devUf" label="UF" description="Selecione a UF do endereço do devedor" required={true} />
                            </div>
                          </div>
                          <Field
                            name="devMunicipio"
                            label="Município"
                            description="Selecione o município do devedor"
                            valueField="municipioId"
                            component={CityFilteredField}
                            selectedUf={this.props.selectedEnderecoUfDevedor}
                            required={true}
                          />
                          <Field name="devBairro" label="Bairro" component={TextField} type="text" maxlength={20} normalize={FieldNormalize.UPPERCASE} required={true} />
                          <Field name="devEndereco" label="Endereço" component={TextField} type="text" maxlength={30} normalize={FieldNormalize.UPPERCASE} required={true} />
                          <div className="row">
                            <div className="col-md-4">
                              <Field
                                name="devNumero"
                                label="Número"
                                component={TextField}
                                type="text"
                                maxlength={5}
                                normalize={
                                  this.props.selectedUf && (this.props.selectedUf === "AC" || this.props.selectedUf === "AP" || this.props.selectedUf === "RR")
                                    ? FieldNormalize.NUMBER
                                    : FieldNormalize.UPPERCASE
                                }
                                required={true}
                              />
                            </div>
                            <div className="col-md-8">
                              <Field
                                name="devComplemento"
                                label="Complemento"
                                component={TextField}
                                type="text"
                                description="Complemento do endereço do devedor"
                                maxlength={20}
                                normalize={FieldNormalize.UPPERCASE}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Field name="devDddTelefone" label="DDD" normalize={FieldNormalize.DDD} component={TextField} type="text" validate={[FieldValidation.maxLength2]} required={true} />
                            </div>
                            <div className="col-md-4">
                              <Field name="devTelefone" label="Telefone" normalize={FieldNormalize.PHONE_N_DDD} component={TextField} type="text" required={true} />
                            </div>
                            <div className="col-md-6">
                              <Field name="devEmail" label="Email" component={TextField} maxlength={40} type="text" normalize={FieldNormalize.UPPERCASE} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <Field name="devDddCelular" label="DDD" normalize={FieldNormalize.DDD} component={TextField} type="text" validate={[FieldValidation.maxLength2]} />
                            </div>
                            <div className="col-md-4">
                              <Field name="devCelular" label="Celular" normalize={FieldNormalize.PHONE_N_DDD} component={TextField} type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {(this.props.contract || this.props.selectedContract) && (
                    <div ref={(ref) => (this.myRefDivVeic = ref)} className="col-lg-5">
                      {!this.props.limitVehicles && (
                        <div className="hpanel">
                          <div className="panel-heading">
                            <div className="panel-tools" hidden={this.state.hiddenDivBtn}>
                              {!this.props.limitVehicles && !this.props.showVehicleForm && (
                                <Link className="btn btn-primary btn-xs" onClick={this.showVehicleForm()}>
                                  <i className="fas fa-plus"></i> Adicionar veículo{" "}
                                </Link>
                              )}
                            </div>
                            Veículos
                          </div>
                          <div className="panel-body">
                            <div className="text-center text-muted font-bold">Veículos do contrato</div>
                          </div>
                          <div>{this.renderVehicleList()}</div>
                        </div>
                      )}

                      {((!this.props.limitVehicles && this.props.showVehicleForm) || this.props.limitVehicles) &&
                      (!this.props.contract ||
                        this.props.contract.status === 0 ||
                        this.props.contract.status === 8 ||
                        this.props.contract.status === 10 ||
                        this.props.contract.status === 3 ||
                        this.props.contract.status === 4 ||
                        this.props.contract.status === 2 ||
                        this.props.contract.status === 90 ||
                        (this.props.contract.status === 90 && this.props.limitVehicles)) ? (
                        <div className="hpanel">
                          <div className="panel-heading">
                            <div className="panel-tools"></div>
                            Veículos
                          </div>
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-md-12">
                                {/* <Field
                                  name="estadoVeiculo"
                                  label="Situação do veículo"
                                  data={[
                                    { id: "U", name: "Usado" },
                                    { id: "N", name: "Novo" },
                                  ]}
                                  component={SelectField}
                                  type="text"
                                  valueField="id"
                                  textField="name"
                                  required={true}
                                  onBlur={this.props.handleSubmit(this.handleBlurRequiredValues.bind(this))}
                                /> */}
                                <Field name="estadoVeiculo" label="Veículo Novo (Veículo 0km)?" component={CheckboxField} type="text" onChange={() => this.handleVeicNovo()} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <Field
                                  disabled={this.props.selectedUf && this.props.selectedUf === "BA" && this.props.contract && this.props.contract.status === 90 ? true : false}
                                  name="numGravame"
                                  label="Nº do Gravame (apontamento)"
                                  component={TextField}
                                  type="text"
                                  required={true}
                                  maxlength={8}
                                  validate={[FieldValidation.gravame]}
                                  normalize={FieldNormalize.NUMBER}
                                  onBlur={this.handleBlurValidaContratoExistente.bind(this)}
                                />
                              </div>
                              <div className="col-md-6">
                                <Field
                                  name="dataGravame"
                                  label="Data do Gravame"
                                  validate={[FieldValidation.date]}
                                  normalize={FieldNormalize.DATE}
                                  placeholder="00/00/0000"
                                  data={this.props.dataGravame}
                                  component={TextDatePickerField}
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <Field
                                  name="renavam"
                                  label="Renavam"
                                  disabled={this.state.estadoVeiculo}
                                  component={TextField}
                                  type="text"
                                  maxlength={11}
                                  normalize={FieldNormalize.NUMBER}
                                  required={!this.state.estadoVeiculo}
                                />
                              </div>

                              <div className="col-md-3">
                                <UfField name="ufPlaca" label="UF" description="UF da placa" disabled={this.state.estadoVeiculo} required={!this.state.estadoVeiculo} />
                              </div>
                              <div className="col-md-5">
                                <Field
                                  name="numPlaca"
                                  label="Placa"
                                  component={TextField}
                                  type="text"
                                  normalize={FieldNormalize.PLATE}
                                  validate={FieldValidation.PLATE}
                                  disabled={this.state.estadoVeiculo}
                                  required={!this.state.estadoVeiculo}
                                />
                              </div>
                            </div>

                            {this.props.haveUfLicenciamento && (
                              <div className="row">
                                <div className="col-md-12">
                                  <Field name="ufLicenciamento" label="UF" description="UF de licenciamento" disabled={true} required={true} component={TextField} type="text" />
                                </div>
                              </div>
                            )}

                            <div className="row">
                              <div className="col-md-7">
                                <Field
                                  disabled={this.props.selectedUf && this.props.selectedUf === "BA" && this.props.contract && this.props.contract.status === 90 ? true : false}
                                  name="chassi"
                                  label="Chassi"
                                  component={TextField}
                                  type="text"
                                  maxlength={17}
                                  validate={[FieldValidation.chassi, FieldValidation.nospace]}
                                  required={true}
                                  normalize={FieldNormalize.UPPERCASE}
                                  onBlur={this.props.handleSubmit(this.handleBlurChassi.bind(this))}
                                  tooltipChassi={this.props.selectedChassi}
                                />
                              </div>
                              <div className="col-md-5">
                                <Field
                                  name="chassiRemarcado"
                                  label="Remarcado"
                                  blank={true}
                                  data={[
                                    { id: "1", name: "Remarcado" },
                                    { id: "2", name: "Não Remarcado" },
                                  ]}
                                  required={true}
                                  component={SelectField}
                                  type="text"
                                  valueField="id"
                                  textField="name"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <Field
                                  name="anoFabricacao"
                                  label="Ano fabricação"
                                  component={TextField}
                                  type="text"
                                  maxlength={4}
                                  required={true}
                                  validate={[FieldValidation.length4]}
                                  normalize={FieldNormalize.NUMBER}
                                />
                              </div>
                              <div className="col-md-4">
                                <Field
                                  name="anoModelo"
                                  label="Ano modelo"
                                  component={TextField}
                                  type="text"
                                  maxlength={4}
                                  required={true}
                                  validate={[FieldValidation.length4]}
                                  normalize={FieldNormalize.NUMBER}
                                />
                              </div>
                              {this.props.selectedCredor &&
                                this.props.selectedCredor.id !== 13 && ( //"TRUQUE" PARA BR CONSÓRCIOS
                                  <div className="col-md-4">
                                    <Field
                                      name="especieVeiculo"
                                      label="Espécie/Tipo"
                                      blank={true}
                                      data={
                                        this.props.selectedUf === "PI"
                                          ? [
                                              { id: "6", name: "Automóvel" },
                                              { id: "1", name: "Bicicleta" },
                                              { id: "9", name: "Bonde" },
                                              { id: "14", name: "Caminhão" },
                                              { id: "17", name: "Caminhão Trator" },
                                              {
                                                id: "23",
                                                name: "Caminhonete",
                                              },
                                              { id: "25", name: "Utilitário" },
                                              { id: "13", name: "Camioneta" },
                                              { id: "16", name: "Carro de Mão" },
                                              { id: "15", name: "Carroça" },
                                              { id: "12", name: "Charrete" },
                                              { id: "22", name: "Chassi-Plataforma" },
                                              { id: "2", name: "Ciclomotor" },
                                              {
                                                id: "7",
                                                name: "Micro-ônibus",
                                              },
                                              { id: "4", name: "Motocicleta" },
                                              { id: "3", name: "Motoneta" },
                                              { id: "26", name: "Motor Casa" },
                                              { id: "8", name: "Ônibus" },
                                              { id: "21", name: "Quadriciclo" },
                                              { id: "10", name: "Reboque" },
                                              { id: "11", name: "Semi-Reboque" },
                                              { id: "24", name: "Side-Car" },
                                              { id: "19", name: "Trator de Esteiras" },
                                              {
                                                id: "18",
                                                name: "Trator de Rodas",
                                              },
                                              { id: "20", name: "Trator Misto" },
                                              { id: "5", name: "Triciclo" },
                                            ]
                                          : [
                                              { id: "1", name: "Motocicleta" },
                                              { id: "2", name: "Automóvel" },
                                              { id: "3", name: "Micro-ônibus" },
                                              { id: "4", name: "Ônibus" },
                                              { id: "5", name: "Caminhão" },
                                              {
                                                id: "6",
                                                name: "Reboque/Semi-Reboque",
                                              },
                                              { id: "7", name: "Caminhonete(a)" },
                                              { id: "8", name: "Outros" },
                                            ]
                                      }
                                      component={SelectField}
                                      type="text"
                                      valueField="id"
                                      textField="name"
                                    />
                                  </div>
                                )}
                            </div>

                            {this.props.selectedUf && (this.props.selectedUf === "MG" || this.props.selectedUf === "RJ" || this.props.selectedUf === "PI") && (
                              <div className="row">
                                <div className="col-md-12">
                                  <Field
                                    name="marcaModelo"
                                    label="Marca/Modelo"
                                    component={DropdownListFieldBrandModel}
                                    type="text"
                                    maxlength={200}
                                    required={this.props.selectedUf === "RJ" || this.props.selectedUf === "PI" ? true : false}
                                  />
                                </div>
                              </div>
                            )}
                            {this.props.selectedUf && this.props.selectedUf === "RJ" && (
                              <div className="row">
                                <div className="col-md-12">
                                  <Field
                                    name="cor"
                                    label="Cor do Veículo"
                                    component={TextField}
                                    type="text"
                                    maxlength={45}
                                    validate={[FieldValidation.nospace]}
                                    normalize={FieldNormalize.UPPERCASE}
                                    required={true}
                                  />
                                </div>
                              </div>
                            )}
                            {!this.props.limitVehicles && (
                              <div className="text-center">
                                {this.props.selectedVehicleIndex === null && (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-info"
                                    onClick={this.props.handleSubmit((values) => {
                                      return new Promise((resolve, reject) => {
                                        const errors = _.assign(validateCar(values, this.props.haveUfLicenciamento));
                                        if (errors && !_.isEmpty(errors)) {
                                          toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
                                          this.scrollToFirstError(errors);
                                          reject(new SubmissionError(errors));
                                        } else {
                                          this.addVehicle(values);
                                          resolve();
                                        }
                                      });
                                    })}
                                  >
                                    {" "}
                                    Adicionar Veículo ao Contrato
                                  </button>
                                )}
                                {this.props.selectedVehicleIndex !== null && (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-info"
                                    onClick={this.props.handleSubmit((values) => {
                                      return new Promise((resolve, reject) => {
                                        const errors = validateCar(values, this.props.haveUfLicenciamento);
                                        if (errors && !_.isEmpty(errors)) {
                                          toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
                                          this.scrollToFirstError(errors);
                                          reject(new SubmissionError(errors));
                                        } else {
                                          this.updateVehicle(values);
                                          resolve();
                                        }
                                      });
                                    })}
                                  >
                                    {" "}
                                    Alterar Veículo
                                  </button>
                                )}

                                <button type="button" className="btn btn-sm btn-default" onClick={this.hideVehicleForm()}>
                                  {" "}
                                  Cancelar{" "}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {(!this.props.contract || (this.props.contract && (this.props.contract.status === 0 || this.props.contract.status === 2))) &&
                        !this.props.isUploading &&
                        this.props.currentUser.funcionalidades.includes(27) && (
                          <div className="hpanel">
                            <div className="panel-heading">
                              <div className="panel-tools"></div>
                              Imagem
                            </div>
                            <div className="panel-body">
                              <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                  <h7>
                                    <strong>
                                      Deseja {(this.props.contract && this.props.contract.nomeImagem) || (this.state.files && this.state.files.length > 0) ? "alterar" : "anexar"} a Imagem do Contrato?
                                    </strong>
                                  </h7>
                                  <br />
                                  <br />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                  {(!this.props.contract || (this.props.contract && this.props.contract.status === 0)) && (
                                    <div>
                                      <Dropzone
                                        accept="application/pdf"
                                        maxSize={this.props.selectedUf == "SC" ? 7000000 : 9999999999}
                                        onDropRejected={this.onDropRejected}
                                        name="file"
                                        className="dropzone-blank fallback"
                                        style={{ margin: "0 2px" }}
                                        onDrop={this.onDrop.bind(this)}
                                      >
                                        <button type="button" className="btn btn-default btn-sm">
                                          <i className="fa fa-upload" /> Buscar
                                        </button>
                                      </Dropzone>
                                      <br />
                                    </div>
                                  )}

                                  <div className="row">
                                    {this.state.files && this.state.files.length > 0 ? (
                                      this.showFiles()
                                    ) : this.props.contract && this.props.contract.nomeImagem ? (
                                      <div>
                                        <ul>
                                          <li>
                                            <div>{this.props.contract.nomeImagem}</div>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : this.state.nomeImagem ? (
                                      <div>
                                        <ul>
                                          <li>
                                            <div>{this.state.nomeImagem}</div>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {this.props.contract && this.props.contract.status === 2 && (
                                    <Dropzone
                                      accept="application/pdf"
                                      maxSize={this.props.selectedUf == "SC" ? 7000000 : 9999999999}
                                      onDropRejected={this.onDropRejected}
                                      name="file"
                                      className="dropzone-blank fallback"
                                      style={{ margin: "0 2px" }}
                                      onDropAccepted={(filesToUpload, e) => this.handleClickUpload(filesToUpload)}
                                    >
                                      <button type="button" className="btn btn-primary btn-sm">
                                        <i className="fa fa-upload" /> Enviar Imagem
                                      </button>
                                    </Dropzone>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className="hpanel">
                        <div className="v-timeline vertical-container animate-panel" data-child="vertical-timeline-block" data-delay="1">
                          <ContractLog logs={this.props.log} position="left center" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
          </form>
        </div>
        <Modal visible={this.state.modalChassiOpen}>
          <form>
            <div className="modal-header">
              <div>
                <h5 className="modal-title" style={{ textAlign: "center" }}>
                  Atenção
                </h5>
                <hr></hr>
                <h6>
                  <strong>
                    Você está solicitando uma substituição de garantia e, por orientação do Detran SC, é necessário que o contrato original seja cancelado e que seja criado um novo contrato e enviado
                    como um novo.
                  </strong>
                </h6>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => this.setState({ modalChassiOpen: false })}>
                Fechar
              </button>
            </div>
          </form>
        </Modal>
        <Modal visible={this.state.modalAlteracaoOpen}>
          <form>
            <div className="modal-header">
              <div className="text-center">
                <h5 className="modal-title font-weight-bold" style={{ color: "red" }}>
                  ATENÇÃO
                </h5>
                <hr></hr>
                <h6>
                  <h4>Não detectamos ALTERAÇÃO de dados nesta ação.</h4>
                  <h4>
                    Caso seja enviado ao Detran, <strong>poderá ser gerada cobrança.</strong>
                  </h4>
                  <h4>Realmente deseja efetuar o envio?</h4>
                </h6>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => this.setState({ modalAlteracaoOpen: !this.state.modalAlteracaoOpen })}>
                Cancelar
              </button>
              <button type="button" className="btn btn-primary" onClick={this.props.handleSubmit(this.onOpenDialog.bind(this))}>
                Confirmar
              </button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }

  showFiles() {
    const { files } = this.state;

    if (!files.length) {
      return null;
    }
    return (
      <div>
        <ul>
          {files.map((file, idx) => {
            return (
              <li key={idx}>
                <div>{file.name}</div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  renderVehicleList() {
    if (!this.props.vehicles || this.props.vehicles.length === 0) {
      return (
        <div className="panel-body note-link" style={{ background: "#e4e5e7" }}>
          <div className="row">
            <div className="col-sm-12 text-center">
              <i className="fas fa-car"></i> Nenhum veículo
            </div>
          </div>
        </div>
      );
    }
    return this.props.vehicles
      .filter((veic) => veic.status != 91)
      .map((v, index) => {
        return (
          <div className="panel-body note-link" style={{ border: "2px solid #e4e5e7" }} key={index}>
            {v.status === 0 || v.status === 8 ? (
              <div className="alert alert-warning" style={{ margin: "-20px", marginBottom: "5px" }}>
                <i className="fa fa-bolt"></i> Não Registrado
              </div>
            ) : v.status === 1 ? (
              <div className="alert alert-warning" style={{ margin: "-20px", marginBottom: "5px" }}>
                <i className="fas fa-exclamation-circle"></i> Aguardando DETRAN
              </div>
            ) : v.status === 2 ? (
              <div className="alert alert-success" style={{ margin: "-20px", marginBottom: "5px" }}>
                <i className="fas fa-exclamation-circle"></i> Registrado sem imagem
              </div>
            ) : v.status === 3 ? (
              <div className="alert alert-success" style={{ margin: "-20px", marginBottom: "5px" }}>
                <i className="fas fa-check-circle"></i> Registrado
              </div>
            ) : v.status === 4 ? (
              <div className="alert alert-success" style={{ margin: "-20px", marginBottom: "5px" }}>
                <i className="fas fa-check-circle"></i> Registrado/Homologado
              </div>
            ) : v.status === 5 ? (
              <div className="alert alert-info" style={{ margin: "-20px", marginBottom: "5px" }}>
                <i className="fas fa-level-down-alt"></i> Baixado
              </div>
            ) : v.status === 6 ? (
              <div className="alert alert-info h-bg-violet" style={{ margin: "-20px", marginBottom: "5px", color: "#fff" }}>
                <i className="fas fa-ban"></i> Cancelado
              </div>
            ) : v.status === 9 ? (
              <div className="alert alert-warning" style={{ margin: "-20px", marginBottom: "5px" }}>
                <i className="fas fa-exclamation-circle"></i> Aguardando DETRAN
              </div>
            ) : v.status === 90 ? (
              <div className="alert alert-danger" style={{ margin: "-20px", marginBottom: "5px" }}>
                <i className="fas fa-exclamation-triangle"></i> Erro no registro do veículo
              </div>
            ) : (
              <div className="alert" style={{ margin: "-20px", marginBottom: "5px" }}>
                <i className="fas fa-exclamation-circle"></i> Status não identificado
              </div>
            )}

            <div className="row">
              <div className="col-sm-8">
                <h5>Chassi: {v.chassi}</h5>
              </div>
              <div className="col-sm-4">
                <div className="project-action m-t-sm">
                  {(v.status === 0 || v.status === 8 || v.status === 10 || v.status === 90) && (
                    <div>
                      <button type="button" className="btn btn-xs btn-default" onClick={this.vehicleToEdit(index)}>
                        <i className="fas fa-pencil-alt"></i> Editar
                      </button>
                      <button type="button" className="btn btn-xs btn-default" onClick={this.vehicleToDelete(index)}>
                        <i className="fas fa-trash-alt"></i> Excluir
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-5">Renavam: {v.renavam}</div>
              <div className="col-sm-7">Gravame: {v.numGravame}</div>
            </div>
            <div className="row">
              <div className="col-sm-5">Data do Gravame: {MaskDate.TO_BR(v.dataGravame)}</div>
              <div className="col-sm-7">Estado do Veículo: {v.estadoVeiculo == "N" ? "Novo" : "Usado"}</div>
            </div>
            <div className="row">
              <div className="col-sm-5">Uf: {v.ufPlaca}</div>
              <div className="col-sm-7">Placa: {v.numPlaca}</div>
            </div>

            {this.props.haveUfLicenciamento && (
              <div className="row">
                <div className="col-md-5">Uf de licenciamento: {v.ufLicenciamento}</div>
                <div className="col-md-7">Remarcado: {v.chassiRemarcado ? (v.chassiRemarcado == 1 ? "Chassi Remarcado" : "Chassi Não Remarcado") : ""}</div>
              </div>
            )}

            <div className="row">
              <div className="col-md-3">Ano fabricação: {v.anoFabricacao}</div>
              <div className="col-md-3">Ano modelo: {v.anoModelo}</div>
              <div className="col-md-6">
                Espécie:{" "}
                {v.especieVeiculo == 1
                  ? "Motocicleta"
                  : v.especieVeiculo == 2
                  ? "Automóvel"
                  : v.especieVeiculo == 3
                  ? "Micro-ônibus"
                  : v.especieVeiculo == 4
                  ? "Ônibus"
                  : v.especieVeiculo == 5
                  ? "Caminhão"
                  : v.especieVeiculo == 6
                  ? "Reboque/Semi-Reboque"
                  : v.especieVeiculo == 7
                  ? "Caminhonete(a)"
                  : v.especieVeiculo == 8
                  ? "Outros"
                  : ""}
              </div>
            </div>

            {this.props.selectedUf && this.props.selectedUf === "MG" && (
              <div className="row">
                <div className="col-md-12">Marca/Modelo: {v.marcaModelo}</div>
              </div>
            )}

            {this.props.selectedUf && this.props.selectedUf === "RJ" && (
              <div className="row">
                <div className="col-md-12">Cor do Veículo: {v.cor}</div>
              </div>
            )}
          </div>
        );
      });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: CONTRACT_EDIT_LOAD, payload }),
  onReset: (payload) => dispatch({ type: CONTRACT_EDIT_RESET }),
  onCompaniesLoad: (payload) => dispatch({ type: CONTRACT_EDIT_COMPANIES_LOAD, payload }),
  onContractLoad: (payload) => dispatch({ type: CONTRACT_EDIT_CONTRACT_LOAD, payload }),
  onAddVehicle: (payload) => dispatch({ type: CONTRACT_EDIT_VEHICLE_ADD, payload }),
  onUnload: (payload) => dispatch({ type: CONTRACT_EDIT_UNLOAD, payload }),
  onOpen: (payload) => dispatch({ type: CONFIRM_OPERATION_OPEN, payload }),
  onActionOpen: (payload) => dispatch({ type: CONFIRM_ACTION_OPEN, payload }),
  onLogLoad: (payload) => dispatch({ type: CONTRACT_EDIT_LOG_LOAD, payload }),
  onErrLoad: (payload) => dispatch({ type: CONTRACT_EDIT_ERR_MSG_LOAD, payload }),
  onVehiclesLoad: (payload) => dispatch({ type: CONTRACT_EDIT_VEHICLE_LOAD, payload }),
  onVehicleIndexSelected: (payload) => dispatch({ type: CONTRACT_EDIT_VEHICLE_SELECTED, payload }),
  onCompanyError: (payload) => dispatch({ type: CONTRACT_EDIT_COMPANY_ERROR, payload }),
  onUploading: (payload) => dispatch({ type: CONTRACT_EDIT_UPLOAD, payload }),
  onAdditiveCreated: (payload) => dispatch({ type: CONTRACT_ADDITIVE_CREATED, payload }),
  onContractVehiclesLoad: (payload) => dispatch({ type: CONTRACT_EDIT_CONTRACT_VEHICLE_LOAD, payload }),
  onContractSentSuccess: (payload) => dispatch({ type: CONTRACT_SENT_SUCCESS, payload }),
  onContractDeleteSuccess: (payload) => dispatch({ type: CONTRACT_DELETE_SUCCESS, payload }),
  onContractCancelSuccess: (payload) => dispatch({ type: CONTRACT_CANCEL_SUCCESS, payload }),
  onContractShelveSuccess: (payload) => dispatch({ type: CONTRACT_SHELVE_SUCCESS, payload }),
  onShowVehicleForm: (payload) => dispatch({ type: SHOW_VEHICLE_FORM }),
  onHideVehicleForm: (payload) => dispatch({ type: HIDE_VEHICLE_FORM }),
  onShowAverbacaoError: (payload) => dispatch({ type: SHOW_AVERBACAO }),
  onUploadSuccess: () => dispatch({ type: CONTRACT_EDIT_UPLOAD_SUCCESS }),
  onUfLoad: (payload) => dispatch({ type: CONTRACT_UF_LOAD, payload }),
  onClearVehicle: (payload) => dispatch({ type: CONTRACT_CLEAR_VEHICLE, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const selector = formValueSelector("ContractForm");
const mapStateToProps = (state) => ({
  synchronousError: getFormSyncErrors("ContractForm")(state),
  limitVehicles: selector(state, "uf") ? (selector(state, "uf") == "PR" ? false : true) : true,
  searchBeforeRegister: selector(state, "uf") ? (selector(state, "uf") == "MG" || selector(state, "uf") == "SP" || selector(state, "uf") == "RJ" ? true : false) : false,
  searchBeforeRegisterUf: selector(state, "uf") ? (selector(state, "uf") == "MG" || selector(state, "uf") == "SP" || selector(state, "uf") == "RJ" ? selector(state, "uf") : "") : "",
  haveUfLicenciamento: selector(state, "uf")
    ? selector(state, "uf") == "PR" ||
      selector(state, "uf") == "SC" ||
      selector(state, "uf") == "MG" ||
      selector(state, "uf") == "PE" ||
      selector(state, "uf") == "AC" ||
      selector(state, "uf") == "AP" ||
      selector(state, "uf") == "PB"
      ? true
      : false
    : false,

  currentUser: state.common.currentUser,
  companies: state.common.companies,
  ...state.contract,
  selectedType: selector(state, "tipoContrato"),
  selectedEnderecoUfDevedor: selector(state, "devUf"),
  selectedUfLiberacaoCred: selector(state, "ufLiberacaoCred"),
  selectedDevCpfCnpj: selector(state, "devCpfCnpj"),
  selectedDevNome: selector(state, "devNome"),
  selectedContract: selector(state, "contrato"),
  selectedUf: selector(state, "uf"),
  dataPrimeiroVenc: selector(state, "dataPrimeiroVenc"),
  dataUltimoVenc: selector(state, "dataUltimoVenc"),
  dataLiberacaoCred: selector(state, "dataLiberacaoCred"),
  data: selector(state, "data"),
  dataAditivo: selector(state, "dataAditivo"),
  dataGravame: selector(state, "dataGravame"),
  selectedCredor: selector(state, "credor"),
  selectedChassi: selector(state, "chassi"),
  selectedGravame: selector(state, "numGravame"),
  selectedQtdeParcelas: selector(state, "qtdeParcelas"),
  initialValues:
    state.contract.contract && state.common.companies.length > 0
      ? {
          ...state.contract.contract,
          devMunicipio: { municipioId: state.contract.contract.devMunicipio },
          credor: state.contract.contract.idFinanceira ? state.common.companies.filter((comp) => comp.id == state.contract.contract.idFinanceira)[0] : "",
          credorName: state.contract.contract.idFinanceira ? state.common.companies.filter((comp) => comp.id == state.contract.contract.idFinanceira)[0].razaoSocialCnpj : "",
          contrato: state.contract.contract.numero,
          uf: state.contract.contract.uf,
          data: MaskDate.TO_BR(state.contract.contract.data),
          taxaJurosMes: MaskDecimal.TO_BR_3_DECIMALS(state.contract.contract.taxaJurosMes),
          taxaJurosAno: MaskDecimal.TO_BR_3_DECIMALS(state.contract.contract.taxaJurosAno),
          taxaMulta: MaskDecimal.TO_BR_3_DECIMALS(state.contract.contract.taxaMulta),
          taxaMora: state.contract.contract.uf == "BA" ? MaskDecimal.TO_BR_TO_REAL(state.contract.contract.taxaMora) : MaskDecimal.TO_BR_3_DECIMALS(state.contract.contract.taxaMora),
          taxaCorrecaoMonetaria: MaskDecimal.TO_BR_3_DECIMALS(state.contract.contract.taxaCorrecaoMonetaria),
          valorTotalFinanciamento: MaskDecimal.TO_BR_TO_REAL(state.contract.contract.valorTotalFinanciamento),
          valorIOF: MaskDecimal.TO_BR_TO_REAL(state.contract.contract.valorIOF),
          valorParcela: MaskDecimal.TO_BR_TO_REAL(state.contract.contract.valorParcela),
          qtdeParcelas: MaskDecimal.TO_BR(state.contract.contract.qtdeParcelas),
          dataPrimeiroVenc: MaskDate.TO_BR(state.contract.contract.dataPrimeiroVenc),
          dataUltimoVenc: MaskDate.TO_BR(state.contract.contract.dataUltimoVenc),
          dataLiberacaoCred: MaskDate.TO_BR(state.contract.contract.dataLiberacaoCred),
          taxaComissao: MaskDecimal.TO_BR_TO_REAL(state.contract.contract.taxaComissao),
          dataAditivo: MaskDate.TO_BR(state.contract.contract.dataAditivo),
          valorCredito: MaskDecimal.TO_BR_TO_REAL(state.contract.contract.valorCredito),
          veiculos: state.contract.vehicles,
          indices:
            state.contract.contract.uf &&
            (state.contract.contract.uf == "RJ" || state.contract.contract.uf == "MG" || state.contract.contract.uf == "SP") &&
            valuesIndice.indexOf(state.contract.contract.indices) == -1
              ? ""
              : valuesIndice.indexOf(state.contract.contract.indices) == -1
              ? "OUTROS"
              : state.contract.contract.indices,
        }
      : null,
});

function isEmpty(val) {
  return val === undefined || val == null || val.length <= 0 ? true : false;
}

function validateContract(values) {
  const errors = {};

  if (!values.uf || values.uf.length === 0 || !values.uf.trim()) {
    errors.uf = "Campo obrigatório";
  }

  if (!values.contrato || values.contrato.length === 0 || !values.contrato.trim()) {
    errors.contrato = "Campo obrigatório";
  }
  if (!values.data || values.data.length === 0 || !values.data.trim()) {
    errors.data = "Campo obrigatório";
  }
  //  else if (values.uf && values.uf === "SP") {
  //   var partesData = values.data.split("/");
  //   var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
  //   var hoje = new Date();
  //   hoje = new Date(hoje.getFullYear(), hoje.getUTCMonth(), hoje.getUTCDate());
  //   if (data < hoje) {
  //     errors.data = "A data de vigência deve ser maior que a data atual";
  //   }
  // }
  if (!values.tipoContrato || (_.isString(values.tipoContrato) && (values.tipoContrato.length === 0 || !values.tipoContrato.trim()))) {
    errors.tipoContrato = "Campo obrigatório";
  } else {
    if (!values.valorTotalFinanciamento || values.valorTotalFinanciamento.length === 0 || !values.valorTotalFinanciamento.trim() || values.valorTotalFinanciamento === "0,00") {
      errors.valorTotalFinanciamento = "Campo obrigatório";
    }
    if (!values.qtdeParcelas || values.qtdeParcelas.length === 0 || !values.qtdeParcelas.trim() || values.qtdeParcelas == 0) {
      errors.qtdeParcelas = "Campo obrigatório";
    }
    if (!values.dataPrimeiroVenc || values.dataPrimeiroVenc.length === 0 || !values.dataPrimeiroVenc.trim()) {
      errors.dataPrimeiroVenc = "Campo obrigatório";
    }
    if (!values.dataUltimoVenc || values.dataUltimoVenc.length === 0 || !values.dataUltimoVenc.trim()) {
      errors.dataUltimoVenc = "Campo obrigatório";
    }
    if (!values.ufLiberacaoCred || values.ufLiberacaoCred.length === 0 || !values.ufLiberacaoCred.trim()) {
      errors.ufLiberacaoCred = "Campo obrigatório";
    }
    if (!values.cidadeLiberacaoCred || !values.cidadeLiberacaoCred.length === 0) {
      errors.cidadeLiberacaoCred = "Campo obrigatório";
    }
    if (!values.dataLiberacaoCred || values.dataLiberacaoCred.length === 0 || !values.dataLiberacaoCred.trim()) {
      errors.dataLiberacaoCred = "Campo obrigatório";
    }
    if (!values.indices || values.indices.length === 0 || !values.indices.trim()) {
      errors.indices = "Campo obrigatório";
    }
  }

  if (values.idContratoAnterior && values.uf && values.uf !== "MA") {
    if (!values.numeroAditivo || values.numeroAditivo.length === 0 || !values.numeroAditivo.trim()) {
      errors.numeroAditivo = "Campo obrigatório";
    }
    if (!values.dataAditivo || values.dataAditivo.length === 0 || !values.dataAditivo.trim()) {
      errors.dataAditivo = "Campo obrigatório";
    }
    if (!values.numRegistroOriginal || values.numRegistroOriginal.length === 0 || !values.numRegistroOriginal.trim()) {
      errors.numRegistroOriginal = "Campo obrigatório";
    }
  }

  if (
    values.uf &&
    (values.uf === "AC" || values.uf === "AP" || values.uf === "RN") &&
    (!values.valorParcela || values.valorParcela.length === 0 || !values.valorParcela.trim() || values.valorParcela === "0,00")
  ) {
    errors.valorParcela = "Campo obrigatório";
  }

  if (values.uf && values.uf === "RJ" && (!values.penalidade || values.penalidade.length === 0 || !values.penalidade.trim())) {
    errors.penalidade = "Campo obrigatório";
  }
  return errors;
}

function validateDebtor(values, selectedUf) {
  const errors = {};

  if (!values.devCpfCnpj || values.devCpfCnpj.length === 0 || !values.devCpfCnpj.trim()) {
    errors.devCpfCnpj = "Campo obrigatório";
  }
  if (!values.devNome || values.devNome.length === 0 || !values.devNome.trim()) {
    errors.devNome = "Campo obrigatório";
  }
  if (!values.devUf || values.devUf.length === 0 || !values.devUf.trim()) {
    errors.devUf = "Campo obrigatório";
  }
  if (!values.devMunicipio || !values.devMunicipio.municipioId) {
    errors.devMunicipio = "Campo obrigatório";
  }
  if (!values.devCep || values.devCep.length === 0 || !values.devCep.trim()) {
    errors.devCep = "Campo obrigatório";
  }
  if (!values.devEndereco || values.devEndereco.length === 0 || !values.devEndereco.trim()) {
    errors.devEndereco = "Campo obrigatório";
  }
  if (!values.devNumero || values.devNumero.length === 0 || !values.devNumero.trim()) {
    errors.devNumero = "Campo obrigatório";
  }
  if (!values.devBairro || values.devBairro.length === 0 || !values.devBairro.trim()) {
    errors.devBairro = "Campo obrigatório";
  }
  if (!values.devDddTelefone || values.devDddTelefone.length === 0 || !values.devDddTelefone.trim()) {
    errors.devDddTelefone = "Campo obrigatório";
  }
  if (!values.devTelefone || values.devTelefone.length === 0 || !values.devTelefone.trim()) {
    errors.devTelefone = "Campo obrigatório";
  }

  if (!ufDevedorNaoValidar.includes(selectedUf) && selectedUf != values.devUf) {
    errors.devUf = `A UF deve ser do(a) ${selectedUf}`;
  }
  return errors;
}

function validateCar(values, haveUfLicenciamento) {
  const errors = {};

  if (!values.numGravame || values.numGravame.length === 0 || !values.numGravame.trim()) {
    errors.numGravame = "Campo obrigatório";
  }
  if (!values.chassi || values.chassi.length === 0 || !values.chassi.trim()) {
    errors.chassi = "Campo obrigatório";
  }
  if (!values.chassiRemarcado || values.chassiRemarcado.length === 0) {
    errors.chassiRemarcado = "Campo obrigatório";
  }
  if (!values.anoFabricacao || values.anoFabricacao.length === 0 || !values.anoFabricacao.trim()) {
    errors.anoFabricacao = "Campo obrigatório";
  }
  if (!values.anoModelo || values.anoModelo.length === 0 || !values.anoModelo.trim()) {
    errors.anoModelo = "Campo obrigatório";
  }

  if (haveUfLicenciamento) {
    if (!values.ufLicenciamento || values.ufLicenciamento.length === 0 || !values.ufLicenciamento.trim()) {
      errors.ufLicenciamento = "Campo obrigatório";
    }
  }

  // if ((values.uf && values.uf === "MG" && values.numPlaca) || values.ufPlaca || values.renavam) {
  //   if (!values.numPlaca || values.numPlaca.length === 0 || !values.numPlaca.trim()) {
  //     errors.numPlaca = "Campo obrigatório";
  //   }
  //   if (!values.ufPlaca || values.ufPlaca.length === 0 || !values.ufPlaca.trim()) {
  //     errors.ufPlaca = "Campo obrigatório";
  //   }
  //   if (!values.renavam || values.renavam.length === 0 || !values.renavam.trim()) {
  //     errors.renavam = "Campo obrigatório";
  //   }
  // } else if ((values.numPlaca && values.numPlaca.trim().length > 0) || (values.ufPlaca && values.ufPlaca.trim().length > 0)) {
  //   if (!values.numPlaca || values.numPlaca.length === 0 || !values.numPlaca.trim()) {
  //     errors.numPlaca = "Campo obrigatório quando a uf da placa é informada";
  //   }
  //   if (!values.ufPlaca || values.ufPlaca.length === 0 || !values.ufPlaca.trim()) {
  //     errors.ufPlaca = "Campo obrigatório quando a placa é informada";
  //   }
  // }

  //if((values.uf && values.uf === 'MG') && (values.numPlaca && values.numPlaca.trim().length > 0) && (!values.renavam || values.renavam.length === 0)){
  //  errors.renavam = 'Campo obrigatório quando a placa é informada';
  //}

  // if (values.uf && values.uf === "BA" && (!values.estadoVeiculo || values.estadoVeiculo.length === 0)) {
  //   errors.estadoVeiculo = "Campo obrigatório";
  // }

  if (!values.estadoVeiculo) {
    if (!values.renavam || values.renavam.length === 0 || !values.renavam.trim() || parseInt(values.renavam) <= 0) {
      errors.renavam = "Campo obrigatório";
    }
    if (!values.numPlaca || values.numPlaca.length === 0 || !values.numPlaca.trim()) {
      errors.numPlaca = "Campo obrigatório";
    }
    if (!values.ufPlaca || values.ufPlaca.length === 0 || !values.ufPlaca.trim()) {
      errors.ufPlaca = "Campo obrigatório";
    }
  }

  if (values.uf && values.uf === "RJ" && (!values.cor || values.cor.length === 0)) {
    errors.cor = "Campo obrigatório";
  }

  if (values.uf && (values.uf === "RJ" || values.uf === "PI") && (!values.marcaModelo || values.marcaModelo.length === 0)) {
    errors.marcaModelo = "Campo obrigatório";
  }

  return errors;
}

function validateToFindMG(values) {
  const errors = {};

  if (!values.findChassi || values.findChassi.length === 0 || !values.findChassi.trim()) {
    errors.findChassi = "Campo obrigatório";
  }
  if (!values.findGravame || values.findGravame.length === 0 || !values.findGravame.trim()) {
    errors.findGravame = "Campo obrigatório";
  }

  return errors;
}

function validateToFindRJ(values) {
  const errors = {};

  if (!values.findChassi || values.findChassi.length === 0 || !values.findChassi.trim()) {
    errors.findChassi = "Campo obrigatório";
  }
  // if (!values.findCodigoAgente || values.findCodigoAgente.length === 0 || !values.findCodigoAgente.trim()) {
  //   errors.findCodigoAgente = 'Campo obrigatório';
  // }

  return errors;
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
function asyncValidate(values, dispatch, props, blurredField) {
  if (blurredField === "contrato") {
    //Consulta contrato pelo numero na base local
    return sleep(0);
  }
  return sleep(0);
}

const form = reduxForm({
  form: "ContractForm",
  asyncValidate,
  asyncChangeFields: ["contrato"],
  asyncBlurFields: ["renavam"],
  //enableReinitialize: true,
  //keepDirtyOnReinitialize: false,
});

Contract.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(form(Contract));
