import React from "react";
import { connect } from "react-redux";
import { Link, browserHistory } from "react-router";
import { reduxForm, Field, reset, change, untouch, formValueSelector } from "redux-form";
import { SubmissionError } from "redux-form";
import SelectField from "../../../components/Fields/SelectField";
import fileDownload from "js-file-download";
import _ from "lodash";
import MaskDate from "../../../components/Util/MaskDate";
import MaskDecimal from "../../../components/Util/MaskDecimal";
import ReactTable from "react-table";
import { toastr } from "react-redux-toastr";

import {
  FINANCIAL_REPORT_PR_LOAD,
  FINANCIAL_REPORT_PR_LIST_LOAD,
  FINANCIAL_REPORT_PR_UNLOAD,
  FINANCIAL_REPORT_DETAILS_PR_LOAD,
  FINANCIAL_REPORT_DETAILS_PR_UNLOAD,
  LOGOUT,
} from "../../../actions/Constants";

import api from "../../../api/apiUtil";

import Title from "../../../components/Layout/Title";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import SvgIcon from "react-icons-kit";
import jsPDF from "jspdf";
import MaskCNPJ from "../../../components/Util/MaskCNPJ";
import DropdownListField from "../../../components/Fields/DropdownListField";
import { BeatLoader } from "react-spinners";

const months = [
  { label: "Selecione", value: "" },
  { label: "Janeiro", value: "01" },
  { label: "Fevereiro", value: "02" },
  { label: "Março", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Maio", value: "05" },
  { label: "Junho", value: "06" },
  { label: "Julho", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Setembro", value: "09" },
  { label: "Outubro", value: "10" },
  { label: "Novembro", value: "11" },
  { label: "Dezembro", value: "12" },
];

const years = [
  { label: "Selecione", value: "" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
];

const columns = [
  {
    Header: "Contrato",
    accessor: "numeroContrato",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Data Registro",
    accessor: "row",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => <div>{MaskDate.TO_BR(row.original.dataRegistro)}</div>,
  },
  {
    Header: "Data Contrato",
    accessor: "row",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => <div>{MaskDate.TO_BR(row.original.dataContrato)}</div>,
  },
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Num. Restrição",
    accessor: "numeroRestricao",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
];

const columnsRN = [
  {
    Header: "Número Registro",
    accessor: "numeroRegistroContrato",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Nome Devedor",
    accessor: "devedorNome",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "CPF/CNPJ Devedor",
    accessor: "devedorCpfCnpj",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
];

const columnsMS = [
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Número do Contrato",
    accessor: "numeroContrato",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "CPF/CNPJ Devedor",
    accessor: "cnpjCpfFinanciado",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
];

const columnsPI = [
  {
    Header: "Contrato",
    accessor: "numeroContrato",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "CPF/CNPJ Devedor",
    accessor: "cpfCnpjDevedor",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Num. Restrição",
    accessor: "numeroGravame",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
];

const ufs = [
  { label: "PR", value: "PR" },
  { label: "RN", value: "RN" },
  { label: "MS", value: "MS" },
  { label: "PI", value: "PI" },
];

var agentesList;
var totalPago = 0;
var qtdeChassis = 0;
var valorTotal = 0;

class FinancialReportPR extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      todosContratos: [],
      pesquisando: false,
    };
  }

  componentWillMount() {
    if (
      _.findIndex(this.props.currentUser.perfis, function (perfil) {
        return perfil === "ALIAS";
      }) > -1
    ) {
      Promise.resolve(api.Financial.filtroPorUf()).then((all) => {
        this.props.onLoad(all);
      });
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  async filter(values) {
    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    var params = {
      ano: values.anoReferencia,
      mes: values.mesReferencia,
      numCIC: values.agente.cnpj,
      tipoCIC: "2",
      uf: values.uf,
    };
    Promise.resolve(
      api.Financial.relatorioBoletosEmitidos(params)
        .then((response) => {
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (values.uf == "PR") {
            this.props.onListLoad(response);
            if (!response || response.length == 0) {
              toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
            }
          } else if (values.uf == "MS") {
            if (response && response.message) {
              toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
            } else {
              this.props.onListLoad([response]);
            }
          } else if (values.uf == "PI") {
            if (!response || !response.body || !response.body.registros || response.body.registros.length == 0) {
              toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
            } else {
              this.props.onListLoad([response.body]);
            }
          } else {
            if (!response || !response.body || !response.body.success) {
              console.log(response);
              toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
            } else {
              this.props.onListLoad(response.body.data);
              if (!response.body.data || response.body.data.length == 0) {
                toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
              }
            }
          }
        })
        .catch((e) => {
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (e.message === "Unauthorized" || e.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        })
    );
  }

  gerarBoleto(values) {
    var props = this.props;
    var anoReferencia = values.anoReferencia;
    var mesReferencia = values.mesReferencia;
    var agente = values.agente.cnpj;
    if (this.props.uf == "PR") {
      Promise.resolve(
        api.Financial.boletoCobranca(anoReferencia, mesReferencia, agente)
          .then((response) => {
            console.log(response);
            //fileDownload(response, 'boleto.pdf');
            this.convertBase(response);
          })
          .catch((e) => {
            if (e.message === "Unauthorized" || e.message === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
            }
          })
      );
    } else if (this.props.uf == "PI") {
      console.log(this.props.all[0].boleto);
      this.convertBase(this.props.all[0].boleto);
    } else {
      var params = {
        numeroDocumento: this.props.all[0].numeroDocumento,
      };
      Promise.resolve(
        api.Financial.boletoCobrancaMS(params)
          .then((response) => {
            console.log(response);
            this.convertBase(response);
          })
          .catch((e) => {
            if (e.message === "Unauthorized" || e.message === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
            }
          })
      );
    }
  }

  detalhar(values) {
    var props = this.props;
    var anoReferencia = values.anoReferencia;
    var mesReferencia = values.mesReferencia;
    var agente = values.agente.cnpj;

    var firstDay = new Date(anoReferencia, mesReferencia - 1, 1);
    var lastDay = new Date(anoReferencia, mesReferencia, 0);

    var params = {
      periodoInicio: MaskDate.TO_YYYY_MM_DD(firstDay),
      periodoFim: MaskDate.TO_YYYY_MM_DD(lastDay),
      numCIC: values.agente.cnpj,
      tipoCIC: "2",
      pagina: "1",
    };
    Promise.resolve(
      api.Financial.relatorioCobranca(params)
        .then((response) => {
          console.log(response);
          this.props.onDetailsLoad([response.body.resultado, Math.ceil(response.body.qtdeTotalRegistros / response.body.qtdePorPagina), response.body.paginaAtual]);
        })
        .catch((e) => {
          if (e.message === "Unauthorized" || e.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        })
    );
  }

  detalharRN = (param) => {
    var params = {
      uf: this.props.uf,
      identificadorDebito: param,
    };
    Promise.resolve(
      api.Financial.relatorioCobranca(params)
        .then((response) => {
          if (!response || !response.body || !response.body.success) {
            console.log(response);
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          } else {
            this.props.onDetailsLoad([response.body.data, null, null]);
          }
        })
        .catch((e) => {
          if (e.message === "Unauthorized" || e.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        })
    );
  };

  detalharMS = () => {
    this.props.onDetailsLoad([this.props.all[0].itens, null, null]);
  };

  detalharPI = () => {
    this.props.onDetailsLoad([this.props.all[0].registros, null, null]);
  };

  detalharPagina(values, page) {
    var props = this.props;
    var anoReferencia = values.anoReferencia;
    var mesReferencia = values.mesReferencia;
    var agente = values.agente.cnpj;

    var firstDay = new Date(anoReferencia, mesReferencia - 1, 1);
    var lastDay = new Date(anoReferencia, mesReferencia, 0);

    var params = {
      periodoInicio: MaskDate.TO_YYYY_MM_DD(firstDay),
      periodoFim: MaskDate.TO_YYYY_MM_DD(lastDay),
      numCIC: values.agente.cnpj,
      tipoCIC: "2",
      pagina: page,
    };
    Promise.resolve(
      api.Financial.relatorioCobranca(params)
        .then((response) => {
          console.log(response);
          this.props.onDetailsLoad([response.body.resultado, Math.ceil(response.body.qtdeTotalRegistros / response.body.qtdePorPagina), response.body.paginaAtual]);
        })
        .catch((e) => {
          if (e.message === "Unauthorized" || e.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        })
    );
  }

  voltar(values) {
    this.props.onDetailsUnload();
  }

  convertBase(response) {
    const byteCharacters = atob(response);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const b64toBlob = (response, contentType = "application/pdf", sliceSize = 512) => {
      const byteCharacters = atob(response);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: "application/pdf" });
      return blob;
    };
    const blobT = b64toBlob(response, "application/pdf");
    fileDownload(blobT, "boleto.pdf");
    //const blobUrl = URL.createObjectURL(blobT);

    //window.location = blobUrl;
  }

  validateValues(values) {
    const errors = {};

    if (!values.uf || values.uf.length === 0 || !values.uf.trim()) {
      errors.uf = "Campo obrigatório";
    }
    if (!values.anoReferencia || values.anoReferencia.length === 0 || !values.anoReferencia.trim()) {
      errors.anoReferencia = "Campo obrigatório";
    }
    if (!values.mesReferencia || values.mesReferencia.length === 0 || !values.mesReferencia.trim()) {
      errors.mesReferencia = "Campo obrigatório";
    }
    if (values.agente == undefined || !values.agente.cnpj) {
      errors.agente = "Campo obrigatório";
    }
    return errors;
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  async exportToCSV() {
    const headersExcell = [["Contrato", "Data Registro", "Data Contrato", "Chassi", "Num. Restrição"]];

    if (this.state.todosContratos.length == 0) {
      this.setState({ todosContratos: await this.recuperaContratosDoBoleto() });
    }

    var DataExcel = this.state.todosContratos.map((row) => {
      return {
        numeroContrato: row.numeroContrato,
        dataRegistro: MaskDate.TO_BR(row.dataRegistro),
        dataContrato: MaskDate.TO_BR(row.dataContrato),
        chassi: row.chassi,
        numeroRestricao: row.numeroRestricao,
      };
    });

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `RelatorioContratosGuiaDetranPR.xlsx`);
  }

  async exportToCSVRN() {
    const headersExcell = [["Número Registro", "Nome Devedor", "CPF/CNPJ Devedor", "Chassi"]];

    var DataExcel = this.props.detailsList.map((row) => {
      return {
        numeroRegistroContrato: row.numeroRegistroContrato,
        devedorNome: row.devedorNome,
        devedorCpfCnpj: row.devedorCpfCnpj,
        chassi: row.chassi,
      };
    });

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `RelatorioContratosGuiaDetranRN.xlsx`);
  }

  async exportToCSVMS() {
    const headersExcell = [["Chassi", "Número do Contrato", "CPF/CNPJ Devedor"]];

    var DataExcel = this.props.detailsList.map((row) => {
      return {
        chassi: row.chassi,
        numeroContrato: row.numeroContrato,
        cnpjCpfFinanciado: row.cnpjCpfFinanciado,
      };
    });

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `RelatorioContratosGuiaDetranMS.xlsx`);
  }

  async exportToCSVPI() {
    const headersExcell = [["Contrato", "CPF/CNPJ Devedor", "Chassi", "Num. Restrição"]];

    var DataExcel = this.props.detailsList.map((row) => {
      return {
        numeroContrato: row.numeroContrato,
        cpfCnpjDevedor: row.cpfCnpjDevedor,
        chassi: row.chassi,
        numeroGravame: row.numeroGravame,
      };
    });

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `RelatorioContratosGuiaDetranPI.xlsx`);
  }

  async exportToPDF() {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 50;

    let headers;
    let data;
    if (this.props.uf == "PR") {
      headers = [["Contrato", "Data Registro", "Data Contrato", "Chassi", "Num. Restrição"]];

      if (this.state.todosContratos.length == 0) {
        this.setState({ todosContratos: await this.recuperaContratosDoBoleto() });
      }

      data = this.state.todosContratos.map((row) => [row.numeroContrato, MaskDate.TO_BR(row.dataRegistro), MaskDate.TO_BR(row.dataContrato), row.chassi, row.numeroRestricao]);
    } else if (this.props.uf == "MS") {
      headers = [["Chassi", "Número do Contrato", "CPF/CNPJ Devedor"]];
      data = this.props.detailsList.map((row) => [row.chassi, row.numeroContrato, row.cnpjCpfFinanciado]);
    } else if (this.props.uf == "PI") {
      headers = [["Contrato", "CPF/CNPJ Devedor", "Chassi", "Num. Restrição"]];
      data = this.props.detailsList.map((row) => [row.numeroContrato, row.cpfCnpjDevedor, row.chassi, row.numeroGravame]);
    } else {
      headers = [["Número Registro", "Nome Devedor", "CPF/CNPJ Devedor", "Chassi"]];
      data = this.props.detailsList.map((row) => [row.numeroRegistroContrato, row.devedorNome, row.devedorCpfCnpj, row.chassi]);
    }

    var title = `RELATÓRIO DE CONTRATOS - GUIA DETRAN ${this.props.uf}`;

    title = title + `\n\nMês/Ano de Referência: ${this.props.mesReferencia}/${this.props.anoReferencia}`;
    startTableY = startTableY + 15;
    var razaoSocial;
    for (var c of this.props.companies) {
      if (c.cnpj == this.props.agente.cnpj) {
        razaoSocial = c.razaoSocial;
        break;
      }
    }
    title = title + `\nCredor: ${razaoSocial}`;
    startTableY = startTableY + 15;
    title = title + `\nCNPJ: ${MaskCNPJ.TO_CNPJ(this.props.agente.cnpj)}`;

    if (this.props.uf == "RN") {
      startTableY = startTableY + 15;
      title = title + `\nQtd. Registros: ${this.props.detailsList.length}`;
      startTableY = startTableY + 15;
      title = title + `\nVencimento: ${this.props.all[0].dataVencimento}`;
      startTableY = startTableY + 15;
      title = title + `\nValor: ${MaskDecimal.TO_BRL(this.props.all[0].valorVencimento)}`;
      startTableY = startTableY + 15;
      title = title + `\nLinha Digitável: ${this.props.all[0].linhaDigitavel}`;
    } else if (this.props.uf == "MS") {
      startTableY = startTableY + 15;
      title = title + `\nQtd. Registros: ${this.props.detailsList.length}`;
      startTableY = startTableY + 15;
      title = title + `\nVencimento: ${this.props.all[0].vencimento}`;
      startTableY = startTableY + 15;
      title = title + `\nValor: R$ ${this.props.all[0].totalGeral}`;
      startTableY = startTableY + 15;
      title = title + `\nLinha Digitável: ${this.props.all[0].linhaDigitalvel}`;
    }
    startTableY = startTableY + 15;

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      //pageBreak: 'always',
      columnStyles: {
        0: { columnWidth: "wrap" },
        1: {
          columnWidth: "wrap",
        },
        2: {
          columnWidth: "wrap",
        },
        3: {
          columnWidth: "wrap",
        },
      },
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.save(`RelatorioContratosGuiaDetran${this.props.uf}.pdf`);
  }

  async recuperaContratosDoBoleto() {
    var result = [];

    var anoReferencia = this.props.anoReferencia;
    var mesReferencia = this.props.mesReferencia;

    var firstDay = new Date(anoReferencia, mesReferencia - 1, 1);
    var lastDay = new Date(anoReferencia, mesReferencia, 0);
    var params = {
      periodoInicio: MaskDate.TO_YYYY_MM_DD(firstDay),
      periodoFim: MaskDate.TO_YYYY_MM_DD(lastDay),
      numCIC: this.props.agente.cnpj,
      tipoCIC: "2",
    };
    for (let i = 1; i <= this.props.pages; i++) {
      params.pagina = i;
      await api.Financial.relatorioCobranca(params).then((response) => {
        result = result.concat(response.body.resultado);
      });
    }
    return result;
  }

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="DAEs DETRAN PR/RN" />{" "}
        <div className="content">
          {!this.props.showDetails && (
            <div className="row">
              <div className="col-lg-2">
                <Field name="uf" blank={true} label="UF" required data={ufs} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
              <div className="col-lg-5">
                <Field name="mesReferencia" required label="Mês de Referência" data={months} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
              <div className="col-lg-5">
                <Field name="anoReferencia" required label="Ano de referência" data={years} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
              <div className="col-lg-12">
                <div className="md-4">
                  <Field
                    name="agente"
                    blank={true}
                    required
                    label="Credor"
                    data={this.props.companies}
                    credor={true}
                    textField="razaoSocial"
                    valueField="cnpj"
                    component={DropdownListField}
                    type="text"
                  />
                </div>
                <div className="hpanel">
                  <Link
                    className="btn btn-info"
                    disabled={this.state.pesquisando}
                    onClick={this.props.handleSubmit((values) => {
                      return new Promise((resolve, reject) => {
                        var errors;
                        if (this) {
                          errors = this.validateValues(values);
                        }
                        if (errors && !this.isEmpty(errors)) {
                          reject(new SubmissionError(errors));
                        } else {
                          this.filter(values);
                          resolve();
                        }
                      });
                    })}
                  >
                    {" "}
                    {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                  </Link>
                  <Link className="btn btn-default" to="/alias/financial-report" onClick={reset}>
                    {" "}
                    Cancelar
                  </Link>
                  <div className="panel-heading">Consultar Cobranças DETRAN</div>
                  <div className="panel-body">
                    <div className="table-responsive">
                      {this.props.all && this.props.all.length > 0 ? (
                        <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                          {this.props.uf == "PR" ? (
                            <>
                              <thead>
                                <tr>
                                  <th className="text-center align-middle">#</th>
                                  <th className="text-center align-middle">Nosso Número</th>
                                  <th className="text-center align-middle">Credor</th>
                                  <th className="text-center align-middle">Qtde. Chassis</th>
                                  <th className="text-center align-middle">Data Registro</th>
                                  <th className="text-center align-middle">Data Vencimento</th>
                                  <th className="text-center align-middle">Valor Total</th>
                                  <th className="text-center align-middle">Situação</th>
                                  <th className="text-center align-middle">Data Pagamento</th>
                                  <th className="text-center align-middle">Valor Pago</th>
                                  <th className="text-center align-middle">Ação</th>
                                </tr>
                              </thead>
                              <tbody>{this.renderFinancialReports()}</tbody>
                              <tfoot>
                                <tr>
                                  <th style={{ textAlign: "left" }} colSpan={3} className="text-center align-middle">
                                    Total
                                  </th>
                                  <th className="text-center align-middle">{qtdeChassis}</th>
                                  <th colSpan={2} className="text-center align-middle"></th>
                                  <th className="text-center align-middle">{MaskDecimal.TO_BRL(valorTotal)}</th>
                                  <th colSpan={2} className="text-center align-middle"></th>
                                  <th className="text-center align-middle">{MaskDecimal.TO_BRL(totalPago)}</th>
                                  <th className="text-center align-middle"></th>
                                </tr>
                              </tfoot>
                            </>
                          ) : this.props.uf == "MS" ? (
                            <>
                              <thead>
                                <tr>
                                  <th className="text-center align-middle">Credor</th>
                                  <th className="text-center align-middle">Linha Digitável</th>
                                  <th className="text-center align-middle">Data Emissão</th>
                                  <th className="text-center align-middle">Data Vencimento</th>
                                  <th className="text-center align-middle">Valor Total</th>
                                  <th className="text-center align-middle">Situação</th>
                                  <th className="text-center align-middle">Qtde. Chassis</th>
                                  <th className="text-center align-middle">Ação</th>
                                </tr>
                              </thead>
                              <tbody>{this.renderFinancialReportsMS()}</tbody>
                            </>
                          ) : this.props.uf == "PI" ? (
                            <>
                              <thead>
                                <tr>
                                  <th className="text-center align-middle">Credor</th>
                                  <th className="text-center align-middle">Linha Digitável</th>
                                  <th className="text-center align-middle">Data Vencimento</th>
                                  <th className="text-center align-middle">Valor Total</th>
                                  <th className="text-center align-middle">Qtde. Chassis</th>
                                  <th className="text-center align-middle">Ação</th>
                                </tr>
                              </thead>
                              <tbody>{this.renderFinancialReportsPI()}</tbody>
                            </>
                          ) : (
                            <>
                              <thead>
                                <tr>
                                  <th className="text-center align-middle">Nosso Número</th>
                                  <th className="text-center align-middle">Credor</th>
                                  <th className="text-center align-middle">Descrição Classe</th>
                                  <th className="text-center align-middle">Linha Digitável</th>
                                  <th className="text-center align-middle">Data Vencimento</th>
                                  <th className="text-center align-middle">Valor Total</th>
                                  <th className="text-center align-middle">Ação</th>
                                </tr>
                              </thead>
                              <tbody>{this.renderFinancialReportsRN()}</tbody>
                              <tfoot>
                                <tr>
                                  <th colSpan={5} className="text-right align-middle">
                                    Total
                                  </th>
                                  <th colSpan={2} className="text-left align-middle">
                                    {MaskDecimal.TO_BRL(valorTotal)}
                                  </th>
                                </tr>
                              </tfoot>
                            </>
                          )}
                        </table>
                      ) : (
                        <small>Nenhum Relatório Encontrado</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.props.showDetails && (
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-right">
                    {this.props.detailsList && this.props.detailsList.length > 0 && (
                      <Link className="btn btn-default btn-sm" onClick={() => this.exportToPDF()} title="Gerar PDF">
                        {" "}
                        <SvgIcon icon={filePdf} />
                      </Link>
                    )}
                    {this.props.detailsList && this.props.detailsList.length > 0 && (
                      <Link
                        className="btn btn-default btn-sm"
                        onClick={() =>
                          this.props.uf == "PR" ? this.exportToCSV() : this.props.uf == "MS" ? this.exportToCSVMS() : this.props.uf == "PI" ? this.exportToCSVPI() : this.exportToCSVRN()
                        }
                        title="Gerar XLS"
                      >
                        {" "}
                        <SvgIcon icon={fileExcelO} />
                      </Link>
                    )}
                    <Link className="btn btn-default btn-sm" onClick={handleSubmit(this.voltar.bind(this))}>
                      <i className="fas fa-arrow-left"></i> Voltar{" "}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="hpanel">
                    <div className="panel-body">
                      <div className="table-responsive">
                        {this.props.uf == "PR" ? this.renderDetails() : this.props.uf == "MS" ? this.renderDetailsMS() : this.props.uf == "PI" ? this.renderDetailsPI() : this.renderDetailsRN()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderDetails() {
    const { handleSubmit } = this.props;
    var props = this.props;
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.detailsList}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={columns}
        defaultPageSize={50}
        showPageSizeOptions={false}
        minRows={1}
        pages={this.props.pages}
        onPageChange={(pageIndex) => {
          console.log(pageIndex);
        }}
        loading={this.state.loading}
        manual
        onFetchData={(state, instance) => {
          this.setState({ loading: true });
          var anoReferencia = this.props.anoReferencia;
          var mesReferencia = this.props.mesReferencia;
          var agente = this.props.agente.cnpj;

          var firstDay = new Date(anoReferencia, mesReferencia - 1, 1);
          var lastDay = new Date(anoReferencia, mesReferencia, 0);

          var params = {
            periodoInicio: MaskDate.TO_YYYY_MM_DD(firstDay),
            periodoFim: MaskDate.TO_YYYY_MM_DD(lastDay),
            numCIC: this.props.agente.cnpj,
            tipoCIC: "2",
            pagina: state.page + 1,
          };
          Promise.resolve(
            api.Financial.relatorioCobranca(params)
              .then((response) => {
                console.log(response);
                this.props.onDetailsLoad([response.body.resultado, Math.ceil(response.body.qtdeTotalRegistros / response.body.qtdePorPagina), response.body.paginaAtual]);
                this.setState({ loading: false });
              })
              .catch((e) => {
                if (e.message === "Unauthorized" || e.message === "jwt expired") {
                  api.Auth.logout();
                  props.onLogout();
                } else {
                  toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
                }
              })
          );
        }}
      />
    );
  }

  renderDetailsRN() {
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.detailsList}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={columnsRN}
        defaultPageSize={10}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  renderDetailsMS() {
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.detailsList}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={columnsMS}
        defaultPageSize={10}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  renderDetailsPI() {
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.detailsList}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={columnsPI}
        defaultPageSize={10}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  renderFinancialReports() {
    const { handleSubmit, reset } = this.props;
    totalPago = 0;
    qtdeChassis = 0;
    valorTotal = 0;
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((financial, index) => {
      totalPago = totalPago + parseFloat(financial.totalPago ? financial.totalPago : 0);
      qtdeChassis = qtdeChassis + parseFloat(financial.qtdeChassis);
      valorTotal = valorTotal + parseFloat(financial.valorTotal);
      return (
        <tr key={index}>
          <td className="text-center align-middle">{financial.id}</td>
          <td className="text-center align-middle">{financial.numeroBoletoGRB}</td>
          <td className="text-center align-middle">{financial.agenteFinanceiro}</td>
          <td className="text-center align-middle">{financial.qtdeChassis}</td>
          <td className="text-center align-middle">{MaskDate.TO_BR_DATETIME(financial.dataRegistro)}</td>
          <td className="text-center align-middle">{financial.dataVencimento}</td>
          <td className="text-center align-middle">{MaskDecimal.TO_BRL(financial.valorTotal)}</td>
          <td className="text-center align-middle">{financial.situacao}</td>
          <td className="text-center align-middle">{MaskDate.TO_BR_DATETIME(financial.dataPagamento)}</td>
          <td className="text-center align-middle">{MaskDecimal.TO_BRL(financial.valorPago)}</td>
          <td className="align-middle text-center">
            <div className="btn-group-vertical">
              <Link onClick={handleSubmit(this.gerarBoleto.bind(this))} target="_blank" className="btn btn-sm btn-default">
                <i className="fas fa-barcode" /> Gerar Boleto
              </Link>
              <Link onClick={handleSubmit(this.detalhar.bind(this))} target="_blank" className="btn btn-sm btn-default">
                <i className="fas fa-search" /> Detalhar
              </Link>
            </div>
          </td>
        </tr>
      );
    });
  }

  renderFinancialReportsRN() {
    const { handleSubmit, reset } = this.props;
    totalPago = 0;
    qtdeChassis = 0;
    valorTotal = 0;
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((financial, index) => {
      valorTotal = valorTotal + parseFloat(financial.valorVencimento);
      return (
        <tr key={index}>
          <td className="text-center align-middle">{financial.nossoNumero}</td>
          <td className="text-center align-middle">
            {financial.cgcCpf} - {financial.nome}
          </td>
          <td className="text-center align-middle">{financial.descricaoClasse}</td>
          <td className="text-center align-middle">{financial.linhaDigitavel}</td>
          <td className="text-center align-middle">{financial.dataVencimento}</td>
          <td className="text-center align-middle">{MaskDecimal.TO_BRL(financial.valorVencimento)}</td>
          <td className="align-middle text-center">
            <div className="btn-group-vertical">
              <Link onClick={() => this.detalharRN(financial.idRegistroContratoDebito)} target="_blank" className="btn btn-sm btn-default">
                <i className="fas fa-search" /> Detalhar
              </Link>
            </div>
          </td>
        </tr>
      );
    });
  }

  renderFinancialReportsMS() {
    const { handleSubmit, reset } = this.props;
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((financial, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{financial.nomeProprietario}</td>
          <td className="text-center align-middle">{financial.linhaDigitalvel}</td>
          <td className="text-center align-middle">{financial.dataEmissao}</td>
          <td className="text-center align-middle">{financial.vencimento}</td>
          <td className="text-center align-middle">{financial.totalGeral}</td>
          <td className="text-center align-middle">{financial.pago ? "Pago" : "Em Aberto"}</td>
          <td className="text-center align-middle">{financial.itens.length}</td>
          <td className="align-middle text-center">
            <div className="btn-group-vertical">
              <Link onClick={handleSubmit(this.gerarBoleto.bind(this))} target="_blank" className="btn btn-sm btn-default">
                <i className="fas fa-barcode" /> Gerar Boleto
              </Link>
              <Link onClick={() => this.detalharMS()} target="_blank" className="btn btn-sm btn-default">
                <i className="fas fa-search" /> Detalhar
              </Link>
            </div>
          </td>
        </tr>
      );
    });
  }

  renderFinancialReportsPI() {
    const { handleSubmit, reset } = this.props;
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((financial, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{financial.credor}</td>
          <td className="text-center align-middle">{financial.linhaDigitavel}</td>
          <td className="text-center align-middle">{MaskDate.TO_BR(financial.dataVencimento.toString())}</td>
          <td className="text-center align-middle">{MaskDecimal.TO_BRL(financial.valor)}</td>
          <td className="text-center align-middle">{financial.quantidadeRegistros}</td>
          <td className="align-middle text-center">
            <div className="btn-group-vertical">
              <Link onClick={handleSubmit(this.gerarBoleto.bind(this))} target="_blank" className="btn btn-sm btn-default">
                <i className="fas fa-barcode" /> Gerar Boleto
              </Link>
              <Link onClick={() => this.detalharPI()} target="_blank" className="btn btn-sm btn-default">
                <i className="fas fa-search" /> Detalhar
              </Link>
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: FINANCIAL_REPORT_PR_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: FINANCIAL_REPORT_PR_LIST_LOAD, payload }),
  onUnload: () => dispatch({ type: FINANCIAL_REPORT_PR_UNLOAD }),
  onDetailsLoad: (payload) => dispatch({ type: FINANCIAL_REPORT_DETAILS_PR_LOAD, payload }),
  onDetailsUnload: () => dispatch({ type: FINANCIAL_REPORT_DETAILS_PR_UNLOAD }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const selector = formValueSelector("FinancialReportPRForm");
const mapStateToProps = (state) => ({
  ...state.financialReportPR,
  currentUser: state.common.currentUser,
  agente: selector(state, "agente"),
  anoReferencia: selector(state, "anoReferencia"),
  mesReferencia: selector(state, "mesReferencia"),
  uf: selector(state, "uf"),
});

const form = reduxForm({
  form: "FinancialReportPRForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(FinancialReportPR));
