import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, untouch } from "redux-form";
import { Link } from "react-router";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import TextField from "../../components/Fields/TextField";
import { NEWS_LIST_LOAD, NEWS_LIST_UNLOAD, NEWS_LOAD_UFS, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";
import Title from "../../components/Layout/Title";
import MaskDate from "../../components/Util/MaskDate";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import MultiSelectField from "../../components/Fields/MultiSelectField";
import Dropzone from "react-dropzone";
import OtherUtils from "../../components/Util/OtherUtils";

var idNov = null;

class NewsList extends React.Component {
  constructor() {
    super();
    this.state = {
      adicionar: true,
      files: [],
    };
  }

  componentDidMount() {
    this.props.onLoad(Promise.resolve(api.News.all()));
    this.props.onLoadUf(api.Ufs.system());
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  handleOnCancel = () => {
    this.setState({ adicionar: true });
    idNov = null;
    this.props.dispatch(change("NewsListForm", "titulo", null));
    this.props.dispatch(untouch("NewsListForm", "titulo"));
    this.props.dispatch(change("NewsListForm", "descricao", null));
    this.props.dispatch(untouch("NewsListForm", "descricao"));
    this.props.dispatch(change("NewsListForm", "data", null));
    this.props.dispatch(untouch("NewsListForm", "data"));
    this.props.dispatch(change("NewsListForm", "dataFim", null));
    this.props.dispatch(untouch("NewsListForm", "dataFim"));
    this.props.dispatch(change("NewsListForm", "link", null));
    this.props.dispatch(untouch("NewsListForm", "link"));
    this.props.dispatch(change("NewsListForm", "ufs", null));
    this.props.dispatch(untouch("NewsListForm", "ufs"));
  };

  handleOnSelectValueClick = (value) => (ev) => {
    this.setState({ adicionar: false });
    idNov = value.id;
    this.props.dispatch(change("NewsListForm", "titulo", value.titulo));
    this.props.dispatch(change("NewsListForm", "descricao", value.descricao));
    this.props.dispatch(change("NewsListForm", "data", MaskDate.TO_BR_DATE(value.data)));
    this.props.dispatch(change("NewsListForm", "dataFim", MaskDate.TO_BR_DATE(value.dataFim)));
    this.props.dispatch(change("NewsListForm", "link", value.link));
    this.props.dispatch(
      change(
        "NewsListForm",
        "ufs",
        value.ufs.map((obj) => obj.uf)
      )
    );
    this.HandleScroll();
  };

  handleClickDelete = (idNovidade) => (ev) => {
    ev.preventDefault();
    var props = this.props;
    Promise.resolve(api.News.delete(idNovidade))
      .then((response) => {
        this.props.onLoad(Promise.resolve(api.News.all()));
        this.props.reset();
        toastr.success("Sucesso", "Registro excluído");
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  formSubmit(values) {
    var props = this.props;
    var ns = {
      titulo: values.titulo,
      descricao: values.descricao,
      data: MaskDate.TO_DATABASE(values.data),
      dataFim: MaskDate.TO_DATABASE(values.dataFim),
      link: values.link,
      ufs: [],
      id: idNov ? idNov : null,
    };

    _.forEach(values.ufs, function (uf) {
      ns.ufs = [uf, ...ns.ufs];
    });

    let param = {
      dados: JSON.stringify(ns),
    };

    if (idNov) {
      Promise.resolve(api.News.update(this.state.files.length > 0 ? this.state.files[0] : null, param))
        .then((ret) => {
          toastr.success("Sucesso", "Registro alterado");
          this.props.onLoad(Promise.resolve(api.News.all()));
          this.props.reset();
          this.setState({ adicionar: true, files: [] });
          idNov = null;
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    } else {
      Promise.resolve(api.News.create(this.state.files[0], param))
        .then((ret) => {
          toastr.success("Sucesso", "Novidade cadastrada");
          this.props.onLoad(Promise.resolve(api.News.all()));
          this.props.reset();
          this.setState({ adicionar: true, files: [] });
          idNov = null;
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", resp.response.body.message);
          }
        });
    }
  }

  HandleScroll() {
    window.scrollTo(0, 0);
  }

  onDrop(files) {
    if (!files[0] || (files[0].type !== "image/jpeg" && files[0].type !== "image/png")) {
      toastr.info("Atenção", "É permitido anexar somente .jpeg ou .png");
    } else {
      this.setState({
        files: files,
      });
    }
  }

  handleClickDownload = (idNovidade) => {
    var props = this.props;
    Promise.resolve(api.News.imagem(idNovidade))
      .then((response) => {
        var base64Flag = "data:image/jpeg;base64,";
        var imageStr = OtherUtils.arrayBufferToBase64(response.arquivo.data);

        var a = document.createElement("a"); //Create <a>
        a.href = base64Flag + imageStr; //Image Base64 Goes here
        a.download = "Imagem.jpg"; //File name Here
        a.click();
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="As postagens castradas aparecerão no Dashboard dos clientes das financeiras" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">Novidades</div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-9">
                        <Field name="titulo" label="Título" component={TextField} type="text" required={true} maxlength={150} validate={[FieldValidation.required]} />
                      </div>
                      <div className="col-md-3">
                        <Field name="ufs" label="UF (Vazio para todas)" data={this.props.ufs} component={MultiSelectField} type="text" textField="uf" valueField="uf" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Field name="descricao" label="Descrição" component={TextField} type="text" maxlength={200} required={true} validate={[FieldValidation.required]} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <Field
                          name="data"
                          label="Data Início"
                          type="text"
                          required={true}
                          placeholder="00/00/0000"
                          component={TextDatePickerField}
                          validate={[FieldValidation.date, FieldValidation.required]}
                          normalize={FieldNormalize.DATE}
                        />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="dataFim"
                          label="Data Fim"
                          type="text"
                          required={true}
                          placeholder="00/00/0000"
                          component={TextDatePickerField}
                          validate={[FieldValidation.date, FieldValidation.required]}
                          normalize={FieldNormalize.DATE}
                        />
                      </div>
                      <div className="col-md-8">
                        <Field name="link" label="Link" component={TextField} type="text" maxlength={190} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="py-1">
                          <h7>
                            <strong>Imagem: {this.state.files && this.state.files.length > 0 ? this.state.files[0].name : ""}</strong>
                          </h7>
                        </div>

                        <Dropzone accept=".jpg, .png" name="file" className="dropzone-blank fallback" multiple={false} onDrop={this.onDrop.bind(this)}>
                          <button className="btn btn-success" type="button">
                            Anexar imagem
                          </button>
                        </Dropzone>
                      </div>
                      <div className="col-md-8 mt-4">
                        <div className="text-right">
                          <Link className="btn btn-default" to="/alias/news" onClick={this.handleOnCancel}>
                            {" "}
                            Cancelar
                          </Link>
                          <button className="btn btn-info" onClick={handleSubmit(this.formSubmit.bind(this))}>
                            {this.state.adicionar ? "Adicionar" : "Alterar"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools"></div>
                  Novidades cadastradas
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    {this.props.all && this.props.all.length > 0 ? (
                      <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="text-center align-middle">Título</th>
                            <th className="text-center align-middle" style={{ width: "5%" }}>
                              UF
                            </th>
                            <th className="text-center align-middle">Descrição</th>
                            <th className="text-center align-middle" style={{ width: "7%" }}>
                              Data Inicío
                            </th>
                            <th className="text-center align-middle" style={{ width: "7%" }}>
                              Data Fim
                            </th>
                            <th className="text-center align-middle" style={{ width: "8%" }}>
                              Visualizações
                            </th>
                            <th className="text-center align-middle" style={{ width: "5%" }}>
                              Ações
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.renderNewsList()}</tbody>
                      </table>
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNewsList() {
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((novidades, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{novidades.titulo}</td>
          <td className="text-center align-middle">
            {novidades.ufs && novidades.ufs.length > 0 ? novidades.ufs.reduce((totalUf, currentValue) => totalUf.concat(", " + currentValue.uf), "").substr(1) : "Todas"}
          </td>
          <td className="text-center align-middle">{novidades.descricao}</td>
          <td className="text-center align-middle">{MaskDate.TO_BR_DATE(novidades.data)}</td>
          <td className="text-center align-middle">{MaskDate.TO_BR_DATE(novidades.dataFim)}</td>
          <td className="text-center align-middle">{novidades.qtdVisualizacao}</td>
          <td className="align-middle text-center">
            <div className="btn-group">
              <button type="button" className="btn btn-sm btn-default" onClick={this.handleOnSelectValueClick(novidades)}>
                <i className="far fa-edit" title="Editar"></i>
              </button>
              <Link className="btn btn-default btn-sm" onClick={this.handleClickDelete(novidades.id)}>
                <i className="fas fa-trash-alt" title="Excluir"></i>
              </Link>
              {novidades.arquivo ? (
                <>
                  <Link className="btn btn-default btn-sm" onClick={() => this.handleClickDownload(novidades.id)}>
                    <i className="fa fa-download" title="Baixar imagem"></i>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: NEWS_LIST_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: NEWS_LIST_UNLOAD, payload }),
  onLoadUf: (payload) => dispatch({ type: NEWS_LOAD_UFS, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.news,
});

const form = reduxForm({
  form: "NewsListForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(NewsList));
