import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import SideNav, { Nav, NavIcon, NavText } from "react-sidenav";
import SvgIcon from "react-icons-kit";
import _ from "lodash";

import { ic_aspect_ratio } from "react-icons-kit/md/ic_aspect_ratio";
import { ic_people } from "react-icons-kit/md/ic_people";
import { dollar } from "react-icons-kit/iconic/dollar";
import { stack } from "react-icons-kit/icomoon/stack";
import { fileText2 } from "react-icons-kit/icomoon/fileText2";
import { ic_assignment_turned_in } from "react-icons-kit/md/ic_assignment_turned_in";
import { ic_account_balance } from "react-icons-kit/md/ic_account_balance";
import { folderUpload } from "react-icons-kit/icomoon/folderUpload";
import { ic_format_list_bulleted } from "react-icons-kit/md/ic_format_list_bulleted";
import { wallet } from "react-icons-kit/entypo/wallet";
import { folderOpen } from "react-icons-kit/icomoon/folderOpen";
import { newspaper } from "react-icons-kit/icomoon/newspaper";
import { fileText } from "react-icons-kit/icomoon/fileText";
import { filesEmpty } from "react-icons-kit/icomoon/filesEmpty";
import { bank } from "react-icons-kit/fa/bank";
import { money } from "react-icons-kit/fa/money";
import { document_upload } from "react-icons-kit/ikons/document_upload";
import { cogs } from "react-icons-kit/icomoon/cogs";
import { documents } from "react-icons-kit/ikons/documents";
import { pencil } from "react-icons-kit/iconic/pencil";
import { tree } from "react-icons-kit/icomoon/tree";
import { ic_local_taxi } from "react-icons-kit/md/ic_local_taxi";
import { listAlt } from "react-icons-kit/fa/listAlt";
import { magicWand } from "react-icons-kit/icomoon/magicWand";
import { pencil2 } from "react-icons-kit/icomoon/pencil2";
import { ic_network_check } from "react-icons-kit/md/ic_network_check";
import { users } from "react-icons-kit/icomoon/users";
import { calculator } from "react-icons-kit/icomoon/calculator";
import { ticket } from "react-icons-kit/icomoon/ticket";
import { book } from "react-icons-kit/entypo/book";
import { powerCord } from "react-icons-kit/icomoon/powerCord";
import { briefcase } from "react-icons-kit/entypo/briefcase";
import { group } from "react-icons-kit/fa/group";
import { groupOutline } from "react-icons-kit/typicons/groupOutline";
import { statsBars } from "react-icons-kit/icomoon/statsBars";
import { table } from "react-icons-kit/icomoon/table";
import { statsDots } from "react-icons-kit/icomoon/statsDots";
import { calendar } from "react-icons-kit/iconic/calendar";
import { warning } from "react-icons-kit/iconic/warning";
class Menu extends React.Component {
  render() {
    if (this.props.currentUser && this.props.currentUser.perfis) {
      return (
        <aside id="menu">
          {" "}
          <div id="navigation">
            <SideNav defaultSelected="sales">
              {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                return perfil === "ALIAS";
              }) === -1 &&
                _.findIndex(this.props.currentUser.perfis, function (perfil) {
                  return perfil === "DETRAN";
                }) === -1 && (
                  <Link to="/secure" onClick={() => this.clearFiltrosListContracts()}>
                    <Nav id="dashboard">
                      <NavIcon>
                        <SvgIcon size={20} icon={ic_aspect_ratio} />
                      </NavIcon>
                      <NavText>Dashboard</NavText>
                    </Nav>
                  </Link>
                )}
              {this.props.currentUser.funcionalidades.includes(44) && (
                <Link to="/secure/contract" onClick={() => this.clearFiltrosListContracts()}>
                  <Nav id="dashboard">
                    <NavIcon>
                      <SvgIcon size={20} icon={fileText2} />
                    </NavIcon>
                    <NavText>Novo Contrato</NavText>
                  </Nav>
                </Link>
              )}
              {this.props.currentUser.funcionalidades.includes(36) && (
                <Link to="/secure/contracts" onClick={() => this.clearFiltrosListContracts()}>
                  <Nav id="dashboard">
                    <NavIcon>
                      <SvgIcon size={20} icon={stack} />
                    </NavIcon>
                    <NavText>Contratos</NavText>
                  </Nav>
                </Link>
              )}
              {/*((_.findIndex(this.props.currentUser.perfis, function(perfil) { return perfil === 'ALIAS'; }) == -1) && (process.env.REACT_APP_UF === 'BA')) &&
                <Link to="/secure/taxasDetran">
                  <Nav id='dashboardTaxasDetran'>
                    <NavIcon><SvgIcon size={20} icon={bank}/></NavIcon>
                    <NavText>
                      Taxa Detran BA
                    </NavText>
                  </Nav>
                </Link>
            */}
              {this.props.currentUser.funcionalidades.includes(34) && (
                <Link to="/secure/users" onClick={() => this.clearFiltrosListContracts()}>
                  <Nav id="dashboard">
                    <NavIcon>
                      <SvgIcon size={20} icon={ic_people} />
                    </NavIcon>
                    <NavText>Usuários</NavText>
                  </Nav>
                </Link>
              )}
              {/* {this.props.currentUser.funcionalidades.includes(43) && this.props.currentUser.integracaoConfia && this.props.currentUser.integracaoConfia == "S" && (
                <Link to="/secure/consultaConfia" onClick={() => this.clearFiltrosListContracts()}>
                  <Nav id="confia">
                    <NavIcon>
                      <SvgIcon size={20} icon={pencil} />
                    </NavIcon>
                    <NavText>Assinaturas</NavText>
                  </Nav>
                </Link>
              )} */}
              {this.props.currentUser.funcionalidades.includes(41) && (
                <Link to="/secure/invoices" onClick={() => this.clearFiltrosListContracts()}>
                  <Nav id="dashboard">
                    <NavIcon>
                      <SvgIcon size={20} icon={dollar} />
                    </NavIcon>
                    <NavText>Financeiro</NavText>
                  </Nav>
                </Link>
              )}

              {this.props.currentUser.funcionalidades.includes(33) && (
                <Link to="/secure/FilesUpload" onClick={() => this.clearFiltrosListContracts()}>
                  <Nav id="filesUpload">
                    <NavIcon>
                      <SvgIcon size={20} icon={folderUpload} />
                    </NavIcon>
                    <NavText>Imagens</NavText>
                  </Nav>
                </Link>
              )}
              {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                return perfil === "ALIAS";
              }) > -1 && (
                <Link to="/alias" onClick={() => this.clearFiltrosListContracts()}>
                  <Nav id="financialDashboard">
                    <NavIcon>
                      <SvgIcon size={20} icon={ic_aspect_ratio} />
                    </NavIcon>
                    <NavText>Dashboard</NavText>
                  </Nav>
                </Link>
              )}

              {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                return perfil === "ALIAS";
              }) > -1 && (
                <Link to="/alias/aprove" onClick={() => this.clearFiltrosListContracts()}>
                  <Nav id="aprove">
                    <NavIcon>
                      <SvgIcon size={20} icon={ic_assignment_turned_in} />
                    </NavIcon>
                    <NavText>Contratos</NavText>
                  </Nav>
                </Link>
              )}
              {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("P")) && (
                <>
                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 && (
                    // <Link to="/alias/faturamento" onClick={() => this.clearFiltrosListContracts()}>
                    //   <Nav id='faturamento'>
                    //     <NavIcon><SvgIcon size={20} icon={money}/></NavIcon>
                    //     <NavText>
                    //       Faturamento
                    //     </NavText>
                    //   </Nav>
                    // </Link>

                    <Nav>
                      <NavIcon>
                        <SvgIcon size={20} icon={money} style={{ color: "#455463" }} />
                      </NavIcon>
                      <NavText style={{ color: "#455463" }}>Faturamento</NavText>
                      <Nav>
                        <NavText style={{ margin: "-45px" }}>
                          <Link to="/alias/faturamento" onClick={() => this.clearFiltrosListContracts()}>
                            <Nav>
                              <NavIcon>
                                <SvgIcon size={20} icon={money} />
                              </NavIcon>
                              <NavText>Emissão</NavText>
                            </Nav>
                          </Link>
                        </NavText>
                      </Nav>
                      <Nav>
                        <NavText style={{ margin: "-49px" }}>
                          <Link to="/alias/financial-report" onClick={() => this.clearFiltrosListContracts()}>
                            <Nav id="financial">
                              <NavIcon>
                                <SvgIcon size={20} icon={ic_account_balance} />
                              </NavIcon>
                              <NavText>Boletos MS/PI/PR/RN</NavText>
                            </Nav>
                          </Link>
                        </NavText>
                      </Nav>
                      <Nav>
                        <NavText style={{ margin: "-47px" }}>
                          <Link to="/alias/charging-report" onClick={() => this.clearFiltrosListContracts()}>
                            <Nav id="chargingReport">
                              <NavIcon>
                                <SvgIcon size={20} icon={ic_account_balance} />
                              </NavIcon>
                              <NavText>Boletos SC/BA/PE</NavText>
                            </Nav>
                          </Link>
                        </NavText>
                      </Nav>
                      <Nav>
                        <NavText style={{ margin: "-45px" }}>
                          <Link to="/alias/relatorioDetranMA" onClick={() => this.clearFiltrosListContracts()}>
                            <Nav id="relatorioDetranMA">
                              <NavIcon>
                                <SvgIcon size={20} icon={book} />
                              </NavIcon>
                              <NavText>Rel. Detran MA</NavText>
                            </Nav>
                          </Link>
                        </NavText>
                      </Nav>
                      <Nav>
                        <NavText style={{ margin: "-45px" }}>
                          <Link to="/alias/taxasDetran" onClick={() => this.clearFiltrosListContracts()}>
                            <Nav id="dashboardTaxasDetran">
                              <NavIcon>
                                <SvgIcon size={20} icon={bank} />
                              </NavIcon>
                              <NavText>Taxa Detran BA</NavText>
                            </Nav>
                          </Link>
                        </NavText>
                      </Nav>
                    </Nav>
                  )}
                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 && (
                    <Link to="/alias/financial" onClick={() => this.clearFiltrosListContracts()}>
                      <Nav id="financial">
                        <NavIcon>
                          <SvgIcon size={20} icon={ic_account_balance} />
                        </NavIcon>
                        <NavText>Financeiras</NavText>
                      </Nav>
                    </Link>
                  )}
                </>
              )}
              {(_.findIndex(this.props.currentUser.perfis, function (perfil) {
                return perfil === "ALIAS";
              }) > -1 ||
                this.props.currentUser.funcionalidades.includes(35)) && (
                <Nav>
                  <NavIcon>
                    <SvgIcon size={20} icon={filesEmpty} style={{ color: "#455463" }} />
                  </NavIcon>
                  <NavText style={{ color: "#455463" }}>Relatórios</NavText>
                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 && (
                    <Nav>
                      <NavText style={{ margin: "-45px" }}>
                        <Link to="/alias/management-report" onClick={() => this.clearFiltrosListContracts()}>
                          <Nav id="chargingReport">
                            <NavIcon>
                              <SvgIcon size={20} icon={fileText} />
                            </NavIcon>
                            <NavText>Gerencial</NavText>
                          </Nav>
                        </Link>
                      </NavText>
                    </Nav>
                  )}
                  {this.props.currentUser.funcionalidades.includes(39) && (
                    <Nav>
                      <NavText style={{ margin: "-45px" }}>
                        <Link to="/secure/management-report" onClick={() => this.clearFiltrosListContracts()}>
                          <Nav id="chargingReport">
                            <NavIcon>
                              <SvgIcon size={20} icon={fileText} />
                            </NavIcon>
                            <NavText>Gerencial</NavText>
                          </Nav>
                        </Link>
                      </NavText>
                    </Nav>
                  )}
                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 &&
                    (!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("P")) && (
                      <Nav>
                        <NavText style={{ margin: "-45px" }}>
                          <Link to="/alias/management-report-error" onClick={() => this.clearFiltrosListContracts()}>
                            <Nav id="chargingReport">
                              <NavIcon>
                                <SvgIcon size={20} icon={ic_format_list_bulleted} />
                              </NavIcon>
                              <NavText>Comun. Detran</NavText>
                            </Nav>
                          </Link>
                        </NavText>
                      </Nav>
                    )}
                  {this.props.currentUser.funcionalidades.includes(38) && (
                    <Nav>
                      <NavText style={{ margin: "-45px" }}>
                        <Link to="/secure/management-report-error" onClick={() => this.clearFiltrosListContracts()}>
                          <Nav id="chargingReport">
                            <NavIcon>
                              <SvgIcon size={20} icon={ic_format_list_bulleted} />
                            </NavIcon>
                            <NavText>Comun. Detran</NavText>
                          </Nav>
                        </Link>
                      </NavText>
                    </Nav>
                  )}
                </Nav>
              )}
              {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("P")) && (
                <>
                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 &&
                    process.env.REACT_APP_UF === "MG" && (
                      <Link to="/alias/financial-shipping" onClick={() => this.clearFiltrosListContracts()}>
                        <Nav id="financialShipping">
                          <NavIcon>
                            <SvgIcon size={20} icon={wallet} />
                          </NavIcon>
                          <NavText>Rem. Financeira</NavText>
                        </Nav>
                      </Link>
                    )}

                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "DETRAN";
                  }) > -1 && (
                    <Link to="/sdetran" onClick={() => this.clearFiltrosListContracts()}>
                      <Nav id="sdetran">
                        <NavIcon>
                          <SvgIcon size={20} icon={ic_assignment_turned_in} />
                        </NavIcon>
                        <NavText>Imagens</NavText>
                      </Nav>
                    </Link>
                  )}

                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ADMIN";
                  }) > -1 &&
                    process.env.REACT_APP_UF === "MG" && (
                      <Link to="/secure/contractLot" onClick={() => this.clearFiltrosListContracts()}>
                        <Nav id="contractLot">
                          <NavIcon>
                            <SvgIcon size={20} icon={folderOpen} />
                          </NavIcon>
                          <NavText>Arq. Lote</NavText>
                        </Nav>
                      </Link>
                    )}

                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 && (
                    <Nav>
                      <NavIcon>
                        <SvgIcon size={20} icon={ic_local_taxi} style={{ color: "#455463" }} />
                      </NavIcon>
                      <NavText style={{ color: "#455463" }}>Detran</NavText>

                      {/* <Nav>
                      <NavText style={{ margin: '-45px' }}>                      
                        <Link to="/alias/loteRJ">
                          <Nav id='loteRJ'>
                            <NavIcon><SvgIcon size={20} icon={table}/></NavIcon>
                            <NavText>
                              Lote RJ
                            </NavText>
                          </Nav>
                        </Link>                      
                      </NavText>
                    </Nav> */}
                      <Nav>
                        <NavText style={{ margin: "-45px" }}>
                          <Link to="/alias/consultaChassiPR" onClick={() => this.clearFiltrosListContracts()}>
                            <Nav id="consultaChassiPR">
                              <NavIcon>
                                <SvgIcon size={20} icon={listAlt} />
                              </NavIcon>
                              <NavText>Consulta</NavText>
                            </Nav>
                          </Link>
                        </NavText>
                      </Nav>
                      <Nav>
                        <NavText style={{ margin: "-45px" }}>
                          <Link to="/alias/duda" onClick={() => this.clearFiltrosListContracts()}>
                            <Nav id="duda">
                              <NavIcon>
                                <SvgIcon size={20} icon={ticket} />
                              </NavIcon>
                              <NavText>Duda RJ</NavText>
                            </Nav>
                          </Link>
                        </NavText>
                      </Nav>
                      <Nav>
                        <NavText style={{ margin: "-45px" }}>
                          <Link to="/alias/financeiroPE" onClick={() => this.clearFiltrosListContracts()}>
                            <Nav id="financeiroPE">
                              <NavIcon>
                                <SvgIcon size={20} icon={calculator} />
                              </NavIcon>
                              <NavText>Financeiro PE</NavText>
                            </Nav>
                          </Link>
                        </NavText>
                      </Nav>
                      <Nav>
                        <NavText style={{ margin: "-45px" }}>
                          <Link to="/alias/statusDetran" onClick={() => this.clearFiltrosListContracts()}>
                            <Nav id="statusDetran">
                              <NavIcon>
                                <SvgIcon size={20} icon={ic_network_check} />
                              </NavIcon>
                              <NavText>Status</NavText>
                            </Nav>
                          </Link>
                        </NavText>
                      </Nav>
                      {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("F")) && (
                        <Nav>
                          <NavText style={{ margin: "-45px" }}>
                            <Link to="/alias/valorDetranUf" onClick={() => this.clearFiltrosListContracts()}>
                              <Nav id="taxasDetran">
                                <NavIcon>
                                  <SvgIcon size={20} icon={tree} />
                                </NavIcon>
                                <NavText>Taxas</NavText>
                              </Nav>
                            </Link>
                          </NavText>
                        </Nav>
                      )}
                      {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("F")) && (
                        <Nav>
                          <NavText style={{ margin: "-45px" }}>
                            <Link to="/alias/configuracaoUf" onClick={() => this.clearFiltrosListContracts()}>
                              <Nav id="configuracaoUf">
                                <NavIcon>
                                  <SvgIcon size={20} icon={calendar} />
                                </NavIcon>
                                <NavText>Vigência UFs</NavText>
                              </Nav>
                            </Link>
                          </NavText>
                        </Nav>
                      )}
                    </Nav>
                  )}
                  {["33660564000100"].indexOf(this.props.currentUser.cnpj) >= 0 && (
                    <Link to="/secure/cargaBNDES" onClick={() => this.clearFiltrosListContracts()}>
                      <Nav id="cargaBNDES">
                        <NavIcon>
                          <SvgIcon size={20} icon={document_upload} />
                        </NavIcon>
                        <NavText>Carga</NavText>
                      </Nav>
                    </Link>
                  )}
                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) === -1 &&
                    [
                      "00000000000191",
                      "01149953000189",
                      "59588111000103",
                      "01858774000110",
                      "10371492000185",
                      "03017677000120",
                      "62063177000194",
                      "92228410000102",
                      "60850229000147",
                      "62307848000115",
                    ].indexOf(this.props.currentUser.cnpj) >= 0 && (
                      <Link to="/secure/lote" onClick={() => this.clearFiltrosListContracts()}>
                        <Nav id="lote">
                          <NavIcon>
                            <SvgIcon size={20} icon={documents} />
                          </NavIcon>
                          <NavText>Lote</NavText>
                        </Nav>
                      </Link>
                    )}
                  {this.props.currentUser.funcionalidades.includes(48) && (
                    <Link to="/secure/LoteSNG" onClick={() => this.clearFiltrosListContracts()}>
                      <Nav id="loteSNG">
                        <NavIcon>
                          <SvgIcon size={20} icon={briefcase} />
                        </NavIcon>
                        <NavText>Lote SNG</NavText>
                      </Nav>
                    </Link>
                  )}
                  {/* {this.props.currentUser.funcionalidades.includes(51) && (
                    <>
                      <Link to="/secure/consultaSNG" onClick={() => this.clearFiltrosListContracts()}>
                        <Nav id="consultaSNG">
                          <NavIcon>
                            <SvgIcon size={20} icon={documents} />
                          </NavIcon>
                          <NavText>Consulta CRV</NavText>
                        </Nav>
                      </Link> */}
                  {/* <Link to="/secure/acompanhamentoSng" onClick={() => this.clearFiltrosListContracts()}>
                    <Nav id="acompanhamentoSng">
                      <NavIcon>
                        <SvgIcon size={20} icon={documents} />
                      </NavIcon>
                      <NavText>Config. sng</NavText>
                    </Nav>
                  </Link> */}
                  {/* </>
                  )} */}
                  {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 && (
                    <span>
                      {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("F")) && (
                        <>
                          <Nav>
                            <NavIcon>
                              <SvgIcon size={20} icon={cogs} style={{ color: "#455463" }} />
                            </NavIcon>
                            <NavText style={{ color: "#455463" }}>Configurações</NavText>
                            {this.props.currentUser.tipo && this.props.currentUser.tipo.includes("A") && (
                              <Nav>
                                <NavText style={{ margin: "-45px" }}>
                                  <Link to="/alias/errosDetran" onClick={() => this.clearFiltrosListContracts()}>
                                    <Nav id="errosDetran">
                                      <NavIcon>
                                        <SvgIcon size={20} icon={warning} />
                                      </NavIcon>
                                      <NavText>Erros Detran E-mail</NavText>
                                    </Nav>
                                  </Link>
                                </NavText>
                              </Nav>
                            )}
                            {/* <Nav>
                              <NavText style={{ margin: "-45px" }}>
                                <Link to="/alias/configuracoes" onClick={() => this.clearFiltrosListContracts()}>
                                  <Nav id="config">
                                    <NavIcon>
                                      <SvgIcon size={20} icon={magicWand} />
                                    </NavIcon>
                                    <NavText>Interface</NavText>
                                  </Nav>
                                </Link>
                              </NavText>
                            </Nav> */}
                            <Nav>
                              <NavText style={{ margin: "-45px" }}>
                                <Link to="/alias/news" onClick={() => this.clearFiltrosListContracts()}>
                                  <Nav id="news">
                                    <NavIcon>
                                      <SvgIcon size={20} icon={newspaper} />
                                    </NavIcon>
                                    <NavText>Novidades</NavText>
                                  </Nav>
                                </Link>
                              </NavText>
                            </Nav>
                          </Nav>
                          <Nav>
                            <NavIcon>
                              <SvgIcon size={20} icon={users} style={{ color: "#455463" }} />
                            </NavIcon>
                            <NavText style={{ color: "#455463" }}>Usuários</NavText>
                            {this.props.currentUser.tipo && this.props.currentUser.tipo.includes("A") && (
                              <Nav>
                                <NavText style={{ margin: "-45px" }}>
                                  <Link to="/alias/userAlias" onClick={() => this.clearFiltrosListContracts()}>
                                    <Nav id="userAlias">
                                      <NavIcon>
                                        <SvgIcon size={20} icon={group} />
                                      </NavIcon>
                                      <NavText>Alias</NavText>
                                    </Nav>
                                  </Link>
                                </NavText>
                              </Nav>
                            )}
                            <Nav>
                              <NavText style={{ margin: "-45px" }}>
                                <Link to="/alias/searchUserList" onClick={() => this.clearFiltrosListContracts()}>
                                  <Nav id="searchUserList">
                                    <NavIcon>
                                      <SvgIcon size={20} icon={groupOutline} />
                                    </NavIcon>
                                    <NavText>Financeira</NavText>
                                  </Nav>
                                </Link>
                              </NavText>
                            </Nav>
                            <Nav>
                              <NavText style={{ margin: "-45px" }}>
                                <Link to="/alias/suporte" onClick={() => this.clearFiltrosListContracts()}>
                                  <Nav id="suporte">
                                    <NavIcon>
                                      <SvgIcon size={20} icon={pencil2} />
                                    </NavIcon>
                                    <NavText>Suporte</NavText>
                                  </Nav>
                                </Link>
                              </NavText>
                            </Nav>
                          </Nav>
                        </>
                      )}
                    </span>
                  )}
                  {this.props.currentUser.funcionalidades.includes(45) && (
                    <Link to="/secure/integration" onClick={() => this.clearFiltrosListContracts()}>
                      <Nav id="integration">
                        <NavIcon>
                          <SvgIcon size={20} icon={powerCord} />
                        </NavIcon>
                        <NavText>Integrações</NavText>
                      </Nav>
                    </Link>
                  )}
                </>
              )}
              {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                return perfil === "ALIAS";
              }) > -1 &&
                this.props.currentUser.tipo != "F" && (
                  <Nav>
                    <NavIcon>
                      <SvgIcon size={20} icon={statsDots} style={{ color: "#455463" }} />
                    </NavIcon>
                    <NavText style={{ color: "#455463" }}>Comercial</NavText>
                    <Nav>
                      <NavText style={{ margin: "-45px" }}>
                        <Link to="/alias/spreadsheet" onClick={() => this.clearFiltrosListContracts()}>
                          <Nav id="spreadsheet">
                            <NavIcon>
                              <SvgIcon size={20} icon={table} />
                            </NavIcon>
                            <NavText>Planilhas</NavText>
                          </Nav>
                        </Link>
                      </NavText>
                    </Nav>
                    {/* <Nav>
                <NavText style={{ margin: "-45px" }}>
                  <Link to="/alias/searchUserList" onClick={() => this.clearFiltrosListContracts()}>
                    <Nav id="searchUserList">
                      <NavIcon>
                        <SvgIcon size={20} icon={statsBars} />
                      </NavIcon>
                      <NavText>Gráficos</NavText>
                    </Nav>
                  </Link>
                </NavText>
              </Nav> */}
                  </Nav>
                )}
            </SideNav>
          </div>
        </aside>
      );
    } else {
      return null;
    }
  }
  clearFiltrosListContracts() {
    localStorage.removeItem("filtros");
  }
}

Menu.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default Menu;
