import React from "react";
import { Router, Route, IndexRoute } from "react-router";
import { history } from "./store.js";
import PrivateRoute from "./containers/Authorization/PrivateRoute";
import App from "./containers/App/App";
import HomeApp from "./containers/App/HomeApp";
import Home from "./containers/Home/Home";
import Login from "./containers/Login/Login";
import ForgotPassword from "./containers/Login/ForgotPassword";
import Contract from "./containers/Contract/Contract";
import ContractList from "./containers/ContractList/ContractList";
import ContractView from "./containers/Contract/ContractView";
import Profile from "./containers/Profile/Profile";
import UserList from "./containers/User/UserList";
import UserLogAcess from "./containers/User/UserLogAcess";
import InvoiceList from "./containers/Invoice/InvoiceList";
import InvoiceDetails from "./containers/Invoice/InvoiceDetails";
import FilesUpload from "./containers/FilesUpload/FilesUpload";
import FinancialProfile from "./containers/FinancialProfile/FinancialProfile";
import FinancialProfileFeatures from "./containers/FinancialProfile/FinancialProfileFeatures";
import UserLogAudit from "./containers/User/UserLogAudit";
import DetailUserLogAudit from "./containers/User/DetailUserLogAudit";
import TaxasDetran from "./containers/TaxasDetran/TaxasDetran";
import LoteRJ from "./containers/LoteRJ/LoteRJ";
import ConsultaChassiPR from "./containers/ConsultaChassiPR/ConsultaChassiPR";
import Faturamento from "./containers/Financeiro/Faturamento";
import CargaBNDES from "./containers/CargaBNDES/CargaBNDES";
import ConsultaConfia from "./containers/Confia/ConsultaConfia";
import ContratoConfia from "./containers/Confia/ContratoConfia";
import Lote from "./containers/Lote/Lote";

/*Alias*/
import Aprove from "./containers/Aprove/Aprove";
import FinancialList from "./containers/Financial/FinancialList";
import FinancialEdit from "./containers/Financial/FinancialEdit";
import FinancialUser from "./containers/Financial/FinancialUser";
import FinancialInvoices from "./containers/Financial/FinancialInvoices";
import FinancialInvoiceDetails from "./containers/Financial/FinancialInvoiceDetails";
import ChargingReport from "./containers/Charging/ChargingReport";
import ManagementReport from "./containers/ManagementReport/ManagementReport";
import ManagementReportError from "./containers/ManagementReportError/ManagementReportError";
import FinancialShipping from "./containers/FinancialShipping/FinancialShipping";
import FinancialDashboard from "./containers/FinancialDashboard/FinancialDashboard";
import News from "./containers/News/News";
import Configuracao from "./containers/Configuracao/Configuracao";
import valorDetranUf from "./containers/ValorDetranUf/ValorDetranUf";
import ContractLot from "./containers/ContractLot/ContractLot";
import Suporte from "./containers/Suporte/Suporte";
import StatusDetran from "./containers/Detran/StatusDetran";
import SearchUserList from "./containers/FinancialUserList/SearchUserList";
import FinanceiroPE from "./containers/FinanceiroPE/FinanceiroPE";
import Duda from "./containers/Duda/Duda";
import RelatorioDetranMA from "./containers/RelatorioDetranMA/RelatorioDetranMA";
import Integration from "./containers/Integration/Integration.js";
import LoteSNG from "./containers/LoteSNG/LoteSNG";
import ConfiguracaoSng from "./containers/ConfiguracaoSng/ConfiguracaoSng.js";
//import ConsultaSNG from "./containers/ConsultaSNG/ConsultaSNG.js";
import AcompanhamentoSng from "./containers/ConfiguracaoSng/AcompanhamentoSng.js";
import UserAlias from "./containers/UserAlias/UserAlias.js";
import Spreadsheet from "./containers/Spreadsheet/Spreadsheet.js";
import ConfiguracaoUf from "./containers/ConfiguracaoUf/ConfiguracaoUf.js";
import ErroDetranEmail from "./containers/ErroDetranEmail/ErroDetranEmail.js";
/*PR*/
import FinancialReportPR from "./containers/Financial/PR/FinancialReportPR";

/*Detran*/
import Supervision from "./containers/Supervision/Supervision";

import NotFound from "./containers/Pages/NotFound";

// build the router
const router = (
  <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
    <Route path="/" component={HomeApp}>
      <IndexRoute component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/forgot" component={ForgotPassword} />
    </Route>

    <Route name="Início" path="/secure" component={App}>
      <Route component={PrivateRoute}>
        <IndexRoute name="Painel de Gestão" component={Home} />
        <Route name="Contratos" staticName={true} path="/secure/contracts" component={ContractList} />
        <Route name="Contratos" staticName={true} path="/secure/contracts/:slug" component={ContractList} />
        <Route name="Contratos" staticName={true} path="/secure/contracts/:slug/:dataI/:dataF" component={ContractList} />
        <Route name="Contratos" staticName={true} path="/secure/contracts/:slug/:dataI" component={ContractList} />
        <Route name="Registro de Contrato" staticName={true} path="/secure/contract-annotation/:slug" component={Contract} />
        <Route name="Registro de Contrato" staticName={true} path="/secure/contract" component={Contract} />
        <Route name="Registro de Contrato" staticName={true} path="/secure/contract/:slug" component={Contract} />
        <Route name="Registro de Contrato" staticName={true} path="/secure/contract/register/:register" component={Contract} />
        <Route name="Visualização de Contrato" staticName={true} path="/secure/contract/view/:slug" component={ContractView} />
        <Route name="Perfil do Usuário" path="/secure/profile" component={Profile} />
        <Route name="Usuários" path="/secure/users" component={UserList} />
        <Route name="Log de Acesso" staticName={true} path="/secure/userLogAcess/:slug" component={UserLogAcess} />
        <Route name="Financeiro" path="/secure/invoices" component={InvoiceList} />
        <Route name="Financeiro" path="/secure/invoices">
          <Route name="Fatura" staticName={true} path="/secure/invoicedetail/:slug" component={InvoiceDetails} />
        </Route>
        <Route name="Upload de Imagens em Lote" staticName={true} path="/secure/FilesUpload" component={FilesUpload} />
        <Route name="Lotes de Contratos" staticName={true} path="/secure/contractLot" component={ContractLot} />
        <Route name="Vínculo de Perfil Funcionalidade" staticName={true} path="/secure/financialProfileFeatures/:slug" component={FinancialProfileFeatures} />
        <Route name="Cadastro de Perfis da Financeira" staticName={true} path="/secure/financialProfile/:slug" component={FinancialProfile} />
        <Route name="Relatório Gerencial" staticName={true} path="/secure/management-report" component={ManagementReport} />
        <Route name="Relatório de comunicação com o Detran" staticName={true} path="/secure/management-report-error" component={ManagementReportError} />
        <Route name="Usuários" staticName={true} path="/secure/userLogAudit/:slug" component={UserLogAudit} />
        <Route name="Usuários" staticName={true} path="/secure/detailUserLogAudit/:slug" component={DetailUserLogAudit} />
        <Route name="Carga" staticName={true} path="/secure/cargaBNDES" component={CargaBNDES} />
        <Route name="Contratos Para Assinatura" staticName={true} path="/secure/ConsultaConfia" component={ConsultaConfia} />
        <Route name="Novo Contrato Para Assinatura" staticName={true} path="/secure/ContratoConfia" component={ContratoConfia} />
        <Route name="Lote de Arquivos" staticName={true} path="/secure/lote" component={Lote} />
        <Route name="Integrações" staticName={true} path="/secure/integration" component={Integration} />
        <Route name="Lote de Arquivos SNG" staticName={true} path="/secure/loteSNG" component={LoteSNG} />
        {/* <Route name="Consulta de Emissão de CRV" staticName={true} path="/secure/consultaSNG" component={ConsultaSNG} /> */}
        <Route name="Configuração SNG" staticName={true} path="/secure/configuracaoSng" component={ConfiguracaoSng} />
        <Route name="Processamento SNG" staticName={true} path="/secure/acompanhamentoSng" component={AcompanhamentoSng} />
        {/*<Route name="Taxas Detran BA" staticName={true} path="/secure/taxasDetran" component={TaxasDetran}/>*/}
      </Route>
    </Route>

    <Route name="Início" path="/alias" component={App}>
      <Route component={PrivateRoute}>
        <IndexRoute name="Dashboard Financeiras" component={FinancialDashboard} />
        <Route name="Homologar Registro" staticName={true} path="/alias/aprove/:dataI/:credor/:uf" component={Aprove} />
        <Route name="Homologar Registro" staticName={true} path="/alias/aprove/:dataI/:dataF/:credor/:uf" component={Aprove} />
        <Route name="Homologar Registro" staticName={true} path="/alias/aprove" component={Aprove} />
        <Route name="Contrato" staticName={true} path="/alias/contract/view/:slug" component={ContractView} />
        <Route name="Vínculo de Perfil Funcionalidade" staticName={true} path="/alias/financialProfileFeatures/:slug" component={FinancialProfileFeatures} />
        <Route name="Cadastro de Perfis da Financeira" staticName={true} path="/alias/financialProfile/:slug" component={FinancialProfile} />
        <Route name="Log de Acesso" staticName={true} path="/alias/userLogAcess/:slug" component={UserLogAcess} />
        <Route name="Financeiras" path="/alias/financial" component={FinancialList} />
        <Route name="Financeiras" path="/alias/financial">
          <Route name="Cadastro de Financeira" path="/alias/financialEdit" component={FinancialEdit} />
          <Route name="Cadastro de Financeira" staticName={true} path="/alias/financialEdit/:slug" component={FinancialEdit} />
          <Route name="Cadastro de Usuários da Financeira" staticName={true} path="/alias/financialUser/:slug" component={FinancialUser} />
          <Route name="Cadastro de Usuários da Financeira" staticName={true} path="/alias/financialUser/:slug/:usuario" component={FinancialUser} />
          <Route name="Faturamentos para Financeira" staticName={true} path="/alias/financialInvoices/:slug" component={FinancialInvoices} />
          <Route name="Faturamento para Financeira" staticName={true} path="/alias/financialInvoiceDetails/:slug" component={FinancialInvoiceDetails} />
        </Route>
        <Route name="Usuários" staticName={true} path="/alias/userLogAudit/:slug" component={UserLogAudit} />
        <Route name="Usuários" staticName={true} path="/alias/detailUserLogAudit/:slug" component={DetailUserLogAudit} />

        <Route name="Boletos" staticName={true} path="/alias/charging-report" component={ChargingReport} />

        <Route name="Boletos" staticName={true} path="/alias/financial-report" component={FinancialReportPR} />
        <Route name="Relatório Detran MA" staticName={true} path="/alias/relatorioDetranMA" component={RelatorioDetranMA} />

        <Route name="Relatório Gerencial" staticName={true} path="/alias/management-report" component={ManagementReport} />

        <Route name="Relatório de comunicação com o Detran" staticName={true} path="/alias/management-report-error" component={ManagementReportError} />

        <Route name="Remessa Financeira" staticName={true} path="/alias/financial-shipping" component={FinancialShipping} />

        <Route name="Cadastro Novidades" staticName={true} path="/alias/news" component={News} />

        <Route name="Taxas Detran BA" staticName={true} path="/alias/taxasDetran" component={TaxasDetran} />

        <Route name="Lote RJ" staticName={true} path="/alias/loteRJ" component={LoteRJ} />
        <Route name="Consulta Contrato no Detran" staticName={true} path="/alias/consultaChassiPR" component={ConsultaChassiPR} />
        <Route name="Faturamento" staticName={true} path="/alias/faturamento" component={Faturamento} />
        <Route name="Financeiro" path="/alias/faturamento">
          <Route name="Financeiro" staticName={true} path="/alias/invoiceDetails/:slug/:sitConc" component={InvoiceDetails} />
        </Route>

        <Route name="Configurações do Sistema" staticName={true} path="/alias/configuracoes" component={Configuracao} />
        <Route name="Cadastrar Valores Detran" staticName={true} path="/alias/valorDetranUf" component={valorDetranUf} />
        <Route name="Usuários de Suporte" staticName={true} path="/alias/suporte" component={Suporte} />
        <Route name="Status dos Detrans" staticName={true} path="/alias/statusDetran" component={StatusDetran} />
        <Route name="Consulta de usuários" staticName={true} path="/alias/searchUserList" component={SearchUserList} />
        <Route name="Consulta Financeira" staticName={true} path="/alias/FinanceiroPE" component={FinanceiroPE} />
        <Route name="Gestão de Dudas RJ" staticName={true} path="/alias/duda" component={Duda} />
        <Route name="Consulta de usuários" staticName={true} path="/alias/userAlias" component={UserAlias} />
        <Route name="Consulta Planilhas" staticName={true} path="/alias/spreadsheet" component={Spreadsheet} />
        <Route name="Configurar UF" staticName={true} path="/alias/configuracaoUf" component={ConfiguracaoUf} />
        <Route name="Cadastrar Erros" staticName={true} path="/alias/errosDetran" component={ErroDetranEmail} />
      </Route>
    </Route>

    <Route name="Início" path="/sdetran" component={App}>
      <Route component={PrivateRoute}>
        <IndexRoute name="Download de Imagens" component={Supervision} />
        <Route name="Contrato" staticName={true} path="/sdetran/contract/view/:slug" component={ContractView} />
      </Route>
    </Route>

    <Route path="*" component={NotFound} />
  </Router>
);

// export
export { router };
