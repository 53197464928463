import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError, formValueSelector } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";

import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import DropdownListField from "../../components/Fields/DropdownListField";
import api from "../../api/apiUtil";
import { toastr } from "react-redux-toastr";
import SvgIcon from "react-icons-kit";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import MaskDecimal from "../../components/Util/MaskDecimal";
import MaskDate from "../../components/Util/MaskDate";
import fileDownload from "js-file-download";

import { SPREADSHEET_ALL_COMPANIES_LOAD, SPREADSHEET_UNLOAD, SPREADSHEET_VISAO_FINANCEIRA_LOAD } from "../../actions/Constants";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import { BeatLoader } from "react-spinners";

let valorTotal = 0;
let qtdTotal = 0;

class Spreadsheet extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
      gerandoVisaoGeral: false,
    };
  }

  componentDidMount() {
    if (this.props.currentUser.tipo && this.props.currentUser.tipo.includes("P")) {
      Promise.resolve(api.Financial.filtroPorUf(null, this.props.currentUser.idParceiro)).then((all) => {
        this.props.onCompaniesLoad(all);
      });
    } else {
      this.props.onCompaniesLoad(api.Companies.all());
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  formSubmit(values) {
    let params = values;
    const errors = this.validateValues(params);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else {
      if (this.datasInvalidas(params)) {
        toastr.warning("Atenção", "Só é possível filtrar no máximo um período de 12 meses!");
        this.props.onLoadVisaoFinanceira(null);
      } else {
        Promise.resolve(api.Comercial.visaoFinanceira(params.credor.id, MaskDate.TO_DATABASE_DATE(params.dtInicial), MaskDate.TO_DATABASE_DATE(params.dtFinal)))
          .then((res) => {
            this.props.onLoadVisaoFinanceira(res);
          })
          .catch(function (resp) {
            if (resp === "Unauthorized" || resp === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
            }
          });
      }
    }
  }

  datasInvalidas = (params) => {
    // Converta as strings em objetos de data
    const data1 = new Date(MaskDate.TO_DATABASE_DATE(params.dtInicial));
    const data2 = new Date(MaskDate.TO_DATABASE_DATE(params.dtFinal));
    // Calcule a diferença em milissegundos entre as duas datas
    const diferencaEmMilissegundos = Math.abs(data1 - data2);

    // Calcule o número de milissegundos em um ano
    const milissegundosEmUmAno = 365 * 24 * 60 * 60 * 1000;

    // Verifique se a diferença é maior do que um ano
    if (diferencaEmMilissegundos > milissegundosEmUmAno) {
      return true;
    } else {
      return false;
    }
  };

  validateValues(values) {
    const errors = {};
    if (!values) {
      errors.credor = "Campo obrigatório";
      errors.dtInicial = "Campo obrigatório";
      errors.dtFinal = "Campo obrigatório";
    } else {
      if (!values.credor || values.credor.length === 0) {
        errors.credor = "Campo obrigatório";
      }
      if (!values.dtInicial || values.dtInicial.length === 0) {
        errors.dtInicial = "Campo obrigatório";
      }
      if (!values.dtFinal || values.dtFinal.length === 0) {
        errors.dtFinal = "Campo obrigatório";
      }
    }
    return errors;
  }

  exportToCSV = () => {
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    this.setState({ gerandoVisaoGeral: true });
    Promise.resolve(api.Comercial.planilhaAnual())
      .then((res) => {
        fileDownload(res, "planilhaAnual.xlsx");
        this.setState({ gerandoVisaoGeral: false });
        toastr.removeByType("info");
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  exportToCSVFilter = (values) => {
    let params = values;
    const errors = this.validateValues(params);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else {
      if (this.datasInvalidas(params)) {
        toastr.warning("Atenção", "Só é possível filtrar no máximo um período de 12 meses!");
        this.props.onLoadVisaoFinanceira(null);
      } else {
        Promise.resolve(api.Comercial.planilhaAnualFiltrada(params.credor.id, MaskDate.TO_DATABASE_DATE(params.dtInicial), MaskDate.TO_DATABASE_DATE(params.dtFinal)))
          .then((res) => {
            fileDownload(res, "planilhaAnual.xlsx");
          })
          .catch(function (resp) {
            if (resp === "Unauthorized" || resp === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else {
              toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
            }
          });
      }
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="" />
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-body">
                  <form className="form-horizontal">
                    <div className="panel-body h-100">
                      <div className="row m-b-sm">
                        <div className="col-md-12">
                          <div className="stats-title float-left">
                            {" "}
                            <h3>VISÃO GERAL FINANCEIRAS</h3>{" "}
                          </div>
                          <div className="text-right">
                            <button disabled={this.state.gerandoVisaoGeral} className="btn btn-info btn-sm" onClick={() => this.exportToCSV()} title="Gerar XLS">
                              {this.state.gerandoVisaoGeral ? "Aguarde..." : "Gerar Visão Geral"}
                              <SvgIcon icon={fileExcelO} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <Field
                            name="credor"
                            blank={true}
                            label="Credor"
                            data={this.props.companies}
                            textField="razaoSocial"
                            credor={true}
                            valueField="cnpj2"
                            component={DropdownListField}
                            type="text"
                            required
                          />
                        </div>
                        <div className="col-md-2">
                          <Field
                            name="dtInicial"
                            label="Data de início"
                            validate={[FieldValidation.date]}
                            normalize={FieldNormalize.DATE}
                            placeholder="00/00/0000"
                            data={this.props.dtInicial}
                            component={TextDatePickerField}
                            type="text"
                            required
                            inline={false}
                          />
                        </div>
                        <div className="col-md-2">
                          <Field
                            name="dtFinal"
                            label="Data de fim"
                            validate={[FieldValidation.date]}
                            normalize={FieldNormalize.DATE}
                            placeholder="00/00/0000"
                            data={this.props.dtFinal}
                            component={TextDatePickerField}
                            type="text"
                            required
                            inline={false}
                          />
                        </div>
                      </div>
                      <div className="text-right">
                        <button disabled={this.state.pesquisando} className="btn btn-success" onClick={this.props.handleSubmit(this.formSubmit.bind(this))}>
                          {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                        </button>
                        <Link className="btn btn-info" onClick={this.props.handleSubmit(this.exportToCSVFilter.bind(this))}>
                          {" "}
                          <SvgIcon icon={fileExcelO} />
                        </Link>
                      </div>
                    </div>
                  </form>
                  <br />
                  <div className="panel-body">
                    <div className="table-responsive">
                      {this.props.allVisao && this.props.allVisao.length > 0 ? (
                        <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th className="text-center align-middle">UF</th>
                              <th className="text-center align-middle">Quantidade</th>
                              <th className="text-center align-middle">Valor Total</th>
                            </tr>
                          </thead>
                          <tbody>{this.renderAllVisao()}</tbody>
                          <tfoot>
                            <tr>
                              <th style={{ textAlign: "left" }} className="text-center align-middle">
                                Total
                              </th>
                              <th className="text-center align-middle">{qtdTotal}</th>
                              <th className="text-center align-middle">{MaskDecimal.TO_BRL(valorTotal)}</th>
                            </tr>
                          </tfoot>
                        </table>
                      ) : (
                        <small>Nenhum Registro Encontrado</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAllVisao() {
    valorTotal = 0;
    qtdTotal = 0;
    if (!this.props.allVisao || this.props.allVisao.length == 0) {
      return null;
    }
    return this.props.allVisao.map((financial, index) => {
      valorTotal = valorTotal + parseFloat(financial.vlTotal ? financial.vlTotal : 0);
      qtdTotal = qtdTotal + financial.qtd;
      return (
        <tr key={index}>
          <td className="text-center align-middle">{financial.uf}</td>
          <td className="text-center align-middle">{financial.qtd}</td>
          <td className="text-center align-middle">{MaskDecimal.TO_BRL(financial.vlTotal)}</td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadVisaoFinanceira: (payload) => dispatch({ type: SPREADSHEET_VISAO_FINANCEIRA_LOAD, payload }),
  onUnload: () => dispatch({ type: SPREADSHEET_UNLOAD }),
  onCompaniesLoad: (payload) => dispatch({ type: SPREADSHEET_ALL_COMPANIES_LOAD, payload }),
});

const selector = formValueSelector("SpreadsheetForm");
const mapStateToProps = (state) => ({
  ...state.spreadsheet,
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "SpreadsheetForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Spreadsheet));
