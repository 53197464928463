import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, untouch, SubmissionError } from "redux-form";
import _ from "lodash";
import Title from "../../components/Layout/Title";
import { Link } from "react-router";
import { SUPORTE_LOAD, SUPORTE_UNLOAD, SUPORTE_ALL_COMPANIES_LOAD, SUPORTE_LOAD_UFS, SUPORTE_UNLOAD_UFS } from "../../actions/Constants";
import api from "../../api/apiUtil";
import MaskCNPJ from "../../components/Util/MaskCNPJ";
import MaskCPF from "../../components/Util/MaskCPF";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import TextField from "../../components/Fields/TextField";
import DropdownListField from "../../components/Fields/DropdownListField";
import { toastr } from "react-redux-toastr";
import FieldValidation from "../../components/Validation/FieldValidation";
import MultiSelectField from "../../components/Fields/MultiSelectField";
class Suporte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    this.props.onListLoad(api.Users.getUsuariosSuporte());
    this.props.onCompaniesLoad(api.Companies.all());
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  editUsuario = (value) => {
    this.setState({ user: value });
    this.props.dispatch(change("SuporteForm", "cpf", value.cpf));
    this.props.dispatch(change("SuporteForm", "credor", this.props.allCompanies.filter((c) => c.id === value.idFinanceira)[0]));
    this.props.dispatch(change("SuporteForm", "ufs", value.ufs));
    this.props.onLoadUf(api.Financial.ufsCadastradas(value.idFinanceira));
  };

  handleOnCancel = () => (ev) => {
    this.setState({ user: null });
    this.props.dispatch(change("SuporteForm", "cpf", null));
    this.props.dispatch(untouch("SuporteForm", "cpf"));
    this.props.dispatch(change("SuporteForm", "credor", null));
    this.props.dispatch(untouch("SuporteForm", "credor"));
    this.props.dispatch(change("SuporteForm", "ufs", null));
    this.props.dispatch(untouch("SuporteForm", "ufs"));
  };

  formSubmit(values) {
    const errors = {};
    if (values.credor == undefined || (!values.credor.id && values.credor.id != 0)) {
      errors.credor = "Campo obrigatório";
    }

    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    }

    var props = this.props;
    var user = {
      id: this.state.user.id,
      idFinanceira: values.credor.id ? values.credor.id : values.credor,
      ufs: [],
    };

    if (!values.ufs || values.ufs.length == 0) {
      _.forEach(this.props.ufs, function (uf) {
        user.ufs = [uf.uf, ...user.ufs];
      });
    } else {
      _.forEach(values.ufs, function (uf) {
        user.ufs = [uf.uf, ...user.ufs];
      });
    }

    Promise.resolve(api.Users.updateUsuarioSuporte(user))
      .then((ret) => {
        toastr.success("Sucesso", "Usuário alterado");
        this.props.onListLoad(api.Users.getUsuariosSuporte());
        this.setState({ user: null });
        this.props.reset();
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", resp.response.body.message);
        }
      });
  }

  validateValues(values) {
    const errors = {};
    if (values.credor == undefined || (!values.credor.id && values.credor.id != 0)) {
      errors.credor = "Campo obrigatório";
    }
    return errors;
  }

  preparaUFs(event) {
    this.props.dispatch(change("SuporteForm", "ufs", null));
    this.props.onLoadUf(api.Financial.ufsCadastradas(event.id));
  }

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Cofiguração usuários de suporte" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">Registros</div>
                {this.state.user && (
                  <div className="panel-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                      <div className="row">
                        <div className="col-md-3">
                          <Field name="cpf" label="CPF" normalize={FieldNormalize.CPF} component={TextField} type="text" disabled={true} />
                        </div>
                        <div className="col-md-7">
                          <Field
                            name="credor"
                            label="Credor"
                            data={this.props.allCompanies}
                            credor={true}
                            required
                            textField="razaoSocialCnpj"
                            valueField="id"
                            component={DropdownListField}
                            type="text"
                            onChange={this.preparaUFs.bind(this)}
                          />
                        </div>
                        <div className="col-md-2">
                          <Field name="ufs" label="UF (Vazio para todas)" data={this.props.ufs} component={MultiSelectField} type="text" textField="uf" valueField="uf" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="text-right">
                            <Link className="btn btn-default" onClick={this.handleOnCancel()}>
                              {" "}
                              Cancelar
                            </Link>
                            <button className="btn btn-info" onClick={handleSubmit(this.formSubmit.bind(this))}>
                              Alterar
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
              <div className="panel-body">
                <div className="table-responsive">
                  {this.props.all && this.props.all.length > 0 ? (
                    <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className="text-center align-middle">Cpf</th>
                          <th className="text-center align-middle">Email</th>
                          <th className="text-center align-middle">Financeira</th>
                          <th className="text-center align-middle">Ações</th>
                        </tr>
                      </thead>
                      <tbody>{this.renderUsuarios()}</tbody>
                    </table>
                  ) : (
                    "Nenhum registro encontrado."
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUsuarios() {
    return this.props.all.map((r, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{MaskCPF.TO_CPF(r.cpf)}</td>
          <td className="text-center align-middle">{r.email}</td>
          <td className="text-center align-middle">{`${MaskCNPJ.TO_CNPJ(r.cnpj)} - ${r.razaoSocial}`}</td>
          <td className="align-middle text-center">
            <div className="btn-group">
              <button type="button" className="btn btn-sm btn-default" onClick={() => this.editUsuario(r)}>
                <i className="far fa-edit" title="Editar"></i>
              </button>
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: SUPORTE_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: SUPORTE_UNLOAD, payload }),
  onCompaniesLoad: (payload) => dispatch({ type: SUPORTE_ALL_COMPANIES_LOAD, payload }),
  onLoadUf: (payload) => dispatch({ type: SUPORTE_LOAD_UFS, payload }),
});

const mapStateToProps = (state) => ({
  ...state.suporte,
  currentUser: state.common.currentUser,
  companies: state.common.companies,
});

const form = reduxForm({
  form: "SuporteForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Suporte));
