import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, SubmissionError, change, untouch } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import { DUDA_LOAD, DUDA_UNLOAD, DUDA_FILES_TO_SEND, DUDA_FILE_SENT, DUDA_LOT_CLOSED } from "../../actions/Constants";

import api from "../../api/apiUtil";
import ReactTable from "react-table";
import SelectField from "../../components/Fields/SelectField";
import TextField from "../../components/Fields/TextField";
import Dropzone from "react-dropzone";
import fileDownload from "js-file-download";
import moment from "moment";
import { BeatLoader } from "react-spinners";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

registerPlugin(FilePondPluginFileValidateType);
class Duda extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
    };
    document.addEventListener("keyup", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    if (event.keyCode === 13 && document.getElementById("filterDuda")) {
      document.getElementById("filterDuda").click();
    }
  };

  componentDidMount() {
    this.props.onDudaLoad(api.Duda.recuperaDuda());
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyDownHandler);
    this.props.onDudaUnload();
  }

  cadastrar = (values) => {
    const errors = this.validateValues(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else if (values.dudaPaga === "N" && (!values.linhaDigitavel || values.linhaDigitavel.length === 0)) {
      toastr.warning("Atenção", "É obrigatório informar a linha digitável quando a duda não está paga.");
    } else {
      var props = this.props;
      Promise.resolve(api.Duda.insereDuda(values))
        .then((ret) => {
          this.props.onDudaLoad(api.Duda.recuperaDuda());
          this.clearForm();
          toastr.success("Sucesso", "Duda cadastrada.");
        })
        .catch(function (resp) {
          if (resp.status === 403) {
            toastr.warning("Atenção", resp.response.body.message);
          } else if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  };

  filtrar = (values) => {
    console.log(values);
    var props = this.props;
    Promise.resolve(api.Duda.recuperaDuda(values.findNrDuda, values.findChassi, values.findDudaUsada))
      .then((ret) => {
        this.props.onDudaLoad(ret);
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  validateValues(values) {
    const errors = {};
    if (!values.numeroDuda || values.numeroDuda.length === 0) {
      errors.numeroDuda = "Campo obrigatório";
    }
    if (!values.dudaPaga || values.dudaPaga.length === 0) {
      errors.dudaPaga = "Campo obrigatório";
    }
    return errors;
  }

  clearForm = () => {
    this.props.dispatch(change("DudaForm", "numeroDuda", null));
    this.props.dispatch(untouch("DudaForm", "numeroDuda"));
    this.props.dispatch(change("DudaForm", "dudaPaga", null));
    this.props.dispatch(untouch("DudaForm", "dudaPaga"));
    this.props.dispatch(change("DudaForm", "linhaDigitavel", null));
    this.props.dispatch(untouch("DudaForm", "linhaDigitavel"));
  };

  delete = (id) => {
    var props = this.props;
    Promise.resolve(api.Duda.delete(id))
      .then((ret) => {
        this.props.onDudaLoad(api.Duda.recuperaDuda());
        toastr.success("Sucesso", "Duda excluída");
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  desvincular = (id) => {
    var props = this.props;
    Promise.resolve(api.Duda.desvincular(id))
      .then((ret) => {
        this.props.onDudaLoad(api.Duda.recuperaDuda());
        toastr.success("Sucesso", "Duda desvinculada do chassi.");
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  async handleGeracaoRemessa() {
    var props = this.props;
    await Promise.resolve(api.Duda.gerarArquivoRemessa240BB())
      .then((response) => {
        this.props.onDudaLoad(api.Duda.recuperaDuda());
        fileDownload(response, `REMRJ-${moment(new Date()).format("DDMMYYYYHHmm")}.TXT`);
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else if (resp.response && resp.response.statusCode === 406) {
          toastr.warning("Atenção", "Não existem dudas para gerar remessa.");
        } else {
          toastr.error("Erro", resp.message);
        }
      });
  }

  async handleUploadRemessa(filesToUpload) {
    var props = this.props;
    toastr.info("Aguarde", "Processando arquivo...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    Promise.resolve(api.Duda.gravarAquivoRetorno240bb(filesToUpload[0]))
      .then((response) => {
        this.props.onDudaLoad(api.Duda.recuperaDuda());
        toastr.removeByType("info");
        toastr.success("Sucesso", "Arquivo de remessa processado.");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.removeByType("info");
          toastr.warning("Atenção", resp.response.body);
        }
      });
  }

  processHandler = (fieldName, file, metadata, load, error) => {
    Promise.resolve(api.Duda.sendLotAnexoDuda(file))
      .then((response) => {
        this.props.onFileSent();
        load(1);
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else if (resp.response && resp.response.status === 400) {
          error(JSON.parse(resp.response.text).message);
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  serverOptions = {
    process: this.processHandler,
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <form className="form-horizontal">
                <div className="hpanel">
                  <div className="panel-heading">
                    <div className="panel-tools">
                      <Link
                        className="btn btn-xs btn-info"
                        onClick={() => {
                          this.handleGeracaoRemessa();
                        }}
                      >
                        <i className="fas fa-book" /> Gerar arquivo remessa
                      </Link>
                      <Dropzone accept=".RET" name="file" className="dropzone-blank fallback float-right" multiple={false} onDrop={(filesToUpload, e) => this.handleUploadRemessa(filesToUpload)}>
                        <button className="btn btn-xs btn-success" type="button">
                          <i className="fa fa-upload" /> Carregar arquivo de retorno
                        </button>
                      </Dropzone>
                    </div>
                    <br></br>
                  </div>
                  <div className="panel-body">
                    <div className="m-b-md">
                      <h5>Cadastrar Duda</h5>
                    </div>
                    <FilePond
                      allowMultiple={true}
                      labelIdle="Clique ou arraste os arquivos para upload"
                      labelFileProcessingComplete="Arquivo enviado"
                      labelFileLoading="Carregando"
                      labelFileProcessing="Enviando"
                      labelTapToCancel=""
                      allowRevert={false}
                      allowReplace={false}
                      files={this.props.files}
                      acceptedFileTypes={["application/pdf"]}
                      labelFileTypeNotAllowed="Tipo de arquivo inválido"
                      fileValidateTypeLabelExpectedTypes="Esperado um arquivo PDF"
                      labelFileProcessingError={(error) => {
                        return error.body;
                      }}
                      labelTapToRetry="Clique para tentar novamente"
                      onupdatefiles={(fileItems) => {
                        if (this.props.qttFilesToSend === this.props.sentFiles && fileItems.length > 0) {
                          this.props.onFilesToSend([fileItems.length, fileItems]);
                        }
                      }}
                      onprocessfile={(error, file) => {
                        if (this.props.sentFiles === this.props.qttFilesToSend) {
                          this.props.onLotClosed();
                          this.props.onDudaLoad(api.Duda.recuperaDuda());
                          toastr.success("Sucesso", "Lote Processado com sucesso");
                        }
                      }}
                      server={this.serverOptions}
                    />
                    <div className="row">
                      <div className="col-md-3">
                        <Field name="numeroDuda" label="Número" component={TextField} type="text" maxlength={17} required={true} normalize={FieldNormalize.NUMBER} />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="dudaPaga"
                          label="Paga"
                          blank={true}
                          data={[
                            { id: "N", name: "Não" },
                            { id: "S", name: "Sim" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                          required={true}
                        />
                      </div>
                      <div className="col-md-6">
                        <Field name="linhaDigitavel" label="Linha Digitável" component={TextField} type="text" maxlength={70} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <button className="btn btn-success" type="button" onClick={this.props.handleSubmit(this.cadastrar.bind(this))}>
                            Cadastrar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hpanel">
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-3">
                        <Field name="findNrDuda" label="Número" component={TextField} type="text" maxlength={17} />
                      </div>
                      <div className="col-md-3">
                        <Field name="findChassi" label="Chassi" component={TextField} type="text" maxlength={17} />
                      </div>
                      <div className="col-md-3">
                        <Field
                          name="findDudaUsada"
                          label="Utilizada"
                          blank={true}
                          data={[
                            { id: "N", name: "Não" },
                            { id: "S", name: "Sim" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div>
                      <div className="col-lg-2 align-self-end form-group">
                        <button className="btn btn-info" id="filterDuda" onClick={handleSubmit(this.filtrar.bind(this))}>
                          Filtrar
                        </button>
                      </div>
                    </div>

                    <hr></hr>
                    <div className="table-responsive">
                      <ReactTable
                        className="-striped -highlight"
                        defaultPageSize={20}
                        data={this.props.all}
                        loadingText="Carregando..."
                        noDataText="Nenhuma Informação encontrada"
                        rowsText="linhas"
                        showPageSizeOptions={false}
                        minRows={1}
                        showPagination={true}
                        previousText="Anterior"
                        nextText="Próximo"
                        pageText="página"
                        ofText="de"
                        columns={[
                          {
                            Header: "ID",
                            accessor: "id",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 100,
                          },
                          {
                            Header: "Número da Duda",
                            accessor: "numeroDuda",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 180,
                          },
                          {
                            Header: "Chassi",
                            accessor: "chassi",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 200,
                          },
                          {
                            Header: "Linha Digitável",
                            accessor: "rowLinhaDig",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            Cell: (rowLinhaDig) => {
                              return (
                                <div>
                                  <span>{rowLinhaDig.original.linhaDigitavel ? rowLinhaDig.original.linhaDigitavel : "Não informada"}</span>
                                  <br />
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Data Pagamento",
                            accessor: "rowData",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 120,
                            Cell: (rowData) => {
                              return (
                                <div>
                                  <span>{rowData.original.dataPagamento ? MaskDate.TO_BR(rowData.original.dataPagamento) : "Não possuí"}</span>
                                  <br />
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Remessa",
                            accessor: "rowRemessa",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 100,
                            Cell: (rowRemessa) => {
                              return (
                                <div>
                                  <span>{rowRemessa.original.idRemessa ? rowRemessa.original.idRemessa : "Não possuí"}</span>
                                  <br />
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Ação",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 80,
                            Cell: (row) => (
                              <div className="btn-group">
                                {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("F")) && (
                                  <div>
                                    <span>
                                      {row.original.chassi && !row.original.idContrato && (
                                        <Link className="btn btn-sm btn-default" onClick={() => this.desvincular(row.original.id)}>
                                          <i className="fas fa-reply" title="Desvincular"></i>
                                        </Link>
                                      )}
                                    </span>
                                    <span>
                                      {!row.original.chassi && !row.original.idRemessa && (
                                        <Link className="btn btn-sm btn-default" onClick={() => this.delete(row.original.id)}>
                                          <i className="fas fa-trash" title="Excluir"></i>
                                        </Link>
                                      )}
                                    </span>
                                  </div>
                                )}
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderList() {
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((valor, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{valor.id}</td>
          <td className="text-center align-middle">{valor.numeroDuda}</td>
          <td className="text-center align-middle">{valor.chassi}</td>
          <td className="text-center align-middle">{valor.linhaDigitavel ? valor.linhaDigitavel : "Não informada"}</td>
          <td className="text-center align-middle">{valor.dataPagamento ? MaskDate.TO_BR(valor.dataPagamento) : "Não possuí"}</td>
          <td className="text-center align-middle">{valor.remessa ? valor.remessa : "Não possuí"}</td>
          <td className="text-center align-middle text-center">
            <div className="btn-group">
              <Link className="btn btn-sm btn-default" onClick={() => this.delete(valor.id)}>
                <i className="fas fa-trash" title="Excluir"></i>
              </Link>
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onDudaLoad: (payload) => dispatch({ type: DUDA_LOAD, payload }),
  onDudaUnload: (payload) => dispatch({ type: DUDA_UNLOAD, payload }),
  onFilesToSend: (payload) => dispatch({ type: DUDA_FILES_TO_SEND, payload }),
  onFileSent: (payload) => dispatch({ type: DUDA_FILE_SENT }),
  onLotClosed: (payload) => dispatch({ type: DUDA_LOT_CLOSED }),
});

const selector = formValueSelector("DudaForm");
const mapStateToProps = (state) => ({
  ...state.duda,
  currentUser: state.common.currentUser,
  selectedDudaPaga: selector(state, "dudaPaga"),
});

const form = reduxForm({
  form: "DudaForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Duda));
