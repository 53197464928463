import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, SubmissionError } from "redux-form";
import { Link } from "react-router";

import FieldNormalize from "../../components/Normalize/FieldNormalize";
import MaskDecimal from "../../components/Util/MaskDecimal";
import SelectField from "../../components/Fields/SelectField";
import FieldValidation from "../../components/Validation/FieldValidation";
import TextField from "../../components/Fields/TextField";
import UfField from "../UfFields/UfField";
import CityField from "../UfFields/CityField";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import { fileText2 } from "react-icons-kit/icomoon/fileText2";
import SvgIcon from "react-icons-kit";

import { FINANCIAL_LIST_LOAD, UF_SYSTEM_LOAD } from "../../actions/Constants";
import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";
import ReactTable from "react-table";

import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import { gear } from "react-icons-kit/fa/gear";
import { checkCircle } from "react-icons-kit/fa/checkCircle";
import MultiSelect from "@kenshooui/react-multi-select";
import { BeatLoader } from "react-spinners";
import { toastr } from "react-redux-toastr";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import MaskDate from "../../components/Util/MaskDate";
class FinancialList extends React.Component {
  constructor() {
    super();
    var selectTable;
    this.handleChange = this.handleChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.state = {
      items: [
        { id: 0, label: "Razão Social" },
        { id: 1, label: "Cnpj" },
        { id: 2, label: "Número do Contrato" },
        { id: 4, label: "Valor ERC - Registradora" },
        { id: 5, label: "Responsável" },
        { id: 6, label: "Inscrição Estadual" },
        { id: 7, label: "Tipo" },
        { id: 8, label: "Nome Fantasia" },
        { id: 9, label: "Início do contrato" },
        { id: 3, label: "Término do contrato" },
        { id: 35, label: "Data da criação" },
        { id: 10, label: "Código do Credor" },
        { id: 11, label: "Telefone" },
        { id: 12, label: "UF" },
        { id: 13, label: "CEP" },
        { id: 14, label: "Endereço" },
        { id: 15, label: "Número Endereço" },
        { id: 16, label: "Complemento" },
        { id: 17, label: "Bairro" },
        { id: 18, label: "Tipo de Pagamento" },
        { id: 19, label: "Periodicidade Alias" },
        { id: 20, label: "Integração Externa" },
        { id: 21, label: "Utiliza API" },
        { id: 22, label: "Periodicidade Detran" },
        { id: 23, label: "Agendamento Aut. Fat." },
        { id: 24, label: "Valor Detran" },
        { id: 25, label: "Valor Alteração Detran" },
        { id: 26, label: "Valor Alteração ERC - Registradora" },
        { id: 27, label: "CPF Responsável" },
        { id: 28, label: "Email Responsável" },
        { id: 29, label: "Telefone Responsável" },
        { id: 30, label: "Função Responsável" },
        { id: 31, label: "Operadora" },
        { id: 32, label: "Correspondente" },
        { id: 33, label: "Nome do Agrupamento" },
        { id: 34, label: "Município" },
      ],
      selectedItems: [
        { id: 0, label: "Razão Social" },
        { id: 1, label: "Cnpj" },
        { id: 2, label: "Número do Contrato" },
        { id: 3, label: "Término do contrato" },
        { id: 4, label: "Valor ERC - Registradora" },
        { id: 5, label: "Responsável" },
      ],
      showToggle: false,
      all: false,
      data: [],
      pesquisando: false,
    };
    document.addEventListener("keyup", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    if (event.keyCode === 13 && document.getElementById("filterFin")) {
      document.getElementById("filterFin").click();
    }
  };

  componentWillMount() {
    this.props.onListLoad(api.Financial.allClustered());
    Promise.resolve(api.Users.reportFields(this.props.currentUser.id)).then((fields) => {
      if (Object.keys(fields).length > 0 && fields.camposRelatorioFinanceira && fields.camposRelatorioFinanceira.length > 0) {
        this.handleLoad(fields.camposRelatorioFinanceira);
      }
    });

    Promise.resolve(api.Ufs.sigla_system()).then((all) => {
      var todas = {
        sigla: "Todas UFs",
      };
      all.unshift(todas);
      this.props.onUfSystemLoad(all);
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyDownHandler);
  }

  exportToXLSEmailsFat = () => {
    let head = [["Razão Social", "CNPJ", "E-mails Faturamento"]];
    let ref = ["razaoSocial", "cnpj", "email"];
    let fileName = "RelatorioEmailsFaturamento";
    let data = [];

    for (const item of this.props.all) {
      if (!item.financeirasAgrupadas || item.financeirasAgrupadas.length === 0) {
        if (item.emailsFaturamento) {
          let arrayEmails = item.emailsFaturamento.split(";").filter((i) => i);
          for (const email of arrayEmails) {
            data.push({
              razaoSocial: item.razaoSocial,
              cnpj: item.cnpjFormat,
              email: email.trim(),
            });
          }
        } else {
          data.push({
            razaoSocial: item.razaoSocial,
            cnpj: item.cnpjFormat,
            email: "",
          });
        }
      } else {
        for (const itemAgrupado of item.financeirasAgrupadas) {
          if (itemAgrupado.emailsFaturamento) {
            let arrayEmails = itemAgrupado.emailsFaturamento.split(";").filter((i) => i);
            for (const email of arrayEmails) {
              data.push({
                razaoSocial: itemAgrupado.razaoSocial,
                cnpj: itemAgrupado.cnpjFormat,
                email: email.trim(),
              });
            }
          } else {
            data.push({
              razaoSocial: itemAgrupado.razaoSocial,
              cnpj: itemAgrupado.cnpjFormat,
              email: "",
            });
          }
        }
      }
    }

    var ws = XLSX.utils.aoa_to_sheet(head);
    XLSX.utils.sheet_add_json(ws, data, {
      header: ref,
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${fileName}.xlsx`);
  };

  exportToXLS = () => {
    var Heading = [
      [
        "Nome",
        "CNPJ",
        "Número do Contrato",
        "Data da Vigência",
        "Valor da Taxa",
        "Endereço",
        "Nº Endereço",
        "Complemento",
        "Bairro",
        "Cód. Município",
        "Município",
        "UF",
        "CEP",
        "DDD",
        "Telefone",
        "Period. Faturamento",
        "Tipo",
        "Valor Taxa ERC",
        "Cobrança Auto",
        "Period. Alias",
        "Insc Estad",
        "Nome Fant",
        "Obs",
        "Resp",
        "CPF Resp",
        "E-mail Resp",
        "DDD Resp",
        "Telefone Resp",
        "Data da Assinatura",
        "Data da Criação",
        "Status",
        "Valor Taxa Alt ERC",
        "Valor Taxa Alt",
        "Tipo de Cobrança",
        "Função Resp",
        "Integração Externa",
        "Utiliza API",
        "Nome do Agrupamento",
        "Correspondente",
        "Operadora",
        "Código do Credor",
        "Email(s) Faturamento",
      ],
    ];
    var Data = [];

    this.props.all.map((r, index) => {
      if (!r.financeirasAgrupadas || r.financeirasAgrupadas.length === 0) {
        Data.push({
          nome: r.razaoSocial,
          cnpj: r.cnpjFormat,
          nrContrato: r.numContrato,
          dtVigencia: r.vigenciaFormat,
          valorTaxa: r.valorTaxa,
          endereco: r.endereco,
          endNumero: r.endNumero,
          endComplemento: r.endComplemento,
          endBairro: r.endBairro,
          endCodMunicipio: r.endCodMunicipio,
          municipio: r.municipio,
          endUf: r.endUf,
          endCep: r.endCep,
          dddTelefone: r.dddTelefone,
          numTelefone: r.numTelefone,
          periodicidadeFaturamento:
            r.periodicidadeFaturamento === 1
              ? "Individual"
              : r.periodicidadeFaturamento === 2
              ? "Semanal"
              : r.periodicidadeFaturamento === 3
              ? "Quinzenal"
              : r.periodicidadeFaturamento === 4
              ? "Mensal"
              : "",
          tipo:
            r.tipo === 1
              ? "Banco"
              : r.tipo === 2
              ? "Banco Múltiplo"
              : r.tipo === 3
              ? "Financeira"
              : r.tipo === 4
              ? "Consórcio"
              : r.tipo === 5
              ? "Concessionária"
              : r.tipo === 6
              ? "Revenda"
              : r.tipo === 7
              ? "Arrendamento"
              : r.tipo === 99
              ? "Outras"
              : "",
          valorTaxaERC: r.valorTaxaERC,
          cobrancaAutomatica: r.cobrancaAutomatica,
          periodicidadeAlias:
            r.periodicidadeAlias === 1 ? "Individual" : r.periodicidadeAlias === 2 ? "Semanal" : r.periodicidadeAlias === 3 ? "Quinzenal" : r.periodicidadeAlias === 4 ? "Mensal" : "",
          inscricaoEstadual: r.inscricaoEstadual,
          nomeFantasia: r.nomeFantasia,
          observacao: r.observacao,
          responsavel: r.nomeResponsavel,
          cpfResponsavel: r.cpfResponsavel,
          emailResponsavel: r.emailResponsavel,
          dddTelefoneResponsavel: r.dddTelefoneResponsavel,
          numTelefoneResponsavel: r.numTelefoneResponsavel,
          assinaturaFormat: r.assinaturaFormat,
          criacaoFormat: r.criacaoFormat,
          status: r.status,
          valorTaxaAltERC: r.valorTaxaAltERC,
          valorTaxaAlt: r.valorTaxaAlt,
          tipoCobranca: r.tipoCobranca === 1 ? "Boleto" : r.tipoCobranca === 2 ? "Depósito" : "",
          funcaoResponsavel: r.funcaoResponsavel,
          integracaoExterna: r.integracaoExterna === "S" ? "Sim" : "Não",
          integracaoApi: r.integracaoApi === "S" ? "Sim" : "Não",
          finAgrupamento: r.finAgrupamento,
          cnpjCorrespondente: r.cnpjCorrespondente !== null ? "Sim" : "Não",
          nomeOperadora: r.nomeOperadora,
          idCredenciamento: r.idCredenciamento,
          emailsFaturamento: r.emailsFaturamento,
        });
      } else {
        r.financeirasAgrupadas.map((finAg) => {
          Data.push({
            nome: finAg.razaoSocial,
            cnpj: finAg.cnpjFormat,
            nrContrato: finAg.numContrato,
            dtVigencia: finAg.vigenciaFormat,
            valorTaxa: finAg.valorTaxa,
            endereco: finAg.endereco,
            endNumero: finAg.endNumero,
            endComplemento: finAg.endComplemento,
            endBairro: finAg.endBairro,
            endCodMunicipio: finAg.endCodMunicipio,
            municipio: finAg.municipio,
            endUf: finAg.endUf,
            endCep: finAg.endCep,
            dddTelefone: finAg.dddTelefone,
            numTelefone: finAg.numTelefone,
            periodicidadeFaturamento:
              finAg.periodicidadeFaturamento === 1
                ? "Individual"
                : finAg.periodicidadeFaturamento === 2
                ? "Semanal"
                : finAg.periodicidadeFaturamento === 3
                ? "Quinzenal"
                : finAg.periodicidadeFaturamento === 4
                ? "Mensal"
                : "",
            tipo:
              finAg.tipo === 1
                ? "Banco"
                : finAg.tipo === 2
                ? "Banco Múltiplo"
                : finAg.tipo === 3
                ? "Financeira"
                : finAg.tipo === 4
                ? "Consórcio"
                : finAg.tipo === 5
                ? "Concessionária"
                : finAg.tipo === 6
                ? "Revenda"
                : finAg.tipo === 7
                ? "Arrendamento"
                : finAg.tipo === 99
                ? "Outras"
                : "",
            valorTaxaERC: finAg.valorTaxaERC,
            cobrancaAutomatica: finAg.cobrancaAutomatica,
            periodicidadeAlias:
              finAg.periodicidadeAlias === 1
                ? "Individual"
                : finAg.periodicidadeAlias === 2
                ? "Semanal"
                : finAg.periodicidadeAlias === 3
                ? "Quinzenal"
                : finAg.periodicidadeAlias === 4
                ? "Mensal"
                : "",
            inscricaoEstadual: finAg.inscricaoEstadual,
            nomeFantasia: finAg.nomeFantasia,
            observacao: finAg.observacao,
            responsavel: finAg.nomeResponsavel,
            cpfResponsavel: finAg.cpfResponsavel,
            emailResponsavel: finAg.emailResponsavel,
            dddTelefoneResponsavel: finAg.dddTelefoneResponsavel,
            numTelefoneResponsavel: finAg.numTelefoneResponsavel,
            assinaturaFormat: finAg.assinaturaFormat,
            criacaoFormat: finAg.criacaoFormat,
            status: finAg.status,
            valorTaxaAltERC: finAg.valorTaxaAltERC,
            valorTaxaAlt: finAg.valorTaxaAlt,
            tipoCobranca: finAg.tipoCobranca === 1 ? "Boleto" : r.tipoCobranca === 2 ? "Depósito" : "",
            funcaoResponsavel: finAg.funcaoResponsavel,
            integracaoExterna: finAg.integracaoExterna === "S" ? "Sim" : "Não",
            integracaoApi: finAg.integracaoApi === "S" ? "Sim" : "Não",
            finAgrupamento: finAg.finAgrupamento,
            cnpjCorrespondente: finAg.cnpjCorrespondente !== null ? "Sim" : "Não",
            nomeOperadora: finAg.nomeOperadora,
            idCredenciamento: finAg.idCredenciamento,
            emailsFaturamento: finAg.emailsFaturamento,
          });
        });
      }
    });

    var ws = XLSX.utils.aoa_to_sheet(Heading);
    XLSX.utils.sheet_add_json(ws, Data, {
      header: [
        "nome",
        "cnpj",
        "nrContrato",
        "dtVigencia",
        "valorTaxa",
        "endereco",
        "endNumero",
        "endComplemento",
        "endBairro",
        "endCodMunicipio",
        "municipio",
        "endUf",
        "endCep",
        "dddTelefone",
        "numTelefone",
        "periodicidadeFaturamento",
        "tipo",
        "valorTaxaERC",
        "cobrancaAutomatica",
        "periodicidadeAlias",
        "inscricaoEstadual",
        "nomeFantasia",
        "observacao",
        "responsavel",
        "cpfResponsavel",
        "emailResponsavel",
        "dddTelefoneResponsavel",
        "numTelefoneResponsavel",
        "assinaturaFormat",
        "criacaoFormat",
        "status",
        "valorTaxaAltERC",
        "valorTaxaAlt",
        "tipoCobranca",
        "funcaoResponsavel",
        "integracaoExterna",
        "integracaoApi",
        "finAgrupamento",
        "cnpjCorrespondente",
        "nomeOperadora",
        "idCredenciamento",
        "emailsFaturamento",
      ],
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), "ListaFinanceiras.xlsx");
  };

  exportPDF = () => (ev) => {
    const cols = this.setCols();
    ev.preventDefault();
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 70;

    var title = `Instituições financeiras`;

    //const headers = [["Nome", "CNPJ", "Número do Contrato", "Data da Vigência", "Valor Alias", "Responsável", "E-mail Responsável"]];
    cols.pop();
    const headers = [cols.map((col) => [col.Header])];
    const accessors = cols.map((col) => col.accessor);

    let Data = [];
    this.props.all.map((r) => {
      if (!r.financeirasAgrupadas || r.financeirasAgrupadas.length === 0) {
        Data.push({
          ...r,
          periodicidadeFaturamento:
            r.periodicidadeFaturamento == 1
              ? "Individual"
              : r.periodicidadeFaturamento == 2
              ? "Semanal"
              : r.periodicidadeFaturamento == 3
              ? "Quinzenal"
              : r.periodicidadeFaturamento == 4
              ? "Mensal"
              : "",
          tipo:
            r.tipo == 1
              ? "Banco"
              : r.tipo == 2
              ? "Banco Múltiplo"
              : r.tipo == 3
              ? "Financeira"
              : r.tipo == 4
              ? "Consórcio"
              : r.tipo == 5
              ? "Concessionária"
              : r.tipo == 6
              ? "Revenda"
              : r.tipo == 7
              ? "Arrendamento"
              : r.tipo == 99
              ? "Outras"
              : "",
          periodicidadeAlias: r.periodicidadeAlias == 1 ? "Individual" : r.periodicidadeAlias == 2 ? "Semanal" : r.periodicidadeAlias == 3 ? "Quinzenal" : r.periodicidadeAlias == 4 ? "Mensal" : "",
          tipoCobranca: r.tipoCobranca == 1 ? "Boleto" : r.tipoCobranca == 2 ? "Depósito" : "",
          integracaoExterna: r.integracaoExterna == "S" ? "Sim" : "Não",
          integracaoApi: r.integracaoApi == "S" ? "Sim" : "Não",
          cnpjCorrespondente: r.cnpjCorrespondente !== null ? "Sim" : "Não",
        });
      } else {
        r.financeirasAgrupadas.map((finAg) => {
          Data.push({
            ...finAg,
            periodicidadeFaturamento:
              finAg.periodicidadeFaturamento == 1
                ? "Individual"
                : finAg.periodicidadeFaturamento == 2
                ? "Semanal"
                : finAg.periodicidadeFaturamento == 3
                ? "Quinzenal"
                : finAg.periodicidadeFaturamento == 4
                ? "Mensal"
                : "",
            tipo:
              finAg.tipo == 1
                ? "Banco"
                : finAg.tipo == 2
                ? "Banco Múltiplo"
                : finAg.tipo == 3
                ? "Financeira"
                : finAg.tipo == 4
                ? "Consórcio"
                : finAg.tipo == 5
                ? "Concessionária"
                : finAg.tipo == 6
                ? "Revenda"
                : finAg.tipo == 7
                ? "Arrendamento"
                : finAg.tipo == 99
                ? "Outras"
                : "",
            periodicidadeAlias:
              finAg.periodicidadeAlias == 1 ? "Individual" : finAg.periodicidadeAlias == 2 ? "Semanal" : finAg.periodicidadeAlias == 3 ? "Quinzenal" : finAg.periodicidadeAlias == 4 ? "Mensal" : "",
            tipoCobranca: finAg.tipoCobranca == 1 ? "Boleto" : finAg.tipoCobranca == 2 ? "Depósito" : "",
            integracaoExterna: finAg.integracaoExterna == "S" ? "Sim" : "Não",
            integracaoApi: finAg.integracaoApi == "S" ? "Sim" : "Não",
            cnpjCorrespondente: finAg.cnpjCorrespondente !== null ? "Sim" : "Não",
          });
        });
      }
    });

    const data = Data.map((d) =>
      accessors.map((ac) => {
        return d[ac];
      })
    );

    /*const data = this.props.all.map((financial) =>
      [ 
        financial.razaoSocial,
        MaskCNPJ.TO_CNPJ(financial.cnpj),
        financial.numContrato,
        `${financial.vigencia ? MaskDate.TO_BR(financial.vigencia) : ''}`,
        `${MaskDecimal.TO_BRL(financial.valorTaxaERC)}`,
        financial.nomeResponsavel,
        financial.emailResponsavel
      ]
    );*/

    var totais = `Quantidade total de Financeiras: ${data.length}`;

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      //pageBreak: 'always',
      /*columnStyles: {
          0: { columnWidth: 'wrap' },
          1: {
              columnWidth: 75
          },
          2: {
              columnWidth: 85
          },
          3: {
              columnWidth: 35
          },
          4: {
            columnWidth: 35  
          }
        },*/
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.text(totais, marginLeft, doc.autoTable.previous.finalY + 20);
    doc.save("RelatorioFinanceiras.pdf");
  };

  handleToggle(selectedItems) {
    this.setState(function (prevState) {
      return { showToggle: !prevState.showToggle };
    });
  }

  toggle(selectedItems) {
    this.setState(function (prevState) {
      return { showToggle: prevState.showToggle };
    });
  }

  handleLoad(selectedItems) {
    this.setState({ selectedItems });
  }

  handleChange(selectedItems) {
    Promise.resolve(api.Users.reportFields(this.props.currentUser.id)).then((fields) => {
      if (fields.length > 0) {
        var fieldsOld = fields; //REGISTROS ANTIGOS DO RELAT GERENCIAL SENDO READEQUADOS PARA NOVO PADRAO...
        fields = {};
        fields["camposRelatorioGerencial"] = fieldsOld;
        fields["camposRelatorioFinanceira"] = selectedItems;
      } else if (Object.keys(fields).length > 0) {
        fields["camposRelatorioFinanceira"] = selectedItems;
      } else {
        fields = {};
        fields["camposRelatorioFinanceira"] = selectedItems;
      }
      api.Users.updateReportFields(this.props.currentUser.id, JSON.stringify(fields));
    });
    this.setState({ selectedItems });
  }

  setCols = () => {
    const columns = [
      {
        Header: "Razão Social",
        accessor: "razaoSocial",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (row) => <div>{row.original.nomeAgrupamento && row.original.financeirasAgrupadas ? row.original.nomeAgrupamento.toUpperCase() : row.original.razaoSocial}</div>,
        ReportCell: (row) => (row.original.nomeAgrupamento && row.original.financeirasAgrupadas ? row.original.nomeAgrupamento.toUpperCase() : row.original.razaoSocial),
      },
      {
        Header: "Cnpj",
        accessor: "cnpjFormat",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        width: 150,
        ReportCell: (row) => row.original.cnpjFormat,
      },
      {
        Header: "Número do Contrato",
        accessor: "numContrato",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.numContrato,
      },
      {
        Header: "Valor ERC - Registradora",
        accessor: "valorTaxaERCFormat",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (row) => (
          <div>
            {row.original.valorTaxaERCFormat
              ? row.original.valorTaxaERCFormat.split("\n").map((item, i) => {
                  return <p key={i}>{item}</p>;
                })
              : ""}
          </div>
        ),
        ReportCell: (row) => row.original.valorTaxaERCFormat,
      },
      {
        Header: "Responsável",
        accessor: "nomeResponsavel",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.nomeResponsavel,
      },
      {
        Header: "Inscrição Estadual",
        accessor: "inscricaoEstadual",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.inscricaoEstadual,
      },
      {
        Header: "Tipo",
        accessor: "tipo",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (tipo) => (
          <div>
            {tipo.original.tipo === 1
              ? "Banco"
              : tipo.original.tipo === 2
              ? "Banco Múltiplo"
              : tipo.original.tipo === 3
              ? "Financeira"
              : tipo.original.tipo === 4
              ? "Consórcio"
              : tipo.original.tipo === 5
              ? "Concessionária"
              : tipo.original.tipo === 6
              ? "Revenda"
              : tipo.original.tipo === 7
              ? "Arrendamento"
              : tipo.original.tipo === 99
              ? "Outras"
              : ""}
          </div>
        ),
        ReportCell: (row) =>
          row.original.tipo === 1
            ? "Banco"
            : row.original.tipo === 2
            ? "Banco Múltiplo"
            : row.original.tipo === 3
            ? "Financeira"
            : row.original.tipo === 4
            ? "Consórcio"
            : row.original.tipo === 5
            ? "Concessionária"
            : row.original.tipo === 6
            ? "Revenda"
            : row.original.tipo === 7
            ? "Arrendamento"
            : row.original.tipo === 99
            ? "Outras"
            : "",
      },
      {
        Header: "Nome Fantasia",
        accessor: "nomeFantasia",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.nomeFantasia,
      },
      {
        Header: "Início do contrato",
        accessor: "assinaturaFormat",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.assinaturaFormat,
      },
      {
        Header: "Término do contrato",
        accessor: "vigenciaFormat",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (vigenciaFormat) => <div>{vigenciaFormat.original.vigenciaFormat}</div>,
        ReportCell: (row) => row.original.vigenciaFormat,
      },
      {
        Header: "Data da criação",
        accessor: "criacaoFormat",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.criacaoFormat,
      },
      {
        Header: "Código do Credor",
        accessor: "idCredenciamento",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.idCredenciamento,
      },
      {
        Header: "Telefone",
        accessor: "numTelefone",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.numTelefone,
      },
      {
        Header: "UF",
        accessor: "endUf",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.endUf,
      },
      {
        Header: "CEP",
        accessor: "endCep",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.endCep,
      },
      {
        Header: "Endereço",
        accessor: "endereco",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.endereco,
      },
      {
        Header: "Número Endereço",
        accessor: "endNumero",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.endNumero,
      },
      {
        Header: "Complemento",
        accessor: "endComplemento",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.endComplemento,
      },
      {
        Header: "Bairro",
        accessor: "endBairro",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.endBairro,
      },
      {
        Header: "Tipo de Pagamento",
        accessor: "tipoCobranca",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (tipoCobranca) => <div>{tipoCobranca.original.tipoCobranca == 1 ? "Boleto" : tipoCobranca.original.tipoCobranca == 2 ? "Depósito" : ""}</div>,
        ReportCell: (row) => (row.original.tipoCobranca === 1 ? "Boleto" : row.original.tipoCobranca === 2 ? "Depósito" : ""),
      },
      {
        Header: "Periodicidade Alias",
        accessor: "periodicidadeAlias",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (periodicidadeAlias) => (
          <div>
            {periodicidadeAlias.original.periodicidadeAlias === 1
              ? "Individual"
              : periodicidadeAlias.original.periodicidadeAlias === 2
              ? "Semanal"
              : periodicidadeAlias.original.periodicidadeAlias === 3
              ? "Quinzenal"
              : periodicidadeAlias.original.periodicidadeAlias === 4
              ? "Mensal"
              : ""}
          </div>
        ),
        ReportCell: (row) =>
          row.original.periodicidadeAlias === 1
            ? "Individual"
            : row.original.periodicidadeAlias === 2
            ? "Semanal"
            : row.original.periodicidadeAlias === 3
            ? "Quinzenal"
            : row.original.periodicidadeAlias === 4
            ? "Mensal"
            : "",
      },
      {
        Header: "Integração Externa",
        accessor: "integracaoExterna",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (integracaoExterna) => (
          <div>{integracaoExterna.original.nomeAgrupamento && integracaoExterna.original.financeirasAgrupadas ? "" : integracaoExterna.original.integracaoExterna === "S" ? "Sim" : "Não"}</div>
        ),
        ReportCell: (row) => (row.original.integracaoExterna === 1 ? "Sim" : "Não"),
      },
      {
        Header: "Utiliza API",
        accessor: "integracaoApi",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (integracaoApi) => <div>{integracaoApi.original.nomeAgrupamento && integracaoApi.original.financeirasAgrupadas ? "" : integracaoApi.original.integracaoApi === "S" ? "Sim" : "Não"}</div>,
        ReportCell: (row) => (row.original.integracaoApi === 1 ? "Sim" : "Não"),
      },
      {
        Header: "Periodicidade Detran",
        accessor: "periodicidadeFaturamento",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (periodicidadeFaturamento) => (
          <div>
            {periodicidadeFaturamento.original.periodicidadeFaturamento === 1
              ? "Individual"
              : periodicidadeFaturamento.original.periodicidadeFaturamento === 2
              ? "Semanal"
              : periodicidadeFaturamento.original.periodicidadeFaturamento === 3
              ? "Quinzenal"
              : periodicidadeFaturamento.original.periodicidadeFaturamento === 4
              ? "Mensal"
              : ""}
          </div>
        ),
        ReportCell: (row) =>
          row.original.periodicidadeFaturamento === 1
            ? "Individual"
            : row.original.periodicidadeFaturamento === 2
            ? "Semanal"
            : row.original.periodicidadeFaturamento === 3
            ? "Quinzenal"
            : row.original.periodicidadeFaturamento === 4
            ? "Mensal"
            : "",
      },
      {
        Header: "Agendamento Aut. Fat.",
        accessor: "cobrancaAutomatica",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (cobrancaAutomatica) => (
          <div>{cobrancaAutomatica.original.nomeAgrupamento && cobrancaAutomatica.original.financeirasAgrupadas ? "" : cobrancaAutomatica.original.cobrancaAutomatica === "S" ? "Sim" : "Não"}</div>
        ),
        ReportCell: (row) => (row.original.cobrancaAutomatica === 1 ? "Sim" : "Não"),
      },
      {
        Header: "Valor Detran",
        accessor: "valorTaxa",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (row) => (
          <div>
            {row.original.valorTaxaFormat
              ? row.original.valorTaxaFormat.split("\n").map((item, i) => {
                  return <p key={i}>{item}</p>;
                })
              : ""}
          </div>
        ),
        ReportCell: (row) => row.original.valorTaxaFormat,
      },

      {
        Header: "Valor ERC - Registradora",
        accessor: "valorTaxaERCFormat",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (row) => (
          <div>
            {row.original.valorTaxaERCFormat
              ? row.original.valorTaxaERCFormat.split("\n").map((item, i) => {
                  return <p key={i}>{item}</p>;
                })
              : ""}
          </div>
        ),
        ReportCell: (row) => row.original.valorTaxaERCFormat,
      },

      {
        Header: "Valor Alteração Detran",
        accessor: "valorTaxaAltFormat",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (row) => (
          <div>
            {row.original.valorTaxaAltFormat
              ? row.original.valorTaxaAltFormat.split("\n").map((item, i) => {
                  return <p key={i}>{item}</p>;
                })
              : ""}
          </div>
        ),
        ReportCell: (row) => row.original.valorTaxaAltFormat,
      },
      {
        Header: "Valor Alteração ERC - Registradora",
        accessor: "valorTaxaAltERCFormat",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (row) => (
          <div>
            {row.original.valorTaxaAltERCFormat
              ? row.original.valorTaxaAltERCFormat.split("\n").map((item, i) => {
                  return <p key={i}>{item}</p>;
                })
              : ""}
          </div>
        ),
        ReportCell: (row) => row.original.valorTaxaAltERCFormat,
      },
      {
        Header: "CPF Responsável",
        accessor: "cpfResponsavel",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.cpfResponsavel,
      },
      {
        Header: "Email Responsável",
        accessor: "emailResponsavel",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.emailResponsavel,
      },
      {
        Header: "Telefone Responsável",
        accessor: "numTelefoneResponsavel",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.numTelefoneResponsavel,
      },
      {
        Header: "Função Responsável",
        accessor: "funcaoResponsavel",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.funcaoResponsavel,
      },
      {
        Header: "Matrícula Responsável",
        accessor: "matriculaResponsavel",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.matriculaResponsavel,
      },
      {
        Header: "Operadora",
        accessor: "nomeOperadora",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (nomeOperadora) => <div>{nomeOperadora.original.nomeOperadora ? nomeOperadora.original.nomeOperadora : ""}</div>,
        ReportCell: (row) => (row.original.nomeOperadora ? row.original.nomeOperadora : ""),
      },
      {
        Header: "Correspondente",
        accessor: "cnpjCorrespondente",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (delegaPara) => <div>{delegaPara.original.cnpjCorrespondente !== null ? "Sim" : "Não"}</div>,
        ReportCell: (row) => (row.original.cnpjCorrespondente !== null ? "Sim" : "Não"),
      },
      {
        Header: "Nome do Agrupamento",
        accessor: "finAgrupamento",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.finAgrupamento,
      },
      {
        Header: "Município",
        accessor: "municipio",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.dados.endCodMunicipio,
      },
    ];
    var col = columns.filter((col) => this.state.selectedItems.find((item) => item.label === col.Header));
    col.push({
      Header: "", // Custom header components!
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
      width: 130,
      Cell: (row) =>
        row.original.id ? (
          <div className="btn-group">
            {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("F")) && (
              <Link to={`/alias/financialEdit/${row.original.id}`} className="btn btn-sm btn-default" title="Editar">
                <i className="far fa-edit" />
              </Link>
            )}
            <Link to={`/alias/financialUser/${row.original.id}`} className="btn btn-sm btn-default" title="Usuários">
              <i className="fas fa-users" />
            </Link>
            {false && row.original.integracaoRoboSng && (
              <Link to={`/alias/configuracaoSng/${row.original.id}`} className="btn btn-sm btn-default" title="Configurar SNG">
                <i className="fas fa-wrench" />
              </Link>
            )}
            {/*<Link to={`/alias/financialInvoices/${row.original.id}`} className="btn btn-sm btn-default" title='Faturamentos'><i className="fas fa-barcode"/></Link>*/}
          </div>
        ) : (
          ""
        ),
      ReportCell: () => "",
    });
    return col;
  };

  getExpander = () => {
    const expander = {
      expander: true,
      Header: "",
      Expander: ({ isExpanded, ...rest }) => {
        return (
          <div className="div-expander">
            {(rest.original.financeirasAgrupadas && rest.original.financeirasAgrupadas.length > 0) ||
            (rest.original.financeirasAgrupadas && rest.original.financeirasAgrupadas.financeirasAgrupadas && rest.original.financeirasAgrupadas.financeirasAgrupadas.length > 0) ? (
              isExpanded ? (
                <span> &#x2bc6;</span>
              ) : (
                <span> &#x2bc8;</span>
              )
            ) : null}
          </div>
        );
      },
      getProps: (state, rowInfo, column) => {
        if (!rowInfo || (rowInfo && rowInfo.original.financeirasAgrupadas && rowInfo.original.financeirasAgrupadas.length < 1)) {
          return {
            onClick: () => {},
          };
        }
        return {
          className: "show-pointer",
        };
      },
    };
    return expander;
  };

  getExpanderSecond = () => {
    const expander = {
      expander: true,
      Header: "",
      Expander: ({ isExpanded, ...rest }) => {
        return (
          <div className="div-expander">
            {rest.original.financeirasAgrupadas && rest.original.financeirasAgrupadas.length > 0 ? isExpanded ? <span> &#x2bc6;</span> : <span> &#x2bc8;</span> : null}
          </div>
        );
      },
      getProps: (state, rowInfo, column) => {
        if (!rowInfo || (rowInfo && rowInfo.original.financeirasAgrupadas && rowInfo.original.financeirasAgrupadas.length < 1)) {
          return {
            onClick: () => {},
          };
        }
        return {
          className: "show-pointer",
        };
      },
    };
    return expander;
  };

  async filter(values) {
    const errors = this.validateValues(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    } else {
      this.setState({ pesquisando: true });
      toastr.info("Aguarde", "Realizando consulta...", {
        timeOut: 0,
        component: () => {
          return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
        },
      });
      this.props.onListLoad(
        await Promise.resolve(
          api.Financial.allClustered(
            MaskDate.TO_DATABASE_DATE(values.dataAssinaturaContrato),
            MaskDate.TO_DATABASE_DATE(values.vigencia),
            values.cnpj ? values.cnpj.replace(/[-./]/g, "") : null,
            values.razaoSocial,
            values.nomeFantasia,
            values.numContrato,
            values.endUf,
            values.endCodMunicipio,
            values.nomeAgrupamento,
            values.uf,
            values.cpf ? values.cpf.replace(/[-./]/g, "") : null,
            MaskDate.TO_DATABASE_DATE(values.dataIniCriacao),
            MaskDate.TO_DATABASE_DATE(values.dataFimCriacao),
            values.emailsFaturamento,
            values.emailsImagem
          )
        )
      );
      this.setState({ pesquisando: false });
      toastr.removeByType("info");
      if (!this.props.all || this.props.all.length == 0) {
        toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
      }
    }
  }

  validateValues(values) {
    const errors = {};
    if (values.dataIniCriacao && !values.dataFimCriacao) {
      errors.dataFimCriacao = "Campo obrigatório";
    } else if (!values.dataIniCriacao && values.dataFimCriacao) {
      errors.dataIniCriacao = "Campo obrigatório";
    }
    return errors;
  }

  render() {
    const { handleSubmit, reset } = this.props;
    const { items, selectedItems } = this.state;
    const messages = {
      searchPlaceholder: "Procurar campos...",
      noItemsMessage: "Sem Itens",
      noneSelectedMessage: "Nenhum selecionado",
      selectedMessage: "selecionados",
      selectAllMessage: "Selecionar tudo",
      clearAllMessage: "Limpar tudo",
      disabledItemsTooltip: "Você só pode selecionar 8 campos",
    };
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Instituições financeiras habilitadas para o registro de contrato" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    {(!this.props.currentUser.tipo || !this.props.currentUser.tipo.includes("F")) && (
                      <Link className="btn btn-xs btn-info" to="/alias/financialEdit">
                        <i className="fas fa-plus" /> Cadastrar
                      </Link>
                    )}
                  </div>
                  Instituições financeiras - {this.props.all && this.props.all.length > 0 ? this.props.all.length : "0"} registro(s).
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-md-2">
                      <Field name="uf" label="Opera em" data={this.props.ufSystem} textField="sigla" component={SelectField} type="text" />
                    </div>
                    <div className="col-md-2">
                      <Field
                        name="dataAssinaturaContrato"
                        label="Data início contrato"
                        placeholder="00/00/0000"
                        component={TextDatePickerField}
                        type="text"
                        validate={[FieldValidation.date]}
                        normalize={FieldNormalize.DATE}
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        name="vigencia"
                        label="Data término contrato"
                        validate={[FieldValidation.date]}
                        placeholder="00/00/0000"
                        component={TextDatePickerField}
                        type="text"
                        normalize={FieldNormalize.DATE}
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        name="dataIniCriacao"
                        label="Data início criação"
                        validate={[FieldValidation.date]}
                        placeholder="00/00/0000"
                        component={TextDatePickerField}
                        type="text"
                        normalize={FieldNormalize.DATE}
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        name="dataFimCriacao"
                        label="Data fim criação"
                        validate={[FieldValidation.date]}
                        placeholder="00/00/0000"
                        component={TextDatePickerField}
                        type="text"
                        normalize={FieldNormalize.DATE}
                      />
                    </div>
                    {/* <div className="col-md-2">
                      <Field name="periodicidadeAlias" label="Periodicidade Alias" blank={true} data={[{ "id": "1", "name": "Individual" },
                      { "id": "2", "name": "Semanal" }, { "id": "3", "name": "Quinzenal" },
                      { "id": "4", "name": "Mensal" }]}
                        component={SelectField} type="text" valueField='id' textField='name' />
                    </div>    
                    <div className="col-md-2">
                      <Field name="periodicidadeFaturamento" label="Periodicidade Detran" blank={true} data={[{ "id": "1", "name": "Individual" },
                      { "id": "2", "name": "Semanal" }, { "id": "3", "name": "Quinzenal" },
                      { "id": "4", "name": "Mensal" }]}
                        component={SelectField} type="text" valueField='id' textField='name' />
                    </div> */}
                    <div className="col-md-2">
                      <Field name="cpf" label="CPF do Usuário" component={TextField} type="text" normalize={FieldNormalize.CPF} validate={[FieldValidation.cpf]} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <Field name="cnpj" label="CNPJ" component={TextField} type="text" normalize={FieldNormalize.CNPJ} />
                    </div>
                    <div className="col-md-4">
                      <Field name="razaoSocial" label="Razão Social" component={TextField} type="text" />
                    </div>
                    <div className="col-md-3">
                      <Field name="nomeFantasia" label="Nome Fantasia" component={TextField} type="text" maxlength={70} />
                    </div>
                    <div className="col-md-3">
                      <Field name="nomeAgrupamento" label="Nome Grupo" component={TextField} type="text" maxlength={70} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <Field name="numContrato" label="Número do contrato" validate={[FieldValidation.nospace]} maxlength={45} component={TextField} type="text" inline={false} />
                    </div>
                    <div className="col-md-2">
                      <UfField name="endUf" label="UF" />
                    </div>
                    <div className="col-md-2">
                      <CityField name="endCodMunicipio" label="Município" valueField="municipioId" selectedUf={this.props.selectedUf} />
                    </div>
                    <div className="col-md-3">
                      <Field name="emailsFaturamento" label="Email Faturamento" component={TextField} type="text" />
                    </div>
                    <div className="col-md-3">
                      <Field name="emailsImagem" label="Email Imagem" component={TextField} type="text" />
                    </div>
                  </div>
                  <button className="btn btn-success" onClick={handleSubmit(this.filter.bind(this))} disabled={this.state.pesquisando} id="filterFin">
                    {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                  </button>
                  <br />
                  {this.props.all && this.props.all.length > 0 && (
                    <>
                      <Link className="btn btn-default float-right" onClick={this.exportToXLSEmailsFat.bind(this)} title="Gerar XLS E-mails faturamento">
                        {" "}
                        <SvgIcon size={20} icon={fileText2} />
                      </Link>
                      <Link className="btn btn-default float-right" onClick={this.exportToXLS.bind(this)} title="Gerar XLS">
                        {" "}
                        <SvgIcon size={20} icon={fileExcelO} />
                      </Link>
                      <Link className="btn btn-default float-right" onClick={this.exportPDF()} title="Gerar PDF">
                        {" "}
                        <SvgIcon size={20} icon={filePdf} />
                      </Link>
                      <Link className="btn btn-default float-right" onClick={this.handleToggle} title="Configurar Colunas da Tabela">
                        {" "}
                        <SvgIcon size={20} icon={this.state.showToggle ? checkCircle : gear} />
                      </Link>
                    </>
                  )}
                  {this.state.showToggle ? (
                    <MultiSelect
                      items={items}
                      selectedItems={selectedItems}
                      onChange={this.handleChange}
                      showSelectAll={true}
                      showSelectedItems={true}
                      maxSelectedItems={8}
                      messages={messages}
                      itemHeight={38}
                      filterFunction={(value) => (item) => FieldNormalize.NO_SP_CHARACTERS(String(item.label)).includes(FieldNormalize.NO_SP_CHARACTERS(value))}
                    />
                  ) : null}
                  <div className="table-responsive">{this.props.all && this.props.all.length > 0 ? this.renderFinancialList() : <small>Nenhuma Financeira encontrada.</small>}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFinancialList() {
    if (!this.props.all) {
      return null;
    }

    const colsWithExpander = [this.getExpander(), ...this.setCols()];
    const colsWithExpanderSecond = [this.getExpanderSecond(), ...this.setCols()];

    return (
      <ReactTable
        className="-striped -highlight"
        defaultPageSize={400}
        data={this.props.all}
        loadingText="Carregando..."
        noDataText="Nenhuma instituição financeira"
        ref={(r) => {
          this.selectTable = r;
        }}
        columns={colsWithExpander}
        showPageSizeOptions={false}
        minRows={1}
        showPagination={false}
        SubComponent={(row) => {
          return (
            <div style={{ padding: "20px" }}>
              <ReactTable
                className="-striped -highlight"
                defaultPageSize={400}
                data={row.original.financeirasAgrupadas}
                loadingText="Carregando..."
                noDataText="Nenhuma instituição financeira"
                ref={(r) => {
                  this.selectTable = r;
                }}
                columns={colsWithExpanderSecond}
                showPageSizeOptions={false}
                minRows={1}
                showPagination={false}
                getTdProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row && column && column.Header) {
                    return {
                      style: {
                        border: "none",
                      },
                    };
                  } else {
                    return {};
                  }
                }}
                SubComponent={(row) => {
                  return (
                    <div style={{ padding: "20px" }}>
                      <ReactTable
                        className="-striped -highlight"
                        defaultPageSize={400}
                        data={row.original.financeirasAgrupadas}
                        loadingText="Carregando..."
                        noDataText="Nenhuma instituição financeira"
                        ref={(r) => {
                          this.selectTable = r;
                        }}
                        columns={colsWithExpanderSecond}
                        showPageSizeOptions={false}
                        minRows={1}
                        showPagination={false}
                        getTdProps={(state, rowInfo, column) => {
                          if (rowInfo && rowInfo.row && column && column.Header) {
                            return {
                              style: {
                                border: "none",
                              },
                            };
                          } else {
                            return {};
                          }
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: FINANCIAL_LIST_LOAD, payload }),
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
});

const selector = formValueSelector("FinancialListForm");
const mapStateToProps = (state) => ({
  ...state.financial,
  currentUser: state.common.currentUser,
  selectedUf: selector(state, "endUf"),
  dtInicial: selector(state, "dataIniCriacao"),
  dtFinal: selector(state, "dataFimCriacao"),
});

const form = reduxForm({
  form: "FinancialListForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(FinancialList));
