import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import { SubmissionError } from "redux-form";
import SelectField from "../../components/Fields/SelectField";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import _ from "lodash";
import MaskDate from "../../components/Util/MaskDate";
import MaskDecimal from "../../components/Util/MaskDecimal";
import MaskCNPJ from "../../components/Util/MaskCNPJ";
import ReactTable from "react-table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import SvgIcon from "react-icons-kit";
import "@kenshooui/react-multi-select/dist/style.css";
import MultiSelect from "@kenshooui/react-multi-select";
import { gear } from "react-icons-kit/fa/gear";
import { checkCircle } from "react-icons-kit/fa/checkCircle";

import { MANAGEMENT_REPORT_LOAD, MANAGEMENT_REPORT_UNLOAD, MANAGEMENT_REPORT_LIST_LOAD, UF_SYSTEM_LOAD, MANAGEMENT_REPORT_PARCEIRO_LOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import DropdownListField from "../../components/Fields/DropdownListField";

import { toastr } from "react-redux-toastr";
import { BeatLoader } from "react-spinners";

const tipos = [
  { label: "Todos", value: "" },
  { label: "Inclusão de contrato", value: "1" },
  { label: "Alteração de contrato", value: "2" },
  { label: "Averbação de contrato", value: "3" },
  { label: "Alteração de Averbação", value: "4" },
];

const anexos = [
  { label: "Todos", value: "" },
  { label: "Possui", value: "S" },
  { label: "Não possui", value: "N" },
  { label: "Não possui (menos cancelados/baixados)", value: "NPCB" },
];

const correspondente = [
  { label: "Não", value: "N" },
  { label: "Sim", value: "S" },
];

class ManagementReport extends React.Component {
  constructor() {
    super();
    var selectTable;
    this.handleChange = this.handleChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.state = {
      items: [
        { id: 0, label: "Tipo" },
        { id: 1, label: "Data Registro" },
        { id: 2, label: "Chassi" },
        { id: 3, label: "Nome Devedor" },
        { id: 4, label: "CPF/CNPJ Devedor" },
        { id: 5, label: "N°. Contrato" },
        { id: 6, label: "Data Anexo" },
        { id: 7, label: "UF Devedor" },
        { id: 8, label: "CEP Devedor" },
        { id: 9, label: "End. Devedor" },
        { id: 10, label: "N°. Devedor" },
        { id: 11, label: "Complemento Devedor" },
        { id: 12, label: "Dev. Município" },
        { id: 13, label: "Bairro Devedor" },
        { id: 14, label: "Tel. Devedor" },
        { id: 15, label: "Email Devedor" },
        { id: 16, label: "Tx Juros Mês" },
        { id: 17, label: "Tx Juros Ano" },
        { id: 18, label: "Tx Multa" },
        { id: 19, label: "Tx Mora" },
        { id: 20, label: "Tx Comissão" },
        { id: 21, label: "Valor ERC - Registradora" },
        //{ id: 22, label: "Valor Integracao"},
        { id: 23, label: "Valor Detran" },
        { id: 24, label: "Valor Total Financ." },
        { id: 25, label: "Valor IOF" },
        { id: 26, label: "Valor Parcela" },
        { id: 27, label: "Data Primeiro Venc." },
        { id: 28, label: "Data Último Venc." },
        { id: 29, label: "Data Lib. Crédito" },
        { id: 30, label: "Data Inclusão" },
        { id: 31, label: "Data Contrato" },
        { id: 32, label: "Índices" },
        { id: 33, label: "N°. Aditivo" },
        { id: 34, label: "Data Aditivo" },
        { id: 35, label: "Penalidade" },
        { id: 36, label: "UF Lib. Cred." },
        { id: 37, label: "Cidade Lib. Cred." },
        { id: 38, label: "N°. Grupo Consorcio" },
        { id: 39, label: "N°. Cota Consorcio" },
        { id: 40, label: "Valor Crédito" },
        { id: 41, label: "Dia Venc. Parcelas" },
        { id: 42, label: "Qtd. Parcelas" },
        { id: 43, label: "N°. Registro" },
        { id: 44, label: "Placa" },
        { id: 45, label: "UF Placa" },
        { id: 46, label: "Renavam" },
        { id: 47, label: "UF Licenciamento" },
        { id: 48, label: "Ano Fabricação" },
        { id: 49, label: "Ano Modelo" },
        { id: 50, label: "N°. Gravame" },
        { id: 51, label: "Nome Credor" },
        { id: 52, label: "CPF/CNPJ Credor" },
        { id: 53, label: "Tipo Contrato" },
        { id: 54, label: "UF Contrato" },
        { id: 55, label: "Nome Fantasia" },
        //{ id: 56, label: "Espécie/Tipo"},
      ],
      selectedItems: [
        { id: 0, label: "Tipo" },
        { id: 1, label: "Data Registro" },
        { id: 2, label: "Chassi" },
        { id: 3, label: "Nome Devedor" },
        { id: 4, label: "CPF/CNPJ Devedor" },
        { id: 5, label: "N°. Contrato" },
        { id: 6, label: "Data Anexo" },
      ],
      showToggle: false,
      all: false,
      data: [],
      admin: false,
      gestor: false,
      financeira: [],
      pesquisando: false,
      dataFimAnteriorIntegra: false,
    };
    document.addEventListener("keyup", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    if (event.keyCode === 13 && document.getElementById("filterRelGer")) {
      document.getElementById("filterRelGer").click();
    }
  };

  exportChartToXLSX = () => {
    var DataExcel = [];

    const headersExcell = [
      [
        "Número",
        "Data Registro",
        "CPF/CNPJ",
        "Nome devedor",
        "UF",
        "Dev. Município",
        "CEP",
        "Endereço",
        "N º",
        "Complemento",
        "Bairro",
        "DDD",
        "Telefone",
        "Data do contrato",
        "Total do financiamento",
        "Valor Parcela",
        "Primeiro vencimento",
        "Último vencimento",
        "Liberação do crédito",
        "Índices",
        "Aditivo",
        "Data Aditivo",
        "UF lib. Crédito",
        "Cidade liberação crédito",
        "Grupo",
        "Cota",
        "Valor crédito",
        "Dia venc. Parcela",
        "Número do registro",
        "Qtde parcelas",
        "Número do registro original",
        "Placa",
        "Uf placa",
        "Renavam",
        "Chassi",
        "Uf licenciamento",
        "Ano fabricação",
        "Ano modelo",
        "Gravame",
        //"Espécie",
        "Remarcado",
        "Nome credor",
        "Nome Fantasia",
        "CPF/CNPJ credor",
        "Data Anexo",
        "Data Inclusão",
        "Tx Juros Mês %",
        "Tx Juros Ano %",
        "Tx Multa %",
        "Tx Mora %",
        "Tx Comissão %",
        "Valor Detran ",
        "Valor ERC - Registradora",
        "Tipo Contrato",
        "UF Contrato",
      ],
    ];
    if (this.props.idParceiro) {
      headersExcell[0].push("Valor Parceiro", "19,53%", "Valor Parceiro com deduções");
    } else if (this.state.dataFimAnteriorIntegra) {
      headersExcell[0].push("Valor Integracao");
    }
    if (this.props.uf == "BA") {
      headersExcell[0].push("Registrado por");
    }

    DataExcel.length = 0;
    var veicJaAdd = false;
    let vlporcentagemTotal = 0;
    let vlInteMenosPorcentTotal = 0;
    this.props.data.registros.map((r, index) => {
      veicJaAdd = false;
      if (r.dados.veiculos) {
        r.dados.veiculos.map((veic, index) => {
          if (!veicJaAdd) {
            var linha = {
              nrContrato: r.dados.numero,
              dtInclusao: MaskDate.TO_BR_DATETIME(r.dataInclusao),
              devCpfCnpj: r.dados.devCpfCnpj,
              devNome: r.dados.devNome,
              devUf: r.dados.devUf,
              municipio: r.municipio,
              devCep: r.dados.devCep,
              devEndereco: r.dados.devEndereco,
              devNumero: r.dados.devNumero,
              devComplemento: r.dados.devComplemento,
              devBairro: r.dados.devBairro,
              devDdd: r.dados.devDddTelefone,
              devTelefone: r.dados.devTelefone,
              data: MaskDate.TO_BR(r.dados.data),
              valorTotalFinanciamento: MaskDecimal.TO_NUMBER(r.dados.valorTotalFinanciamento),
              valorParcela: MaskDecimal.TO_NUMBER(r.dados.valorParcela),
              dataPrimeiroVenc: MaskDate.TO_BR(r.dados.dataPrimeiroVenc),
              dataUltimoVenc: MaskDate.TO_BR(r.dados.dataUltimoVenc),
              dataLiberacaoCred: MaskDate.TO_BR(r.dados.dataLiberacaoCred),
              indices: r.dados.indices,
              numeroAditivo: r.dados.numeroAditivo,
              dataAditivo: MaskDate.TO_BR(r.dados.dataAditivo),
              ufLiberacaoCred: r.dados.ufLiberacaoCred,
              cidadeLiberacaoCred: r.dados.cidadeLiberacaoCred,
              numGrupoConsorcio: r.dados.numGrupoConsorcio,
              numCotaConsorcio: r.dados.numCotaConsorcio,
              valorCredito: MaskDecimal.TO_NUMBER(r.dados.valorCredito),
              diaVencParcelas: r.dados.diaVencParcelas,
              numRegistro: r.dados.numRegistro,
              qtdeParcelas: r.dados.qtdeParcelas,
              numRegistroOriginal: r.dados.numRegistroOriginal,
              numPlaca: veic.numPlaca,
              ufPlaca: veic.ufPlaca,
              renavam: veic.renavam,
              chassi: veic.chassi,
              ufLicenciamento: veic.ufLicenciamento,
              anoFabricacao: veic.anoFabricacao,
              anoModelo: veic.anoModelo,
              numGravame: veic.numGravame,
              //especieVeiculo: veic.especieVeiculo,
              remarcado: veic.remarcado == 1 ? "Sim" : "Não",
              nmCredor: r.razaoSocial,
              nomeFantasia: r.nomeFantasia,
              crdCpfCnpj: MaskCNPJ.TO_CNPJ(r.dados.cnpjFinanceira),
              dataAnexo: MaskDate.TO_BR_DATETIME(r.dataAnexo),
              dataSistema: MaskDate.TO_BR(r.dataSistema),
              taxaJurosMes: MaskDecimal.TO_NUMBER(r.dados.taxaJurosMes),
              taxaJurosAno: MaskDecimal.TO_NUMBER(r.dados.taxaJurosAno),
              taxaMulta: MaskDecimal.TO_NUMBER(r.dados.taxaMulta),
              taxaMora: MaskDecimal.TO_NUMBER(r.dados.taxaMora),
              taxaComissao: MaskDecimal.TO_NUMBER(r.dados.taxaComissao),
              valorTaxa: MaskDecimal.TO_NUMBER(r.valorTaxa),
              valorTaxaERC: MaskDecimal.TO_NUMBER(r.valorTaxaERC),
              tipoContrato:
                r.dados.tipoContrato === 1
                  ? "Alienação Fiduciária - Financiamento"
                  : r.dados.tipoContrato === 2
                  ? "Alienação Fiduciária - Consórcio"
                  : r.dados.tipoContrato === 3
                  ? "Alienação Fiduciária - Cédula de Crédito"
                  : r.dados.tipoContrato === 4
                  ? "Arrendamento Mercantil - Leasing"
                  : r.dados.tipoContrato === 5
                  ? "Reserva de Domínio"
                  : r.dados.tipoContrato === 6
                  ? "Penhor"
                  : "",
              ufContrato: r.uf ? r.uf : "",
            };
            if (this.props.idParceiro) {
              let vlporcentagem = 0.1953 * r.valorIntegracaoExterna;
              let vlInteMenosPorcent = r.valorIntegracaoExterna - vlporcentagem;
              linha.valorIntegracaoExterna = MaskDecimal.TO_NUMBER(r.valorIntegracaoExterna);
              vlporcentagemTotal += vlporcentagem;
              vlInteMenosPorcentTotal += vlInteMenosPorcent;
              linha.vlporcentagem = vlporcentagem;
              linha.vlInteMenosPorcent = vlInteMenosPorcent;
            } else if (this.state.dataFimAnteriorIntegra) {
              linha.valorIntegracaoExterna = MaskDecimal.TO_NUMBER(r.valorIntegracaoExterna);
            }
            if (this.props.uf == "BA") {
              linha.direcionadoPor = r.direcionadoPor;
            }
            DataExcel.push(linha);
          } else {
            DataExcel.push({
              dtInclusao: MaskDate.TO_BR_DATETIME(r.dataInclusao),
              numPlaca: veic.numPlaca,
              ufPlaca: veic.ufPlaca,
              renavam: veic.renavam,
              chassi: veic.chassi,
              ufLicenciamento: veic.ufLicenciamento,
              anoFabricacao: veic.anoFabricacao,
              anoModelo: veic.anoModelo,
              numGravame: veic.numGravame,
              //especieVeiculo: veic.especieVeiculo,
              remarcado: veic.remarcado == 1 ? "Sim" : "Não",
              nmCredor: r.razaoSocial,
              crdCpfCnpj: MaskCNPJ.TO_CNPJ(r.dados.cnpjFinanceira),
              ufContrato: r.uf ? r.uf : "",
            });
          }
          veicJaAdd = true;
        });
      }
    });

    DataExcel.push({ nrContrato: "" });
    {
      this.props.data.quantidade && DataExcel.push({ nrContrato: `Financeira: ${this.state.all ? "TODAS AS FINANCEIRAS" : this.state.financeira}` });
      DataExcel.push({ nrContrato: `Data Início Cobrança: ${MaskDate.TO_BR(this.props.data.dataInicio)}` });
      DataExcel.push({ nrContrato: `Data Fim Cobrança: ${MaskDate.TO_BR(this.props.data.dataFim)}` });
      DataExcel.push({ nrContrato: "" });
      DataExcel.push({ nrContrato: `Quantidade de eventos: ${this.props.data.quantidade}` });
    }
    {
      this.props.data.qtdChassis && DataExcel.push({ nrContrato: `Quantidade de chassis: ${this.props.data.qtdChassis}` });
    }
    {
      this.props.data.valorTaxaTotal && DataExcel.push({ nrContrato: `Total Cobrança Detran: ${MaskDecimal.TO_BRL(this.props.data.valorTaxaTotal)}` });
    }
    {
      this.props.data.valorERCTotal && DataExcel.push({ nrContrato: `Total Cobrança Registradora: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal)}` });
    }

    // {
    //   this.props.uf && this.props.uf !== "PR" && (
    <div>
      {this.props.data.valorIntegracaoExternaTotal && this.props.idParceiro && (
        <>
          {DataExcel.push({ nrContrato: `Total Cobrança Parceiro: ${MaskDecimal.TO_BRL(this.props.data.valorIntegracaoExternaTotal)}` })}
          {DataExcel.push({ nrContrato: `Total Imposto NF Alias: ${MaskDecimal.TO_BRL(vlporcentagemTotal)}` })}
          {DataExcel.push({ nrContrato: `Líquido Total Parceiro: ${MaskDecimal.TO_BRL(vlInteMenosPorcentTotal)}` })}
          {DataExcel.push({ nrContrato: `Líquido Cobrança Alias: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal - this.props.data.valorIntegracaoExternaTotal)}` })}
        </>
      )}
      {!this.props.data.valorIntegracaoExternaTotal &&
        !this.props.idParceiro &&
        this.state.dataFimAnteriorIntegra &&
        DataExcel.push({ nrContrato: `Valor Total Taxa Integra+: ${MaskDecimal.TO_BRL(this.props.data.valorIntegracaoExternaTotal)}` })}
    </div>;
    //   );
    // }
    {
      // this.props.uf && this.props.uf !== "PR" && (
      //   <div>
      //     {this.props.data.valorERCTotal && this.props.data.valorIntegracaoExternaTotal && this.props.idParceiro
      //       ? DataExcel.push({ nrContrato: `Valor Total Taxa Registradora e Parceiro: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal + this.props.data.valorIntegracaoExternaTotal)}` })
      //       : this.state.dataFimAnteriorIntegra
      //       ? DataExcel.push({ nrContrato: `Valor Total Taxa Registradora e Integra+: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal + this.props.data.valorIntegracaoExternaTotal)}` })
      //       : ""}
      //   </div>
      // );
    }

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var colsFormat = ["O", "P", "AA", "AY", "AZ", "BC", "BD", "BE"];
    //var colNum = XLSX.utils.decode_col("O"); //decode_col converts Excel col name to an integer for col #
    var fmt = "#,##0.00"; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format

    /* get worksheet range */
    var range = XLSX.utils.decode_range(ws["!ref"]);
    for (var i = range.s.r + 1; i <= range.e.r; ++i) {
      for (const coluna of colsFormat) {
        var colNum = XLSX.utils.decode_col(coluna); //decode_col converts Excel col name to an integer for col #
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var ref = XLSX.utils.encode_cell({ r: i, c: colNum });
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!ws[ref]) continue;
        /* `.t == "n"` for number cells */
        if (ws[ref].t != "n") continue;
        /* assign the `.z` number format */
        ws[ref].z = fmt;
      }
    }

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "RelatorioGerencial");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `RelatorioGerencial.xlsx`);
  };

  handleLoad(selectedItems) {
    this.setState({ selectedItems });
  }

  handleChange(selectedItems) {
    Promise.resolve(api.Users.reportFields(this.props.currentUser.id)).then((fields) => {
      if (fields.length > 0) {
        //SO TEM FINANCEIRO ANTIGO
        fields = {};
        fields["camposRelatorioGerencial"] = selectedItems;
      } else if (Object.keys(fields).length > 0) {
        //NAO TEM FINANCEIRO ANTIGO
        fields["camposRelatorioGerencial"] = selectedItems;
      } else {
        //NÃO EXITE GRAVACAO DE CAMPOS
        fields = {};
        fields["camposRelatorioGerencial"] = selectedItems;
      }
      api.Users.updateReportFields(this.props.currentUser.id, JSON.stringify(fields));
    });
    this.setState({ selectedItems });
  }

  handleToggle(selectedItems) {
    this.setState(function (prevState) {
      return { showToggle: !prevState.showToggle };
    });
  }

  setCols = () => {
    const columns = [
      {
        Header: "Tipo",
        accessor: "tipo",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (tipo) => (
          <div>
            {tipo.original.tipo === 1
              ? "Inclusão de contrato"
              : tipo.original.tipo === 2
              ? "Alteração de contrato"
              : tipo.original.tipo === 3
              ? "Averbação de contrato"
              : tipo.original.tipo === 4
              ? "Alteração de averbação"
              : ""}
          </div>
        ),
        ReportCell: (row) =>
          row.original.tipo === 1
            ? "Inclusão de contrato"
            : row.original.tipo === 2
            ? "Alteração de contrato"
            : row.original.tipo === 3
            ? "Averbação de contrato"
            : row.original.tipo === 4
            ? "Alteração de averbação"
            : "",
      },
      {
        Header: "Data Registro",
        accessor: "dtInclusao",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (dtInclusao) => <div>{MaskDate.TO_BR_DATETIME(dtInclusao.original.dataInclusao)}</div>,
        ReportCell: (row) => MaskDate.TO_BR_DATETIME(row.original.dataInclusao),
      },
      {
        Header: "Chassi",
        accessor: "chassi",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (chassi) => <div>{this.renderVehicleList(chassi.original.dados.veiculos, "chassi")}</div>,
        ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map((veic) => veic.chassi).join("\n") : null),
      },
      {
        Header: "Nome Devedor",
        accessor: "dados.devNome",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devNome,
      },
      {
        Header: "CPF/CNPJ Devedor",
        accessor: "dados.devCpfCnpj",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devCpfCnpj,
      },
      {
        Header: "N°. Contrato",
        accessor: "dados.numero",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.numero,
      },
      {
        Header: "Data Anexo",
        accessor: "dataAnexo",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (dataAnexo) => <div>{MaskDate.TO_BR_DATETIME(dataAnexo.original.dataAnexo)}</div>,
        ReportCell: (row) => MaskDate.TO_BR_DATETIME(row.original.dataAnexo),
      },
      {
        Header: "UF Devedor",
        accessor: "dados.devUf",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devUf,
      },
      {
        Header: "CEP Devedor",
        accessor: "dados.devCep",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devCep,
      },
      {
        Header: "End. Devedor",
        accessor: "dados.devEndereco",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devEndereco,
      },
      {
        Header: "N°. Devedor",
        accessor: "dados.devNumero",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devNumero,
      },
      {
        Header: "Complemento Devedor",
        accessor: "dados.devComplemento",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devComplemento,
      },
      {
        Header: "Bairro Devedor",
        accessor: "dados.devBairro",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devBairro,
      },
      {
        Header: "Tel. Devedor",
        accessor: "dados.devTelefone",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devTelefone,
      },
      {
        Header: "Email Devedor",
        accessor: "dados.devEmail",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.devEmail,
      },
      {
        Header: "Tx Juros Mês",
        accessor: "dados.taxaJurosMes",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (taxaJurosMes) => <div>{`${taxaJurosMes.original.dados.taxaJurosMes ? MaskDecimal.TO_BR(taxaJurosMes.original.dados.taxaJurosMes) + " %" : ""}`}</div>,
        ReportCell: (row) => (row.original.dados.taxaJurosMes ? MaskDecimal.TO_BR(row.original.dados.taxaJurosMes) + " %" : ""),
      },
      {
        Header: "Tx Juros Ano",
        accessor: "dados.taxaJurosAno",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (taxaJurosAno) => <div>{`${taxaJurosAno.original.dados.taxaJurosAno ? MaskDecimal.TO_BR(taxaJurosAno.original.dados.taxaJurosAno) + " %" : ""}`}</div>,
        ReportCell: (row) => (row.original.dados.taxaJurosAno ? MaskDecimal.TO_BR(row.original.dados.taxaJurosAno) + " %" : ""),
      },
      {
        Header: "Tx Multa",
        accessor: "dados.taxaMulta",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (taxaMulta) => <div>{`${taxaMulta.original.dados.taxaMulta ? MaskDecimal.TO_BR(taxaMulta.original.dados.taxaMulta) + " %" : ""}`}</div>,
        ReportCell: (row) => (row.original.dados.taxaMulta ? MaskDecimal.TO_BR(row.original.dados.taxaMulta) + " %" : ""),
      },
      {
        Header: "Tx Mora",
        accessor: "dados.taxaMora",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (taxaMora) => <div>{`${taxaMora.original.dados.taxaMora ? MaskDecimal.TO_BR(taxaMora.original.dados.taxaMora) + " %" : ""}`}</div>,
        ReportCell: (row) => (row.original.dados.taxaMora ? MaskDecimal.TO_BR(row.original.dados.taxaMora) + " %" : ""),
      },
      {
        Header: "Tx Comissão",
        accessor: "dados.taxaComissao",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (taxaComissao) => <div>{`${taxaComissao.original.dados.taxaComissao ? MaskDecimal.TO_BR(taxaComissao.original.dados.taxaComissao) + " %" : ""}`}</div>,
        ReportCell: (row) => (row.original.dados.taxaComissao ? MaskDecimal.TO_BR(row.original.dados.taxaComissao) + " %" : ""),
      },
      {
        Header: "Valor Detran",
        accessor: "valorTaxa",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (valorTaxa) => <div>{`${valorTaxa.original.valorTaxa ? MaskDecimal.TO_BRL(valorTaxa.original.valorTaxa) : ""}`}</div>,
        ReportCell: (row) => (row.original.valorTaxa ? MaskDecimal.TO_BRL(row.original.valorTaxa) : ""),
      },
      {
        Header: "Valor ERC - Registradora",
        accessor: "valorTaxaERC",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (valorTaxaERC) => <div>{`${valorTaxaERC.original.valorTaxaERC ? MaskDecimal.TO_BRL(valorTaxaERC.original.valorTaxaERC) : ""}`}</div>,
        ReportCell: (row) => (row.original.valorTaxaERC ? MaskDecimal.TO_BRL(row.original.valorTaxaERC) : ""),
      },
      this.props.currentUser.tipo && (this.props.currentUser.tipo.includes("F") || this.props.currentUser.tipo.includes("G") || (this.props.currentUser.tipo.includes("P") && this.props.idParceiro))
        ? {
            Header: "Valor Parceiro",
            accessor: "valorIntegracaoExterna",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
            Cell: (valorIntegracaoExterna) => (
              <div>{`${valorIntegracaoExterna.original.valorIntegracaoExterna ? MaskDecimal.TO_BRL(valorIntegracaoExterna.original.valorIntegracaoExterna) : ""}`}</div>
            ),
            ReportCell: (row) => (row.original.valorIntegracaoExterna ? MaskDecimal.TO_BRL(row.original.valorIntegracaoExterna) : ""),
          }
        : this.state.dataFimAnteriorIntegra
        ? {
            Header: "Valor Integracao",
            accessor: "valorIntegracaoExterna",
            className: "align-middle text-center",
            headerClassName: "font-extra-bold",
            Cell: (valorIntegracaoExterna) => (
              <div>{`${valorIntegracaoExterna.original.valorIntegracaoExterna ? MaskDecimal.TO_BRL(valorIntegracaoExterna.original.valorIntegracaoExterna) : ""}`}</div>
            ),
            ReportCell: (row) => (row.original.valorIntegracaoExterna ? MaskDecimal.TO_BRL(row.original.valorIntegracaoExterna) : ""),
          }
        : "",
      {
        Header: "Valor Total Financ.",
        accessor: "valorTotalFinanciamento",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (valorTotalFinanciamento) => <div>{MaskDecimal.TO_BRL(valorTotalFinanciamento.original.dados.valorTotalFinanciamento)}</div>,
        ReportCell: (row) => MaskDecimal.TO_BRL(row.original.dados.valorTotalFinanciamento),
      },
      {
        Header: "Valor IOF",
        accessor: "dados.valorIOF",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.valorIOF,
      },
      {
        Header: "Valor Parcela",
        accessor: "valorParcela",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (valorParcela) => <div>{MaskDecimal.TO_BRL(valorParcela.original.dados.valorParcela)}</div>,
        ReportCell: (row) => MaskDecimal.TO_BRL(row.original.dados.valorParcela),
      },
      {
        Header: "Data Primeiro Venc.",
        accessor: "dataPrimeiroVenc",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (dataPrimeiroVenc) => <div>{MaskDate.TO_BR_DATE(dataPrimeiroVenc.original.dados.dataPrimeiroVenc)}</div>,
        ReportCell: (row) => MaskDate.TO_BR_DATE(row.original.dados.dataPrimeiroVenc),
      },
      {
        Header: "Data Último Venc.",
        accessor: "dataUltimoVenc",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (dataUltimoVenc) => <div>{MaskDate.TO_BR_DATE(dataUltimoVenc.original.dados.dataUltimoVenc)}</div>,
        ReportCell: (row) => MaskDate.TO_BR_DATE(row.original.dados.dataUltimoVenc),
      },
      {
        Header: "Data Lib. Crédito",
        accessor: "dataLiberacaoCred",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (dataLiberacaoCred) => <div>{MaskDate.TO_BR_DATE(dataLiberacaoCred.original.dados.dataLiberacaoCred)}</div>,
        ReportCell: (row) => MaskDate.TO_BR_DATE(row.original.dados.dataLiberacaoCred),
      },
      {
        Header: "Índices",
        accessor: "dados.indices",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.indices,
      },
      {
        Header: "N°. Aditivo",
        accessor: "dados.numeroAditivo",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.numeroAditivo,
      },
      {
        Header: "Data Aditivo",
        accessor: "dataAditivo",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (dataAditivo) => <div>{MaskDate.TO_BR_DATE(dataAditivo.original.dados.dataAditivo)}</div>,
        ReportCell: (row) => MaskDate.TO_BR_DATE(row.original.dados.dataAditivo),
      },
      {
        Header: "Penalidade",
        accessor: "dados.penalidade",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.penalidade,
      },
      {
        Header: "UF Lib. Cred.",
        accessor: "dados.ufLiberacaoCred",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.ufLiberacaoCred,
      },
      {
        Header: "Cidade Lib. Cred.",
        accessor: "dados.cidadeLiberacaoCred",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.cidadeLiberacaoCred,
      },
      {
        Header: "N°. Grupo Consorcio",
        accessor: "dados.numGrupoConsorcio",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.numGrupoConsorcio,
      },
      {
        Header: "N°. Cota Consorcio",
        accessor: "dados.numCotaConsorcio",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.numCotaConsorcio,
      },
      {
        Header: "Valor Crédito",
        accessor: "valorCredito",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (valorCredito) => <div>{MaskDecimal.TO_BRL(valorCredito.original.dados.valorCredito)}</div>,
        ReportCell: (row) => MaskDecimal.TO_BRL(row.original.dados.valorCredito),
      },
      {
        Header: "Dia Venc. Parcelas",
        accessor: "dados.diaVencParcelas",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.diaVencParcelas,
      },
      {
        Header: "Qtd. Parcelas",
        accessor: "dados.qtdeParcelas",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        ReportCell: (row) => row.original.dados.qtdeParcelas,
      },
      {
        Header: "N°. Registro",
        accessor: "dados.numRegistro",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.dados.numRegistro,
      },
      {
        Header: "Placa",
        accessor: "numPlaca",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (numPlaca) => <div>{this.renderVehicleList(numPlaca.original.dados.veiculos, "numPlaca")}</div>,
        ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map((veic) => veic.numPlaca).join("\n") : null),
      },
      {
        Header: "UF Placa",
        accessor: "ufPlaca",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (ufPlaca) => <div>{this.renderVehicleList(ufPlaca.original.dados.veiculos, "ufPlaca")}</div>,
        ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map((veic) => veic.ufPlaca).join("\n") : null),
      },
      {
        Header: "Renavam",
        accessor: "renavam",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (renavam) => <div>{this.renderVehicleList(renavam.original.dados.veiculos, "renavam")}</div>,
        ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map((veic) => veic.renavam).join("\n") : null),
      },
      {
        Header: "UF Licenciamento",
        accessor: "ufLicenciamento",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (ufLicenciamento) => <div>{this.renderVehicleList(ufLicenciamento.original.dados.veiculos, "ufLicenciamento")}</div>,
        ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map((veic) => veic.ufLicenciamento).join("\n") : null),
      },
      {
        Header: "Ano Fabricação",
        accessor: "anoFabricacao",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (anoFabricacao) => <div>{this.renderVehicleList(anoFabricacao.original.dados.veiculos, "anoFabricacao")}</div>,
        ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map((veic) => veic.anoFabricacao).join("\n") : null),
      },
      {
        Header: "Ano Modelo",
        accessor: "anoModelo",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (anoModelo) => <div>{this.renderVehicleList(anoModelo.original.dados.veiculos, "anoModelo")}</div>,
        ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map((veic) => veic.anoModelo).join("\n") : null),
      },
      {
        Header: "N°. Gravame",
        accessor: "numGravame",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (numGravame) => <div>{this.renderVehicleList(numGravame.original.dados.veiculos, "numGravame")}</div>,
        ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map((veic) => veic.numGravame).join("\n") : null),
      },
      {
        Header: "Nome Credor",
        accessor: "razaoSocial",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.razaoSocial,
      },
      {
        Header: "CPF/CNPJ Credor",
        accessor: "dados.cnpjFinanceira",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (cnpjFinanceira) => <div>{MaskCNPJ.TO_CNPJ(cnpjFinanceira.original.dados.cnpjFinanceira)}</div>,
        ReportCell: (row) => MaskCNPJ.TO_CNPJ(row.original.dados.cnpjFinanceira),
      },
      {
        Header: "Data Inclusão",
        accessor: "dataSistema",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (dataSistema) => <div>{MaskDate.TO_BR_DATETIME(dataSistema.original.dataSistema)}</div>,
        ReportCell: (row) => MaskDate.TO_BR_DATETIME(row.original.dataSistema),
      },
      {
        Header: "Data Contrato",
        accessor: "data",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        Cell: (data) => <div>{MaskDate.TO_BR_DATE(data.original.dados.data)}</div>,
        ReportCell: (row) => MaskDate.TO_BR_DATE(row.original.dados.data),
      },
      {
        Header: "Dev. Município",
        accessor: "municipio",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.municipio,
      },
      {
        Header: "Tipo Contrato",
        accessor: "dados.tipoContrato",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (tipoContrato) => (
          <div>
            {tipoContrato.original.dados.tipoContrato === 1
              ? "Alienação Fiduciária - Financiamento"
              : tipoContrato.original.dados.tipoContrato === 2
              ? "Alienação Fiduciária - Consórcio"
              : tipoContrato.original.dados.tipoContrato === 3
              ? "Alienação Fiduciária - Cédula de Crédito"
              : tipoContrato.original.dados.tipoContrato === 4
              ? "Arrendamento Mercantil - Leasing"
              : tipoContrato.original.dados.tipoContrato === 5
              ? "Reserva de Domínio"
              : tipoContrato.original.dados.tipoContrato === 6
              ? "Penhor"
              : ""}
          </div>
        ),
        ReportCell: (row) =>
          row.original.dados.tipoContrato === 1
            ? "Alienação Fiduciária - Financiamento"
            : row.original.dados.tipoContrato === 2
            ? "Alienação Fiduciária - Consórcio"
            : row.original.dados.tipoContrato === 3
            ? "Alienação Fiduciária - Cédula de Crédito"
            : row.original.dados.tipoContrato === 4
            ? "Arrendamento Mercantil - Leasing"
            : row.original.dados.tipoContrato === 5
            ? "Reserva de Domínio"
            : row.original.dados.tipoContrato === 6
            ? "Penhor"
            : "",
      },
      {
        Header: "UF Contrato",
        accessor: "ufContrato",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        sortable: false,
        Cell: (ufContrato) => <div>{ufContrato.original.uf}</div>,
        ReportCell: (row) => row.original.uf,
      },
      {
        Header: "Nome Fantasia",
        accessor: "nomeFantasia",
        className: "align-middle text-center",
        headerClassName: "font-extra-bold",
        ReportCell: (row) => row.original.nomeFantasia,
      },
      /*{
      Header: 'Espécie/Tipo',
      accessor: 'especieVeiculo',
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
      sortable: false,
      Cell: (especieVeiculo) => (<div>{this.renderVehicleList(especieVeiculo.original.dados.veiculos, 'especieVeiculo')}</div>),
      ReportCell: (row) => (row.original.dados.veiculos ? row.original.dados.veiculos.map(veic => veic.especieVeiculo).join('\n') : null)
    }*/
    ];
    return columns.filter((col) => this.state.selectedItems.find((item) => item.label === col.Header));
  };

  componentWillMount() {
    if (
      _.findIndex(this.props.currentUser.perfis, function (perfil) {
        return perfil === "ALIAS";
      }) > -1
    ) {
      if (this.props.currentUser.tipo && this.props.currentUser.tipo.includes("P")) {
        Promise.resolve(api.Financial.recuperaUfsParceiro(this.props.currentUser.idParceiro)).then((all) => {
          this.props.onUfSystemLoad([{ sigla: "Todas" }, ...all.ufs]);
          this.props.onLoadParceiro(all.parceiro);
          this.props.dispatch(change("ManagementReportForm", "idParceiro", all.parceiro[0].id));
        });
      } else {
        Promise.resolve(api.Ufs.sigla_system()).then((all) => {
          this.props.onUfSystemLoad([{ sigla: "Todos" }, ...all]);
        });
      }
      this.setState({ admin: true });
    } else {
      var todas = null;
      Promise.resolve(api.Financial.get(this.props.currentUser.idFinanceira)).then((financeira) => {
        if (!financeira.delegaPara) {
          todas = {
            id: 0,
            razaoSocial: "Todos",
          };
        }
        Promise.resolve(api.Companies.authorized(this.props.currentUser.idFinanceira)).then((all) => {
          if (todas !== null) {
            all.unshift(todas);
          }
          this.props.onLoad(all);
        });
      });
    }
    Promise.resolve(api.Users.reportFields(this.props.currentUser.id)).then((fields) => {
      if (fields.length > 0) {
        this.handleLoad(fields);
      }
      if (Object.keys(fields).length > 0 && fields.camposRelatorioGerencial && fields.camposRelatorioGerencial.length > 0) {
        this.handleLoad(fields.camposRelatorioGerencial);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyDownHandler);
    this.props.onUnload();
  }

  async filter(values) {
    await this.setState({ dataFimAnteriorIntegra: MaskDate.TO_DATABASE_DATE(values.dataFim) <= MaskDate.TO_DATABASE_DATE(new Date("2021-04-01")) ? true : false });
    await this.setState({ items: this.state.items.filter((item) => item.id !== 22) });

    if (
      this.props.currentUser.tipo &&
      (this.props.currentUser.tipo.includes("F") || this.props.currentUser.tipo.includes("G") || (this.props.currentUser.tipo.includes("P") && this.props.idParceiro))
    ) {
      await this.setState((prevState) => ({
        items: [...prevState.items, { id: 22, label: "Valor Parceiro" }],
      }));
      var index = this.state.selectedItems.findIndex((i) => i.id == 22);
      if (index > -1) {
        this.state.selectedItems[index].label = "Valor Parceiro";
      }
    } else if (this.state.dataFimAnteriorIntegra) {
      await this.setState((prevState) => ({
        items: [...prevState.items, { id: 22, label: "Valor Integracao" }],
      }));
      var index = this.state.selectedItems.findIndex((i) => i.id == 22);
      if (index > -1) {
        this.state.selectedItems[index].label = "Valor Integracao";
      }
    }
    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });

    Promise.resolve(
      api.Management.managementReport(
        values.agente.id,
        values.tipo,
        values.dataInicio ? MaskDate.TO_DATABASE_DATE(values.dataInicio) : null,
        values.dataFim ? MaskDate.TO_DATABASE_DATE(values.dataFim) : null,
        values.anexo,
        values.correspondente,
        values.uf.length === 2 ? values.uf : null,
        values.idParceiro
      )
    ).then((all) => {
      this.props.onListLoad(all);
      var filtered = this.props.companies.filter((comp) => comp.id == values.agente.id);
      this.setState({ financeira: filtered[0].razaoSocial, pesquisando: false });
      toastr.removeByType("info");
      if (!all || all.registros.length == 0) {
        toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
      }
    });
  }

  validateValues(values) {
    const errors = {};
    if (!values.uf || (values.uf && (values.uf.length === 0 || !values.uf.trim()))) {
      errors.uf = "Campo obrigatório";
    }
    if (values.agente == undefined || (!values.agente.id && values.agente.id != 0)) {
      errors.agente = "Campo obrigatório";
    }
    return errors;
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  renderVehicleListPDF(veiculos) {
    if (!veiculos || veiculos.length === 0) {
      return "";
    }
    return veiculos.map((v, index) => {
      return v.chassi + "\n";
    });
  }

  toggle(selectedItems) {
    this.setState(function (prevState) {
      return { showToggle: prevState.showToggle };
    });
  }

  exportPDF = () => (ev) => {
    const cols = this.setCols();
    const sortedData = this.selectTable.getResolvedState().sortedData;
    ev.preventDefault();
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 70;

    var title = `Relatório Gerencial E-Registro\n\n${`Financeira: ${this.state.all ? "TODAS AS FINANCEIRAS" : this.state.financeira}`}`;
    if (this.props.data.dataInicio) {
      title = title + `\nData Início Cobrança: ${MaskDate.TO_BR(this.props.data.dataInicio)} `;
      startTableY = startTableY + 15;
    }
    if (this.props.data.dataFim) {
      title = title + `\nData Fim Cobrança: ${MaskDate.TO_BR(this.props.data.dataFim)} `;
      startTableY = startTableY + 15;
    }
    if (this.props.data.temAnexo) {
      var temAnexoDesc = "Todos";
      if (this.props.data.temAnexo == "S") {
        temAnexoDesc = "Sim";
      }
      if (this.props.data.temAnexo == "N") {
        temAnexoDesc = "Não";
      }
      title = title + `\nAnexo: ${temAnexoDesc} `;
      startTableY = startTableY + 15;
    }
    //const headers = [["TIPO", "DATA REGISTRO", "CHASSI", "NOME DEVEDOR", "CPF/CNPJ DEVEDOR", "NUMERO CONTRATO", "DATA ANEXO"]];
    const headers = [cols.map((col) => [col.Header])];
    var totais = `Quantidade total de eventos: ${this.props.data.quantidade} \nQuantidade total de chassis: ${this.props.data.qtdChassis} `;
    if (this.props.data.valorTaxaTotal) {
      totais = totais + `\nTotal Cobrança Detran: ${MaskDecimal.TO_BRL(this.props.data.valorTaxaTotal)} `;
    }
    if (this.props.data.valorERCTotal) {
      totais = totais + `\nTotal Cobrança Registradora: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal)} `;
    }
    if (this.props.data.valorIntegracaoExternaTotal && this.props.uf && this.props.uf !== "PR" && this.props.idParceiro) {
      totais = totais + `\nTotal Cobrança Parceiro: ${MaskDecimal.TO_BRL(this.props.data.valorIntegracaoExternaTotal)} `;
      let vlporcentagemTotal = 0;
      let vlInteMenosPorcentTotal = 0;
      this.props.data.registros.forEach((item) => {
        let vlporcentagem = 0.1953 * item.valorIntegracaoExterna;
        vlporcentagemTotal += vlporcentagem;
        vlInteMenosPorcentTotal += item.valorIntegracaoExterna - vlporcentagem;
      });
      totais = totais + `\nTotal Imposto NF Alias: ${MaskDecimal.TO_BRL(vlporcentagemTotal)} `;
      totais = totais + `\nLíquido Total Parceiro: ${MaskDecimal.TO_BRL(vlInteMenosPorcentTotal)} `;
      totais = totais + `\nLíquido Cobrança Alias: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal - this.props.data.valorIntegracaoExternaTotal)} `;
    }
    if (this.props.data.valorIntegracaoExternaTotal && this.props.uf && this.props.uf !== "PR") {
      !this.props.idParceiro && this.state.dataFimAnteriorIntegra ? (totais = totais + `\nValor Total Taxa Integra+: ${MaskDecimal.TO_BRL(this.props.data.valorIntegracaoExternaTotal)} `) : "";
    }
    // if (this.props.data.valorERCTotal && this.props.data.valorIntegracaoExternaTotal && this.props.uf && this.props.uf !== "PR") {
    //   this.props.idParceiro
    //     ? (totais = totais + `\nValor Total Taxa Registradora e Parceiro: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal + this.props.data.valorIntegracaoExternaTotal)} `)
    //     : this.state.dataFimAnteriorIntegra
    //     ? (totais = totais + `\nValor Total Taxa Registradora e Integra+: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal + this.props.data.valorIntegracaoExternaTotal)} `)
    //     : "";
    // }

    const data = sortedData.map((row) => {
      row.original = row._original;
      return cols.map((col) => col.ReportCell(row));
    });
    //   const data = this.props.data.registros.map(row =>
    //     [ row.tipo === 1 ? 'Inclusão de contrato' : row.tipo === 2 ? 'Alteração de contrato' : row.tipo === 3 ? 'Averbação de contrato' : row.tipo === 4 ? 'Alteração de averbação' : '',
    //       MaskDate.TO_BR_DATETIME(row.dataInclusao),
    //       this.renderVehicleListPDF(row.dados.veiculos).toString().replace(',',''),
    //       row.dados.devNome,
    //       row.dados.devCpfCnpj,
    //       row.dados.numero,
    //       MaskDate.TO_BR_DATETIME(row.dataAnexo),
    //     ]
    //   );

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      // //pageBreak: 'always',
      // columnStyles: {
      //   0: { columnWidth: 'wrap' },
      //   1: {
      //     columnWidth: 53
      //   },
      //   2: {
      //     columnWidth: 85
      //   },
      //   3: {
      //     columnWidth: 35
      //   },
      //   4: {
      //     columnWidth: 35
      //   },
      //   5: {
      //     columnWidth: 35
      //   },
      //   6: {
      //     columnWidth: 35
      //   }
      // },
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.text(totais, marginLeft, doc.autoTable.previous.finalY + 20);
    doc.save("RelatorioGerencial.pdf");
  };

  result(params) {
    console.log(params);
  }

  recuperaCredoresUf = (event) => {
    var props = this.props;
    if (
      _.findIndex(this.props.currentUser.perfis, function (perfil) {
        return perfil === "ALIAS";
      }) > -1
    ) {
      Promise.resolve(
        api.Financial.filtroPorUf(
          event.target.value != "Todos" ? event.target.value : null,
          this.props.currentUser.tipo && this.props.currentUser.tipo.includes("P") ? this.props.parceiros[0].id : null
        )
      ).then((all) => {
        var todas = {
          id: 0,
          razaoSocial: this.props.currentUser.tipo && this.props.currentUser.tipo.includes("P") ? "Todas do Parceiro" : "Todos",
        };
        all.unshift(todas);
        this.props.onLoad(all);
        if (props.agente && !all.find((item) => item.id === props.agente.id)) {
          this.props.dispatch(change("ManagementReportForm", "agente", null));
        }
      });
      if (this.props.currentUser.tipo && (this.props.currentUser.tipo.includes("G") || this.props.currentUser.tipo.includes("F"))) {
        this.props.onLoadParceiro(Promise.resolve(api.Financial.getParceirosPorUf(event.target.value)));
      }
    }
  };

  recuperaCredoresDoParceiro = (event) => {
    var props = this.props;
    this.props.onLoad([]);
    if (event.target.value) {
      Promise.resolve(api.Financial.filtroPorParceiro(event.target.value)).then((all) => {
        if (all.length > 0) {
          var todas = {
            id: 0,
            razaoSocial: "Todas do Parceiro",
          };
          all.unshift(todas);
        }
        this.props.onLoad(all);
        if (props.agente && !all.find((item) => item.id === props.agente.id)) {
          this.props.dispatch(change("ManagementReportForm", "agente", null));
        }
      });
    } else if (this.props.uf) {
      Promise.resolve(api.Financial.filtroPorUf(this.props.uf != "Todos" ? this.props.uf : null)).then((all) => {
        var todas = {
          id: 0,
          razaoSocial: "Todos",
        };
        all.unshift(todas);
        this.props.onLoad(all);
      });
    }
  };

  render() {
    const { handleSubmit, reset } = this.props;
    const { items, selectedItems, dataFimAnteriorIntegra } = this.state;
    const messages = {
      searchPlaceholder: "Procurar campos...",
      noItemsMessage: "Sem Itens",
      noneSelectedMessage: "Nenhum selecionado",
      selectedMessage: "selecionados",
      selectAllMessage: "Selecionar tudo",
      clearAllMessage: "Limpar tudo",
      disabledItemsTooltip: "Você só pode selecionar 8 campos",
    };
    // remover a opcao Todos das ufs do usuario
    var ufsUser = [...this.props.currentUser.ufs];
    //if (ufsUser && ufsUser.length > 0) {
    //    ufsUser.shift();
    //}
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="de Contratos Registrados" />{" "}
        <div className="content">
          <div className="row">
            <div
              className={
                this.props.currentUser.tipo && (this.props.currentUser.tipo.includes("G") || this.props.currentUser.tipo.includes("P") || this.props.currentUser.tipo.includes("F"))
                  ? "col-lg-6"
                  : "col-lg-12"
              }
            >
              <Field
                name="uf"
                blank={true}
                label="UF"
                required
                data={this.state.admin ? this.props.ufSystem : ufsUser}
                textField="sigla"
                component={SelectField}
                type="text"
                onChange={this.recuperaCredoresUf.bind(this)}
              />
            </div>
            {this.props.currentUser.tipo && (this.props.currentUser.tipo.includes("G") || this.props.currentUser.tipo.includes("P") || this.props.currentUser.tipo.includes("F")) && (
              <div className="col-lg-6">
                <Field
                  name="idParceiro"
                  blank={this.props.currentUser.tipo.includes("P") ? false : true}
                  label="Parceiro"
                  data={this.props.parceiros}
                  textField="nome"
                  valueField="id"
                  component={SelectField}
                  disabled={this.props.currentUser.tipo.includes("P")}
                  type="text"
                  onChange={this.recuperaCredoresDoParceiro.bind(this)}
                />
              </div>
            )}
            <div className={this.state.admin ? "col-lg-6" : "col-lg-12"}>
              <Field name="agente" blank={true} required label="Credor" data={this.props.companies} textField="razaoSocial" credor={true} valueField="id" component={DropdownListField} type="text" />
            </div>
            {this.state.admin && (
              <div className="col-lg-6">
                <Field name="correspondente" label="Correspondente" data={correspondente} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
            )}
            <div className="col-lg-6">
              <Field
                name="dataInicio"
                label="Data Início Cobrança"
                required
                placeholder="00/00/0000"
                data={this.props.dataInicio}
                component={TextDatePickerField}
                type="text"
                validate={[FieldValidation.date, FieldValidation.required]}
                normalize={FieldNormalize.DATE}
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="dataFim"
                label="Data Fim Cobrança"
                required
                placeholder="00/00/0000"
                data={this.props.dataFim}
                component={TextDatePickerField}
                type="text"
                validate={[FieldValidation.date, FieldValidation.required]}
                normalize={FieldNormalize.DATE}
              />
            </div>
            <div className="col-lg-6">
              <Field name="tipo" label="Tipo de registro" data={tipos} textField="label" valueField="value" component={SelectField} type="text" />
            </div>
            <div className="col-lg-6">
              <Field name="anexo" label="Anexo" data={anexos} textField="label" valueField="value" component={SelectField} type="text" />
            </div>

            <div className="col-lg-12">
              <div className="hpanel">
                <button
                  className="btn btn-info"
                  disabled={this.state.pesquisando}
                  id="filterRelGer"
                  onClick={this.props.handleSubmit((values) => {
                    //Guarda se o usuário escolheu TODAS FINANCEIRAS/Financeira específica
                    this.setState({ all: values.agente == 0 });
                    return new Promise((resolve, reject) => {
                      var errors;
                      if (this) {
                        errors = this.validateValues(values);
                      }
                      if (errors && !this.isEmpty(errors)) {
                        reject(new SubmissionError(errors));
                      } else {
                        this.filter(values);
                        resolve();
                      }
                    });
                  })}
                >
                  {" "}
                  {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                </button>

                <div className="panel-heading">
                  {this.props.data.quantidade ? `Quantidade de eventos: ${this.props.data.quantidade} ` : ""}
                  <br />
                  {this.props.data.qtdChassis ? `Quantidade de chassis: ${this.props.data.qtdChassis} ` : ""}
                  <br />
                  {this.props.data.valorTaxaTotal ? `Total Cobrança Detran: ${MaskDecimal.TO_BRL(this.props.data.valorTaxaTotal)} ` : ""}
                  <br />
                  {this.props.data.valorERCTotal ? `Total Cobrança Registradora: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal)} ` : ""}
                  <br />
                  {/* {this.props.uf && this.props.uf !== "PR" && ( */}
                  <>
                    <div>{dataFimAnteriorIntegra && this.props.data.valorIntegracaoExternaTotal && `Valor Total Integra+: ${MaskDecimal.TO_BRL(this.props.data.valorIntegracaoExternaTotal)} `}</div>
                    <div>{this.props.idParceiro && this.props.data.valorIntegracaoExternaTotal ? this.renderInfoParceiro() : null}</div>
                  </>
                  {/* )} */}
                  {/* {this.props.uf && this.props.uf !== "PR" && (
                    <div>
                      {dataFimAnteriorIntegra
                        ? this.props.data.valorERCTotal && this.props.data.valorIntegracaoExternaTotal
                          ? `Valor Total Taxa Registradora e Integra+: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal + this.props.data.valorIntegracaoExternaTotal)} `
                          : ""
                        : this.props.idParceiro && this.props.data.valorERCTotal && this.props.data.valorIntegracaoExternaTotal
                        ? `Valor Total Taxa Registradora e Parceiro: ${MaskDecimal.TO_BRL(this.props.data.valorERCTotal + this.props.data.valorIntegracaoExternaTotal)} `
                        : ""}
                    </div>
                  )} */}
                </div>
                <div className="panel-body">
                  {this.props.data.quantidade &&
                  (_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 ||
                    this.props.currentUser.funcionalidades.includes(20)) ? (
                    <Link className="btn btn-default float-right" onClick={() => this.exportChartToXLSX()} title="Gerar Excel">
                      {" "}
                      <SvgIcon size={20} icon={fileExcelO} />
                    </Link>
                  ) : null}
                  {this.props.data.quantidade &&
                  (_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 ||
                    this.props.currentUser.funcionalidades.includes(19)) ? (
                    <Link className="btn btn-default float-right" onClick={this.exportPDF()} title="Gerar PDF">
                      {" "}
                      <SvgIcon size={20} icon={filePdf} />
                    </Link>
                  ) : null}
                  {this.props.data.quantidade &&
                  (_.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1 ||
                    this.props.currentUser.funcionalidades.includes(18)) ? (
                    <Link className="btn btn-default float-right" onClick={this.handleToggle} title="Configurar Colunas da Tabela">
                      {" "}
                      <SvgIcon size={20} icon={this.state.showToggle ? checkCircle : gear} />
                    </Link>
                  ) : null}
                  {this.state.showToggle ? (
                    <MultiSelect
                      items={items}
                      selectedItems={selectedItems}
                      onChange={this.handleChange}
                      showSelectAll={true}
                      showSelectedItems={true}
                      maxSelectedItems={8}
                      messages={messages}
                      itemHeight={38}
                      filterFunction={(value) => (item) => FieldNormalize.NO_SP_CHARACTERS(String(item.label)).includes(FieldNormalize.NO_SP_CHARACTERS(value))}
                    />
                  ) : null}
                  <div className="table-responsive">{this.renderDataList()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInfoParceiro() {
    let vlporcentagemTotal = 0;
    let vlInteMenosPorcentTotal = 0;
    this.props.data.registros.forEach((item) => {
      let vlporcentagem = 0.1953 * item.valorIntegracaoExterna;
      vlporcentagemTotal += vlporcentagem;
      vlInteMenosPorcentTotal += item.valorIntegracaoExterna - vlporcentagem;
    });
    return (
      <>
        Valor Total Parceiro: {MaskDecimal.TO_BRL(this.props.data.valorIntegracaoExternaTotal)}
        <br />
        Total Imposto NF Alias: {MaskDecimal.TO_BRL(vlporcentagemTotal)}
        <br />
        Líquido Total Parceiro: {MaskDecimal.TO_BRL(vlInteMenosPorcentTotal)}
        <br />
        Líquido Cobrança Alias: {MaskDecimal.TO_BRL(this.props.data.valorERCTotal - this.props.data.valorIntegracaoExternaTotal)}
      </>
    );
  }

  renderVehicleList(veiculos, field) {
    if (!veiculos || veiculos.length === 0) {
      return <div></div>;
    }
    return veiculos.map((v, index) => {
      return (
        <div>
          {v[field]}
          <br />
        </div>
      );
    });
  }

  renderDataList() {
    const { handleSubmit } = this.props;
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.data.registros}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando.."
        noDataText="Nenhum registro encontrado"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
        columns={this.setCols()}
        defaultPageSize={50}
        showPageSizeOptions={false}
        minRows={1}
        ref={(r) => {
          this.selectTable = r;
        }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: MANAGEMENT_REPORT_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: MANAGEMENT_REPORT_LIST_LOAD, payload }),
  onUnload: () => dispatch({ type: MANAGEMENT_REPORT_UNLOAD }),
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
  onLoadParceiro: (payload) => dispatch({ type: MANAGEMENT_REPORT_PARCEIRO_LOAD, payload }),
});

const selector = formValueSelector("ManagementReportForm");
const mapStateToProps = (state) => ({
  ...state.managementReport,
  currentUser: state.common.currentUser,
  agente: selector(state, "agente"),
  dataInicio: selector(state, "dataInicio"),
  dataFim: selector(state, "dataFim"),
  uf: selector(state, "uf"),
  idParceiro: selector(state, "idParceiro"),
});

const form = reduxForm({
  form: "ManagementReportForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ManagementReport));
