import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import OtherUtils from "../components/Util/OtherUtils";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = ""; //https://pr.e-registro.net.br

const encode = encodeURIComponent;
const responseBody = (res) => res.body;
const responseText = (res) => res.text;

let refreshToken = null;
let token = null;
const tokenPlugin = (req) => {
  var token = localStorage.getItem("jwt");
  if (token) {
    req.set("Authorization", `Bearer ${token}`);
  } else {
    console.log("NO TOKEN");
  }
  req.set("CSRF-Token", Cookies.get("XSRF-TOKEN"));
};

const applicationJson = (req) => {
  req.set("Accept", `application/json`);
  req.set("Content-Type", `application/json`);
};

const applicationPdf = (req) => {
  req.set("Accept", `application/pdf`);
  req.set("Content-Type", `application/pdf`);
};

const applicationTxt = (req) => {
  req.set("Accept", `text/plain`);
  req.set("Content-Type", `text/plain`);
};

function decodeUserProfile(idToken) {
  try {
    const userProfile = jwt_decode(idToken);
    userProfile.cnpj = userProfile.cnpj ? OtherUtils.decrypt(Buffer.from(userProfile.cnpj, "base64"), userProfile.idFinanceira).toString("utf8") : null;
    userProfile.nome = userProfile.nome ? OtherUtils.decrypt(Buffer.from(userProfile.nome, "base64"), userProfile.idFinanceira).toString("utf8") : null;
    userProfile.user = userProfile.user ? OtherUtils.decrypt(Buffer.from(userProfile.user, "base64"), userProfile.idFinanceira).toString("utf8") : null;
    return userProfile;
  } catch (err) {
    return null;
  }
}

function loadUserProfile() {
  try {
    const idToken = localStorage.getItem("jwt");
    const userProfile = jwt_decode(idToken);
    userProfile.cnpj = userProfile.cnpj ? OtherUtils.decrypt(Buffer.from(userProfile.cnpj, "base64"), userProfile.idFinanceira).toString("utf8") : null;
    userProfile.nome = userProfile.nome ? OtherUtils.decrypt(Buffer.from(userProfile.nome, "base64"), userProfile.idFinanceira).toString("utf8") : null;
    userProfile.user = userProfile.user ? OtherUtils.decrypt(Buffer.from(userProfile.user, "base64"), userProfile.idFinanceira).toString("utf8") : null;

    const now = new Date().getTime() / 1000; // Date().getTime() returns milliseconds.
    // So divide by 1000 to get seconds
    if (now > userProfile.exp) {
      // user profile has expired.
      removeIdToken();
      return null;
    }
    if (userProfile.ufs.length > 0 && userProfile.ufs.indexOf("Todas") == -1) {
      userProfile.ufs.unshift({ sigla: "Todas" });
    }
    return userProfile;
  } catch (err) {
    return null;
  }
}

function removeIdToken() {
  localStorage.removeItem("jwt");
}

function getAsUriParameters(data) {
  var url = "?";
  for (var prop in data) {
    url += encodeURIComponent(prop) + "=" + encodeURIComponent(data[prop]) + "&";
  }
  return url.substring(0, url.length - 1);
}

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .use(applicationJson)
      .then(responseBody)
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
        }
        throw err;
      }),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .use(applicationJson)
      .then(responseBody)
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
        }
        throw err;
      }),
  get: (url, body) =>
    superagent
      .get(`${API_ROOT}${url}${getAsUriParameters(body)}`)
      .use(tokenPlugin)
      .use(applicationJson)
      .then(responseBody)
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
        }
        throw err;
      }),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .use(applicationJson)
      .then(responseBody)
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
        }
        throw err;
      }),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .use(applicationJson)
      .then(responseBody)
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
          throw err.response.body.message;
        }
        throw err;
      }),
  postFile: (url, file) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .attach("imagem", file)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
        }
        throw err;
      }),
  postMultipleFiles: (url, files) => {
    return new Promise(function (fulfill, reject) {
      let post = superagent.post(`${API_ROOT}${url}`);
      for (const file of files) {
        post.attach("imagem", file);
      }
      post
        .use(tokenPlugin)
        .then((responseBody) => fulfill(responseBody))
        .catch(function (err) {
          if (err.message === "Unauthorized" || err.message === "jwt expired") {
            token = null;
          }
          reject(err);
        });
    });
  },
  postMultipleFilesWithName: (url, files) => {
    return new Promise(function (fulfill, reject) {
      let post = superagent.post(`${API_ROOT}${url}`);
      for (const file of files) {
        //let name = file.name;
        //delete file.name;
        post.attach(file.name, file.file);
      }
      post
        .use(tokenPlugin)
        .then((responseBody) => fulfill(responseBody))
        .catch(function (err) {
          if (err.message === "Unauthorized" || err.message === "jwt expired") {
            token = null;
          }
          reject(err);
        });
    });
  },
  postFileDescription: (url, file, body) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .field("descricao", body.descricao)
      .attach("imagem", file)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
        }
        throw err;
      }),
  postFileDados: (url, file, body) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .field("dados", body.dados)
      .attach("imagem", file)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
        }
        throw err;
      }),
  getFile: (url, body) =>
    superagent
      .get(`${API_ROOT}${url}${getAsUriParameters(body)}`)
      .responseType("blob")
      .use(tokenPlugin)
      .use(applicationPdf)
      .then(responseBody)
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
        }
        throw err;
      }),
  getTxt: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .responseType("text")
      .use(tokenPlugin)
      .use(applicationTxt)
      .then(responseText)
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
        }
        throw err;
      }),
  getFileGeneric: (url, body) =>
    superagent
      .get(`${API_ROOT}${url}${getAsUriParameters(body)}`)
      .responseType("blob")
      .use(tokenPlugin)
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch(function (err) {
        if (err.message === "Unauthorized" || err.message === "jwt expired") {
          token = null;
        }
        throw err;
      }),
};

const Auth = {
  login: (username, password, captcha, idFinanceira) =>
    requests.post(`/api/login`, {
      user: username,
      password: password,
      captcha: captcha,
      idFinanceira: idFinanceira,
    }),
  logout: () => requests.post(`/api/logout`),
  refresh: () => requests.post(`/api/refresh`),
  checkOperationPassword: (username, password) =>
    requests.post(`/api/checkOperationPassword`, {
      user: username,
      passwordOperation: password,
    }),
  newPassword: (username) => requests.post(`/api/nova-senha`, { user: username }),
  loginMicrosoft: (access_token) => requests.post(`/api/loginMicrosoft`, { access_token: access_token }),
  ping: () => requests.get("/api"),
  loginRecovery: (uuidRecovery) => requests.post(`/api/loginRecovery`, { uuidRecovery: uuidRecovery }),
};

const Users = {
  companyUsers: (companyId, delegaPara) =>
    requests.get(`/api/usuarios/detalhados`, {
      idFinanceira: companyId,
      delegaPara,
    }),
  get: (id) => requests.get(`/api/usuarios/${id}`),
  create: (user) => requests.post(`/api/usuarios`, JSON.stringify(user)),
  update: (user) => requests.put(`/api/usuarios/${user.id}`, JSON.stringify(user)),
  lastAccess: (id) => requests.get(`/api/usuarios/${id}/ultimo-acesso`),
  block: (id) => requests.put(`/api/usuarios/${id}/bloquear`),
  unblock: (id) => requests.put(`/api/usuarios/${id}/desbloquear`),
  reportFields: (id) => requests.get(`/api/usuarios/${id}/camposRelatorio`),
  updateReportFields: (id, fields) => requests.put(`/api/usuarios/${id}/camposRelatorio`, fields),
  allLogAcess: (id) => requests.get(`/api/usuarios/logAcesso/${id}`),
  confirmTermsOfUse: () => requests.put(`/api/usuarios/aceiteTermoDeUso`),
  getFeatures: (id) => requests.get(`/api/usuarios/funcionalidades/${id}`),
  getEvents: () => requests.get(`/api/gestao/eventos`),
  getAuditoria: (idFinanceira, idUsuario, evento, dtInicio, dtFim) =>
    requests.get(
      `/api/usuarios/auditoria/financeira/${idFinanceira}?1=1` +
        (idUsuario && idUsuario > 0 ? `&idUsuario={"eq":"${idUsuario}"}` : "") +
        (evento && evento != 0 ? `&evento={"eq":${JSON.stringify(evento)}}` : "") +
        (dtInicio ? `&data={"ge":"${dtInicio}"}` : "") +
        (dtFim ? `&data={"le":"${dtFim}"}` : "")
    ),
  getAuditoriaUser: (id) => requests.get(`/api/usuarios/auditoria/${id}`),
  deleteUser: (id) => requests.del(`/api/usuarios/${id}`),
  updateStatus: (id, value) => requests.put(`/api/usuarios/${id}/situacao/${value}`),
  getUsuariosSuporte: () => requests.get(`/api/usuarios/suporte`),
  updateUsuarioSuporte: (user) => requests.put(`/api/usuarios/suporte`, JSON.stringify(user)),
  filterSearchUserList: (dados) => requests.post(`/api/usuarios/searchListFinancial`, JSON.stringify(dados)),
  getUsuariosAlias: () => requests.get(`/api/usuarios/gestao/alias`),
  createAlias: (user) => requests.post(`/api/usuariosAlias`, JSON.stringify(user)),
  updateAlias: (user) => requests.put(`/api/usuariosAlias/${user.id}`, JSON.stringify(user)),
};

const Roles = {
  all: () => requests.get(`/api/perfis`),
  financialRoles: (id) => requests.get(`/api/perfis/financeiras/${id}`),
  get: (id) => requests.get(`/api/perfis/${id}`),
};

const Companies = {
  all: () => requests.get(`/api/financeiras`),
  get: (id) => requests.get(`/api/financeiras/${id}`),
  empowered: (id) => requests.get(`/api/financeiras/${id}/delegadas`),
  authorized: (id) => requests.get(`/api/financeiras/${id}/credenciadas`),
  responsible: (id) => requests.get(`/api/financeiras/${id}/correspondentes`),
};

const Agentes = {
  byCnpj: (documento) => requests.get(`/api/agentes/${documento}`),
};

const Contracts = {
  get: (id) => requests.get(`/api/contratos/${encode(id)}`),
  getByNumber: (number, uf) => requests.get(`/api/contratos/numero/${encode(number)}/${uf}`),
  getByNumberExact: (dados) => requests.post(`/api/contratos/numeroExato`, dados),
  getByChassi: (chassi) => requests.get(`/api/contratos/chassi/${encode(chassi)}`),
  getByRegister: (number) => requests.get(`/api/contratos/registro/${encode(number)}`),
  notRegistered: (
    numRegistro,
    devCpfCnpj,
    numero,
    credor,
    dtInicial,
    dtFinal,
    status,
    start,
    size,
    renavam,
    chassi,
    devNome,
    devUf,
    devMunicipio,
    tipoContrato,
    marcaModelo,
    placa,
    ano,
    dataGravame,
    tpPesquisaData,
    uf
  ) =>
    requests.get(
      `/api/contratos/naoEnviados?1=1` +
        (devCpfCnpj ? `&devCpfCnpj={"eq":${JSON.stringify(devCpfCnpj)}}` : "") +
        (numero ? `&numero={"like":${JSON.stringify(numero)}}` : "") +
        (credor ? `&idFinanceira={"eq":${credor}}` : "") +
        (dtInicial ? `&dataI={"ge":"${dtInicial}"}` : "") +
        (dtFinal ? `&dataF={"le":"${dtFinal}"}` : "") +
        (status ? `&status={"eq":${status}}` : "") +
        (renavam ? `&renavam={"like":${JSON.stringify(renavam)}}` : "") +
        (chassi ? `&chassi={"like":${JSON.stringify(chassi)}}` : "") +
        (devNome ? `&devNome={"like":${JSON.stringify(devNome)}}` : "") +
        (numRegistro ? `&like={"eq":${JSON.stringify(numRegistro)}}` : "") +
        (devUf ? `&devUf={"like":${JSON.stringify(devUf)}}` : "") +
        (devMunicipio ? `&devMunicipio={"like":${JSON.stringify(devMunicipio)}}` : "") +
        (tipoContrato ? `&tipoContrato={"like":${JSON.stringify(tipoContrato)}}` : "") +
        (marcaModelo ? `&marcaModelo={"like":${JSON.stringify(marcaModelo)}}` : "") +
        (placa ? `&placa={"like":${JSON.stringify(placa)}}` : "") +
        (ano ? `&ano={"like":${JSON.stringify(ano)}}` : "") +
        (size ? `&page={"start":${start},"size":${size}}` : "") +
        (dataGravame ? `&dataGravame={"eq":"${dataGravame}"}` : "") +
        (tpPesquisaData ? `&tpPesquisaData={"eq":"${tpPesquisaData}"}` : "") +
        (uf ? `&uf={"eq":"${uf}"}` : "")
    ),
  vehicles: (idContract) => requests.get(`/api/contratos/${idContract}/veiculos`),
  create: (data) => requests.post(`/api/contratos`, JSON.stringify(data)),
  createSync: (data) => requests.post(`/api/contratos/sync  `, JSON.stringify(data)),
  additive: (data) => requests.post(`/api/contratos/${data.dados.contrato.id}/averbar`, JSON.stringify(data)),
  update: (data) => requests.put(`/api/contratos/veiculos`, JSON.stringify(data)),
  log: (id) => requests.get(`/api/contratos-logs/${id}/log`),
  lastLog: (id) => requests.get(`/api/contratos-logs/${id}/last-log`),
  comunicaDetran: (id) => requests.get(`/api/contratos/${id}/detran`),
  //addVehicle: (data, contractId) => requests.post(`/api/contratos/${contractId}/veiculo`,JSON.stringify(data)),
  //updateVehicle: (data, contractId) => requests.put(`/api/contratos/${contractId}/veiculo/${data.veiculo.id}`,JSON.stringify(data)),
  register: (id) => requests.post(`/api/contratos/${id}/registrarDetran`),
  registerAdditive: (id) => requests.post(`/api/contratos/${id}/averbarDetran`),
  registerTaxaPaga: (id) => requests.post(`/api/contratos/${id}/taxaPaga`),
  //registerAdditive: (id,data) => requests.post(`/api/contratos/${id}/averbarDetran`,JSON.stringify(data)),
  change: (data) => requests.put(`/api/contratos`, JSON.stringify(data)),
  sendImages: (id, files) => requests.postMultipleFiles(`/api/contratos/${id}/imagem`, files),
  sendImagesTemp: (id, file) => requests.postMultipleFiles(`/api/contratos/${id}/imagemTemp`, file),
  updateImages: (id, file) => requests.postMultipleFiles(`/api/contratos/${id}/alteraimagem`, file),
  sendFiles: async (files) => await requests.postFiles(`/api/imagensLote`, files),
  //finishVehicle: (cnpj, contractId, renavam) => requests.post(`/api/detran/veiculos/baixar`,{cnpjFinanceira: cnpj, idContrato: contractId, renavam: renavam}),
  //cancel: (cnpj, contractId, numeroRegistro) => requests.post(`/api/detran/contratos/cancelar`,{cnpjFinanceira: cnpj, idContrato: contractId, numeroRegistro: numeroRegistro}),
  countNotRegistered: () => requests.get(`/api/contratos/quantidadeNaoEnviados`),
  getImage: (id) => requests.getFile(`/api/contratos/${id}/imagem`),
  getRegistered: (
    cnpj,
    status,
    numeroRegistro,
    numero,
    devCpfCnpj,
    renavam,
    chassi,
    dtInicio,
    dtFim,
    devNome,
    devUf,
    devMunicipio,
    tipoContrato,
    marcaModelo,
    placa,
    ano,
    dataGravame,
    integra,
    tpPesquisaData,
    uf,
    numGravame,
    idParceiro
  ) =>
    requests.get(
      `/api/contratos/generico/${cnpj}?1=1` +
        (status ? `&status={"eq":${JSON.stringify(status)}}` : "") +
        (numeroRegistro ? `&numRegistro={"like":${JSON.stringify(numeroRegistro)}}` : "") +
        (numero ? `&numero={"like":${JSON.stringify(numero)}}` : "") +
        (devCpfCnpj ? `&devCpfCnpj={"eq":${JSON.stringify(devCpfCnpj)}}` : "") +
        (renavam ? `&renavam={"like":${JSON.stringify(renavam)}}` : "") + //ok
        (chassi ? `&chassi={"like":${JSON.stringify(chassi)}}` : "") + //ok
        (dtInicio ? `&data={"ge":"${dtInicio}"}` : "") +
        (dtFim ? `&data={"le":"${dtFim}"}` : "") +
        (devNome ? `&devNome={"like":${JSON.stringify(devNome)}}` : "") +
        (devUf ? `&devUf={"like":${JSON.stringify(devUf)}}` : "") +
        (devMunicipio ? `&devMunicipio={"like":${JSON.stringify(devMunicipio)}}` : "") +
        (tipoContrato ? `&tipoContrato={"eq":${JSON.stringify(tipoContrato)}}` : "") +
        (marcaModelo ? `&marcaModelo={"like":"${marcaModelo}"}` : "") + //ok
        (placa ? `&numPlaca={"like":${JSON.stringify(placa)}}` : "") + //ok
        (ano ? `&anoFabricacao={"like":${JSON.stringify(ano)}}` : "") + //ok
        (dataGravame ? `&dataGravame={"eq":"${dataGravame}"}` : "") + //ok
        (integra ? `&integra={"eq":"${integra}"}` : "") +
        (tpPesquisaData ? `&tpPesquisaData={"eq":"${tpPesquisaData}"}` : "") +
        (uf ? `&uf={"eq":"${uf}"}` : "") +
        (numGravame ? `&numGravame={"like":${JSON.stringify(numGravame)}}` : "") +
        (idParceiro ? `&idParceiro={"eq":"${idParceiro}"}` : "")
    ),
  findByChassi: (id) => requests.get(`/api/contratos/dadosGravame/chassi/${id}`),
  findByPlaca: (id) => requests.get(`/api/contratos/dadosGravame/placa/${id}`),
  lastMessage: (id) => requests.get(`/api/contratos/${id}/retorno`),
  findByDadosMG: (chassi, gravame) =>
    requests.post(`/api/contratos/dadosGravameMG`, {
      chassi: chassi,
      gravame: gravame,
    }),
  aprove: (id) => requests.post(`/api/contratos/${id}/homologarRegistro`),
  reprove: (reprove) => requests.post(`/api/contratos/${reprove.idContrato}/naoHomologarRegistro`, JSON.stringify(reprove)),
  getPersonalDataByCPFCNPJ: (cpf_cnpj, idFinanceira) => requests.get(`/api/contratos/devedor/${encode(cpf_cnpj)}/${idFinanceira}`),
  deleteContract: (id) => requests.post(`/api/contratos/${id}/excluir`),
  cancelContract: (params) => requests.post(`/api/contratos/${params.id}/cancelar`, JSON.stringify(params)),
  shelveContract: (params) => requests.post(`/api/contratos/${params.id}/baixar`, JSON.stringify(params)),
  getLots: () => requests.get(`/api-financeiras/lotes`),
  getLotsUser: () => requests.get(`/api-financeiras/lotes/usuario`),
  getLotIn: (id) => requests.getTxt(`/api-financeiras/lote/${id}/E`),
  getLotOut: (id) => requests.getTxt(`/api-financeiras/lote/${id}/S`),
  sendLot: (file) => requests.postFile(`/api-financeiras/envioLote`, file),
  findByDadosRJ: (cnpj, chassi) =>
    requests.post(`/api/contratos/dadosGravameRJ`, {
      cnpj: cnpj,
      chassi: chassi,
    }),
  getLotRJ: () => requests.get(`/api/contratos/lotes/recuperaRJ`),
  getLoteBV: () => requests.get(`/api/contratos/lotes/recupera`),
  getLoteBB: () => requests.get(`/api/contratos/lotes/recuperaBB`),
  getLotePAN: () => requests.get(`/api/contratos/lotes/recuperaPAN`),
  getLoteOMNI: () => requests.get(`/api/contratos/lotes/recuperaOMNI`),
  getLoteFinanceira: (id) => requests.get(`/api/contratos/lote/financeira/${id}`),
  getLoteFormatoFinanceira: (id, formato) => requests.get(`/api/contratos/lote/financeira/${id}/${formato}`),
  getLote: (id, tipo) => requests.getFile(`/api/contratos/lote/${id}/recupera/${tipo}`),
  recuperaRespostasPAN: (id) => requests.getFile(`/api/contratos/lotes/${id}/recuperaRespostaPAN`),
  sendLoteRJ: (file) => requests.postFile(`/api/contratos/envioLoteRJ`, file),
  sendLoteOMNIXLS: (file) => requests.postFile(`/api/contratos/envioLoteOMNIXLS`, file),
  sendLoteBV: (file) => requests.postFile(`/api/contratos/envioLote`, file),
  sendLoteBB: (file) => requests.postFile(`/api/contratos/envioLoteBB`, file),
  sendLoteBNDES: (uf, file) => requests.postFile(`/api/contratos/lotes/uf/${uf}/envioLoteBNDES`, file),
  sendLotePAN: (file) => requests.postFile(`/api/contratos/envioLotePAN`, file),
  sendLotePANXLS: (file) => requests.postFile(`/api/contratos/envioLotePANXLS`, file),
  sendMultipleImagesWithName: (files) => requests.postMultipleFilesWithName(`/api/contratos/envioLoteSNG`, files),
  getLoteRJ: (id) => requests.getFile(`/api/contratos/lotes/${id}/recuperaRJ`),
  verificaLoteRJ: (id) => requests.get(`/api/contratos/lotes/${id}/verificaLoteRJ`),
  recuperaContratoRegistradoPR: (id) => requests.get(`/api/contratos/recuperaPorChassi/${id}/PR`),
  recuperaContratoRegistradoBA: (id) => requests.get(`/api/contratos/recuperaPorChassi/${id}/BA`),
  recuperaContratoRegistradoSP: (id) => requests.get(`/api/contratos/recuperaPorChassi/${id}/SP`),
  recuperaContratoRegistradoRJ: (id) => requests.get(`/api/contratos/recuperaPorChassi/${id}/RJ`),
  getContratosAlias: (cnpj, status, numeroRegistro, numero, devCpfCnpj, renavam, chassi, dtInicio, dtFim, integra, tpPesquisaData, uf) =>
    requests.get(
      `/api/contratos/alias/dasboard?1=1` +
        (cnpj ? `&cnpj={"eq":"${cnpj}"}` : "") +
        (status ? `&status={"eq":${JSON.stringify(status)}}` : "") +
        (numeroRegistro ? `&numRegistro={"like":${JSON.stringify(numeroRegistro)}}` : "") +
        (numero ? `&numero={"like":${JSON.stringify(numero)}}` : "") +
        (devCpfCnpj ? `&devCpfCnpj={"eq":${JSON.stringify(devCpfCnpj)}}` : "") +
        (renavam ? `&renavam={"like":${JSON.stringify(renavam)}}` : "") +
        (chassi ? `&chassi={"like":${JSON.stringify(chassi)}}` : "") +
        (dtInicio ? `&data={"ge":"${dtInicio}"}` : "") +
        (dtFim ? `&data={"le":"${dtFim}"}` : "") +
        (integra ? `&integra={"eq":"${integra}"}` : "") +
        (tpPesquisaData ? `&tpPesquisaData={"eq":"${tpPesquisaData}"}` : "") +
        (uf ? `&uf={"eq":"${uf}"}` : "")
    ),
  getContractsByIdLote: (id) => requests.get(`/api/contratos/lotes/${id}`),
  getQtdVisaoGeral: (cnpj, uf) => requests.get(`/api/contratos/qtdVisaoGeral/${cnpj}/${uf}`),
  getQtdImagensContratos: (cnpj, uf) => requests.get(`/api/contratos/qtdImagensContratos/${cnpj}/${uf}`),
  getQtdEnvioImagensPendentes: (cnpj, uf, dataI, dataF) => requests.get(`/api/contratos/qtdEnvioImagensPendentes/${cnpj}/${uf}/${dataI}/${dataF}`),
  processaRegistrosLote: (data) => requests.put(`/api/contratos/lotes`, JSON.stringify(data)),
  reenviarContratos: (params) => requests.post(`/api/contratos/erro/reenviarDetran`, JSON.stringify(params)),
  recupera785: (id) => requests.get(`/api/contratos/${id}/recupera785`),
  averbacaoCriada: (id) => requests.get(`/api/contratos/${id}/recuperaAverbacao`),
  getContractByNrUf: (numero, uf) => requests.get(`/api/contratos/${encode(numero)}/${uf}`),
  getQtdNaoEnvSemImg: () => requests.get(`/api/contratos/qtdContratosNaoEnvSemImg`),
};

const Lot = {
  create: () => requests.post(`/api/criarLoteArquivos`),
  close: (idLote) => requests.post(`/api/fecharLoteArquivos/${idLote}`),
  sendFile: (idLote, inclusaoAlteracao, file) => requests.postFile(`/api/enviarArquivoLote/${idLote}/${inclusaoAlteracao}`, file),
};

const Management = {
  countContractByStatus: (status) => requests.get(`/api/gestao/quantidade-contratos/status/${status}`),
  managementReport: (idFinanceira, tipo, dataInicio, dataFim, anexo, correspondente, uf, idParceiro) =>
    requests.post(`/api/contratos/relatorioGerencial`, {
      idFinanceira: idFinanceira,
      tipo: tipo,
      dataInicio: dataInicio,
      dataFim: dataFim,
      temAnexo: anexo,
      correspondente: correspondente,
      uf: uf,
      idParceiro: idParceiro,
    }),
  managementReportError: (idFinanceira, dataInicio, dataFim, tipoErro, correspondente, uf) =>
    requests.post(`/api/contratos/relatorioGerencialError`, {
      idFinanceira: idFinanceira,
      dataInicio: dataInicio,
      dataFim: dataFim,
      tipoErro: tipoErro,
      correspondente: correspondente,
      uf: uf,
    }),
  dashboardFinancial: (trimestre, ano, uf, idParceiro) => requests.post(`/api/contratos/dashboardFinanceiro`, { trimestre, ano, uf, idParceiro }),
};

const Home = {
  homeChart: (idFinanceira, correspondente, trimestre, ano, uf) =>
    requests.post(`/api/contratos/home/graficos`, {
      idFinanceira,
      correspondente,
      trimestre,
      ano,
      uf,
    }),
};

const Ufs = {
  ufs: () => requests.get(`/api/ufs`),
  cities: (uf) => requests.get(`/api/ufs/${uf}/municipios`),
  cep: (cep) => requests.get(`/api/cep/${cep}`),
  system: () => requests.get(`/api/ufs/sistema`),
  sigla_system: () => requests.get(`/api/sigla_ufs/sistema`),
  allCollumns: () => requests.get(`/api/ufs/sistema/allColumns`),
  config: (dados) => requests.put(`/api/ufs/config`, JSON.stringify(dados)),
};

const Vehicles = {
  getByBrandModel: (model) => requests.post(`/api/veiculos/marcaModelo`, JSON.stringify(model)),
};

const Detrans = {
  companyStatus: (cnpj) => requests.get(`/api/detran/financeiras/${cnpj}/situacao`),
  renavamStatus: (renavam) => requests.get(`/api/detran/veiculos/${renavam}/situacao`),
  renavamData: (renavam) => requests.get(`/api/detran/veiculos/${renavam}/dados`),
  contractsNoImage: (cnpj) => requests.get(`/api/detran/contratos/${cnpj}/sem_imagem`),
  status: () => requests.get(`/api/detran`),
  allContractStatus: (cnpj, dataInicial, dataFinal) =>
    requests.get(`/api/detran/contratos/${cnpj}/quantidades?generico=1` + (dataInicial ? `&dataInicial=${dataInicial}` : "") + (dataFinal ? `&dataFinal=${dataFinal}` : "")),
  allContractStatusDetran: (cnpj, dataInicial, dataFinal, uf) =>
    requests.get(
      `/api/detran/contratos/${cnpj}/quantidadesDetran?generico=1` + (dataInicial ? `&dataInicial=${dataInicial}` : "") + (dataFinal ? `&dataFinal=${dataFinal}` : "") + (uf ? `&uf=${uf}` : "")
    ),
  contracts: (cnpj, status, numeroRegistro, numero, devCpfCnpj, renavam, chassi, dtInicio, dtFim) =>
    requests.get(
      `/api/detran/contratos/${cnpj}/generico?1=1` +
        (status ? `&status={"eq":${JSON.stringify(status)}}` : "") +
        (numeroRegistro ? `&numeroRegistro={"like":${JSON.stringify(numeroRegistro)}}` : "") +
        (numero ? `&numero={"like":${JSON.stringify(numero)}}` : "") +
        (devCpfCnpj ? `&devCpfCnpj={"eq":${JSON.stringify(devCpfCnpj)}}` : "") +
        (renavam ? `&renavam={"like":${JSON.stringify(renavam)}}` : "") +
        (chassi ? `&chassi={"like":${JSON.stringify(chassi)}}` : "") +
        (dtInicio ? `&data={"ge":"${dtInicio}"}` : "") +
        (dtFim ? `&data={"le":"${dtFim}"}` : "")
    ),
  charges: () => requests.get(`/api/detran/cobrancas/totais`),
  chargesByPeriod: (period) => requests.get(`/api/detran/cobrancas/periodo/${period}`),
  aprove: (aprove) => requests.post(`/api/detran/contratos/homologar`, JSON.stringify(aprove)),
  reprove: (reprove) => requests.post(`/api/detran/contratos/nao_homologar`, JSON.stringify(reprove)),
  countAguardandoDetran: () => requests.get(`/api/detran/quantidadeAguardandoDetran`),
  statusDetranUF: (uf) => requests.get(`/api/detran/${uf}/comunicacao`),
  statusDetran: () => requests.get(`/api/detran/comunicacao`),
  geraTaxaDetranBA: (dados) => requests.post(`/api/cobrancaDetranBA`, JSON.stringify(dados)),
  allTaxasDetranBA: (idFinanceira, chassi, contrato, status) =>
    requests.post(`/api/cobrancasDetranBA`, {
      idFinanceira,
      chassi,
      contrato,
      status,
    }),
  situacaoFinanceiraPE: (dados) => requests.post(`/api/detran/pe/situacaoFinanceira`, JSON.stringify(dados)),
  regeraTaxaBA: (idCobranca) => requests.post(`/api/cobrancaDetranBA/${idCobranca}/regerarTaxa`),
};

const Financial = {
  all: (periodicidadeAlias, periodicidadeFaturamento, cnpj, razaoSocial, nomeFantasia, numContrato, endUf, endCodMunicipio, uf) =>
    requests.get(
      `/api/financeiras?1=1` +
        (periodicidadeAlias ? `&periodicidadeAlias={"like":${JSON.stringify(periodicidadeAlias)}}` : "") +
        (periodicidadeFaturamento ? `&periodicidadeFaturamento={"like":${JSON.stringify(periodicidadeFaturamento)}}` : "") +
        (cnpj ? `&cnpj={"like":${JSON.stringify(cnpj)}}` : "") +
        (razaoSocial ? `&razaoSocial={"like":${JSON.stringify(razaoSocial)}}` : "") +
        (nomeFantasia ? `&nomeFantasia={"like":${JSON.stringify(nomeFantasia)}}` : "") +
        (numContrato ? `&numContrato={"like":${JSON.stringify(numContrato)}}` : "") +
        (endUf ? `&endUf={"like":${JSON.stringify(endUf)}}` : "") +
        (endCodMunicipio ? `&endCodMunicipio={"like":${JSON.stringify(endCodMunicipio)}}` : "") +
        (uf ? `&uf={"like":${JSON.stringify(uf)}}` : "")
    ),
  allClustered: (
    dataAssinaturaContrato,
    vigencia,
    cnpj,
    razaoSocial,
    nomeFantasia,
    numContrato,
    endUf,
    endCodMunicipio,
    nomeAgrupamento,
    uf,
    cpf,
    dataIniCriacao,
    dataFimCriacao,
    emailsFaturamento,
    emailsImagem
  ) =>
    requests.get(
      `/api/financeiras/clustered?1=1` +
        (dataAssinaturaContrato ? `&dataAssinaturaContrato={"ge":${JSON.stringify(dataAssinaturaContrato)}}` : "") +
        (vigencia ? `&vigencia={"le":${JSON.stringify(vigencia)}}` : "") +
        (cnpj ? `&cnpj={"like":${JSON.stringify(cnpj)}}` : "") +
        (razaoSocial ? `&razaoSocial={"like":${JSON.stringify(razaoSocial)}}` : "") +
        (nomeFantasia ? `&nomeFantasia={"like":${JSON.stringify(nomeFantasia)}}` : "") +
        (numContrato ? `&numContrato={"like":${JSON.stringify(numContrato)}}` : "") +
        (endUf ? `&endUf={"like":${JSON.stringify(endUf)}}` : "") +
        (endCodMunicipio ? `&endCodMunicipio={"like":${JSON.stringify(endCodMunicipio)}}` : "") +
        (nomeAgrupamento ? `&nomeAgrupamento={"like":${JSON.stringify(nomeAgrupamento)}}` : "") +
        (uf ? `&uf={"like":${JSON.stringify(uf)}}` : "") +
        (cpf ? `&cpf={"like":${JSON.stringify(cpf)}}` : "") +
        (dataIniCriacao ? `&dataIniCriacao={"ge":${JSON.stringify(dataIniCriacao)}}` : "") +
        (dataFimCriacao ? `&dataFimCriacao={"ge":${JSON.stringify(dataFimCriacao)}}` : "") +
        (emailsFaturamento ? `&emailsFaturamento={"like":${JSON.stringify(emailsFaturamento)}}` : "") +
        (emailsImagem ? `&emailsImagem={"like":${JSON.stringify(emailsImagem)}}` : "")
    ),
  get: (id) => requests.get(`/api/financeiras/${id}`),
  filtroPorUf: (uf, idParceiro) =>
    requests.get("/api/financeiras/filtroPorUF?1=1" + (uf ? `&uf={"like":${JSON.stringify(uf)}}` : "") + (idParceiro ? `&idParceiro={"like":${JSON.stringify(idParceiro)}}` : "")),
  relatorioCobranca: (data) => requests.post(`/api/contratos/relatorioCobranca`, JSON.stringify(data)),
  boletoCobranca: (ano, mes, agente) => requests.get(`/api/contratos/boletoCobranca/${ano}/${mes}/${agente}`),
  boletoCobrancaMS: (data) => requests.post(`/api/contratos/boletoCobrancaMS`, JSON.stringify(data)),
  relatorioSituacaoBoletosMensal: (data) => requests.post(`/api/contratos/relatorioSituacaoBoletosMensal`, JSON.stringify(data)),
  relatorioBoletosEmitidos: (data) => requests.post(`/api/contratos/relatorioBoletosEmitidos`, JSON.stringify(data)),
  create: (authorized) => requests.post(`/api/financeiras`, JSON.stringify(authorized)),
  update: (authorized) => requests.put(`/api/financeiras/${authorized.id}`, JSON.stringify(authorized)),
  registerDocument: (file, data) => requests.postFileDescription(`/api/financeiras/${data.id}/documento`, file, data),
  getDocument: (idFinanceira, idDocumento) => requests.getFile(`/api/financeiras/${idFinanceira}/documento/${idDocumento}`),
  allDocuments: (id) => requests.get(`/api/financeiras/${id}/documento/`),
  deleteDocument: (id) => requests.del(`/api/financeiras/documento/${id}`),
  recuperaLog: (id) => requests.get(`/api/financeiras/${id}/logAuditoria/insert_financeira/update_financeira`),
  UsersOnline: (id) => requests.get(`/api/logados/${id}/`),
  allValuesPeriod: (id) => requests.get(`/api/financeiras/${id}/vigenciaValores/`),
  deleteVigenciaValores: (id) => requests.del(`/api/financeiras/vigenciaValores/${id}`),
  createVigenciaValores: (idFinanceira, data) => requests.post(`/api/financeiras/${idFinanceira}/vigenciaValores`, JSON.stringify(data)),
  updateVigenciaValores: (idFinanceira, data) => requests.put(`/api/financeiras/${idFinanceira}/vigenciaValores/${data.id}`, JSON.stringify(data)),
  getByCnpj: (cnpj) => requests.get(`/api/financeiras/cnpj/${encode(cnpj)}`),
  ufsCadastradas: (id) => requests.get(`/api/financeiras/${id}/uf/`),
  recuperaContratosDaUF: (uf, id) => requests.get(`/api/financeiras/${id}/uf/${uf}/contratosDaUF`),
  desabilitaSenhaOperacao: (data) => requests.put(`/api/financeiras/${data.id}/desabilitaSenhaOperacao`, JSON.stringify(data)),
  createValorDetran: (taxa) => requests.post(`/api/financeiras/taxasDetran`, JSON.stringify(taxa)),
  getValorDetran: (uf) => requests.get(`/api/financeiras/taxasDetran/${uf}`),
  delValorDetran: (id) => requests.del(`/api/financeiras/taxasDetran/${id}`),
  getParceiros: () => requests.get(`/api/financeiras/parceiro/todos`),
  filtroPorParceiro: (id) => requests.get(`/api/financeiras/parceiro/${id}`),
  recuperaUfsParceiro: (id) => requests.get(`/api/financeiras/ufs/parceiro/${id}`),
  getParceirosPorUf: (uf) => requests.get(`/api/financeiras/parceiro/uf/${uf}`),
  listFinancialInvalid: () => requests.get(`/api/financeirasInvalidas`),
  registrosDetranMA: (data) => requests.post(`/api/contratos/relatorioCobrancaMA`, JSON.stringify(data)),
  allContacts: (id) => requests.get(`/api/financeiras/${id}/contatos/`),
  createContact: (data) => requests.post(`/api/financeiras/contato`, JSON.stringify(data)),
  updateContact: (data) => requests.put(`/api/financeiras/contato/${data.id}`, JSON.stringify(data)),
  deleteContact: (id) => requests.del(`/api/financeiras/contato/${id}`),
};

const Charging = {
  all: () => requests.get(`/api/cobranca`),
  allByIdClose: (id) => requests.get(`/api/cobrancas/${id}`),
  get: (id) => requests.get(`/api/cobranca/${id}`),
  registros: (id) => requests.get(`/api/cobranca/${id}/registros`),
  registrosSemContestacao: (id) => requests.get(`/api/cobranca/${id}/registrosSemContestacao`),
  create: (id) => requests.post(`/api/cobranca/fecharPeriodoFinanceira/${id}`),
  getByPeriod: (period, cnpj) => requests.get(`/api/cobranca/periodo/${period}/financeira/${cnpj}`),
  getAnexoCobranca: (id, tipo) => requests.getFileGeneric(`/api/cobranca/${id}/anexo/${tipo}`),
  sendImage: (id, file) => requests.postFile(`/api/cobranca/${id}/imagem`, file),
  pago: (id) => requests.put(`/api/cobranca/${id}/pago`),
  aberto: (id) => requests.put(`/api/cobranca/${id}/aberto`),
  fechado: (id) => requests.put(`/api/cobranca/${id}/fechado`),
  contestarRegistro: (idVeiculo, idCobranca) => requests.put(`/api/cobranca/${idCobranca}/registro/${idVeiculo}/contestado`),
  chargingByPeriod: (mes, ano, cnpj, uf) => requests.get(`/api/cobrancaDetran/${mes}/${ano}/${cnpj}/${uf}`),
  chargingDetails: (id) => requests.get(`/api/cobrancaDetran/registros/${id}`),
  allOpen: () => requests.get(`/api/cobranca/status/abertas`),
  gerarRemessa: () => requests.getFile(`/api/cobranca/status/abertas/remessa`),
  retornoRemessa: (file) => requests.postFile(`/api/cobranca/retorno/remessa`, file),
  gerarBoleto: (id) => requests.getFile(`/api/cobranca/${id}/boleto`),
  delete: (id) => requests.del(`/api/cobranca/delete/${id}`),
  recuperaFaturamentos: (uf, mesReferencia, anoReferencia, cnpj, razaoSocial, situacaoConciliacao) =>
    requests.get(
      `/api/cobranca/financeiro/recuperaFaturamentos?1=1` +
        (uf ? `&uf={"like":${JSON.stringify(uf)}}` : "") +
        (mesReferencia ? `&mesReferencia={"like":${JSON.stringify(mesReferencia)}}` : "") +
        (anoReferencia ? `&anoReferencia={"like":${JSON.stringify(anoReferencia)}}` : "") +
        (cnpj ? `&cnpj={"like":${JSON.stringify(cnpj)}}` : "") +
        (razaoSocial ? `&razaoSocial={"like":${JSON.stringify(razaoSocial)}}` : "") +
        (situacaoConciliacao ? `&situacaoConciliacao={"like":${JSON.stringify(situacaoConciliacao)}}` : "")
    ),
  regeraCobranca: (dados) => requests.post(`/api/cobranca/regeraCobranca`, JSON.stringify(dados)),
  abrirCobranca: (dados) => requests.post(`/api/cobranca/abrirCobranca`, JSON.stringify(dados)),
  registrosFaturamento: (id) => requests.get(`/api/cobranca/${id}/registrosFaturamento`),
  sendAnexoCobranca: (id, tipo, file) => requests.postFile(`/api/cobranca/${id}/${tipo}/documento`, file),
  sendLotAnexoCobranca: (file) => requests.postFile(`/api/cobranca/documento`, file),
  getTiposAnexo: (id) => requests.get(`/api/cobranca/${id}/tiposAnexo`),
  fechaCobranca: (dados) => requests.post(`/api/cobranca/fechaCobranca`, JSON.stringify(dados)),
  emailCobranca: (dados) => requests.post(`/api/cobranca/emailCobranca`, JSON.stringify(dados)),
  emailCobranca2: (dados) => requests.post(`/api/cobranca/emailCobranca2`, JSON.stringify(dados)),
  gerarArquivoRemessa240BB: (param) => requests.getFile(`/api/cobranca/taxas/remessa?empresa=${param}`),
  baixarArquivoRemessa240BB: (id) => requests.getFile(`/api/cobranca/taxas/remessa/${id}`),
  gravarAquivoRetorno240bb: (file) => requests.postFile(`/api/cobranca/taxas/retornoRemessa`, file),
  alteraSituacaoPag: (dados) => requests.post(`/api/cobranca/atualizaSituacaoPagamento`, JSON.stringify(dados)),
  registrosFaturamentoDivergente: (id) => requests.get(`/api/cobranca/${id}/registrosDivergente`),
  sendArquivoCobrancaDetran: (uf, mes, ano, file) => requests.postFile(`/api/cobranca/arquivoDetran/${uf}/${mes}/${ano}`, file),
};

const News = {
  all: (upToToday) => requests.get(`/api/news?1=1` + (upToToday ? `&upToToday=1` : "")),
  create: (file, data) => requests.postFileDados(`/api/news`, file, data),
  update: (file, data) => requests.postFileDados(`/api/news/${data.id}`, file, data),
  delete: (id) => requests.del(`/api/news/${id}`),
  imagem: (id) => requests.get(`/api/news/${id}/imagem`),
  imagemPorNovidadeUf: (dados) => requests.post(`/api/news/imagem/novidadeUf`, JSON.stringify(dados)),
  criaVisualizacao: (id) => requests.post(`/api/news/${id}/criaVisualizacao`),
};

const Profile = {
  all: (id) => requests.get(`/api/perfis/financeira/${id}`),
  create: (perfil) => requests.post(`/api/perfis`, JSON.stringify(perfil)),
  update: (data) => requests.put(`/api/perfis/${data.id}`, JSON.stringify(data)),
  getChallenge: () => requests.get(`/api/biometria/get-challenge`),
  savePublicKey: (data) => requests.post(`/api/biometria/save-public-key`, JSON.stringify(data)),
  getChallengePublicKey: () => requests.get(`/api/biometria/get-challenge-public-key`),
  verifySignature: (data) => requests.post(`/api/biometria/verify-signature`, JSON.stringify(data)),
};

const Feature = {
  all: () => requests.get(`/api/funcionalidades`),
  perfil: (id) => requests.get(`/api/funcionalidades/perfil/${id}`),
  insertUpdateProfileFeatures: (data) => requests.post(`/api/funcionalidades`, JSON.stringify(data)),
};

const ChargeSC = {
  details: (id) => requests.post(`/api/contratos/cobrancaFinanceira`),
};

const Confia = {
  allByCnpj: (cnpj) => requests.get(`/api/confia/contratos/${cnpj}`),
  getDetailsByHash: (hash) => requests.get(`/api/confia/signatarios/${hash}`),
  sendContratoConfia: (file, data) => requests.postFileDados(`/api/confia/contrato`, file, data),
  getArquivo: (hash) => requests.getFile(`/api/confia/arquivo/${hash}`),
};

const Configuracao = {
  configLayout: (dados) => requests.put(`/api/gestao/configLayout`, JSON.stringify(dados)),
  deleteConfigPorAtributo: (atributo) => requests.del(`/api/gestao/configuracao/${atributo}`),
  getPorAtributo: (atributo) => requests.get(`/api/gestao/configuracao/${atributo}`),
  insereIntegracao: (data) => requests.post(`/api/financeiras/integracao`, JSON.stringify(data)),
  getDadosIntegracoes: () => requests.get(`/api/financeirasIntegracoes`),
  getTipoIntegracao: (tipo) => requests.get(`/api/financeiras/integracao/${tipo}`),
  getVeiculoIntegracao: (chassi) => requests.get(`/api/rsng/consultaVeiculo/${chassi}`),
  getMonitoramentoCrlv: (data) => requests.post(`/api/rsng/consultaMonitoramento`, JSON.stringify(data)),
  getQtdExpiracaoCrlv: () => requests.get(`/api/rsng/consultaMonitoramento/vencimento`),
  getErrosDetran: (uf) => requests.get(`/api/gestao/errosEmail/${uf}`),
  delErroDetran: (id) => requests.del(`/api/gestao/errosEmail/${id}`),
  createErroDetran: (erro) => requests.post(`/api/gestao/errosEmail`, JSON.stringify(erro)),
};

const Duda = {
  recuperaDuda: (numeroDuda, chassi, utilizada) =>
    requests.get(
      `/api/gestao/duda?1=1` +
        (numeroDuda ? `&numeroDuda={"like":${JSON.stringify(numeroDuda)}}` : "") +
        (chassi ? `&chassi={"like":${JSON.stringify(chassi)}}` : "") +
        (utilizada ? `&utilizada={"like":${JSON.stringify(utilizada)}}` : "")
    ),
  insereDuda: (data) => requests.post(`/api/gestao/duda`, JSON.stringify(data)),
  delete: (id) => requests.del(`/api/gestao/duda/${id}`),
  desvincular: (id) => requests.post(`/api/gestao/duda/${id}/desvincular`),
  gerarArquivoRemessa240BB: () => requests.getFile(`/api/cobranca/taxas/remessaRJ`),
  gravarAquivoRetorno240bb: (file) => requests.postFile(`/api/cobranca/taxas/retornoRemessaRJ`, file),
  sendLotAnexoDuda: (file) => requests.postFile(`/api/gestao/dudaPdf`, file),
};

const Comercial = {
  visaoFinanceira: (id, dtInicial, dtFinal) => requests.get(`/api/comercial/visaoFinanceira?1=1&id=${id}&dtInicial=${dtInicial}&dtFinal=${dtFinal}`),
  planilhaAnualFiltrada: (id, dtInicial, dtFinal) => requests.getFile(`/api/comercial/planilhaAnual?1=1&id=${id}&dtInicial=${dtInicial}&dtFinal=${dtFinal}`),
  planilhaAnual: () => requests.getFile(`/api/comercial/planilhaAnual`),
};

export default {
  Auth,
  Users,
  Roles,
  Companies,
  Contracts,
  Ufs,
  Vehicles,
  Management,
  Detrans,
  Financial,
  Agentes,
  Charging,
  setToken: (_token) => {
    token = _token;
  },
  setRefreshToken: (_refreshToken) => {
    refreshToken = _refreshToken;
  },
  decodeUserProfile,
  loadUserProfile,
  removeIdToken,
  Lot,
  ChargeSC,
  Home,
  News,
  Profile,
  Feature,
  Confia,
  Configuracao,
  Duda,
  Comercial,
};
