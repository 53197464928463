import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import TextField from "../../components/Fields/TextField";
//import FieldNormalize from "../../components/Normalize/FieldNormalize";
//import FieldValidation from "../../components/Validation/FieldValidation";
//import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import SelectField from "../../components/Fields/SelectField";
import MaskDate from "../../components/Util/MaskDate";
import { ic_warning } from "react-icons-kit/md/ic_warning";
import SvgIcon from "react-icons-kit";
import { library } from "react-icons-kit/icomoon/library";

import { TAXA_DETRAN_LIST_LOAD, TAXA_DETRAN_LIST_UNLOAD, TAXA_DETRAN_ALL_COMPANIES_LOAD, LOGOUT, CONFIRM_ACTION_OPEN } from "../../actions/Constants";

import api from "../../api/apiUtil";
import ReactTable from "react-table";
//import fileDownload from "js-file-download";
//import moment from "moment";
import { BeatLoader } from "react-spinners";
//import Dropzone from "react-dropzone";

class TaxasDetran extends React.Component {
  constructor() {
    super();
    this.state = {
      //checked: true,
      //showGeracaoTaxa: false,
      pesquisando: false,
      idCobranca: null,
      disabledBtnRegPag: false,
    };
    document.addEventListener("keyup", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    if (event.keyCode === 13 && document.getElementById("filterTaxas")) {
      document.getElementById("filterTaxas").click();
    }
  };

  componentWillMount() {
    this.props.onListLoad(api.Detrans.allTaxasDetranBA(null, null, null, "0"));
    if (
      _.findIndex(this.props.currentUser.perfis, function (perfil) {
        return perfil === "ALIAS";
      }) > -1
    ) {
      Promise.resolve(api.Companies.all()).then((companies) => {
        this.props.onCompaniesLoad(companies);
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyDownHandler);
    this.props.onUnload();
  }

  // formSubmit(values) {
  //   var props = this.props;
  //   const errors = {};
  //   if (
  //     _.findIndex(this.props.currentUser.perfis, function (perfil) {
  //       return perfil === "ALIAS";
  //     }) > -1
  //   ) {
  //     if (!values.credor || values.credor.length == 0) {
  //       errors.credor = "Campo obrigatório";
  //     }
  //   } else if (this.props.companies && this.props.companies.length > 1) {
  //     if (!values.credor || values.credor.length == 0) {
  //       errors.credor = "Campo obrigatório";
  //     }
  //   }
  //   if (!values.chassi || values.chassi.length == 0) {
  //     errors.chassi = "Campo obrigatório";
  //   }
  //   if (!values.dataContrato || values.dataContrato.length == 0) {
  //     errors.dataContrato = "Campo obrigatório";
  //   }
  //   if (!values.numGravame || values.numGravame.length == 0) {
  //     errors.numGravame = "Campo obrigatório";
  //   }
  //   if (errors && !_.isEmpty(errors)) {
  //     toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
  //     throw new SubmissionError(errors);
  //   }
  //   var dados = {
  //     cnpj: !values.credor ? this.props.companies[0].cnpj : values.credor,
  //     chassi: values.chassi,
  //     dataContrato: MaskDate.TO_DATABASE(values.dataContrato),
  //     numGravame: values.numGravame,
  //   };
  //   Promise.resolve(api.Detrans.geraTaxaDetranBA(dados))
  //     .then((res) => {
  //       if (res.codigo == 0) {
  //         toastr.success("Sucesso", "Taxa Gerada");
  //       } else {
  //         toastr.error("Erro", res.descricao);
  //       }
  //       this.props.onListLoad(api.Detrans.allTaxasDetranBA(null, null, null, "0"));
  //       this.props.reset();
  //     })
  //     .catch(function (resp) {
  //       if (resp === "Unauthorized" || resp === "jwt expired") {
  //         api.Auth.logout();
  //         props.onLogout();
  //       } else {
  //         toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
  //       }
  //     });
  // }

  async onSendRegister(value) {
    this.setState({ disabledBtnRegPag: true });
    var props = this.props;
    await Promise.resolve(api.Contracts.registerTaxaPaga(value))
      .then((e) => {
        Promise.resolve(api.Contracts.get(value)).then((response) => {
          if (response.status === 2) {
            toastr.success("Operação realizada", "O contrato e todos seus veículos foram registrados!");
          } else {
            toastr.info("Operação realizada", "O contrato e todos seus veículos foram enviados ao DETRAN, aguardando confirmação do processamento!");
          }
          this.props.onListLoad(api.Detrans.allTaxasDetranBA(null, null, null, "0"));
          this.setState({ disabledBtnRegPag: false });
        });
      })
      .catch((e) => {
        this.setState({ disabledBtnRegPag: false });
        if (e === "Unauthorized" || e === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  // handleShowGeracaoTaxa() {
  //   this.setState({ showGeracaoTaxa: !this.state.showGeracaoTaxa });
  // }

  // async handleGeracaoRemessa(value) {
  //   var props = this.props;
  //   await Promise.resolve(api.Charging.gerarArquivoRemessa240BB(value))
  //     .then((response) => {
  //       this.props.onListLoad(api.Detrans.allTaxasDetranBA(null, null, null, "0"));
  //       fileDownload(response, `${value}-REM-${moment(new Date()).format("DDMMYYYYHHmm")}.TXT`);
  //       this.props.onListLoad(api.Detrans.allTaxasDetranBA(null, null, null, "0"));
  //     })
  //     .catch(function (resp) {
  //       if (resp === "Unauthorized" || resp === "jwt expired") {
  //         api.Auth.logout();
  //         props.onLogout();
  //       } else if (resp.response && resp.response.statusCode === 406) {
  //         toastr.warning("Atenção", "Não existem taxas para gerar remessa.");
  //       } else {
  //         toastr.error("Erro", resp.message);
  //       }
  //     });
  // }

  // async handleUploadRemessa(filesToUpload) {
  //   var props = this.props;
  //   toastr.info("Aguarde", "Processando arquivo...", {
  //     timeOut: 0,
  //     component: () => {
  //       return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
  //     },
  //   });
  //   Promise.resolve(api.Charging.gravarAquivoRetorno240bb(filesToUpload[0]))
  //     .then((response) => {
  //       this.props.onListLoad(api.Detrans.allTaxasDetranBA(null, null, null, "0"));
  //       toastr.removeByType("info");
  //       toastr.success("Sucesso", "Arquivo de remessa processado.");
  //     })
  //     .catch(function (resp) {
  //       if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
  //         api.Auth.logout();
  //         props.onLogout();
  //       } else {
  //         toastr.removeByType("info");
  //         toastr.warning("Atenção", resp.response.body);
  //       }
  //     });
  // }

  async filtrar(values) {
    if ((values.findStatus === "0,1,2" || values.findStatus === "1") && (!values.findChassi || values.findChassi.length === 0) && (!values.findContrato || values.findContrato.length === 0)) {
      toastr.error("Erro", "Para os status Aberto ou Registrado é necessário preencher o chassi ou contrato.");
    } else {
      this.setState({ pesquisando: true });
      toastr.info("Aguarde", "Realizando consulta...", {
        timeOut: 0,
        component: () => {
          return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
        },
      });
      this.props.onListLoad(await Promise.resolve(api.Detrans.allTaxasDetranBA(values.findCredor, values.findChassi, values.findContrato, values.findStatus)));
      this.setState({ pesquisando: false });
      toastr.removeByType("info");
      if (!this.props.all || this.props.all.length === 0) {
        toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
      }
    }
  }

  // async downloadRemessa(id, param) {
  //   var props = this.props;
  //   await Promise.resolve(api.Charging.baixarArquivoRemessa240BB(id))
  //     .then((response) => {
  //       fileDownload(response, `${param}-REM-${id}.TXT`);
  //     })
  //     .catch((e) => {
  //       if (e === "Unauthorized" || e === "jwt expired") {
  //         api.Auth.logout();
  //         props.onLogout();
  //       } else {
  //         toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
  //       }
  //     });
  // }

  onRegeraTaxa = () => {
    this.props.onActionOpen({
      description: "Confirma gerar novamente a taxa do contrato?",
      onSuccess: this.props.handleSubmit(this.regerarTaxa.bind(this)),
    });
  };

  regerarTaxa() {
    Promise.resolve(api.Detrans.regeraTaxaBA(this.state.idCobranca))
      .then((ret) => {
        this.setState({ idCobranca: null });
        this.props.onListLoad(api.Detrans.allTaxasDetranBA(null, null, null, "0"));
        toastr.success("Sucesso", "Taxa regerada!");
      })
      .catch((ret) => {
        if (ret === "Unauthorized" || ret === "jwt expired") {
          api.Auth.logout();
          this.props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  render() {
    //const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Cadastrar Taxa Detran" />
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    {/* <Link
                      className="btn btn-xs btn-warning"
                      onClick={() => {
                        this.handleGeracaoRemessa("ALIAS");
                      }}
                    >
                      <i className="fas fa-book" /> Gerar arquivo remessa ALIAS
                    </Link>
                    <Link
                      className="btn btn-xs btn-info"
                      onClick={() => {
                        this.handleGeracaoRemessa("THOTH");
                      }}
                    >
                      <i className="fas fa-book" /> Gerar arquivo remessa THOTH
                    </Link>
                    <Dropzone accept=".RET" name="file" className="dropzone-blank fallback float-right" multiple={false} onDrop={(filesToUpload, e) => this.handleUploadRemessa(filesToUpload)}>
                      <button className="btn btn-xs btn-success" type="button">
                        <i className="fa fa-upload" /> Carregar arquivo de retorno
                      </button>
                    </Dropzone> */}

                    {
                      //<Link className="btn btn-xs btn-info" onClick={() => { this.handleShowGeracaoTaxa() }}><i className="fas fa-wrench"/> {this.state.showGeracaoTaxa ? 'Ocultar' : 'Exibir'} Geração Taxa Manual</Link>
                    }
                  </div>
                  Taxas
                </div>

                <div className="panel-body">
                  <form
                    className="form-horizontal"
                    //onSubmit={handleSubmit(this.formSubmit.bind(this))}
                  >
                    {/* {this.state.showGeracaoTaxa && (
                      <div className="row">
                        <div className="col-md-3">
                          {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                            return perfil === "ALIAS";
                          }) > -1 && (
                            <Field
                              name="credor"
                              label="Financeira"
                              blank={true}
                              data={this.props.allCompanies}
                              textField="cnpj"
                              valueField="cnpj"
                              required={true}
                              component={SelectField}
                              type="text"
                            />
                          )}
                          {this.props.companies && this.props.companies.length > 1 ? (
                            <Field name="credor" label="Financeira" blank={true} data={this.props.companies} textField="cnpj" valueField="cnpj" required={true} component={SelectField} type="text" />
                          ) : this.props.companies[0] ? (
                            <Field name="credor" label="Financeira" data={this.props.companies} textField="cnpj" valueField="cnpj" component={SelectField} type="text" />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-3">
                          <Field name="chassi" label="Chassi" component={TextField} type="text" required={true} maxlength={17} />
                        </div>
                        <div className="col-md-2">
                          <Field
                            name="dataContrato"
                            label="Data do Contrato"
                            validate={[FieldValidation.date]}
                            required={true}
                            normalize={FieldNormalize.DATE}
                            placeholder="00/00/0000"
                            data={this.props.dataGravame}
                            component={TextDatePickerField}
                            type="text"
                          />
                        </div>
                        <div className="col-md-2">
                          <Field name="numGravame" label="Número do Gravame" component={TextField} type="text" required={true} maxlength={8} normalize={FieldNormalize.NUMBER} />
                        </div>
                        <div className="col-lg-2 align-self-end form-group">
                          <button className="btn btn-info" onClick={handleSubmit(this.formSubmit.bind(this))}>
                            Gerar Taxa
                          </button>
                        </div>
                      </div>
                    )} */}
                    <div className="m-b-md">
                      <h4>Filtros</h4>
                      <small>Pesquise as taxas pelos filtros abaixo.</small>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <Field name="findCredor" label="Financeira" blank={true} data={this.props.allCompanies} textField="razaoSocialCnpj" valueField="id" component={SelectField} type="text" />
                      </div>
                      <div className="col-md-3">
                        <Field
                          name="findChassi"
                          label="Chassi"
                          component={TextField}
                          type="text"
                          maxlength={17}
                          required={this.props.selectedStatus && (this.props.selectedStatus === "0,1,2" || this.props.selectedStatus === "1") && !this.props.selectedContrato ? true : false}
                        />
                      </div>
                      {/* <div className="col-md-2">
                                                <Field name="findContrato" label="Contrato" component={TextField} type="text" required={this.props.selectedStatus && (this.props.selectedStatus === '0,1,2' || this.props.selectedStatus === '1') && !this.props.selectedChassi ? true : false}/>
                                            </div> */}
                      <div className="col-md-2">
                        <Field
                          name="findStatus"
                          label="Status"
                          data={[
                            { id: "0,1,2", desc: "Todos" },
                            { id: 0, desc: "Em aberto" },
                            { id: 1, desc: "Registrado" },
                            { id: 2, desc: "Com erro" },
                          ]}
                          component={SelectField}
                          type="text"
                          textField="desc"
                          valueField="id"
                        />
                      </div>
                      <div className="col-lg-2 align-self-end form-group">
                        <button className="btn btn-info" onClick={this.props.handleSubmit(this.filtrar.bind(this))} disabled={this.state.pesquisando} id="filterTaxas">
                          {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">Taxas cadastradas</div>
                <div className="panel-body">
                  <div className="table-responsive">
                    {this.props.all && this.props.all.length > 0 ? (
                      <ReactTable
                        className="-striped -highlight"
                        defaultPageSize={20}
                        data={this.props.all}
                        loadingText="Carregando..."
                        noDataText="Nenhuma Informação encontrada"
                        rowsText="linhas"
                        showPageSizeOptions={false}
                        minRows={1}
                        showPagination={true}
                        previousText="Anterior"
                        nextText="Próximo"
                        pageText="página"
                        ofText="de"
                        columns={[
                          {
                            Header: "Financeira",
                            accessor: "razaoSocial",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                          },
                          {
                            Header: "Chassi",
                            accessor: "rowContratoChassi",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 145,
                            Cell: (rowContratoChassi) => {
                              return (
                                <div>
                                  <span>{rowContratoChassi.original.chassi}</span>
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Dt Gera./Venc.",
                            accessor: "rowData",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 120,
                            Cell: (rowData) => {
                              return (
                                <div>
                                  <span>{MaskDate.TO_BR(rowData.original.dataGeracao)}</span>
                                  <br />
                                  <span>{MaskDate.TO_BR(rowData.original.dataVencimento)}</span>
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Valor",
                            accessor: "valorDetran",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 100,
                          },
                          {
                            Header: "Cód ident Pag",
                            accessor: "codIdentPag",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 150,
                          },
                          {
                            Header: "Status Envio Banco",
                            accessor: "statusEnvioBanco",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 150,
                          },
                          {
                            Header: "Dt Estado Pag Banco",
                            accessor: "rowDataEstadoPag",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 150,
                            Cell: (rowDataEstadoPag) => {
                              return (
                                <div>
                                  <span>{MaskDate.TO_BR(rowDataEstadoPag.original.dataEstadoPagBanco)}</span>
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Direcionado Por",
                            accessor: "direcionadoPor",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 130,
                          },
                          // {
                          //   Header: "Linha Digitável",
                          //   accessor: "rowValor",
                          //   className: "align-middle text-center",
                          //   headerClassName: "font-extra-bold",
                          //   width: 400,
                          //   Cell: (rowValor) => (
                          //     <div>
                          //       {rowValor.original.idRemessa ? (
                          //         <a
                          //           href="#"
                          //           onClick={() => this.downloadRemessa(rowValor.original.idRemessa, rowValor.original.aliasOuThoth)}
                          //           title="Clique para baixar o arquivo de remessa."
                          //           style={{ textDecoration: "underline" }}
                          //         >
                          //           {rowValor.original.aliasOuThoth}-REM-{rowValor.original.idRemessa}
                          //         </a>
                          //       ) : rowValor.original.status === 11 && rowValor.original.mensagemCobranca ? (
                          //         <span title={rowValor.original.mensagemCobranca} style={{ color: "red" }}>
                          //           {rowValor.original.linhaDigitavel}
                          //         </span>
                          //       ) : (
                          //         rowValor.original.linhaDigitavel
                          //       )}
                          //     </div>
                          //   ),
                          // },
                          {
                            Header: "Ação",
                            accessor: "row",
                            className: "align-middle text-center",
                            headerClassName: "font-extra-bold",
                            width: 190,
                            Cell: (row) => (
                              <div className="btn-group">
                                {!row.original.idRemessa &&
                                  MaskDate.IS_AFTER(new Date(), row.original.dataVencimento) &&
                                  row.original.idContrato &&
                                  row.original.status === 11 &&
                                  _.findIndex(this.props.currentUser.perfis, function (perfil) {
                                    return perfil === "ALIAS";
                                  }) > -1 && (
                                    <Link
                                      className="btn btn-default btn-sm"
                                      onClick={() =>
                                        this.onRegeraTaxa(
                                          this.setState({
                                            idCobranca: row.original.id,
                                          })
                                        )
                                      }
                                    >
                                      {" "}
                                      <SvgIcon size={22} icon={library} title="Regerar taxa" />
                                      {"Regerar Taxa"}
                                    </Link>
                                  )}
                                {row.original.statusEnvioBanco === null &&
                                  row.original.idContrato &&
                                  (row.original.status === 11 || row.original.status === 90) &&
                                  _.findIndex(this.props.currentUser.perfis, function (perfil) {
                                    return perfil === "ALIAS";
                                  }) > -1 && (
                                    <button className="btn btn-default btn-sm" onClick={() => this.onSendRegister(row.original.idContrato)} disabled={this.state.disabledBtnRegPag}>
                                      {" "}
                                      Registrar Pagamento{" "}
                                    </button>
                                  )}
                                {row.original.idContrato &&
                                  row.original.status === 90 &&
                                  _.findIndex(this.props.currentUser.perfis, function (perfil) {
                                    return perfil === "ALIAS";
                                  }) > -1 && (
                                    <Link style={{ color: "#ff0000" }} title={JSON.parse(row.original.mensagem)[0].descricao}>
                                      {" "}
                                      <SvgIcon size={22} icon={ic_warning} />
                                    </Link>
                                  )}
                                {row.original.idContrato &&
                                  row.original.mensagem &&
                                  JSON.parse(row.original.mensagem)[0].descricao === "Taxa não quitada" &&
                                  _.findIndex(this.props.currentUser.perfis, function (perfil) {
                                    return perfil === "ALIAS";
                                  }) > -1 && (
                                    <Link style={{ color: "#ff8300" }} title={JSON.parse(row.original.mensagem)[0].descricao}>
                                      {" "}
                                      <SvgIcon size={22} icon={ic_warning} />
                                    </Link>
                                  )}
                              </div>
                            ),
                          },
                        ]}
                      />
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: TAXA_DETRAN_LIST_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: TAXA_DETRAN_LIST_UNLOAD, payload }),
  onCompaniesLoad: (payload) => dispatch({ type: TAXA_DETRAN_ALL_COMPANIES_LOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onActionOpen: (payload) => dispatch({ type: CONFIRM_ACTION_OPEN, payload }),
});

const selector = formValueSelector("TaxasDetranForm");
const mapStateToProps = (state) => ({
  ...state.taxasDetran,
  companies: state.common.companies,
  currentUser: state.common.currentUser,
  selectedStatus: selector(state, "findStatus"),
  selectedChassi: selector(state, "findChassi"),
  selectedContrato: selector(state, "findContrato"),
  initialValues: { findStatus: "0" },
});

const form = reduxForm({
  form: "TaxasDetranForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(TaxasDetran));
