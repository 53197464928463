import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, SubmissionError, untouch, change } from "redux-form";
import { Link } from "react-router";

import { ListGroup, ListGroupItem } from "react-bootstrap";
import { toastr } from "react-redux-toastr";

import ReactTable from "react-table";

import TextField from "../../components/Fields/TextField";
import SelectField from "../../components/Fields/SelectField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import MaskDate from "../../components/Util/MaskDate";

import Title from "../../components/Layout/Title";
import { APROVE_COMPANIES_LOAD, APROVE_REGISTERED_LOAD, APROVE_UNLOAD, UF_SYSTEM_LOAD, LOGOUT } from "../../actions/Constants";
import api from "../../api/apiUtil";
import _ from "lodash";
import DropdownListField from "../../components/Fields/DropdownListField";

import { filePdf } from "react-icons-kit/icomoon/filePdf";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import SvgIcon from "react-icons-kit";
import jsPDF from "jspdf";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import { BeatLoader } from "react-spinners";

const myDateBefore15Days = new Date();
myDateBefore15Days.setDate(myDateBefore15Days.getDate() - 60);

const columns = [
  {
    Header: () => (
      <span title="Quantidade de e-emails já enviados para notificar o erro do contrato">
        <i style={{ fontSize: "18px" }} className="far fa-envelope-open"></i>
      </span>
    ),
    accessor: "rowQtdEmailsErro",
    width: 40,
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (rowQtdEmailsErro) =>
      rowQtdEmailsErro.original.qtdEmailsErro && (
        <span title="Quantidade de e-emails já enviados para notificar o erro do contrato">
          <div>{rowQtdEmailsErro.original.qtdEmailsErro}</div>
        </span>
      ),
  },
  {
    Header: "Status",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    accessor: "status",
    width: 70,
    Cell: (row) => (
      <span>
        <span
          style={{
            color:
              row.value === 0 || row.value === 8
                ? "#6A6C6F"
                : row.value === 1 || row.value === 9
                ? "#b9a463"
                : row.value === 3 || row.value === 4
                ? "#28A745"
                : row.value === 7
                ? "#a1ed09"
                : row.value === 2
                ? "#FFC107"
                : row.value === 5
                ? "#00b7ff"
                : row.value === 6
                ? "#007BFF"
                : row.value === 11
                ? "#cb09ed"
                : row.value === 90 || row.value === 10
                ? "#DC3545"
                : "#57d500",
            transition: "all .3s ease",
          }}
        >
          {row.original.idContratoAnterior ? (
            <span title={row.original.status == 90 ? row.original.msgComunicacaoDetran : "Averbação"} key="averbacao" style={{ fontSize: "28px" }}>
              <i className="fa fa-file-alt"></i>
            </span>
          ) : (
            <span title={row.original.status == 90 ? row.original.msgComunicacaoDetran : "Contrato"} key="contrato" style={{ fontSize: "28px" }}>
              <i className="fa fa-file"></i>
            </span>
          )}
        </span>
      </span>
    ),
  },
  {
    Header: "UF",
    accessor: "uf",
    width: 50,
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Contrato",
    accessor: "numero",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
  },
  {
    Header: "Credor",
    accessor: "row",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div>
        {row.original.razaoSocial}
        <br />
        <small>{FieldNormalize.CNPJ(row.original.cnpj, row.original.cnpj)}</small>
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Devedor",
    accessor: "row",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div>
        {row.original.devNome}
        <br />
        {row.original.devCpfCnpj}
      </div>
    ), // Custom cell components!
  },
  {
    Header: "Chassi",
    accessor: "chassi",
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="align-middle text-center">
        {row.original.chassi.split(",").map((item) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  {
    Header: "Gravame",
    accessor: "row",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="align-middle text-center">
        {row.original.numGravame.split(",").map((item, index) => {
          return (
            <span>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    ),
  },
  {
    Header: "", // Custom header components!
    className: "align-middle text-center",
    headerClassName: "font-extra-bold",
    Cell: (row) => (
      <div className="btn-group-vertical">
        <Link className="btn btn-sm btn-default" to={`/alias/contract/view/${row.original.id}`}>
          <i className="fa fa-info-circle"></i> Detalhes
        </Link>
      </div>
    ),
  },
];

class Aprove extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLegend: false,
      showFilters: false,
      pesquisando: false,
    };
    this.myRef = null;
    this.myRefFilters = null;
    this.myRefDivSuperior = null;

    document.addEventListener("keyup", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    if (event.keyCode === 13 && document.getElementById("filterAprove")) {
      document.getElementById("filterAprove").click();
    }
  };

  componentDidMount() {
    if (this.props.currentUser.tipo && this.props.currentUser.tipo.includes("P")) {
      Promise.resolve(api.Financial.recuperaUfsParceiro(this.props.currentUser.idParceiro)).then((all) => {
        this.props.onUfSystemLoad([{ sigla: "Todas" }, ...all.ufs]);
      });
      Promise.resolve(api.Financial.filtroPorUf(null, this.props.currentUser.idParceiro)).then((all) => {
        this.props.onCompaniesLoad(all);
      });
    } else {
      this.props.onCompaniesLoad(api.Companies.all());
      Promise.resolve(api.Ufs.sigla_system()).then((all) => {
        var todas = {
          sigla: "Todas",
        };
        all.unshift(todas);
        this.props.onUfSystemLoad(all);
      });
    }

    let filtros = JSON.parse(window.localStorage.getItem("filtros"));
    if (filtros) {
      this.preencheFiltros(filtros);
      this.filterR(filtros);
    } else {
      let dataI, dataF, credor, uf;
      if (this.props.params.dataI) {
        dataI = this.props.params.dataI;
      }
      if (this.props.params.dataF) {
        dataF = this.props.params.dataF;
      }
      if (this.props.params.credor && this.props.params.credor != null && this.props.params.credor != "0") {
        credor = this.props.params.credor;
      }
      if (this.props.params.uf && this.props.params.uf != null && this.props.params.uf.length === 2) {
        uf = this.props.params.uf;
      }

      Promise.resolve(
        api.Contracts.getRegistered(
          credor ? credor : null,
          dataI ? "2" : "90",
          null,
          null,
          null,
          null,
          null,
          dataI && dataF ? MaskDate.TO_DATABASE_DATE(dataI) : dataI ? null : MaskDate.TO_DATABASE_DATE(myDateBefore15Days), //Logica para funcionar com os redirecionamentos
          dataI && dataF ? MaskDate.TO_DATABASE_DATE(dataF) : dataI ? MaskDate.TO_DATABASE_DATE(dataI) : null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          dataI ? "0" : "1",
          uf,
          null,
          this.props.currentUser.idParceiro
        )
      ).then((results) => {
        this.props.onRegisteredLoad(results.contratos);
      });
    }
  }

  preencheFiltros(values) {
    this.props.dispatch(change("AproveForm", "chassi", values.chassi));
    this.props.dispatch(change("AproveForm", "credor", values.credor));
    this.props.dispatch(change("AproveForm", "uf", values.uf));
    this.props.dispatch(change("AproveForm", "numero", values.numero));
    this.props.dispatch(change("AproveForm", "numGravame", values.numGravame));
    this.props.dispatch(change("AproveForm", "devCpfCnpj", values.devCpfCnpj));
    this.props.dispatch(change("AproveForm", "status", values.status));
    this.props.dispatch(change("AproveForm", "renavam", values.renavam));
    this.props.dispatch(change("AproveForm", "registro", values.registro));
    this.props.dispatch(change("AproveForm", "tpPesquisaData", values.tpPesquisaData));
    this.props.dispatch(change("AproveForm", "dtInicial", values.dtInicial));
    this.props.dispatch(change("AproveForm", "dtFinal", values.dtFinal));
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyDownHandler);
    this.props.onUnload();
  }

  async filterUf(value) {
    const { reset } = this.props;
    var uf = value.uf;
    Promise.resolve(
      api.Contracts.getRegistered(
        null,
        "90",
        null,
        null,
        null,
        null,
        null,
        MaskDate.TO_DATABASE_DATE(myDateBefore15Days),
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        "2",
        value.uf,
        null,
        this.props.currentUser.idParceiro
      )
    ).then((results) => {
      this.props.onRegisteredLoad(results.contratos);
    });
    if (this.props.anyTouched) reset();
    this.props.dispatch(change("AproveForm", "uf", uf));
  }

  async filterR(values) {
    if (values && !values.numGravame && !values.numero && !values.devCpfCnpj && !values.renavam && !values.chassi && !values.dtInicial && !values.dtFinal) {
      toastr.info("Aviso", "É necessário preencher algum campo digitável para realizar a pesquisa.");
    } else {
      this.setState({ pesquisando: true });
      this.armazenaFiltros(values);
      toastr.info("Aguarde", "Realizando consulta...", {
        timeOut: 0,
        component: () => {
          return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
        },
      });
      Promise.resolve(
        api.Contracts.getRegistered(
          values.credor ? values.credor.cnpj : null,
          values.status ? values.status : "2,0,1,90,8,10,5,6,11,3,4,7",
          values.registro,
          values.numero,
          values.devCpfCnpj,
          values.renavam,
          values.chassi ? values.chassi.trim() : values.chassi,
          MaskDate.TO_DATABASE_DATE(values.dtInicial),
          MaskDate.TO_DATABASE_DATE(values.dtFinal),
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          values.tpPesquisaData,
          values.uf,
          values.numGravame,
          this.props.currentUser.idParceiro
        )
      ).then((results) => {
        this.props.onRegisteredLoad(results.contratos);
        if (!this.props.registered || this.props.registered.length == 0) {
          toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
        }
      });
      this.setState({ pesquisando: false });
      toastr.removeByType("info");
    }
  }

  armazenaFiltros(values) {
    let filtros = {
      chassi: values.chassi,
      credor: values.credor,
      uf: values.uf,
      numero: values.numero,
      numGravame: values.numGravame,
      devCpfCnpj: values.devCpfCnpj,
      status: values.status,
      renavam: values.renavam,
      tpPesquisaData: values.tpPesquisaData,
      dtInicial: values.dtInicial,
      dtFinal: values.dtFinal,
      registro: values.registro,
    };
    window.localStorage.setItem("filtros", JSON.stringify(filtros));
  }

  renderRegistered() {
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.registered}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={columns}
        defaultPageSize={10}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  handleShowFilters() {
    if (this.state.showFilters) {
      this.props.dispatch(change("ContractListForm", "tpPesquisaData", null));
      this.props.dispatch(untouch("ContractListForm", "tpPesquisaData"));
      this.props.dispatch(change("ContractListForm", "dtInicial", null));
      this.props.dispatch(untouch("ContractListForm", "dtInicial"));
      this.props.dispatch(change("ContractListForm", "dtFinal", null));
      this.props.dispatch(untouch("ContractListForm", "dtFinal"));
    }
    this.setState({ showFilters: !this.state.showFilters });
    this.handleAutoScroll("filters");
  }

  handleAutoScroll(pos) {
    if (pos === "table") {
      window.scrollTo(600, 600);
    } else if (pos === "filters") {
      window.scrollTo(0, this.myRefFilters.offsetTop - 5);
    }
  }

  exportToPDF() {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();

    doc.page = 1;
    doc.setFontSize(12);

    var startY = 50;

    var footer = function () {
      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    var title = "Relatório de Contratos";
    var fileName = "RelatorioContratosVisaoAlias";
    var content = {};
    var body = [];
    var head = [
      [
        { content: "UF (Detran)" },
        { content: "Nome do Credor" },
        { content: "Registro" },
        { content: "Nr Contrato" },
        { content: "Chassi" },
        { content: "Gravame" },
        // { content: "Placa " },
        { content: "CPF Devedor" },
        { content: "Nome Devedor" },
        { content: "Situação" },
        { content: "Espécie/Tipo" },
      ],
    ];
    var styles = {
      cellPadding: 1.5,
      overflow: "linebreak",
      valign: "middle",
      halign: "center",
      lineColor: [0, 0, 0],
      lineWidth: 0.2,
      fontSize: 10,
    };

    body = this.props.registered.map((row) => [
      row.uf,
      row.razaoSocial,
      row.numRegistro,
      row.numero,
      row.chassi.split(",").join(", "),
      row.numGravame.split(",").join(", "),
      // row.ufPlaca
      //   .split(",")
      //   .map((item, index) => {
      //     return item + " " + row.numPlaca.split(",")[index];
      //   })
      //   .join(", "),
      FieldNormalize.CPF_CNPJ(row.devCpfCnpj),
      row.devNome,
      row.status === 0 || row.status === 8
        ? "Contrato Criado"
        : row.status === 1 || row.status === 9
        ? "Contrato Aguardando Detran"
        : row.status === 2
        ? "Contrato Registrado"
        : row.status === 3
        ? "Imagem do Contrato Enviada"
        : row.status === 5
        ? "Contrato Baixado"
        : row.status === 6
        ? "Contrato Cancelado"
        : row.status === 7
        ? "Contrato Averbado"
        : row.status === 11
        ? "Contrato Em Processamento"
        : row.status === 90
        ? "Erro no registro do Contrato"
        : "Não tratado",
      row.especieVeiculo.split(",").join(", "),
    ]);

    styles.fontSize = 8;

    content = {
      startY,
      head,
      body,
      bodyStyles: { valign: "top" },
      styles,
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.save(`${fileName}.pdf`);
  }

  exportToCSV = (type) => {
    var head = [["UF (Detran)", "Nome do Credor", "Registro", "Nr Contrato", "Data Criação Contrato", "Chassi", "Gravame", "Placa", "CPF Devedor", "Nome Devedor", "Situação", "Espécie/Tipo"]];

    var ref = ["uf", "razaoSocial", "numRegistro", "numero", "dataCriacao", "chassi", "numGravame", "placa", "devCpfCnpj", "devNome", "status", "especieVeiculo"];

    var fileName = "RelatorioContratosVisaoAlias";

    var data = this.props.registered.map((row) => {
      return {
        uf: row.uf,
        razaoSocial: row.razaoSocial,
        numRegistro: row.numRegistro,
        numero: row.numero,
        dataCriacao: MaskDate.TO_BR_DATETIME(row.dataCriacao),
        chassi: row.chassi.split(",").join(", "),
        numGravame: row.numGravame.split(",").join(", "),
        placa: row.ufPlaca
          .split(",")
          .map((item, index) => {
            return item + " " + row.numPlaca.split(",")[index];
          })
          .join(", "),
        devCpfCnpj: FieldNormalize.CPF_CNPJ(row.devCpfCnpj),
        devNome: row.devNome,
        status:
          row.status === 0 || row.status === 8
            ? "Contrato Criado"
            : row.status === 1 || row.status === 9
            ? "Contrato Aguardando Detran"
            : row.status === 2
            ? "Contrato Registrado"
            : row.status === 3
            ? "Imagem do Contrato Enviada"
            : row.status === 5
            ? "Contrato Baixado"
            : row.status === 6
            ? "Contrato Cancelado"
            : row.status === 7
            ? "Contrato Averbado"
            : row.status === 11
            ? "Contrato Em Processamento"
            : row.status === 90
            ? "Erro no registro do Contrato"
            : "Não tratado",
        especieVeiculo: row.especieVeiculo.split(",").join(", "),
        erro: row.msgComunicacaoDetran && JSON.parse(row.msgComunicacaoDetran)[0] ? JSON.parse(row.msgComunicacaoDetran)[0].descricao : "",
      };
    });

    if (this.props.status == "90") {
      head[0].push("Mensagem Erro");
      ref.push("erro");
    } else {
      data.forEach((item) => {
        delete item.erro;
      });
    }

    var ws = XLSX.utils.aoa_to_sheet(head);
    XLSX.utils.sheet_add_json(ws, data, {
      header: ref,
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${fileName}.xlsx`);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Lista de contratos para homologação" />{" "}
        <div className="content">
          <div className="row" style={{ marginRight: "-40px", marginLeft: "-40px" }}>
            <div className="col-md-12">
              <div className="hpanel">
                <div className="row">
                  <div className="col-md-2">
                    <Field name="uf" label="UF" data={this.props.ufSystem} textField="sigla" component={SelectField} type="text" />
                  </div>
                  <div className="col-lg-1 align-self-end form-group">
                    <button className="btn btn-success" onClick={handleSubmit(this.filterUf.bind(this))} type="button">
                      Filtrar
                    </button>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="m-b-md">
                    <h4>Filtros</h4>
                    <small>Pesquise os veículos pelos filtros abaixo.</small>
                  </div>
                  <form>
                    <div className="row" ref={(ref) => (this.myRefFilters = ref)}>
                      <div className="col-md-4">
                        <Field name="numero" label="Número do contrato" component={TextField} type="text" inline={false} />
                      </div>
                      <div className="col-md-4">
                        <Field name="chassi" label="Chassi" validate={[FieldValidation.chassi]} component={TextField} type="text" inline={false} normalize={FieldNormalize.CHASSI} />
                      </div>
                      <div className="col-md-4">
                        <Field name="numGravame" label="Gravame" maxlength={8} normalize={FieldNormalize.NUMBER} component={TextField} type="text" />
                      </div>
                    </div>
                    {this.state.showFilters && (
                      <div className="row">
                        <div className="col-md-8">
                          <Field
                            name="credor"
                            blank={true}
                            label="Credor"
                            credor={true}
                            data={this.props.companies}
                            textField="razaoSocial"
                            valueField="id"
                            component={DropdownListField}
                            type="text"
                          />
                        </div>
                        <div className="col-md-4">
                          <Field name="renavam" label="Renavam" component={TextField} type="text" inline={false} />
                        </div>
                        <div className="col-md-4">
                          <Field name="devCpfCnpj" label="CPF/CNPJ do Devedor" normalize={FieldNormalize.CPF_CNPJ} component={TextField} type="text" inline={false} />
                        </div>
                        <div className="col-md-4">
                          <Field
                            name="status"
                            label="Status"
                            data={[
                              { id: "2,0,1,90,8,9,10,5,6,11,3,4,7", desc: "Todos" },
                              { id: "0,8", desc: "Não Enviado" },
                              { id: "11", desc: "Em Processamento" },
                              { id: "1,9", desc: "Aguardando DETRAN" },
                              { id: "2", desc: "Registrado sem imagem" },
                              { id: "3,4,7", desc: "Registrado" },
                              { id: "90", desc: "Registro com erro" },
                              { id: "5", desc: "Baixado" },
                              { id: "6", desc: "Cancelado" },
                            ]}
                            component={SelectField}
                            type="text"
                            textField="desc"
                            valueField="id"
                            description="Status do registro do veículo"
                          />
                        </div>
                        <div className="col-md-4">
                          <Field name="registro" label="Número de registro" component={TextField} type="text" inline={false} />
                        </div>
                        <div className="col-md-4">
                          <Field
                            name="tpPesquisaData"
                            label="Pesquisar por"
                            blank={true}
                            data={[
                              { id: 0, desc: "Data de Registro" },
                              { id: 1, desc: "Data de Cadastro" },
                              { id: 2, desc: "Data do Contrato" },
                            ]}
                            component={SelectField}
                            type="text"
                            textField="desc"
                            valueField="id"
                          />
                        </div>
                        <div className="col-md-4">
                          <Field
                            name="dtInicial"
                            label="Data de início"
                            validate={[FieldValidation.date]}
                            normalize={FieldNormalize.DATE}
                            placeholder="00/00/0000"
                            data={this.props.dtInicial}
                            component={TextDatePickerField}
                            type="text"
                            inline={false}
                          />
                        </div>
                        <div className="col-md-4">
                          <Field
                            name="dtFinal"
                            label="Data de fim"
                            validate={[FieldValidation.date]}
                            normalize={FieldNormalize.DATE}
                            placeholder="00/00/0000"
                            data={this.props.dtFinal}
                            component={TextDatePickerField}
                            type="text"
                            inline={false}
                          />
                        </div>
                      </div>
                    )}
                  </form>
                  <div className="text-right">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => {
                        this.handleShowFilters();
                      }}
                    >
                      {this.state.showFilters ? "Ocultar" : "+"} Filtros
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-success"
                      disabled={this.state.pesquisando}
                      type="button"
                      id="filterAprove"
                      onClick={this.props.handleSubmit((values) => {
                        return new Promise((resolve, reject) => {
                          const errors = validate(values);
                          if (errors && !_.isEmpty(errors)) {
                            reject(new SubmissionError(errors));
                          } else {
                            if (!this.state.showFilters) {
                              const { numero, chassi, numGravame } = values;
                              const smallFilterValues = {
                                numero,
                                chassi,
                                numGravame,
                              };
                              this.filterR(smallFilterValues);
                            } else {
                              this.filterR(values);
                            }
                            this.handleAutoScroll("table");
                            resolve();
                          }
                        });
                      })}
                    >
                      {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="panel-heading m-b-md">
                <div className="row">
                  <div className="col-md-3">
                    <button className="btn btn-secondary btn-sm" onClick={() => this.setState({ showLegend: !this.state.showLegend })}>
                      {this.state.showLegend ? "Ocultar" : "Mostrar"} Legenda
                    </button>
                  </div>
                </div>
                {this.state.showLegend && <br></br>}
                {this.state.showLegend && (
                  <div className="row">
                    <div className="col-md-3">
                      Tipo:
                      <ListGroup>
                        <ListGroupItem>
                          <span style={{ color: "#6A6C6F" }}>
                            <span style={{ fontSize: "20px" }}>
                              <i className="fa fa-file"></i>
                            </span>{" "}
                            - Contrato
                          </span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <span style={{ color: "#6A6C6F" }}>
                            <span style={{ fontSize: "20px" }}>
                              <i className="fa fa-file-alt"></i>
                            </span>{" "}
                            - Averbação
                          </span>
                        </ListGroupItem>
                      </ListGroup>
                    </div>
                    <br />
                    <div className="col-md-9">
                      Status:
                      <ListGroup>
                        <div>
                          <ListGroupItem>
                            <i className="fa fas fa-square"> </i> - Não Enviado &nbsp;
                            <i className="fa fas fa-square" style={{ color: "#b9a463" }}>
                              {" "}
                            </i>{" "}
                            - Aguardando DETRAN &nbsp;
                            <i className="fa fas fa-square text-danger"></i> - Registro com erro
                          </ListGroupItem>
                          <ListGroupItem>
                            <i className="fa fas fa-square text-warning"></i> - Registrado sem imagem &nbsp;
                            <i className="fa fas fa-square text-success"></i> - Registrado &nbsp;
                            <i className="fa fas fa-square" style={{ color: "#a1ed09" }}></i> - Registrado (Averbado)
                            {/* &nbsp; <i className="fa fas fa-square text-info"></i> - Homologado */}
                          </ListGroupItem>
                          <ListGroupItem bsStyle="info">
                            <i className="fa fas fa-square" style={{ color: "#00b7ff" }}></i> - Baixado &nbsp;
                            <i className="fa fas fa-square text-primary"></i> - Cancelado &nbsp;
                            <i className="fa fas fa-square" style={{ color: "#cb09ed" }}></i> - Em Processamento
                          </ListGroupItem>
                        </div>
                      </ListGroup>
                    </div>
                  </div>
                )}
              </div>
              <div className="hpanel">
                <div className="panel-body">
                  <div className="stats-title float-left m-b-sm">
                    <h3>
                      Quantidade: <b>{this.props.registered ? this.props.registered.length : 0}</b>
                    </h3>
                  </div>
                  {this.props.registered && this.props.registered.length > 0 && (
                    <div className="panel-tools">
                      <Link className="btn btn-default btn-sm" onClick={() => this.exportToPDF()} title="Gerar PDF">
                        {" "}
                        <SvgIcon icon={filePdf} />
                      </Link>
                      <Link className="btn btn-default btn-sm" onClick={() => this.exportToCSV()} title="Gerar XLS">
                        {" "}
                        <SvgIcon icon={fileExcelO} />
                      </Link>
                    </div>
                  )}
                  <div className="table-responsive" ref={(ref) => (this.myRefDivSuperior = ref)}>
                    {this.renderRegistered()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if ((values.dtInicial || values.dtFinal) && !values.tpPesquisaData) {
    errors.tpPesquisaData = "Campo obrigatório quando alguma das datas são informadas.";
  }
  return errors;
}

const mapDispatchToProps = (dispatch) => ({
  onRegisteredLoad: (payload) => dispatch({ type: APROVE_REGISTERED_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: APROVE_UNLOAD }),
  onCompaniesLoad: (payload) => dispatch({ type: APROVE_COMPANIES_LOAD, payload }),
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.aprove,
  currentUser: state.common.currentUser,
  initialValues: {
    status: "90",
    tpPesquisaData: "1",
    dtInicial: MaskDate.TO_BR(myDateBefore15Days),
  },
  dtInicial: selector(state, "dtInicial"),
  dtFinal: selector(state, "dtFinal"),
  status: selector(state, "status"),
});

const selector = formValueSelector("AproveForm");
const form = reduxForm({
  form: "AproveForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Aprove));
