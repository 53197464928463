import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError, formValueSelector } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import TextField from "../../components/Fields/TextField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import SelectField from "../../components/Fields/SelectField";
import MaskDate from "../../components/Util/MaskDate";
import {
  UF_SYSTEM_LOAD,
  LOGOUT,
  FINANCEIRO_LIST_UNLOAD,
  FINANCEIRO_LIST_LOAD,
  CONFIRM_ACTION_OPEN,
  FINANCEIRO_FILES_TO_SEND,
  FINANCEIRO_FILE_SENT,
  FINANCEIRO_LOT_CLOSED,
} from "../../actions/Constants";
import api from "../../api/apiUtil";
import ReactTable from "react-table";
import { toastr } from "react-redux-toastr";
import MaskDecimal from "../../components/Util/MaskDecimal";
import { BeatLoader } from "react-spinners";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import SvgIcon from "react-icons-kit";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import jsPDF from "jspdf";
import Dropzone from "react-dropzone";

registerPlugin(FilePondPluginFileValidateType);

const months = [
  { label: "Janeiro", value: "0" },
  { label: "Fevereiro", value: "1" },
  { label: "Março", value: "2" },
  { label: "Abril", value: "3" },
  { label: "Maio", value: "4" },
  { label: "Junho", value: "5" },
  { label: "Julho", value: "6" },
  { label: "Agosto", value: "7" },
  { label: "Setembro", value: "8" },
  { label: "Outubro", value: "9" },
  { label: "Novembro", value: "10" },
  { label: "Dezembro", value: "11" },
];

const years = [
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
];

const situacaoConciliacao = [
  { label: "Aguardando", value: "0" },
  { label: "Conciliado", value: "2" },
  { label: "Inconsistente", value: "3" },
  { label: "Sem Dados Detran", value: "1" },
];

const data = new Date();

class Faturamento extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
      showAddFiles: false,
    };
    document.addEventListener("keyup", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    if (event.keyCode === 13 && document.getElementById("filterFat")) {
      document.getElementById("filterFat").click();
    }
  };

  componentDidMount() {
    this.props.onUfSystemLoad(api.Ufs.sigla_system());
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyDownHandler);
    this.props.onUnload();
  }

  async formSubmit(values) {
    var props = this.props;
    const errors = this.validateValues(values);
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    }
    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    Promise.resolve(
      api.Charging.recuperaFaturamentos(
        values.uf,
        parseInt(values.mesReferencia) + 1,
        values.anoReferencia,
        values.cnpj ? values.cnpj.replace(/[-./]/g, "") : null,
        values.razaoSocial,
        values.sitConciliacao
      )
    )
      .then((response) => {
        this.setState({ pesquisando: false });
        toastr.removeByType("info");
        if (response && response.length > 0) {
          props.onListLoad(response);
        } else {
          toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
          props.onListLoad();
        }
      })
      .catch(
        function (e) {
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (e.message === "Unauthorized" || e.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        }.bind(this)
      );
  }

  regerarFaturamento = (value) => (ev) => {
    ev.preventDefault();
    var props = this.props;
    var dados = {
      idCobranca: value,
    };
    Promise.resolve(api.Charging.regeraCobranca(dados))
      .then((response) => {
        toastr.success("Sucesso", "Faturamento regerado com sucesso");
        this.props.onListLoad(
          Promise.resolve(
            api.Charging.recuperaFaturamentos(
              props.selectedUf,
              parseInt(props.selectedMes) + 1,
              props.selectedAno,
              props.selectedCnpj ? props.selectedCnpj.replace(/[-./]/g, "") : null,
              props.selectedRazaoSocial
            )
          )
        );
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  handleClickOpen = (value) => () => {
    this.props.onActionOpen({
      description: `Confirma a reabertura da cobrança?`,
      onSuccess: this.abrirFaturamento(value).bind(this),
    });
  };

  abrirFaturamento = (value) => () => {
    var props = this.props;
    var dados = {
      idCobranca: value,
    };
    Promise.resolve(api.Charging.abrirCobranca(dados))
      .then((response) => {
        toastr.success("Sucesso", "Faturamento reaberto com sucesso");
        this.props.onListLoad(
          Promise.resolve(
            api.Charging.recuperaFaturamentos(
              props.selectedUf,
              parseInt(props.selectedMes) + 1,
              props.selectedAno,
              props.selectedCnpj ? props.selectedCnpj.replace(/[-./]/g, "") : null,
              props.selectedRazaoSocial
            )
          )
        );
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  validateValues(values) {
    const errors = {};
    if (!values.uf || values.uf.length === 0) {
      errors.uf = "Campo obrigatório";
    }
    return errors;
  }

  exportToCSV = (type) => {
    var head = [["UF", "Razão Social", "Período", "Registros", "Valor da Fatura", "Status", "Situação Pagamento", "Situação Conciliação", "Última atualização"]];

    var ref = ["uf", "razaoSocial", "periodo", "quantidade", "valorFatura", "status", "situacaoPagamento", "situacaoConciliacao", "dataConciliacao"];
    var fileName = "RelatorioFaturamento";

    var data = this.props.all.map((row) => {
      return {
        uf: row.uf,
        razaoSocial: row.razaoSocial,
        periodo: `${MaskDate.TO_BR(row.periodoInicio)} - ${MaskDate.TO_BR(row.periodoFim)}`,
        quantidade: row.quantidade,
        valorFatura: MaskDecimal.TO_BRL(row.valorTotal),
        status: row.status,
        situacaoPagamento: row.situacaoPagamento == 1 ? "Em andamento" : row.situacaoPagamento == 2 ? "Pago" : row.situacaoPagamento == 3 ? "Em aberto" : "",
        situacaoConciliacao:
          row.situacaoConciliacao == 0
            ? "Aguardando"
            : row.situacaoConciliacao == 1
            ? "Sem Dados Detran"
            : row.situacaoConciliacao == 2
            ? "Conciliado"
            : row.situacaoConciliacao == 3
            ? "Inconsistente"
            : "Não possuí",
        dataConciliacao: MaskDate.TO_BR_DATETIME(row.dataConciliacao),
      };
    });

    var ws = XLSX.utils.aoa_to_sheet(head);
    XLSX.utils.sheet_add_json(ws, data, {
      header: ref,
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${fileName}.xlsx`);
  };

  exportToPDF() {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 70; //70

    const headers = [["UF", "Razão Social", "CNPJ", "Período", "Qtd registros", "Valor da Fatura", "Status", "Situação do Pagamento", "Situação Conciliação", "Última atualização"]];
    const data = this.props.all.map((row) => [
      row.uf,
      row.razaoSocial,
      row.cnpj,
      MaskDate.TO_BR(row.periodoInicio) + " - " + MaskDate.TO_BR(row.periodoFim),
      row.quantidade,
      MaskDecimal.TO_BRL(row.valorTotal),
      row.status,
      row.situacaoPagamento == 1 ? "Em andamento" : row.situacaoPagamento == 2 ? "Pago" : row.situacaoPagamento == 3 ? "Em aberto" : "",
      row.situacaoConciliacao == 0
        ? "Aguardando"
        : row.situacaoConciliacao == 1
        ? "Sem Dados Detran"
        : row.situacaoConciliacao == 2
        ? "Conciliado"
        : row.situacaoConciliacao == 3
        ? "Inconsistente"
        : "Não possuí",
      MaskDate.TO_BR_DATETIME(row.dataConciliacao),
    ]);

    var footer = function () {
      //print number bottom right
      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      //pageBreak: 'always',
      columnStyles: {
        0: { columnWidth: "wrap" },
        1: {
          columnWidth: "wrap",
        },
        2: {
          columnWidth: "wrap",
        },
        3: {
          columnWidth: "wrap",
        },
      },
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text("Relatório de Faturamento", marginLeft, 30);
    doc.autoTable(content);
    doc.save(`RelatorioFaturamento.pdf`);
  }

  processHandler = (fieldName, file, metadata, load, error) => {
    Promise.resolve(api.Charging.sendLotAnexoCobranca(file))
      .then((response) => {
        this.props.onFileSent();
        load(1);
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else if (resp.response && resp.response.status === 400) {
          error("");
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  };

  serverOptions = {
    process: this.processHandler,
  };

  handleChangeSit = (param) => {
    if (param.target.value) {
      var props = this.props;
      var dados = {
        idCobranca: param.target.id,
        situacaoPagamento: param.target.value,
      };
      Promise.resolve(api.Charging.alteraSituacaoPag(dados))
        .then((response) => {
          toastr.success("Sucesso", "Situação do Pagamento alterada.");
          this.props.onListLoad(
            Promise.resolve(
              api.Charging.recuperaFaturamentos(
                props.selectedUf,
                parseInt(props.selectedMes) + 1,
                props.selectedAno,
                props.selectedCnpj ? props.selectedCnpj.replace(/[-./]/g, "") : null,
                props.selectedRazaoSocial
              )
            )
          );
        })
        .catch((e) => {
          if (e.message === "Unauthorized" || e.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        });
    }
  };

  inserirArquivoCobranca = (filesToUpload) => {
    var types = [];
    var alerta = "";
    if (this.props.selectedUf == "PB") {
      types.push("application/pdf");
      alerta = "É permitido anexar somente PDF.";
    } else if (this.props.selectedUf == "MG") {
      alerta = "É permitido anexar somente CSV.";
      types.push("application/vnd.ms-excel");
      types.push("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      types.push("text/csv");
    } else {
      alerta = "É permitido anexar somente TXT.";
      types.push("text/plain");
    }

    if (!filesToUpload[0] || types.indexOf(filesToUpload[0].type) < 0) {
      toastr.info("Atenção", alerta);
    } else {
      this.setState({ pesquisando: true });
      toastr.info("Aguarde", "Processando arquivo...", {
        timeOut: 0,
        component: () => {
          return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
        },
      });
      var props = this.props;
      Promise.resolve(api.Charging.sendArquivoCobrancaDetran(props.selectedUf, parseInt(props.selectedMes) + 1, props.selectedAno, filesToUpload[0]))
        .then((response) => {
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          toastr.success("Sucesso", response.message);
        })
        .catch(
          function (resp) {
            this.setState({ pesquisando: false });
            toastr.removeByType("info");
            if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
              api.Auth.logout();
              props.onLogout();
            } else if (resp && resp.response && resp.response.body) {
              toastr.error("Erro", resp.response.body.message);
            } else {
              toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
            }
          }.bind(this)
        );
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Gerenciamento de Faturamento para instituições financeiras" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    <Link className="btn btn-xs btn-info" onClick={() => this.setState({ showAddFiles: !this.state.showAddFiles })}>
                      <i className="far fa-folder-open" /> {this.state.showAddFiles ? "Ocultar" : "Exibir"} Arquivos para Processamento em lote
                    </Link>
                  </div>
                  <br />
                </div>
                {this.state.showAddFiles && (
                  <div className="panel-body">
                    <FilePond
                      allowMultiple={true}
                      labelIdle="Clique ou arraste os arquivos para upload"
                      labelFileProcessingComplete="Arquivo enviado"
                      labelFileLoading="Carregando"
                      labelFileProcessing="Enviando"
                      labelTapToCancel=""
                      allowRevert={false}
                      allowReplace={false}
                      files={this.props.files}
                      acceptedFileTypes={["application/pdf", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                      labelFileTypeNotAllowed="Tipo de arquivo inválido"
                      fileValidateTypeLabelExpectedTypes="Esperado um arquivo PDF ou Excel"
                      labelFileProcessingError="Erro ao processar o arquivo"
                      labelTapToRetry="Clique para tentar novamente"
                      onupdatefiles={(fileItems) => {
                        if (this.props.qttFilesToSend === this.props.sentFiles && fileItems.length > 0) {
                          this.props.onFilesToSend([fileItems.length, fileItems]);
                        }
                      }}
                      onprocessfile={(error, file) => {
                        if (this.props.sentFiles === this.props.qttFilesToSend) {
                          this.props.onLotClosed();
                          this.props.onUfSystemLoad(api.Ufs.sigla_system());
                          toastr.success("Sucesso", "Lote Processado com sucesso");
                        }
                      }}
                      server={this.serverOptions}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-2">
                        <Field name="uf" required={true} blank={true} label="UF" data={this.props.ufSystem} textField="sigla" component={SelectField} type="text" />
                      </div>
                      <div className="col-lg-2">
                        <Field name="mesReferencia" label="Mês de Referência" data={months} textField="label" valueField="value" component={SelectField} type="text" />
                      </div>
                      <div className="col-lg-2">
                        <Field name="anoReferencia" label="Ano de Referência" data={years} textField="label" valueField="value" component={SelectField} type="text" />
                      </div>
                      <div className="col-lg-2">
                        <Field name="sitConciliacao" label="Situação Conciliação" data={situacaoConciliacao} textField="label" valueField="value" component={SelectField} type="text" blank={true} />
                      </div>
                      <div className="col-md-2">
                        <Field name="cnpj" label="CNPJ" component={TextField} type="text" normalize={FieldNormalize.CNPJ} />
                      </div>
                      <div className="col-md-2">
                        <Field name="razaoSocial" label="Razão Social" component={TextField} type="text" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-1 align-self-end form-group">
                        <button className="btn btn-info" disabled={this.state.pesquisando} onClick={handleSubmit(this.formSubmit.bind(this))} id="filterFat">
                          {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                        </button>
                      </div>
                      {(this.props.selectedUf == "PB" || this.props.selectedUf == "MG" || this.props.selectedUf == "SP") && (
                        <div className="col-md-3">
                          {/* <Link className="btn btn-success" onClick={handleSubmit(this.inserirArquivoCobranca.bind(this))}>
                            Inserir arquivo cobrança Detran
                          </Link>
                          <Dropzone accept="application/pdf" name="file" className="dropzone-blank fallback" multiple={false} onDrop={this.onDrop.bind(this)}>
                          <Link className="btn btn-xs">
                            <i className="fas fa-upload" /> Inserir arquivo cobrança Detran
                          </Link>
                        </Dropzone> */}

                          <Dropzone
                            accept={this.props.selectedUf == "PB" ? "application/pdf" : this.props.selectedUf == "MG" ? ".csv" : ".txt"}
                            name="file"
                            className="dropzone-blank fallback"
                            multiple={false}
                            onDrop={(filesToUpload, e) => this.inserirArquivoCobranca(filesToUpload)}
                          >
                            <button type="button" className="btn btn-success" disabled={this.state.pesquisando}>
                              <i className="fas fa-upload" /> {this.state.pesquisando ? "Aguarde..." : "Inserir arquivo cobrança Detran"}
                            </button>
                          </Dropzone>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  {this.props.all && this.props.all.length > 0 && (
                    <div>
                      Total de faturamentos: {this.props.all.length}
                      <br /> <i className="far fa-clock" title="Aguardando" /> Aguardando Conciliação: {this.props.all.filter((item) => item.situacaoConciliacao == 0).length}
                      <br /> <i className="far fa-thumbs-up" title="Conciliado" color="#0ac70d" /> Conciliado: {this.props.all.filter((item) => item.situacaoConciliacao == 2).length}
                      <br /> <i className="far fa-thumbs-down" title="Inconsistente" color="#ff5b5b" /> Inconsistente: {this.props.all.filter((item) => item.situacaoConciliacao == 3).length}
                      <br /> <i className="far fa-envelope-open" title="Sem Dados Detran" color="#d4b000" /> Sem Dados Detran: {this.props.all.filter((item) => item.situacaoConciliacao == 1).length}
                      <br /> Não possuí: {this.props.all.filter((item) => item.situacaoConciliacao == null).length}
                    </div>
                  )}
                </div>
                <div className="panel-body">
                  {this.props.all && this.props.all.length > 0 && (
                    <div className="panel-tools">
                      <Link className="btn btn-default float-right" onClick={() => this.exportToPDF()} title="Gerar PDF">
                        {" "}
                        <SvgIcon size={20} icon={filePdf} />
                      </Link>
                      <Link className="btn btn-default btn-sm" onClick={() => this.exportToCSV()} title="Gerar XLS">
                        {" "}
                        <SvgIcon icon={fileExcelO} />
                      </Link>
                    </div>
                  )}
                  <div className="table-responsive">
                    <ReactTable
                      className="-striped -highlight"
                      defaultPageSize={50}
                      data={this.props.all && this.props.all.length > 0 ? this.props.all : []}
                      loadingText="Carregando..."
                      noDataText="Nenhuma Informação encontrada"
                      rowsText="linhas"
                      showPageSizeOptions={false}
                      minRows={1}
                      showPagination={true}
                      previousText="Anterior"
                      nextText="Próximo"
                      pageText="página"
                      ofText="de"
                      columns={[
                        {
                          Header: "UF",
                          accessor: "uf",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          width: 50,
                        },
                        {
                          Header: "Razão Social",
                          accessor: "razaoSocial",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                        },
                        {
                          Header: "CNPJ",
                          accessor: "cnpj",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          width: 200,
                        },
                        {
                          Header: "Período",
                          accessor: "rowData",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          width: 200,
                          Cell: (rowData) => {
                            return (
                              <div>
                                <span>{MaskDate.TO_BR(rowData.original.periodoInicio) + " - " + MaskDate.TO_BR(rowData.original.periodoFim)}</span>
                                <br />
                              </div>
                            );
                          },
                        },
                        {
                          Header: "Registros",
                          accessor: "quantidade",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          width: 100,
                        },
                        {
                          Header: "Valor da Fatura",
                          accessor: "rowValor",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          width: 130,
                          Cell: (rowValor) => <div>{MaskDecimal.TO_BRL(rowValor.original.valorTotal)}</div>,
                        },
                        {
                          Header: "Status",
                          accessor: "status",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          width: 100,
                        },
                        {
                          Header: "Sit. Conciliação",
                          accessor: "rowSitConc",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          width: 200,
                          Cell: (rowSitConc) => (
                            <div>
                              {rowSitConc.original.situacaoConciliacao == 0 ? (
                                <div>Aguardando</div>
                              ) : rowSitConc.original.situacaoConciliacao == 1 ? (
                                <div>Sem Dados Detran</div>
                              ) : rowSitConc.original.situacaoConciliacao == 2 ? (
                                <div>Conciliado</div>
                              ) : rowSitConc.original.situacaoConciliacao == 3 ? (
                                <div>Inconsistente</div>
                              ) : (
                                <div>Não possuí</div>
                              )}
                            </div>
                          ),
                        },
                        {
                          Header: "Última Atualização",
                          accessor: "rowDataConc",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          width: 200,
                          Cell: (rowDataConc) => {
                            return (
                              <div>
                                <span>{MaskDate.TO_BR_DATETIME(rowDataConc.original.dataConciliacao)}</span>
                              </div>
                            );
                          },
                        },
                        // {
                        //   Header: "Sit. Pagamento",
                        //   accessor: "rowSituacao",
                        //   className: "align-middle text-center",
                        //   headerClassName: "font-extra-bold",
                        //   width: 150,
                        //   Cell: (rowSituacao) => {
                        //     return this.props.currentUser.tipo === "F" ? (
                        //       <label>
                        //         <select value={rowSituacao.original.situacaoPagamento} id={rowSituacao.original.id} onChange={this.handleChangeSit.bind(this)}>
                        //           <option value=""></option>
                        //           <option value="1">Em andamento</option>
                        //           <option value="2">Pago</option>
                        //           <option value="3">Em aberto </option>
                        //         </select>
                        //       </label>
                        //     ) : rowSituacao.original.situacaoPagamento == 1 ? (
                        //       "Em andamento"
                        //     ) : rowSituacao.original.situacaoPagamento == 2 ? (
                        //       "Pago"
                        //     ) : rowSituacao.original.situacaoPagamento == 3 ? (
                        //       "Em aberto"
                        //     ) : (
                        //       ""
                        //     );
                        //   },
                        // },
                        {
                          Header: "Ação",
                          accessor: "row",
                          className: "align-middle text-center",
                          headerClassName: "font-extra-bold",
                          width: 100,
                          Cell: (row) => (
                            <div className="btn-group">
                              <Link className="btn btn-sm btn-default" title="Detalhar" to={`/alias/invoiceDetails/${row.original.id}/${row.original.situacaoConciliacao}`}>
                                <i className="fa fa-info-circle" />
                              </Link>
                              {row.original.status !== "FECHADO" && (
                                <Link className="btn btn-sm btn-default" title="Regerar Faturamento" onClick={this.regerarFaturamento(row.original.id)}>
                                  <i className="fas fa-sync" />
                                </Link>
                              )}

                              {row.original.status === "FECHADO" && (
                                <Link className="btn btn-sm btn-default" title="Abrir Faturamento" onClick={this.handleClickOpen(row.original.id)}>
                                  <i className="fas fa-exclamation-triangle" />
                                </Link>
                              )}
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: FINANCEIRO_LIST_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: FINANCEIRO_LIST_UNLOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onActionOpen: (payload) => dispatch({ type: CONFIRM_ACTION_OPEN, payload }),
  onFilesToSend: (payload) => dispatch({ type: FINANCEIRO_FILES_TO_SEND, payload }),
  onFileSent: (payload) => dispatch({ type: FINANCEIRO_FILE_SENT }),
  onLotClosed: (payload) => dispatch({ type: FINANCEIRO_LOT_CLOSED }),
});

const selector = formValueSelector("FaturamentoForm");
const mapStateToProps = (state) => ({
  ...state.financeiro,
  companies: state.common.companies,
  currentUser: state.common.currentUser,
  selectedUf: selector(state, "uf"),
  selectedMes: selector(state, "mesReferencia"),
  selectedAno: selector(state, "anoReferencia"),
  selectedCnpj: selector(state, "cnpj"),
  selectedRazaoSocial: selector(state, "razaoSocial"),
  initialValues: {
    mesReferencia: data.getMonth() - 1,
    anoReferencia: data.getFullYear(),
  },
});

const form = reduxForm({
  form: "FaturamentoForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Faturamento));
