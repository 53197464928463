import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError } from "redux-form";
import _ from "lodash";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";
//import TextField from '../../components/Fields/TextField';
import { BeatLoader } from "react-spinners";
import SelectField from "../../components/Fields/SelectField";
import { FINANCEIRO_PE_LIST_UNLOAD, FINANCEIRO_PE_LIST_LOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
class ConsultaFinanceiraPE extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.props.onUnload();
  }

  formSubmit(values) {
    var props = this.props;
    const errors = {};
    if (!values.mesInicio) {
      errors.mesInicio = "Campo obrigatório";
    }
    if (!values.anoInicio) {
      errors.anoInicio = "Campo obrigatório";
    }
    if (!values.mesFinal) {
      errors.mesFinal = "Campo obrigatório";
    }
    if (!values.anoFinal) {
      errors.anoFinal = "Campo obrigatório";
    }
    if (errors && !_.isEmpty(errors)) {
      toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
      throw new SubmissionError(errors);
    }

    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    var dados = {
      mesInicio: values.mesInicio,
      anoInicio: values.anoInicio,
      mesFinal: values.mesFinal,
      anoFinal: values.anoFinal,
      inadimplentes: values.inadimplentes,
    };
    Promise.resolve(api.Detrans.situacaoFinanceiraPE(dados))
      .then((res) => {
        this.props.onListLoad(res);
        this.setState({ pesquisando: false });
        toastr.removeByType("info");
        if (!res) {
          toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
        }
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  render() {
    const { handleSubmit } = this.props;
    const months = [
      { label: "Selecione", value: "" },
      { label: "Janeiro", value: "01" },
      { label: "Fevereiro", value: "02" },
      { label: "Março", value: "03" },
      { label: "Abril", value: "04" },
      { label: "Maio", value: "05" },
      { label: "Junho", value: "06" },
      { label: "Julho", value: "07" },
      { label: "Agosto", value: "08" },
      { label: "Setembro", value: "09" },
      { label: "Outubro", value: "10" },
      { label: "Novembro", value: "11" },
      { label: "Dezembro", value: "12" },
    ];

    const years = [
      { label: "Selecione", value: "" },
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
      { label: "2022", value: "2022" },
      { label: "2023", value: "2023" },
      { label: "2024", value: "2024" },
    ];
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Detran Pernambuco" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools"></div>
                  Realizar Consulta
                </div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-lg-2">
                        <Field name="mesInicio" label="Mês de início" data={months} textField="label" valueField="value" component={SelectField} type="text" />
                      </div>
                      <div className="col-lg-2">
                        <Field name="anoInicio" label="Ano de início" data={years} textField="label" valueField="value" component={SelectField} type="text" />
                      </div>
                      <div className="col-lg-2">
                        <Field name="mesFinal" label="Mês Fim" data={months} textField="label" valueField="value" component={SelectField} type="text" />
                      </div>
                      <div className="col-lg-2">
                        <Field name="anoFinal" label="Ano Fim" data={years} textField="label" valueField="value" component={SelectField} type="text" />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="inadimplentes"
                          label="Somente inadimplentes"
                          blank={false}
                          data={[
                            { id: "S", name: "Sim" },
                            { id: "N", name: "Não" },
                          ]}
                          component={SelectField}
                          type="text"
                          valueField="id"
                          textField="name"
                        />
                      </div>
                      <div className="col-lg-2 align-self-end form-group">
                        <button className="btn btn-info" type="submit" disabled={this.state.pesquisando}>
                          {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="panel-body">
                    <div className="row col-md-12">
                      <div id="res"></div>
                      {this.props.all && this.props.all.length > 0 ? this.ajax(this.props.all) : "Nenhum registro encontrado."}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  ajax(dados) {
    var html = `<ul class="list-group">`;
    for (let item of dados) {
      html += `<li class="list-group-item" style="border: none;">` + JSON.stringify(item).replace(/[,]+/g, "<br>") + "</li>";
    }
    html += "</ul>";
    document.getElementById("res").innerHTML = html;
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: FINANCEIRO_PE_LIST_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: FINANCEIRO_PE_LIST_UNLOAD, payload }),
});

const mapStateToProps = (state) => ({
  ...state.financeiroPE,
});

const form = reduxForm({
  form: "ConsultaFinanceiraPEForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ConsultaFinanceiraPE));
